export class Project {
    id: string;
    projectName: string;
    companyName: string;
    scaffoldNum: string;
    clientName: string;
    overdueScaffoldInspectionNum: number;
    sevenDueScaffoldInspectionNum: number;
    state: string;
    label: string;
    color: string;
    address: string;
    branchId: string;
    branchName: string;
    inspectors:Array<string>;
    
}

export class ProjectNumVo {
    activeNum: number;
    archivedNum: number;
    allNumber: number;
}
