import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Branch } from 'src/app/observable/model/branch';
import { ProjectRepository } from 'src/app/repository/project-repository';
import { InviteUser } from '../../../observable/model/user';
import { ToastRepository } from '../../../repository/toast-repository';
import { UserRepository } from '../../../repository/user-repository';

@Component({
    selector: 'app-invitation-email',
    templateUrl: './invitation-email.component.html',
    styleUrls: ['./invitation-email.component.less']
})
export class InvitationEmailComponent implements OnInit {
    inviteUser: InviteUser = new InviteUser();
    isLoading = false;
    branchList: Branch[] = [];

    constructor(private ngbActiveModal: NgbActiveModal, private userRepository: UserRepository, private projectRepository: ProjectRepository,
        public toastRepository: ToastRepository) {
    
        this.inviteUser.branches = [];
    }

    ngOnInit(): void {
        this.getBranchList();
    }

    getBranchList(): void {
        this.projectRepository.getBranch('').subscribe(result => {
            this.branchList = result.map(branch => {
                return {
                    ...branch,
                    checked: this.inviteUser.branches.includes(branch.id)
                };
            });
        });
    }

    selectBranchBox(item: Branch): void {
        if (item.checked) {
            this.inviteUser.branches.push(item.id);
        } else {
            const index = this.inviteUser.branches.indexOf(item.id);
            if (index > -1) {
                this.inviteUser.branches.splice(index, 1);
            }
        }
    }

    cancel(): void {
        this.ngbActiveModal.close();
    }

    selectAllBranches(): void {
        console.log("asfdafs");
        
        this.branchList.forEach(branch => {
            branch.checked = true;
            if (!this.inviteUser.branches.includes(branch.id)) {
                this.inviteUser.branches.push(branch.id);
            }
        });
    }

    restorePreviousBranches(): void {
        this.inviteUser.branches = [];  
        this.branchList.forEach(branch => {
            branch.checked = false;  
        });
    }

    onRoleTypeChange(): void {
        if (this.inviteUser.roleType === 1) {
            this.selectAllBranches();
        } else if (this.inviteUser.roleType === 2) {
            this.restorePreviousBranches();
        }
    }

    invite(): void {
        const isSuccess = this.check();
        if (!isSuccess) {
            return;
        }
        this.isLoading = true;
        this.userRepository.invite(this.inviteUser).subscribe(result => {
            if (!result) {
                this.isLoading = false;
                this.toastRepository.showDanger('Oops! Something went wrong, please try again.');
                return;
            }
            this.toastRepository.showSuccess('Invitation sent successfully.');
            this.ngbActiveModal.close();
        }, error => {
            this.isLoading = false;
        });
    }

    check(): boolean {
        if (!this.inviteUser.account) {
            this.toastRepository.showDanger('Email is required.');
            return false;
        }
        if (!this.inviteUser.roleType) {
            this.toastRepository.showDanger('Role is required.');
            return false;
        }
        return true;
    }
}
