import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastRepository} from '../../../repository/toast-repository';
import {NgxLoadingSpinnerService} from '@k-adam/ngx-loading-spinner';
import {UserRepository} from '../../../repository/user-repository';
import {UserForgot} from '../../../observable/model/user';

@Component({
    selector: 'app-forgot',
    templateUrl: './forgot.component.html',
    styleUrls: ['./forgot.component.less']
})
export class ForgotComponent implements OnInit {
    public userForgot = new UserForgot();

    constructor(
        private router: Router,
        public activatedRoute: ActivatedRoute,
        private spinnerService: NgxLoadingSpinnerService,
        private userRepository: UserRepository,
        private toastRepository: ToastRepository) {
        this.userForgot.account = activatedRoute.snapshot.queryParamMap.get("account") || '';
    }

    ngOnInit(): void {
    }

    sendForgotEmail(): void {
        this.userRepository.sendForgotEmail(this.userForgot).subscribe(result => {
            if (result) {
                this.router.navigate(['signup-code'], {
                    queryParams: {
                        account: this.userForgot.account,
                        token: result,
                        fromType: 'forgot'
                    }
                });
                this.toastRepository.showSuccess('Email verification code has been sent');
            } else {
                this.toastRepository.showDanger('Oops! Something went wrong, please try again.');
            }
        });
    }
    geToBack(): void{
        this.router.navigate(['/login'])
    }
}
