import {Component, Input, OnInit,} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-agreement-user',
    templateUrl: './agreement-user.component.html',
    styleUrls: ['./agreement-user.component.less']
})

export class AgreementUserComponent implements OnInit {
    type: string;
    constructor(private ngbActiveModal: NgbActiveModal) {

    }

    ngOnInit(): void {
        console.log(this.type,'..');
    }

    cancel(): void {
        this.ngbActiveModal.close();
    }


}

