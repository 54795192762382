<div class="container">
    <div class="top-bar">
        <h1>Projects</h1>
        <div class="right-bar">
            <div ngbDropdown *ngIf="jwtUser.role != 0">
                <button class="btn add" id="gfg" ngbDropdownToggle>Actions</button>
                <div ngbDropdownMenu="gfg">
                    <button ngbDropdownItem (click)="addProject()">Add Projects</button>
                    <!-- <button ngbDropdownItem [routerLink]="['/import']">Import Projects</button> -->
                </div>
            </div>
        </div>
    </div>
    <div class="content">
        <div class="sub-head-box">
            <div class="search">
                <input type="text" placeholder="Search by project..." [(ngModel)]="condition.name" (keypress)="searchKeywordByPress($event)" (blur)="getProjectList()">
                <i class="icon-search"></i>
            </div>

            <div class="filter-box">
                <div class="item" [ngClass]="{'active': condition.status==null|| condition.status==''}" (click)="statueSearchProjectList('')">All ({{ProjectNumVo.allNumber}})</div>
                <div class="item" [ngClass]="{'active': condition.status=='ACTIVATED'}" (click)="statueSearchProjectList('ACTIVATED')">Active ({{ProjectNumVo.activeNum}})</div>
                <div class="item" [ngClass]="{'active': condition.status=='ARCHIVED'}" (click)="statueSearchProjectList('ARCHIVED')">Archived ({{ProjectNumVo.archivedNum}})</div>
            </div>
        </div>

        <div class="table project-box">
            <table>
                <thead>
                    <tr>
                        <th class="id" style="width: 30%">
                            <div class="id-box">
                                <p> Projects <i class="icon icon-16 icon-title-down-arrow"
                                        [ngClass]="{'icon-title-down-arrow': currentSort['projectName'] == 'ASC' || !currentSort['projectName'],'icon-up-arrow':currentSort['projectName'] == 'DESC' }"
                                        (click)="sortProjectList('projectName')"></i></p>
                            </div>
                        </th>
                        <th class="text-left" >
                            <p>Customer <i class="icon icon-16 icon-title-down-arrow"
                                    [ngClass]="{'icon-title-down-arrow': currentSort['companyName'] == 'ASC' || !currentSort['companyName'],'icon-up-arrow':currentSort['companyName'] == 'DESC' }"
                                    (click)="sortProjectList('companyName')"></i> </p>
                        </th>
                        <th class="text-left">
                            <p>Branch   </p>
                        </th>
                        <th>
                            <p>Total Scaffolds <i class="icon icon-16 icon-title-down-arrow"
                                    [ngClass]="{'icon-title-down-arrow': currentSort['scaffoldNum'] == 'ASC' || !currentSort['scaffoldNum'],'icon-up-arrow':currentSort['scaffoldNum'] == 'DESC' }"
                                    (click)="sortProjectList('scaffoldNum')"></i> </p>
                        </th>
                        <th >
                            <p> Overdue<i class="icon icon-16 icon-title-down-arrow"
                                    [ngClass]="{'icon-title-down-arrow': currentSort['overdueScaffoldInspectionNum'] == 'ASC' || !currentSort['overdueScaffoldInspectionNum'],'icon-up-arrow':currentSort['overdueScaffoldInspectionNum'] == 'DESC' }"
                                    (click)="sortProjectList('overdueScaffoldInspectionNum')"></i></p>
                        </th>
                        <th >
                            <p>Due in 7 days <i class="icon icon-16 icon-title-down-arrow"
                                    [ngClass]="{'icon-title-down-arrow': currentSort['sevenDueScaffoldInspectionNum'] == 'ASC' || !currentSort['sevenDueScaffoldInspectionNum'],'icon-up-arrow':currentSort['sevenDueScaffoldInspectionNum'] == 'DESC' }"
                                    (click)="sortProjectList('sevenDueScaffoldInspectionNum')"></i></p>
                        </th>
                        <th *ngIf="jwtUser.role != 0">
                            <p></p>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let project of projectPage.records">
                        <td class="id">
                            <div class="id-box">
                                <span><i class="icon-24 icon"
                                        [ngClass]="{'icon-house':project.label == 'ff9b51e0','icon-construction':project.label == 'ff2f80ed','icon-bank':project.label == 'ff219653','icon-building':project.label == 'fff2994a'}"></i></span>
                                <div class="text-box">
                                    <h3 class="h3cursor" (click)="goScaffold(project)" title="{{project.projectName}}">
                                        {{project.projectName || '-----'}}</h3>
                                    <p title="{{project.clientName}}">{{project.clientName || '-----'}}</p>
                                </div>
                            </div>
                        </td>
                        <td class="text-left">
                            <p>{{project.companyName || '-----'}}</p>
                        </td>
                        <td>
                            <p>{{project.branchName ||  '-----'}}</p>
                        </td>
                        <td>
                            <p>{{project.state == "ARCHIVED" ? '-----' : project.scaffoldNum || '-----'}}</p>
                        </td>
                        <td>
                            <!--                            <p [ngClass]="{'green': project.overdueScaffoldInspectionNum>=7,'yellow':project.overdueScaffoldInspectionNum<7 && project.overdueScaffoldInspectionNum>0,'red': project.overdueScaffoldInspectionNum<=0}">-->
                            <!--                                {{project.overdueScaffoldInspectionNum}}</p>-->

                            <p>{{project.state == "ARCHIVED" ? '-----' : project.overdueScaffoldInspectionNum ||
                                '-----'}}</p>
                        </td>
                        <td>
                            <!--                            <p [ngClass]="{'green': project.sevenDueScaffoldInspectionNum>=7,'yellow':project.sevenDueScaffoldInspectionNum<7 && project.sevenDueScaffoldInspectionNum>0,'red': project.sevenDueScaffoldInspectionNum<=0}">-->
                            <!--                                {{project.sevenDueScaffoldInspectionNum}}</p>-->
                            <p>{{ project.state == "ARCHIVED"? '-----' : project.sevenDueScaffoldInspectionNum ||
                                '-----'}}</p>
                        </td>
                        <td class="end-right button-item" *ngIf="jwtUser.role != 0">
                            <a (click)="editProject(project)"><i class="icon icon-16 icon-edit-new"></i></a>
                          </td>
                    </tr>
                </tbody>
            </table>
            <ngb-pagination class="justify-content-center" *ngIf="projectPage.pages>1" [(page)]="projectPage.current" [pageSize]="projectPage.size" [maxSize]="5"
                [boundaryLinks]="false" (pageChange)="getProjectListByPage(projectPage.current)" [collectionSize]="projectPage.total">
            </ngb-pagination>
        </div>
    </div>
</div>