export class Order {
    id: string;
    orderType: number;
    orderNum: string;
    customerName: string;
    companyName: string;
    orderStatus: number;
    orderStatusName: string;
    boxNum: string;
    price: number;
    email:string;
    mobile:string;
    streetNumber: string;
    suburb: string;
    state: string;
    postCode: string;
    country: string;
}

export class BoxObj {
    id: string;
    boxNum: number;
    boxNumName: string;
    companyName: string;
}

export class OrderStatusNumVo {
    newOrderNum = 0;
    boxAssignedNum = 0;
    shippedNum = 0;
    completedNum = 0;
    archivedNum = 0;
}

export class BoxStatusNumVo {
    assignedNum: number;
    unassignedNum: number;
    allNumber: number;
}
export class BoxNumVo {
    tagActiveNum: number;
    tagReplacedLostNum: number;
    allNumber: number;
}

export class OrderInfo {
    boxNumber: number;
    orderType:number;
    price: number;
    companyId: string;
    recipientFullName: string;

    streetNumber: string;
    suburb: string;
    state: string;
    postCode: string;
    country: string;
    email:string;
    mobile:string;
}
