import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrderInfo } from '../../../observable/model/order';
import { SelectStringVo } from '../../../observable/model/selectVo';
import { JWTUser } from '../../../observable/model/user';
import { CompanyRepository } from '../../../repository/company-repository';
import { OrderRepository } from '../../../repository/order-repository';
import { ToastRepository } from '../../../repository/toast-repository';
import { CancelOrderComponent } from '../cancel-order/cancel-order.component';
import { ConfirmPurchaseComponent } from '../confirm-purchase/confirm-purchase.component';

@Component({
    selector: 'app-create-order',
    templateUrl: './create-order.component.html',
    styleUrls: ['./create-order.component.less']
})
export class CreateOrderComponent implements OnInit {
    orderInfo = new OrderInfo();
    selectCompany: SelectStringVo;
    selectBox: SelectStringVo;
    selectCountry: SelectStringVo;
    selectState: SelectStringVo;
    companyArray = [];
    boxTypeList = [
        {label:'Paid order',value:1},
        {label:'Free order',value:3},
    ]
    stateArray = [ {label: 'ACT', value: 'ACT'},
        {label: 'NSW', value: 'NSW'},
        {label: 'NT', value: 'NT'},
        {label: 'QLD', value: 'QLD'},
        {label: 'SA', value: 'SA'},
        {label: 'TAS', value: 'TAS'},
        {label: 'VIC', value: 'VIC'},
        {label: 'WA', value: 'WA'}, ];
    countryArray = [
        {label: 'Australia', value: 'Australia'}
    ];
    price: string;
    status: string;
    loading = false;
    userRole: number;
    rateControl = new FormControl('', [Validators.max(100), Validators.min(0)]);
    constructor(public activeModal: NgbActiveModal,
                private companyRepository: CompanyRepository,
                private orderRepository: OrderRepository,
                private modalService: NgbModal,
                private jwtHelperService: JwtHelperService,
                public toastRepository: ToastRepository) {
    }

    ngOnInit(): void {
        const accessToken = localStorage.getItem('accessToken');
        const jwtUser = this.jwtHelperService.decodeToken(accessToken).inf as JWTUser;
        this.userRole = jwtUser.role;
       
        this.companyRepository.listParam().subscribe(result => {
            this.companyArray = result;
            const arr = this.companyArray.filter(v => v.value == jwtUser.companyId );
            this.selectCompany = arr.length > 0 ? arr[0] : {};
            this.getCompanyInfo(this.selectCompany);
        });
    }
    getBoxValue(val){
        this.orderInfo.orderType = val.value;
    }
    getCompanyInfo(val): void {
        if (JSON.stringify(val) == '{}'){
            return;
        }
        const id = val.value;
        this.companyRepository.getInfo(id).subscribe(result => {
             if (result){
              
                 // this.orderInfo.boxNumber = result.boxNumber;
                 // this.orderInfo.price = result.price;
                 this.orderInfo.recipientFullName = result.recipientFullName;
                 this.orderInfo.streetNumber = result.streetNumber;
                 this.orderInfo.suburb = result.suburb;
                 this.orderInfo.state = result.state;
                 this.orderInfo.postCode = result.postCode;
                 this.orderInfo.country = result.country;
                 this.orderInfo.companyId = id;
                 // this.orderInfo.orderType = result.orderType;
                 this.selectState = {label: result.state, value: result.state};
                 // this.selectBox = {label: result.orderType == 1?'Paid order':'Free order', value: result.orderType};
                 this.selectCountry =  {label: result.country, value: result.country};
                 this.orderInfo.email = result.email;
                 this.orderInfo.mobile = result.mobile;
             }else{
                 // this.orderInfo.boxNumber = null;
                 // this.orderInfo.price = null;
                 this.orderInfo.recipientFullName = null;
                 this.orderInfo.streetNumber = null;
                 this.orderInfo.suburb = null;
                 this.orderInfo.state = null;
                 this.orderInfo.postCode = null;
                 this.orderInfo.country = null;
                 this.orderInfo.companyId = id;
                 this.selectState = null;
                 // this.selectBox = null;
                 this.selectCountry = null;
                 // this.orderInfo.orderType = null;
             }
        });
    }

    saveCreateOrder(): void {
        if (this.selectCompany) {
            this.orderInfo.companyId = this.selectCompany.value;
        }

        if (this.selectState) {
            this.orderInfo.state = this.selectState.value;
        }
        if (this.selectCountry) {
            this.orderInfo.country = this.selectCountry.value;
        }
        
        if (this.userRole == 0) {
            if (!this.orderInfo.email || !this.orderInfo.mobile) {
                this.toastRepository.showDanger('Please enter the required information');
                return;
            }
        }
        if (!this.orderInfo.boxNumber || !this.orderInfo.recipientFullName || !this.orderInfo.streetNumber || !this.orderInfo.suburb || !this.orderInfo.state || !this.orderInfo.postCode || !this.orderInfo.country   ){
            this.toastRepository.showDanger('Please enter the required information');
            return;
        }
        this.loading = true;
        if (this.orderInfo.boxNumber){
            this.orderInfo.price = this.orderInfo.boxNumber * 25;
        }
        if (this.userRole == 0){
            this.superRole();
            return;
        }
        const modalRef = this.modalService.open(ConfirmPurchaseComponent, {
            backdrop: 'static',
            keyboard: true,
            windowClass: 'modal-400'
        });
        modalRef.componentInstance.orderInfo = this.orderInfo;
        modalRef.result.then((result) => {
            this.loading = false;
            if (result){
                return;
            }
            this.activeModal.close();
        }).catch(() => {
            this.loading = false;
        });
    }

    superRole(){
        this.orderRepository.save(this.orderInfo).subscribe(result => {
            this.loading = false;
            if (!result) {
                this.toastRepository.showDanger('Oops! Something went wrong, please try again.');
                return;
            }
            this.activeModal.close();
            this.toastRepository.showSuccess('Your order has been added.');
        },error => {
            this.loading = false;
        });
    }

    cancelCreateOrder(): void {
        const modalRef = this.modalService.open(CancelOrderComponent, {
            backdrop: 'static',
            keyboard: true,
            windowClass: 'modal-400'
        });
        modalRef.result.then(result => {
            if (result){
                return;
            }
            this.activeModal.close();
        }).catch(() => {
        });
    }

    changePrice($event): void{
        // this.price = this.orderInfo.boxNumber ?  '$' + this.orderInfo.boxNumber * 25 : '';
        this.orderInfo.boxNumber = $event.target.value.replace(/[^\d]/g, '');
    }

    blurHandler(): void{
        if(this.orderInfo.boxNumber == undefined || !this.orderInfo.boxNumber){
            this.orderInfo.boxNumber = null;
        }else if ( this.orderInfo.boxNumber < 1  ){
            this.orderInfo.boxNumber = 1;
        }
        else {
            this.orderInfo.boxNumber =  Math.floor(Math.abs(this.orderInfo.boxNumber));
        }
    }


    keypress($event: KeyboardEvent): void {
        console.log($event);
        const numHasZeroArr = [ 0 , 1, 2, 3, 4, 5, 6, 7, 8, 9];
        const numNoZeroArr = [ 1, 2, 3, 4, 5, 6, 7, 8, 9];
        if ( !this.orderInfo.boxNumber){
            if ( numNoZeroArr.indexOf(Number($event.key)) == -1 ) {
                $event.preventDefault();
            }
        }
        else {
            if (numHasZeroArr.indexOf(Number($event.key)) == -1) {
                $event.preventDefault();
            }
        }
    }
    capitalizeFirst(value: string): string {
        if (!value) return value;
        return value.charAt(0).toUpperCase() + value.slice(1);
      }

      capitalize(value: string): string {
        if (!value) return value;
        return value.toLowerCase().replace(/( |^)[a-z]/g, (L) => L.toUpperCase());  
    }
    capitalizeUpperCase(value: string): string {
        if (!value) return value;
        return value.toUpperCase();  
    }
    
}
