<div class="container">
    <div class="top-bar">
        <h1>{{title}}</h1>
    </div>
    <div class="content">
        <div class="sub-head-box">
            <div class="search">
                <input type="text" [(ngModel)]="condition.name" (keypress)="searchKeywordByPress($event)" placeholder="Search by box ID and company name..."  (blur)="getOrderList()">
                <i class="icon-search"></i>
            </div>

            <div class="filter-box">
                <div class="item" [ngClass]="{'active':condition.boxStatus==null}"
                     (click)="statueSearchOrderList(null)">All ({{boxStatusNumVo.allNumber}})
                </div>

                <div class="item" [ngClass]="{'active': condition.boxStatus==1}" (click)="statueSearchOrderList(1)" >
                    Assigned to Customer ({{boxStatusNumVo.assignedNum}})
                </div>

                <div class="item" [ngClass]="{'active': condition.boxStatus==0}" (click)="statueSearchOrderList(0)" >
                    Unassigned ({{boxStatusNumVo.unassignedNum}})
                </div>
            </div>

        </div>
        <div class="table order-table">
            <table >
                <thead>
                <tr>
                    <th style="width: 45%">
                        <p>Box ID<i class="icon icon-16 icon-title-down-arrow"  [ngClass]="{'icon-title-down-arrow': currnetSort['boxNum'] == 'ASC' || !currnetSort['boxNum'],'icon-up-arrow':currnetSort['boxNum'] == 'DESC' }"
                                           (click)="sortOrderList('boxNum')"></i></p>
                    </th>
                    <th style="width: 45%">
                        <p>Company Name<i class="icon icon-16 icon-title-down-arrow"
                                          [ngClass]="{'icon-title-down-arrow': currnetSort['companyName'] == 'ASC' || !currnetSort['companyName'],'icon-up-arrow':currnetSort['companyName'] == 'DESC' }"
                                          (click)="sortOrderList('companyName')"></i></p>
                    </th>
                    <th style="width: 10%"></th>
                </tr>
                </thead>
                <tbody >
                <tr *ngFor="let box of boxPage.records">
                    <td class="id"  >
                        <p style="overflow: inherit;white-space: nowrap" (click)="goBoxContent(box)">{{box.boxNumName|| '-----'}}</p>
                    </td>
                    <td class="companyname">
                        <p>{{box.companyName || '-----'}}</p>
                    </td>
                    <td class="end-right"></td>
                </tr>
                </tbody>
            </table>
            <ngb-pagination class="justify-content-center" *ngIf="boxPage.pages>1" [(page)]="boxPage.current"
                            [pageSize]="boxPage.size" [maxSize]="5" [boundaryLinks]="false"
                            (pageChange)="getBoxListByPage(boxPage.current)"
                            [collectionSize]="boxPage.total">
            </ngb-pagination>
        </div>

    </div>
</div>
