import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs/internal/Observable';
import {Condition, PageCondition} from '../observable/model/condition';

@Injectable({
    providedIn: 'root'
})
export class InvoiceRepository {

    constructor(private http: HttpClient) {
    }

    public list(condition: PageCondition): Observable<any> {
        return this.http.post<any>(environment.baseURL + `/pc/invoice/v1/list`, condition);
    }

    public getInvoiceNumberInfo(): Observable<any> {
        return this.http.post<any>(environment.baseURL + `/pc/invoice/v1/getInvoiceStatusNumVo`,{});
    }

}
