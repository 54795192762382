import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Billing, OtherInfo,StripeBillInfo} from '../observable/model/billing';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class BillingRepository {
    constructor(private http: HttpClient) {
    }

    public info(): Observable<any> {
        return this.http.get(environment.baseURL + '/pc/stripe/billing/v1/get');
    }

    public save(otherInfo: OtherInfo): Observable<any> {
        return this.http.post(environment.baseURL + '/pc/stripe/billing/v1/save', otherInfo);
    }

    public update(stripeBillInfo: StripeBillInfo): Observable<any> {
        return this.http.post(environment.baseURL + '/pc/stripe/billing/v1/updatePaymentMethodDetail', stripeBillInfo);
    }

}
