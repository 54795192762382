<div class="admin-wrapper">
    <div class="admin">
        <div class="admin-left">
            <div class="logo"></div>
            <div class="bg-box"></div>
        </div>
        <div class="admin-right">
            <div class="text-box">
                <form class="form" name="loginForm">
                    <div class="back-top" (click)="geToBack()"> <span class="icon icon-24 icon-arrow-back"></span>Login</div>
                    <h2 class="title">Forgot password?</h2>
                    <div class="subtitle">Please verify your email. We will send you a PIN code to start the password reset.</div>
                    <div class="form-control-group  form-icon mb-40">
                        <input type="email" id="account" name="account" [(ngModel)]="userForgot.account"
                               placeholder="Enter email">
                        <span class="icon icon-16 icon-email icon-first-pic"></span>
                    </div>

                    <button class="btn btn-o btn-block" [disabled]="userForgot.account==''||userForgot.account==null"
                            (click)="sendForgotEmail()">Verify
                    </button>
                </form>
            </div>
        </div>
    </div>
</div>
