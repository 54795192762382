import {Component, OnInit} from '@angular/core';
import {Address, Billing} from '../../../observable/model/billing';
import {Router} from '@angular/router';
import {StripeInfo} from '../../../observable/model/base';

@Component({
    selector: 'app-confirm-purchase',
    templateUrl: './detail-confirm.component.html',
    styleUrls: ['./detail-confirm.component.less']
})
export class DetailConfirmComponent implements OnInit {
    billing :  Billing;
    public address = new Address();
    taxPrice: string;
    totalPrice: string;
    price:string;
    stripeInfo = new StripeInfo();
    constructor(  private router: Router,) {
    }

    ngOnInit(): void {
        this.stripeInfo = JSON.parse(localStorage.getItem('stripeInfo'));
        this.price = Number(this.stripeInfo.price).toFixed(2);
        if(this.stripeInfo.region == 'AU/NZ' ){
            if(this.billing.abn){
                this.taxPrice = this.stripeInfo.baseTaxRate == 0 ? '0.00' : this.stripeInfo.baseTaxRate ? (Number(this.stripeInfo.price) / this.stripeInfo.baseTaxRate).toFixed(2) : '18.09';
            }else{
                this.taxPrice =  '0.00' ;
            }
        }else{
            this.taxPrice = '0.00';
        }
        this.totalPrice = (Number(this.price) + Number(this.taxPrice)).toFixed(2);
    }


    saveConfirm(): void{
        this.router.navigate(['detail-complete']);
    }

}
