import { Params } from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs/internal/Observable';
import {PageCondition} from '../observable/model/condition';
 
import { AddProject } from '../observable/model/addProject';
import { query } from '@angular/animations';

@Injectable({
    providedIn: 'root'
})
export class ProjectRepository {
    constructor(private http: HttpClient) {
    }

    public list(condition: PageCondition): Observable<any> {
        return this.http.post<any>(environment.baseURL + `/pc/project/v1/list`, condition);
    }

    public listParam(): Observable<any> {
        return this.http.post<any>(environment.baseURL + `/pc/project/v1/param/list`, null);
    }

    public getProjectNumber(): Observable<any> {
        return this.http.post<any>(environment.baseURL + `/pc/project/v1/getProjectStatusNumVo`, null);
    }

    public getBranch(projectId: string): Observable<any> {
        return this.http.get<any>(environment.baseURL + `/pc/project/v1/branches`,
         {params:{'projectId':projectId}}
        );
    }

    

    
    public saveProject(project: AddProject):Observable<any>{
        return this.http.post<any>(environment.baseURL + `/pc/project/v1/save`,project);
    }

    public archive(id: String):Observable<any>{
        return this.http.post<any>(environment.baseURL + `/pc/project/v1/status/${id}`,{});
    }
    
    public getBranchUserList(id:string):Observable<any>{
        return this.http.get<any>(environment.baseURL + `/pc/project/v1/branchUser/${id}`);
    }
}
