import { APP_BASE_HREF, CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtInterceptor, JwtModule } from '@auth0/angular-jwt';
import { NgxLoadingSpinnerModule } from '@k-adam/ngx-loading-spinner';
import { NgbActiveModal, NgbAlertModule, NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxValidatorModule, ValidationFeedbackStrategyBuilder } from '@why520crazy/ngx-validator';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxEchartsModule } from 'ngx-echarts';
import { NgxFileDropModule } from 'ngx-file-drop';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { FooterComponent } from './common/footer/footer.component';
import { HeaderComponent } from './common/header/header.component';
import { LayoutSettingComponent } from './common/layout-setting/layout-setting.component';
import { LayoutComponent } from './common/layout/layout.component';
import { SidebarComponent } from './common/sidebar/sidebar.component';
import { AppRoutingModule } from './config/app-routing-module';
import { HttpResultInterceptor } from './interceptor/http-result.interceptor';
import { LayoutObservable } from './observable/layout-observable';
import { LocalStorageObServable } from './observable/local-storage-observable';
import { AuthModule } from './pages/auth/auth.module';
import { InvoicesComponent } from './pages/invoices/invoices.component';
import { LockComponent } from './pages/lock/lock.component';
import { AgreementUserComponent } from './pages/modal/agreement-user/agreement-user.component';
import { CancelSubscriptionComponent } from './pages/modal/cancel-subscription/cancel-subscription.component';
import { ConfirmPurchaseComponent } from './pages/modal/confirm-purchase/confirm-purchase.component';
import { CreateOrderComponent } from './pages/modal/create-order/create-order.component';
import { CreatePayComponent } from './pages/modal/create-pay/create-pay.component';
import { DisableStaffComponent } from './pages/modal/disable-staff/disable-staff.component';
import { EditStaffComponent } from './pages/modal/edit-staff/edit-staff.component';
import { EmailCodeComponent } from './pages/modal/email-code/email-code.component';
import { EnterNewEmailComponent } from './pages/modal/enter-new-email/enter-new-email.component';
import { InvitationEmailComponent } from './pages/modal/invitation-email/invitation-email.component';
import { OrderStatusComponent } from './pages/modal/order-status/order-status.component';
import { ReactiveStaffComponent } from './pages/modal/reactive-staff/reactive-staff.component';
import { OrderContentComponent } from './pages/order-content/order-content.component';
import { OrderComponent } from './pages/order/order.component';
import { ProjectComponent } from './pages/project/project.component';
import { ScaffoldComponent } from './pages/scaffold/scaffold.component';
import { BillingComponent } from './pages/setting/billing/billing.component';
import { ProfileComponent } from './pages/setting/profile/profile.component';
import { SubscriptionsComponent } from './pages/setting/subscriptions/subscriptions.component';
import { TagInspectionComponent } from './pages/tag-inspection/tag-inspection.component';
import { TagComponent } from './pages/tag/tag.component';
import { TeamComponent } from './pages/team/team.component';
import { StatePipe } from './pipes/state.pipe';
import { TimePipe } from './pipes/time.pipe';
import { UserRolePipe } from './pipes/userRole.pipe';
import { ToastRepository } from './repository/toast-repository';
import { UserRepository } from './repository/user-repository';
import { MessagingService } from './service/messaging.service';
import { ImportComponent } from './pages/import/import.component';
import { UploadComponent } from './pages/upload/upload.component';
import { StepComponent } from './common/step/step.component';
import { FileRepository } from './repository/file-repository';
import { FileSizePipe } from './pipes/fileSize.pipe';
import { ClassPipe } from './pipes/class.pipe';
import { HandoverComponent } from './pages/setting/handover/handover.component';
import { AddProjectComponent } from './pages/modal/add-project/add-project.component';
import { BranchesComponent } from './pages/setting/branches/branches.component';
import { AddBranchComponent } from './pages/modal/add-branch/add-branch.component';
import { BranchNoticeComponent } from './pages/modal/branch-notice/branch-notice.component';
import { CapitalizeFirstPipe } from './pipes/capitalize-first.pipe';


@NgModule({
    declarations: [
        AppComponent,
        TimePipe,
        LayoutComponent,
        HeaderComponent,
        FooterComponent,
        SidebarComponent,
        OrderComponent,
        TagComponent,
        OrderContentComponent,
        CreateOrderComponent,
        ScaffoldComponent,
        TagInspectionComponent,
        ProjectComponent,
        CreatePayComponent,
        ConfirmPurchaseComponent,
        CancelSubscriptionComponent,
        SubscriptionsComponent,
        StatePipe,
        OrderStatusComponent,
        InvoicesComponent,
        TeamComponent,
        EditStaffComponent,
        DisableStaffComponent,
        ReactiveStaffComponent,
        InvitationEmailComponent,
        EmailCodeComponent,
        EnterNewEmailComponent,
        LayoutSettingComponent,
        ProfileComponent,
        BillingComponent,
        UserRolePipe,
        LockComponent,
        AgreementUserComponent,
        ImportComponent,
        UploadComponent,
        StepComponent,
        FileSizePipe,
        ClassPipe,
        HandoverComponent,
        AddProjectComponent,
        BranchesComponent,
        AddBranchComponent,
        BranchNoticeComponent,
        CapitalizeFirstPipe
    ],
    imports: [
        CommonModule,
        AppRoutingModule,
        BrowserModule,
        AppRoutingModule,
        AuthModule,
        HttpClientModule,
        NgbModule,
        NgbPaginationModule,
        NgbAlertModule,
        FormsModule,
        ReactiveFormsModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        BrowserAnimationsModule,
        NgSelectModule,
        NgxLoadingSpinnerModule.forRoot(),
        ModalModule.forRoot(),
        NgxFileDropModule,

        NgxEchartsModule.forRoot({
            echarts: () => import('echarts')
        }),
        JwtModule.forRoot({
            config: {
                tokenGetter: () => localStorage.getItem('accessToken'),
                headerName: 'Authorization',
                authScheme: 'Bearer ',
                allowedDomains: environment.allowedDomains,
                // allowedDomains: [environment.baseURL],
                disallowedRoutes: [],
            },
        }),
        NgxValidatorModule.forRoot({
            validationFeedbackStrategy: ValidationFeedbackStrategyBuilder.bootstrap(), // default is bootstrap 4 style
            validateOn: 'submit' // default is submit
        })
    ],
    providers: [
        { provide: APP_BASE_HREF, useValue: '/' },
        {
            provide: OWL_DATE_TIME_LOCALE, useValue: 'en-au'
        },
        ToastRepository,
        UserRepository,
        FileRepository,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpResultInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true,
        },
        LocalStorageObServable,
        LayoutObservable,
        MessagingService,
        NgbActiveModal
    ],
    bootstrap: [AppComponent],
    exports: [
        FooterComponent,
    ]
})
export class AppModule {
}
