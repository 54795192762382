import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs/internal/Observable';
import {Condition, PageCondition} from '../observable/model/condition';

@Injectable({
    providedIn: 'root'
})
export class ScaffoldRepository {

    constructor(private http: HttpClient) {
    }

    public list(condition: PageCondition): Observable<any> {
        return this.http.post<any>(environment.baseURL + `/pc/scaffold/v1/list`, condition);
    }

    public info(id: string): Observable<any> {
        return this.http.get<any>(environment.baseURL + `/pc/scaffold/v1/info/${id}`);
    }

    public listParam(condition: Condition): Observable<any> {
        return this.http.post<any>(environment.baseURL + `/pc/scaffold/v1/param/list`, condition);
    }

    public downLoad(condition: Condition): Observable<Blob> {
        return this.http.post(environment.baseURL + `/pc/scaffold/v1/export`,condition, {responseType: 'blob'})
    }

    public getScaffoldNumberInfo(condition: Condition): Observable<any> {
        return this.http.post<any>(environment.baseURL + `/pc/scaffold/v1/getScaffoldStatusNumVo`,condition);
    }
}
