export class Condition {
    q = '';
    current = 1;
    records = [];
    pages: number;
    total: number;
    size: number;

    id: string;
    scaffoldId: string;
    scaffoldIds: Array<string>;
    name: string;
    status: string;
    orderStatus: number;
    orderId: string;
    boxId: string;
    projectId: string;
    projectIds: Array<string>;
    tagStatus: string;
    scaffoldStatus: string;
    inspectionStatus: string;
    userStatus: string;
    invoiceStatus: string;
    boxInfoId: string;
    boxStatus:number;
}

export class PageCondition extends Condition {
    sortName: string;
    sortOrder: 'ASC' | 'DESC' = 'ASC';

    constructor(sortName?: string, size = 100) {
        super();
        this.size = size;
        if (sortName) {
            this.sortName = sortName;
        }
    }
}
