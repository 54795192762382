import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { StripeInfo } from '../../../observable/model/base';
import { OrderInfo } from '../../../observable/model/order';
import { OrderRepository } from '../../../repository/order-repository';
import { ToastRepository } from '../../../repository/toast-repository';

@Component({
    selector: 'app-confirm-purchase',
    templateUrl: './confirm-purchase.component.html',
    styleUrls: ['./confirm-purchase.component.less']
})
export class ConfirmPurchaseComponent implements OnInit {
    orderInfo: OrderInfo;
    stripeInfo = new StripeInfo();
    loading = false;
    taxPrice: string;
    price: string;
    totalPrice: string;
    constructor(
        public activeModal: NgbActiveModal,
        private orderRepository: OrderRepository,
        private toastRepository: ToastRepository) {
    }

    ngOnInit(): void {
        this.stripeInfo = JSON.parse(localStorage.getItem('stripeInfo'));
        this.price = '0';
        this.taxPrice = '0';
        this.totalPrice = '0';
    }

    cancel(): void {
        this.activeModal.close({ type: '1' });
    }

    saveConfirm(): void {
        this.loading = true;
        this.orderRepository.save(this.orderInfo).subscribe(result => {
            this.loading = false;
            if (!result) {
                this.toastRepository.showDanger('Oops! Something went wrong, please try again.');
                return;
            }
            this.toastRepository.showSuccess('Your order has been added.');
            this.activeModal.close();
        });
    }
}
