import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxValidatorConfig } from '@why520crazy/ngx-validator';
import { UserRepository } from '../../../repository/user-repository';
import { RegisterInfo } from '../signup/sign-up-model/register-info';

@Component({
    selector: 'app-signup-tagged',
    templateUrl: './signup-tagged.component.html',
    styleUrls: ['./signup-tagged.component.less']
})
export class SignupTaggedComponent implements OnInit {

    public registerInfo = new RegisterInfo();
    public loading = false;
    public regionList = [
        {label:'AU/NZ',value:"AU/NZ"},
        {label:'UK',value:"UK"},
        {label:'US',value:"US"},
        {label:'France',value:"France"},
        {label:'Saudi Arabia (US)',value:"Saudi Arabia (US)"},
        {label:'Saudi Arabia (UK)',value:"Saudi Arabia (UK)"},
        {label:'UAE (US)',value:"UAE (US)"},
        {label:'UAE (UK)',value:"UAE (UK)"},
        {label:'Qatar (US)',value:"Qatar (US)"},
        {label:'Qatar (UK)',value:"Qatar (UK)"},
    ]
    country = [
        { value: 1, label: 'BOX-00001' },
        { value: 2, label: 'BOX-00002' },
        { value: 3, label: 'BOX-00003' }
      ];

    validatorConfig: NgxValidatorConfig = {
        validationMessages: {
            companyName: {
                required: 'Company name is required.',
            },
            mobile: {
                required: 'Mobile/Phone is required.'
            },
        },

        validateOn: 'submit'
    };

    constructor(public activatedRoute: ActivatedRoute, public router: Router, public userRepository: UserRepository) {
        const personalInformation = activatedRoute.snapshot.queryParamMap.get('personalInformation');
        try {
            this.registerInfo = JSON.parse(decodeURIComponent(personalInformation)) || new RegisterInfo();
        } catch (e) {
            this.router.navigate(['signup']);
        }
    }

    ngOnInit(): void {
    }

    back(): void {
        // const personalInformation = this.activatedRoute.snapshot.queryParamMap.get('personalInformation');
        const personalInformation: String = encodeURIComponent(JSON.stringify(this.registerInfo));
        this.router.navigate(['signup'], {queryParams: {personalInformation: personalInformation}});
    }

    register(): void {
        this.loading = true;
        const personalInformation: String = encodeURIComponent(JSON.stringify(this.registerInfo));
        this.userRepository.register(this.registerInfo).subscribe(userInfo => {
            this.router.navigate(['signup-code'], {queryParams: {account: userInfo.account, fromType: 'signup',personalInformation: personalInformation}});
        });
        setTimeout(() => {
            this.loading = false;
        }, 1000);
    }
        capitalize(value: string): string {
            if (!value) return value;
            return value.toLowerCase().replace(/( |^)[a-z]/g, (L) => L.toUpperCase());  
        }
}
