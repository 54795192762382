export class AddProject{
    address: string;
    branch: string;
    branchName: string;
    client: string;
    id: string;
    label: string;
    name: string;
    state: string;
    inspectors: Array<string>;
}