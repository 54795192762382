import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { StripeInfo } from '../../../observable/model/base';
import { Subscription } from '../../../observable/model/subscription';
import { LocalStorageObServable } from '../../../observable/local-storage-observable';
import { SubscriptionRepository } from '../../../repository/subscription-repository';
import { ToastRepository } from '../../../repository/toast-repository';
import { CancelSubscriptionComponent } from '../../modal/cancel-subscription/cancel-subscription.component';
import { CreatePayComponent } from '../../modal/create-pay/create-pay.component';

@Component({
    selector: 'app-subscriptions',
    templateUrl: './subscriptions.component.html',
    styleUrls: ['./subscriptions.component.less']
})
export class SubscriptionsComponent implements OnInit {

    subscription: Subscription;
    stripeInfo = new StripeInfo();
    days: number;
    trialDay: number;
    page = 'company';
    useFlag = false;
    billFlag = false;
    trialEnd :string;
    cancelAt:string;
    currentPeriodEnd :string;
    billableDate :String;
    isLoading=false;
    constructor(
        private ngbModal: NgbModal,
        private subscriptionRepository: SubscriptionRepository,
        private toastRepository: ToastRepository,
        private localStorageObservrable: LocalStorageObServable,
    ) {
    }

    ngOnInit(): void {
        this.init();
    }

    init(): void {
        this.subscriptionRepository.info().subscribe(result => {
            this.stripeInfo = result;
            this.cancelAt = result.cancelAt || '';
            this.useFlag = this.stripeInfo.subscriptionStatus == 'active' || this.stripeInfo.subscriptionStatus == 'trialing';
            this.trialEnd = this.stripeInfo.trialEnd  ? this.handleDate(this.stripeInfo.trialEnd.replace(/-/g, '/'),'trial') : '';
            this.currentPeriodEnd = this.stripeInfo.currentPeriodEnd ? this.handleDate(this.stripeInfo.currentPeriodEnd.replace(/-/g, '/'),'current') : '';
            this.stripeInfo.trialEnd = this.stripeInfo.trialEnd ? this.stripeInfo.trialEnd.split(" ")[0] + ' 00:00:00' : '';
            this.stripeInfo.currentTime = this.stripeInfo.currentTime.split(" ")[0] + ' 00:00:00';
            if (this.trialEnd) { 
                this.trialDay = (new Date(this.stripeInfo.trialEnd.replace(/-/g, '/')).getTime() - new Date(this.stripeInfo.currentTime.replace(/-/g, '/')).getTime()) / (24 * 3600 * 1000);
            }else{
                this.trialDay = 0
            }
            if(this.stripeInfo.currentPeriodEnd){
                this.days = (new Date(this.stripeInfo.currentPeriodEnd.replace(/-/g, "/")).getTime() - new Date(this.stripeInfo.currentTime.replace(/-/g, "/")).getTime())/(24*3600*1000);
                if(this.days > 0 && this.days < 1){
                    this.days = 1;
                }else if(this.days < 0){
                    this.days = 0;
                }else{
                    this.days = Math.floor(this.days);
                }
            }
        });
        this.getBillableDate();
    }
    handleDate(date,type){
        if(!date || type == 'trial' && this.stripeInfo.trialEnd && (new Date(this.stripeInfo.trialEnd.replace(/-/g, '/')) < new Date(this.stripeInfo.currentTime.replace(/-/g, '/')))){
            return '';
        }

        let year = String(new Date(date).getFullYear());
        let month = String(new Date(date).getMonth() + 1);
        let day = String(new Date(date).getDate());
        day = day.length == 1 ? '0' + day : day;
        month = month.length == 1 ? '0' + month : month;
        return day + '/' + month + '/' + year ;
    }
    createPay(): void {
        const ngbModalRef = this.ngbModal.open(CreatePayComponent, {
            backdrop: 'static',
            keyboard: true,
            size: 'createpay sm',
            centered: true,
            windowClass:'sub',
            backdropClass:'sub-back'
        });
        ngbModalRef.result.then(result => {
            window.location.reload();
        });
    }

    upgrade(): void {
        this.isLoading = true
        this.subscriptionRepository.info().subscribe(result => {
            this.stripeInfo = result;
            if (!this.stripeInfo.billFlag) {
                this.createPay();
            }
        },error => { this.isLoading = false;});
    }

    cancel(): void {
        const ngbModalRef = this.ngbModal.open(CancelSubscriptionComponent, {backdrop: 'static', keyboard: true, size: 'sm'});
        ngbModalRef.componentInstance.subscription = this.subscription;
        ngbModalRef.result.then(result => {
            this.init();
        });
    }

   getBillableDate(){
        const currentDate = moment();
        if (currentDate.day() === 0) {
        currentDate.add(1, 'week');
        }
        while (currentDate.day() !== 0) {
         currentDate.add(1, 'day');
        }
        this.billableDate = currentDate.format('DD/MM/YYYY');
 

       
   }

}
