import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs/internal/Observable';
import {PageCondition} from '../observable/model/condition';

@Injectable({
    providedIn: 'root'
})
export class TagInspectionRepository {

    constructor(private http: HttpClient) {
    }

    public listTag(condition: PageCondition): Observable<any> {
        return this.http.post<any>(environment.baseURL + `/pc/tag/v1/list`, condition);
    }

    public listInspection(condition: PageCondition): Observable<any> {
        return this.http.post<any>(environment.baseURL + `/pc/inspection/v1/list`, condition);
    }

    public listTagByOrder(condition: PageCondition): Observable<any> {
        return this.http.post<any>(environment.baseURL + `/pc/tag/v1/listByOrder`, condition);
    }

    public listTagByBox(condition: PageCondition): Observable<any> {
        return this.http.post<any>(environment.baseURL + `/pc/tag/v1/listByBox`, condition);
    }

    downLoadTag(condition: PageCondition) {
        return this.http.post(environment.baseURL + `/pc/tag/v1/export`,condition, {responseType: 'blob'})
    }

    downLoadInspection(condition: PageCondition) {
        return this.http.post(environment.baseURL + `/pc/inspection/v1/export`,condition, {responseType: 'blob'})
    }

    public getTagStatusNumVo(condition: PageCondition): Observable<any> {
        return this.http.post<any>(environment.baseURL + `/pc/tag/v1/getBoxByTagStatusNumVo`,condition);
    }
    public getOrderStatusNumVo(condition: PageCondition): Observable<any> {
        return this.http.post<any>(environment.baseURL + `/pc/tag/v1/getTagStatusByOrder`,condition);
    }

    public getTagInspectionNumVo(condition: PageCondition): Observable<any> {
        return this.http.post<any>(environment.baseURL + `/pc/tag/v1/getTagStatusNumVo`,condition);
    }
    public getInspectionNumVo(condition: PageCondition): Observable<any> {
        return this.http.post<any>(environment.baseURL + `/pc/inspection/v1/getInspectionStatusNumVo`,condition);
    }
}
