<div class="setting-right">
    <div class="top-bar">
        <h1>Settings</h1>
    </div>
    <!-- subscriptions -->
    <div class="content subscriptions">
        <div class="sub-head-box">
            <div class="filter-box">
                <div class="item" routerLinkActive="active">
                    <a [routerLink]="['/setting/profile']">Your Profile</a>
                </div>
                <div class="item" routerLinkActive="active">
                    <a [routerLink]="['/setting/branches']">Branches</a>
                </div>
                <div class="item" routerLinkActive="active">
                    <a [routerLink]="['/setting/handover']">Handover Certificates</a>
                </div>
                
                <div class="item" routerLinkActive="active">
                    <a [routerLink]="['/setting/subscriptions']">Your Subscription</a>
                </div>
                <div class="item" routerLinkActive="active">
                    <a [routerLink]="['/setting/billing']">Billing Information</a>
                </div>
            </div>
        </div>

        <div class="permission-comparison">
            <ul >
    
                <li class="diamond">
                    <div class="content-top">
                        <div class="icon-box">
                            <i class="icon icon-12 icon-briefcase-new"></i>
                        </div>
                        <h2 class="h2">Standard</h2>
                    </div>
                    <p class="p">Pricing for each Erect Scaffold</p>

                    <div class="price">
                        <p><strong>$0.40</strong> Per week*</p>
                    </div>

                    <ul>
                        <li><i class="icon icon-24 icon-checked-green"></i>Unlimited Projects</li>
                        <li><i class="icon icon-24 icon-checked-green"></i>Unlimited Active Tags</li>
                        <li><i class="icon icon-24 icon-checked-green"></i>Scaffold Tags are free and so is shipping</li>
                        <li><i class="icon icon-24"></i> </li>
                    </ul>

                    <div class="button-box">
                        <p class="info">* Next billable date: {{billableDate}}</p>
                        <!-- <p class="info trial" *ngIf="trialEnd && trialDay > 0 && (stripeInfo.subscriptionStatus == 'canceled' || stripeInfo.subscriptionStatus == 'trialing')">* Free trial ends at: {{trialEnd}}</p>
                        <button class="btn btn-red" *ngIf="(stripeInfo.cancelAt && useFlag) || stripeInfo.subscriptionStatus == 'canceled'" [disabled]="isLoading"  (click)="upgrade();">Upgrade</button>
                        <button class="btn btn-red" *ngIf=" !stripeInfo.cancelAt && stripeInfo.subscriptionStatus != 'canceled'" (click)="cancel();">Cancel</button> -->
                    </div>
                    <!-- <div class="button-box" *ngIf="!useFlag">
                        <button class="btn btn-red" [disabled]="isLoading" (click)="upgrade();">Upgrade</button>
                    </div> -->
                    <!-- <div *ngIf="useFlag && trialDay <= 0 && days > 0 && cancelAt" class="mark bg-green">
                        {{days}} days left
                    </div> -->
                    <!-- <div *ngIf="useFlag  && (trialDay >= 0 || days == 0 ) && (!cancelAt || cancelAt && stripeInfo.subscriptionStatus == 'trialing')" class="mark bg-green">Active</div>
                    <div *ngIf="!useFlag" class="mark">Expired</div> -->
                </li>
            </ul>
        </div>
    </div>
</div>
