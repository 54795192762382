<div class="disable-modal reactive-modal">
    <div class="modal-header">
        <h4 class="modal-title">Reactiveate Staff</h4>
    </div>
    <div class="modal-body">
        <div class="form form-content">
            <div class="inner-box">
                <p>Reactivating the user will bring back all the system access linking to his/her previous role. </p>
            </div>
            <div class="form-button form-button-two-with_div">
                <div class="close">
                    <button class="color-o" (click)="cancel()">No, take me back</button>
                </div>
                <div class="submit">
                    <button class="btn btn-o" [disabled]="isLoading" (click)="reactive()">Yes, reactivate</button>
                </div>
            </div>
        </div>
    </div>
</div>
