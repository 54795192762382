import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpResult } from '../observable/model/common/http-result';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
    providedIn: 'root'
})
export class FileRepository {
    constructor(private http: HttpClient) { }

    public uploadFile(file: File): Observable<any> {
        return this.http.post<HttpResult<any>>(environment.baseURL + `/pc/project/v1/import?name=${file.name}`, file);
    }

    public downloadTemplate(): Observable<Blob> {
        return this.http.get(environment.baseURL + `/pc/project/v1/import/template`, { responseType: 'blob' });
    }

    public history() {
        return this.http.get(environment.baseURL + `/pc/project/v1/import/history`);
    }

    public upload(name: string,file: File): Observable<any> {
        var formData = new FormData();
        formData.append('files',file);
        return this.http.post<HttpResult<any>>(environment.baseURL + `/attachment/upload/${name}`, formData);
    }
}