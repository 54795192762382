<div class="subscription">
    <div class="modal-header">
        <h4 class="modal-title">Cancel Order?</h4>
    </div>
    <div class="modal-body">
        <div class="form form-content">
            <div class="inner-box-1">
                <p>You are about to cancel your order. Are you sure about this action?</p>
            </div>
            <div class="form-button">
                <div class="close">
                    <button class="color-o" (click)="cancel()">No, take me back</button>
                </div>
                <div class="submit">
                    <button class="btn btn-green bg-o" (click)="confirm()">Yes, cancel order</button>
                </div>
            </div>
        </div>
    </div>
</div>
