import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, OperatorFunction } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LocalStorageObServable } from '../observable/local-storage-observable';
import { ToastRepository } from '../repository/toast-repository';


@Injectable()
export class HttpResultInterceptor implements HttpInterceptor {

    urls = ['/pc/project/v1/import']

    constructor(private storage: LocalStorageObServable, private router: Router, private toastRepository: ToastRepository) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const headers = request.headers;
        request = request.clone({
            headers,
            url: request.url + (request.url.indexOf('?') > 0 ? '&' : '?') + 'random=' + new Date().getTime()
        });
        const path = new URL(request.url).pathname;
        if (this.urls.filter(u => path.endsWith(u)).length > 0) {
            return next.handle(request);
        }
        return next.handle(request).pipe(
            catchError(this.handleError)
        );
    }

    handleError = (errorResponse: HttpErrorResponse): OperatorFunction<HttpEvent<any>, any> => {
        if (errorResponse.error?.message != null) {
            this.toastRepository.showDanger(errorResponse.error.message, 'toastTop');
        }
        if (errorResponse.status == 401) {
            this.router.navigate(['login']);
        }
        if (errorResponse.status == 402) {
            localStorage.setItem('responseCode', '402');
            this.router.navigate(['setting', 'billing']);
        }
        throw errorResponse;
    }
}
