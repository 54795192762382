<div class="subscription">
    <div class="modal-header">
        <h4 class="modal-title">Cancel Subscription</h4>
    </div>
    <div class="modal-body">
        <div class="form form-content">
            <div class="inner-box">
                <p>Cancelling subscription will shuffle the plan back to free trial version (if days remaining) at the
                    end of this billing cycle, or terminate the access to all functionalities if free trial version has
                    expired. </p>
            </div>
            <div class="form-button form-button-two-with_div">
                <div class="close">
                    <button class="color-o" (click)="cancel()">Back</button>
                </div>
                <div class="submit">
                    <button class="btn btn-red" (click)="confirm()">Cancel my subscription</button>
                </div>
            </div>
        </div>
    </div>
</div>
