import { Component, OnInit } from '@angular/core';
import { History } from 'src/app/observable/model/history';
import { FileRepository } from 'src/app/repository/file-repository';

@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.less']
})
export class ImportComponent implements OnInit {

  list: Array<History> = new Array<History>();

  constructor(public fileRepository: FileRepository) { }

  ngOnInit(): void {
    this.history();
  }
  goBack(): void {
    history.back();
  }

  download(): void {
    this.fileRepository.downloadTemplate().subscribe((b) => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(b);
      link.download = 'Template.csv';
      link.click();
      link.remove();
    });
  }

  history(): void {
    this.fileRepository.history().subscribe((result: Array<History>) => this.list = result.slice(0, 2));
  }
}
