import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs/internal/Observable';
import {Condition, PageCondition} from '../observable/model/condition';
import {OrderInfo} from '../observable/model/order';

@Injectable({
    providedIn: 'root'
})
export class OrderRepository {

    constructor(private http: HttpClient) {
    }

    public list(condition: PageCondition): Observable<any> {
        return this.http.post<any>(environment.baseURL + `/pc/order/v1/list`, condition);
    }
    public boxList(condition: PageCondition): Observable<any> {
        return this.http.post<any>(environment.baseURL + `/pc/box/v1/listPage`,condition );
    }
    public getOrderStatusNumVo(): Observable<any> {
        return this.http.get<any>(environment.baseURL + `/pc/order/v1/getOrderStatusNumVo`);
    }

    public getBoxStatusNumVo(): Observable<any> {
        return this.http.get<any>(environment.baseURL + `/pc/box/v1/getBoxStatusNumVo`);
    }

    public save(orderInfo: OrderInfo): Observable<any> {
        return this.http.post<any>(environment.baseURL + `/pc/order/v1/save`, orderInfo);
    }

    public saveFree(orderInfo: OrderInfo): Observable<any> {
        return this.http.post<any>(environment.baseURL + `/pc/order/v1/saveFree`, orderInfo);
    }

    public updateStatus(condition: Condition): Observable<any> {
        return this.http.post<any>(environment.baseURL + `/pc/order/v1/status`, condition);
    }
    public allotBox(condition: Condition): Observable<any> {
        return this.http.post<any>(environment.baseURL + `/pc/order/v1/allotBox`, condition);
    }

    public listBox(): Observable<any> {
        return this.http.get<any>(environment.baseURL + `/pc/box/v1/list`);
    }

}
