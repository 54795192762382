import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LayoutObservable } from 'src/app/observable/layout-observable';
import { JWTUser } from '../../observable/model/user';
import { SignOutComponent } from '../../pages/modal/sign-out/sign-out.component';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.less']
})
export class SidebarComponent implements OnInit {
    isActive: boolean;
    
    userRole: number;

    constructor(
        private router: Router,
        private modalAdService: NgbModal,
        private ngbActiveModal: NgbActiveModal,
        private jwtHelperService: JwtHelperService,
        private layoutObservable: LayoutObservable
    ) {
    }

    ngOnInit(): void {
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken == null) {
            this.router.navigate(['login']);
            return;
        }
        const jwtUser = this.jwtHelperService.decodeToken(accessToken).inf as JWTUser;
        this.userRole = jwtUser.role;
    }

    logout(): void {

        const modalRef = this.modalAdService.open(SignOutComponent, {
            backdrop: 'static',
            keyboard: true,
            windowClass: 'modal-500'
        });
        modalRef.result.then((result) => {
            this.ngbActiveModal.close();
        }).catch(() => {
        });

    }

    show(): void {
        this.isActive = !this.isActive;
    }

    remove(): void {
        this.isActive = false;
    }

    goSettingInit(): void {
        this.router.navigate(['setting', 'profile']);
    }
}
