import { Component, KeyValueDiffer, KeyValueDiffers, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { RegisterStep } from 'src/app/enum/register-step.enum';
import { StripeInfo } from '../../../observable/model/base';
import { Address, Billing, OtherInfo, StripeBillDetail } from '../../../observable/model/billing';
import { BillingRepository } from '../../../repository/billing-repository';
import { SubscriptionRepository } from '../../../repository/subscription-repository';
import { ToastRepository } from '../../../repository/toast-repository';
import { UserRepository } from '../../../repository/user-repository';

declare var Stripe: any;
var cardNumberElement: any;
var cardCvcElement: any;
var cardExpiryElement: any;
var elements: any;
var stripes: any;

@Component({
    selector: 'app-signup-verified',
    templateUrl: './signup-stripe.component.html',
    styleUrls: ['./signup-stripe.component.less']
})
export class SignupStripeComponent implements OnInit {
    billing = new Billing();
    otherInfo = new OtherInfo();
    stripeInfo = new StripeInfo();
    stripeBillDetail = new StripeBillDetail();
    address = new Address();
    switchStatus: number;
    numberError: string;
    dateError: string;
    cvcError: string;
    taxPrice: string;
    price: string;
    totalPrice: string;
    fromPage: string;
    differ: KeyValueDiffer<string, any>;
    public loading = true;
    public isComplete = false;

    constructor(private differs: KeyValueDiffers, private userRepository: UserRepository, public activatedRoute: ActivatedRoute, private billingRepository: BillingRepository, private router: Router, private toastRepository: ToastRepository, private subscriptionRepository: SubscriptionRepository, private jwtHelperService: JwtHelperService) {
        this.fromPage = activatedRoute.snapshot.queryParamMap.get('fromPage');
        this.differ = this.differs.find({}).create();

    }

    ngOnInit(): void {
        this.switchStatus = 1;
        this.isComplete = false;
        this.stripeInfo = JSON.parse(localStorage.getItem('stripeInfo')) || new StripeInfo();
        this.price = this.stripeInfo.price ? Number(this.stripeInfo.price).toFixed(2) : '200.00';

        this.getPubkeyInfo();

    }
    ngDoCheck() {
        const change = this.differ.diff(this);
        if (change) {
            change.forEachChangedItem(item => {
                console.log('item changed', item);
                if (item.key == 'switchStatus') {
                    // item.currentValue == 2
                    if (item.currentValue == 1) {
                        this.checkFirstBtn();
                    } else if (item.currentValue == 2) {
                        this.checkBtn();
                    } else if (item.currentValue == 3) {
                        this.checkThirdBtn();
                    }
                }
            });
        }
    }
    checkFirstBtn(): void {
        if (this.switchStatus == 1) {
            if ((this.cvcError != '' || this.dateError != '' || this.numberError != '') || this.checkInput(this.billing.name) || this.checkInput(this.billing.companyName) || this.checkInput(this.billing.abn)) {
                this.loading = true;
            } else {
                this.loading = false;
            }
        }
    }
    checkBtn(): void {
        if (this.switchStatus == 2) {
            if (this.billing.address && !this.checkInput(this.billing.address) && this.billing.city && !this.checkInput(this.billing.city) && this.billing.country && !this.checkInput(this.billing.country) && this.billing.postcode && !this.checkInput(this.billing.postcode)) {
                this.loading = false;
            } else {
                this.loading = true;
            }
        }
    }
    checkThirdBtn(): void {
        if (this.switchStatus == 3) {
            if (this.billing.email && !this.checkInput(this.billing.email) && this.billing.firstName && !this.checkInput(this.billing.firstName) && this.billing.lastName && !this.checkInput(this.billing.lastName)) {
                this.loading = false;
            } else {
                this.loading = true;
            }
        }
    }
    checkInput(val) {
        return val == undefined || val.replace(/(^\s*)|(\s*$)/g, "") == "";
    }
    getPubkeyInfo() {
        this.subscriptionRepository.getPubkey().subscribe(result => {
            if (result) {
                stripes = new Stripe(result);
                elements = stripes.elements({
                    locale: 'en', // 设置默认显示语种   en 英文 cn 中文 auto 自动获取语种
                });
                setTimeout(() => {
                    this.initElement();
                }, 200);
            }
        });
    }



    next(): void {
        if (this.cvcError != '' || this.dateError != '' || this.numberError != '') {
            this.toastRepository.showDanger('Please enter your credit card information');
            return;
        }
        if (this.switchStatus < 3) {
            this.switchStatus++;
            if (this.switchStatus == 2) {
                this.checkBtn();
            }
            return;
        }
        if (this.stripeInfo.region == 'AU/NZ') {
            if (this.billing.abn) {
                this.taxPrice = this.stripeInfo.baseTaxRate == 0 ? '0.00' : this.stripeInfo.baseTaxRate ? (Number(this.stripeInfo.price) / this.stripeInfo.baseTaxRate).toFixed(2) : '18.09';
            } else {
                this.taxPrice = '0.00';
            }
        } else {
            this.taxPrice = '0.00';
        }
        this.totalPrice = (Number(this.price) + Number(this.taxPrice)).toFixed(2);
        this.confirmPay();
    }

    cancel() {
        this.isComplete = false;
        this.switchStatus = 1;
    }
    changeAbn(): void {
        this.billing.abn = this.billing.abn.replace(/\s*/g, "");
    }
    back(): void {
        if (this.fromPage == 'download') {
            this.router.navigate(['download-app']);
        } else if (this.fromPage == 'invite') {
            this.router.navigate(['/signup-team-email']);
        } else if (this.fromPage == 'signup-verified') {
            this.router.navigate(['/signup-verified']);
        }
    }

    backDetail(): void {
        if (this.isComplete == true) {
            this.isComplete = false;
            this.switchStatus = 3;
        } else {
            this.switchStatus--;
        }
    }

    confirmPay() {
        this.loading = true;
        this.address.city = this.billing.city.replace(/^\s+|\s+$/g, "");
        this.address.line1 = this.billing.address.replace(/^\s+|\s+$/g, "");
        this.address.postal_code = this.billing.postcode.replace(/^\s+|\s+$/g, "");
        // this.address.country = this.billing.country;

        this.stripeBillDetail.email = this.billing.email.replace(/^\s+|\s+$/g, "");
        this.stripeBillDetail.name = this.billing.name.replace(/^\s+|\s+$/g, "");
        this.stripeBillDetail.address = this.address;
        stripes
            .createPaymentMethod({
                type: 'card',
                card: cardNumberElement, // 创建好的cardNumber实例
                billing_details: this.stripeBillDetail, // 组装好的billing_details对象数据
            }).then(res => {
                console.log(res, 'res');
                if (res.error) {
                    this.toastRepository.showDanger(res.error.message || '');
                    this.loading = false;
                } else {
                    this.otherInfo.paymentMethodId = res.paymentMethod.id || '';
                    //    调用保存回调接口
                    this.saveInfo();
                }
            }).catch(err => {
                this.loading = false;
                this.toastRepository.showDanger(err.error.message || '');

            });
    }

    saveInfo(): void {
        this.otherInfo.abn = this.billing.abn.replace(/^\s+|\s+$/g, "");
        this.otherInfo.country = this.billing.country.replace(/^\s+|\s+$/g, "");
        this.otherInfo.companyName = this.billing.companyName.replace(/^\s+|\s+$/g, "");
        this.otherInfo.billingContactFirstName = this.billing.firstName.replace(/^\s+|\s+$/g, "");
        this.otherInfo.billingContactLastName = this.billing.lastName.replace(/^\s+|\s+$/g, "");
        this.otherInfo.saveFlag = 1;
        this.billingRepository.save(this.otherInfo).subscribe(result => {
            this.userRepository.registerStep(RegisterStep.Paymentdetails).subscribe(v => { });
            localStorage.setItem('login', 'true');
            this.router.navigate(['/signup-complete-stripe']);
            localStorage.setItem('step', RegisterStep.Paymentdetails.toString());
           
            this.loading = false;
        });
    }

    confirm(): void {
        this.router.navigate(['/project']);
    }

    initElement(): void {
        let style = {
            base: {
                fontFamily: 'Inter',
                fontSize: '14px',
                color: '#121212',
                fontWeight: '600',
                lineHeight: '48px',
                paddingLeft: '24px',
                ':-webkit-autofill': {
                    color: 'rgba(0,0, 0, 0.35)',
                },
                ':focus': {
                    border: '1px solid #6F767E',
                },
                '::placeholder': {
                    fontFamily: 'Inter',
                    color: 'rgba(0, 0, 0, 0.25)',
                    fontSize: '14px',
                    fontWeight: '500',
                    paddingLeft: '24px',
                },
            },
        };
        // 创建cardNumber并实例化
        cardNumberElement = elements.create('cardNumber', {
            showIcon: false,
            placeholder: 'Card number',
            style: style,

        });
        cardNumberElement.on('change', (data) => {
            if (data.empty) {
                this.numberError = 'card number is required'
            } else {
                if (data.error) {
                    this.numberError = data.error.message || '';
                } else {
                    this.numberError = '';
                }
            }
            this.checkFirstBtn();
        });
        cardNumberElement.mount('#card-number-element');

        // 创建cardExpiry并实例化
        cardExpiryElement = elements.create('cardExpiry', {
            style: style,
        });
        cardExpiryElement.mount('#card-expiry-element');
        cardExpiryElement.on('change', (data) => {
            if (data.empty) {
                this.dateError = 'date is required'
            } else {
                if (data.error) {
                    this.dateError = 'Invalid expiration date';
                } else {
                    this.dateError = '';
                }
            }
            this.checkFirstBtn();
        });


        // 创建cardCvc并实例化
        cardCvcElement = elements.create('cardCvc', {
            placeholder: 'CVV',
            style: style,
        });
        cardCvcElement.mount('#card-cvc-element');
        cardCvcElement.on('change', (data) => {
            if (data.empty) {
                this.cvcError = 'CVV is required'
            } else {
                if (data.error) {
                    this.cvcError = 'Wrong CVV';
                } else {
                    this.cvcError = '';
                }
            }
            this.checkFirstBtn();
        });

    }

    capitalizeFirst(value: string): string {
        if (!value) return value;
        return value.charAt(0).toUpperCase() + value.slice(1);
      }

      capitalize(value: string): string {
        if (!value) return value;
        return value.toLowerCase().replace(/( |^)[a-z]/g, (L) => L.toUpperCase());  
    }
    capitalizeUpperCase(value: string): string {
        if (!value) return value;
        return value.toUpperCase();  
    }

}
