import {Component, OnInit} from '@angular/core';
import {UserRepository} from '../../../repository/user-repository';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastRepository} from '../../../repository/toast-repository';
import {NgxLoadingSpinnerService} from '@k-adam/ngx-loading-spinner';
import {UserForgot} from '../../../observable/model/user';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.less']
})
export class ResetPasswordComponent implements OnInit {

    userForgot: UserForgot = new UserForgot();
    showPassword: boolean;
    showConfirmPassword: boolean;
    constructor(
        private userRepository: UserRepository,
        private spinnerService: NgxLoadingSpinnerService,
        private route: ActivatedRoute,
        private router: Router,
        private toastRepository: ToastRepository) {
        this.userForgot.token = this.route.snapshot.queryParamMap.get("token");
        this.userForgot.account = this.route.snapshot.queryParamMap.get("account");
    }

    ngOnInit(): void {
    }
    geToBack(): void{
        this.router.navigate(['/login'])
    }
    confirm(): void {
        if (!this.userForgot.newPassword || !this.userForgot.confirmNewPassword) {
            this.toastRepository.showDanger('Password is required.');
            return;
        }
        if (this.userForgot.newPassword !== this.userForgot.confirmNewPassword) {
            this.toastRepository.showDanger('Passwords don\'t match.');
            return;
        }
        this.spinnerService.show();
        this.userRepository.updatePassword(this.userForgot).subscribe(result => {
            this.spinnerService.hide();
            this.toastRepository.showSuccess('Password updated successfully.');
            this.router.navigate(['/login']);
        });
    }
    changePassword(val):void{
        this.showPassword =  val == 'show' ? true :false;
    }
    changeConfirmPassword(val):void{
        this.showConfirmPassword =  val == 'show' ? true :false;
    }
}
