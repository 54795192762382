<div class="confirm-purchase">
    <div class="page-header">
        <h4 class="modal-title">Please Confirm Purchase </h4>
        <div class="sub-title">You will be able to access all the content available once the details have been received.</div>
    </div>
    <div class="page-body">
        <div class="form-two ">
            <p>Subscription</p>
            <p><strong>Professional plan</strong></p>
        </div>
        <div class="form-two line">
            <p>Frequency</p>
            <p><strong>Monthly</strong></p>
        </div>
        <div class="form-two">
            <p>Price</p>
            <p><strong>${{price}}</strong></p>
        </div>
        <div class="form-two">
            <p>Tax</p>
            <p><strong>${{taxPrice}}</strong></p>
        </div>
        <div class="form-two no-line">
            <p>Discount</p>
            <p class="green2-color"><strong>-$0.00</strong></p>
        </div>
        <div class="form-two">
            <p>Total</p>
            <p class="total"><strong>${{totalPrice}}</strong></p>
        </div>

        <div class="form-button">
            <div class="submit">
                <button class="btn btn-green bg-o" (click)="saveConfirm()">Confirm the payment</button>
            </div>
        </div>
    </div>

</div>
