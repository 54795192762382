import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Page } from '../../observable/model/base';
import { PageCondition } from '../../observable/model/condition';
import { BoxNumVo } from '../../observable/model/order';
import { TagByOrder } from '../../observable/model/tag-inspection';
import { TagInspectionRepository } from '../../repository/tag-inspection-repository';

@Component({
    selector: 'app-order-content',
    templateUrl: './order-content.component.html',
    styleUrls: ['./order-content.component.less']
})
export class OrderContentComponent implements OnInit {

    numberName: string;
    type: string;
    condition = new PageCondition('', 10);
    BoxNumVo: BoxNumVo = new BoxNumVo();
    tagByOrderPage: Page<TagByOrder> = new Page<TagByOrder>();
    currnetSort = new Map();
    screenHeight = document.body.clientHeight;
    constructor(private route: ActivatedRoute,
        private tagInspectionRepository: TagInspectionRepository,
        private router: Router) {
    }

    ngOnInit(): void {
        var freeTrial = document.getElementsByClassName("fourteen-tip");
        let hideHeight = freeTrial && freeTrial.length > 0 ? 80 : 0;
        this.condition.size = Math.trunc((this.screenHeight - 72 - 99 - 98 - 46 - hideHeight) / 75) - 1;
        this.route.queryParams.subscribe(params => {
            this.type = params.type;
            if (params.type == 'box') {
                this.condition.boxId = this.route.snapshot.paramMap.get('orderId');
                this.numberName = params.boxNum;

            } else {
                this.condition.orderId = this.route.snapshot.paramMap.get('orderId');
                this.numberName = params.orderNum;

            }
        });

        this.init();
    }

    init(): void {
        if (this.type == 'box') {
            this.getTagByBoxList();
            this.getTagBoxNumber();
        } else {
            this.getTagByOrderList();
            this.getOrderBoxNumber();
        }
    }

    getTagBoxNumber(): void {
        this.tagInspectionRepository.getTagStatusNumVo(this.condition).subscribe(result => {
            this.BoxNumVo = result;
            this.BoxNumVo.allNumber = result.tagActiveNum + result.tagReplacedLostNum + result.tagInactiveNum;;
        });
    }
    getOrderBoxNumber(): void {
        this.tagInspectionRepository.getOrderStatusNumVo(this.condition).subscribe(result => {
            this.BoxNumVo = result;
            this.BoxNumVo.allNumber = result.tagActiveNum + result.tagReplacedLostNum + result.tagInactiveNum;;
        });
    }
    searchKeywordByPress($event: KeyboardEvent): void {
        if ($event.key != 'Enter') {
            return;
        }
        this.init();
    }


    getTagByOrderList(): void {
        this.tagInspectionRepository.listTagByOrder(this.condition).subscribe(result => {
            this.tagByOrderPage = result;
        });
    }
    getTagByBoxList(): void {
        this.tagInspectionRepository.listTagByBox(this.condition).subscribe(result => {
            this.tagByOrderPage = result;
        });
    }


    sortTagByOrderList(sortName: string): void {
        this.condition.sortName = sortName;
        this.condition.sortOrder = this.condition.sortOrder == 'ASC' ? 'DESC' : 'ASC';
        this.init()
        this.currnetSort = new Map();
        this.currnetSort[sortName] = this.condition.sortOrder;
    }

    statueSearchITagByOrderList(tagStatus: string): void {
        this.condition.tagStatus = tagStatus;
        this.condition.current = 1;
        this.init()
    }

    getTagByOrderListByPage(current: number): void {
        this.condition.current = current;
        this.init()
    }


    goBack(): void {
        // if(this.type == 'order'){
        //     this.router.navigate(['order']);
        // }else{
        //     this.router.navigate(['tag']);
        // }
        history.back();
    }
}
