import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {Profile} from '../observable/model/user';

@Injectable({
    providedIn: 'root'
})
export class ProfileRepository {
    constructor(private http: HttpClient) {
    }

    public info(): Observable<any> {
        return this.http.get(environment.baseURL + '/pc/profile/info');
    }

    public save(profile: Profile): Observable<any> {
        return this.http.post(environment.baseURL + '/pc/profile/save', profile);
    }

    public send(account: string): Observable<any> {
        const params: HttpParams = new HttpParams();
        const body = params.append('account', account).toString();
        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        return this.http.post(environment.baseURL + `/pc/profile/send`, body, {headers});
    }

    public verify(code: string): Observable<any> {
        const params: HttpParams = new HttpParams();
        const body = params.append('code', code).toString();
        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        return this.http.post(environment.baseURL + `/pc/profile/verify`, body, {headers});
    }

    public reset(code: string, account: string): Observable<any> {
        const params: HttpParams = new HttpParams();
        const body = params.append('code', code).append('account', account).toString();
        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        return this.http.post(environment.baseURL + `/pc/profile/reset`, body, {headers});
    }
}
