import { Moment } from 'moment/moment';
import { Attachment } from './attachment';

export class CurrentUser {
    id?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    role?: string;
    openId?: string;
    mobile?: string;
    status?: number;
    createdAt?: Moment;
    avatar: Attachment;
}

export class RestPassword {
    validToken: string;
    password: string;
    confirmPassword: string;
}
export class TeamNumberInfo {
    teamActiveNum: number;
    teamPendingNum: number;
    teamDisabledNum: number;
    allNumber: number;
}

export class LoginUser {
    account?: string;
    password?: string;
    accessToken?: string;
}

export class InviteUser {
    account: string;
    roleType: number;
    phone: string;
    ticketNumber: string;
    ticketClass: string;
    branches:Array<string>;
}

export class Profile {
    companyName: string;
    companyPhone: string;
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    role: number;
    timezone: string;
    phone: string;
    region: string;
}

export class TeamUser {
    userId: string;
    fullName: string;
    firstName: string;
    lastName: string;
    companyName: string;
    selfFlag: number;
    roleType: number;
    account: string;
    phone: string;
    createTime: Date;
    status: string;
    token: string;
    ticketNumber: string;
    ticketClass: string;
    branches:Array<string>;
    
}

export class TeamUserPassword {
    firstName: string;
    lastName: string;
    token: string;
    password: string;
    phone: string;
    confirmPassword: string;
    trcheck: boolean;
}

export class UserForgot {
    account: string;
    code: string;
    token: string;
    newPassword: string;
    confirmNewPassword: string;
}

export class JWTUser {
    id: string;
    firstName: string;
    lastName: string;
    account: string;
    company: string;
    companyId: string;
    companyState: string;
    role: number;
    pubKey: string;
    step: number;
    phone:string;
}



