import {Component, ElementRef, KeyValueDiffer, KeyValueDiffers, OnInit} from '@angular/core';
import {Billing} from '../../../observable/model/billing';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastRepository} from '../../../repository/toast-repository';
import {ConfirmPurchasePayComponent} from '../confirm-purchase-pay/confirm-purchase-pay.component';
import {StripeInfo} from '../../../observable/model/base';
declare var Stripe: any;
var cardNumberElement : any;
var cardCvcElement : any;
var cardExpiryElement : any;
var elements : any;
var stripes: any;
@Component({
    selector: 'app-create-pay',
    templateUrl: './create-pay.component.html',
    styleUrls: ['./create-pay.component.less']
})
export class CreatePayComponent implements OnInit {
    billing = new Billing();
    stripeInfo = new StripeInfo();
    elements:undefined;
    switchStatus:number;
    numberError: string;
    dateError: string;
    cvcError: string;
    price:number;
    public loading = true;
    differ: KeyValueDiffer<string, any>;
    constructor( private differs: KeyValueDiffers,private el : ElementRef,private modalService: NgbModal,private toastRepository: ToastRepository,  private ngbActiveModal: NgbActiveModal) {
        this.differ = this.differs.find({}).create();
    }
    ngDoCheck() {
        const change = this.differ.diff(this);
        if (change) {
            change.forEachChangedItem(item => {
                if(item.key == 'switchStatus'){
                    // item.currentValue == 2
                    if(item.currentValue == 1){
                        this.checkFirstBtn();
                    }else if(item.currentValue == 2){
                        this.checkBtn();
                    }else if(item.currentValue == 3){
                        this.checkThirdBtn();
                    }
                }
            });
        }
    }
    ngOnInit(): void {
        this.switchStatus = 1;
        this.stripeInfo = JSON.parse(localStorage.getItem('stripeInfo'));
        stripes = new Stripe(this.stripeInfo.pubKey);
        elements = stripes.elements({
            locale: "en", // 设置默认显示语种   en 英文 cn 中文 auto 自动获取语种
        });
        setTimeout(()=>{
            this.initElement();
        },200)
    }
    checkFirstBtn():void{
        if(this.switchStatus == 1){
            if( (this.cvcError != '' ||  this.dateError != '' ||  this.numberError != '') || this.checkInput(this.billing.name) || this.checkInput(this.billing.companyName)){
                this.loading = true;
            }else{
                this.loading = false;
            }
        }
    }
    checkBtn():void{
        if(this.switchStatus == 2){
            if( this.billing.address && !this.checkInput(this.billing.address) && this.billing.city && !this.checkInput(this.billing.city)  && this.billing.country && !this.checkInput(this.billing.country)&& this.billing.postcode && !this.checkInput(this.billing.postcode)){
                this.loading = false;
            }else{
                this.loading = true;
            }
        }
    }
    checkThirdBtn():void{
        if(this.switchStatus == 3){
            if( this.billing.email && !this.checkInput(this.billing.email) && this.billing.firstName && !this.checkInput(this.billing.firstName) && this.billing.lastName && !this.checkInput(this.billing.lastName) ){
                this.loading = false;
            }else{
                this.loading = true;
            }
        }
    }
    checkInput(val){
        return val == undefined || val.replace(/(^\s*)|(\s*$)/g, "") == "";
    }
    changeAbn(): void{
        this.billing.abn = this.billing.abn.replace(/\s*/g,"");
    }
    openModal(): void {
        if(this.cvcError != '' || this.dateError != '' || this.numberError != ''){
            if(this.cvcError != ''){
                this.cvcError = 'CVV is required';
            }
            if(this.dateError != ''){
                this.dateError = 'Date is required';
            }
            if(this.numberError != ''){
                this.numberError = 'Card is required';
            }
            return;
        }

        if(this.switchStatus < 3){
            this.switchStatus ++ ;
            return;
        }

        const ngbModalRef = this.modalService.open(ConfirmPurchasePayComponent, {
            backdrop: 'static',
            keyboard: true,
            size: 'sm',
            windowClass:'pay',
            backdropClass:'pay-back'
        });
        if(document.getElementsByClassName('sub')){
            var ele = <HTMLElement>document.getElementsByClassName('sub')[0];
            ele.style.setProperty('display','none','important');
        }
        ngbModalRef.componentInstance.billing = this.billing;
        ngbModalRef.componentInstance.cardNumberElement = cardNumberElement;
        ngbModalRef.componentInstance.stripes = stripes;
        ngbModalRef.result.then(result => {
            if(!result){
                this.switchStatus = 1;
                if(document.getElementsByClassName('sub')){
                    var ele = <HTMLElement>document.getElementsByClassName('sub')[0];
                    ele.style.setProperty('display','block','important');
                }
                return;
            }
            this.cancel();
        }).catch((err) => {
            console.log(err,'???.....');
        });
    }



    cancel(): void {
        this.ngbActiveModal.close();
    }

    initElement(): void {
        let style = {
            base: {
                fontFamily: "Inter",
                fontSize: "14px",
                color: "#000000",
                fontWeight: "600",
                lineHeight: "48px",
                ":-webkit-autofill": {
                    color: "rgba(0,0,0,0.25)",
                },
                "::placeholder": {
                    color: "rgba(0,0,0,0.25)",
                    fontSize: "14px",
                    fontWeight: "500",
                    fontFamily: "Inter",
                },
            },

        };
        // 创建cardNumber并实例化
        cardNumberElement = elements.create("cardNumber", {
            showIcon: true,
            placeholder: "Enter card number",
            style: style,

        });
        cardNumberElement.on('change', (data) => {
            if(data.empty){
                this.numberError = 'card number is required'
            }else{
                if (data.error) {
                    this.numberError = data.error.message || '';
                } else {
                    this.numberError = '';
                }
            }
            this.checkFirstBtn();
        });
        cardNumberElement.mount('#card-number-element');

        // 创建cardExpiry并实例化
        cardExpiryElement = elements.create("cardExpiry", {
            style: style,
        });
        cardExpiryElement.mount("#card-expiry-element");
        cardExpiryElement.on('change', (data) => {
            if(data.empty){
                this.dateError = 'date is required'
            }else{
                if (data.error) {
                    this.dateError = 'Invalid expiration date';
                } else {
                    this.dateError = '';
                }
            }
            this.checkFirstBtn();
        });


        // 创建cardCvc并实例化
        cardCvcElement = elements.create("cardCvc", {
            placeholder: "Enter CVV",
            style: style,
        });
        cardCvcElement.mount("#card-cvc-element");
        cardCvcElement.on('change', (data) => {
            if(data.empty){
                this.cvcError = 'CVV is required'
            }else{
                if (data.error) {
                    this.cvcError = 'Wrong CVV';
                } else {
                    this.cvcError = '';
                }
            }
            this.checkFirstBtn();
        });

    }
}
