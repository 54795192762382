<div class="list-main">
    <div class="list">
        <h3>Pending Payment</h3>
        <p>When payment is yet to be received</p>
    </div>
    <div class="list active">
        <h3>Paid</h3>
        <p>Client has paid for the box of tags</p>
    </div>
    <div class="list">
        <h3>Sent to manufacturer</h3>
        <p>Client has paid for the box of tags</p>
    </div>
    <div class="list">
        <h3>Shipped</h3>
        <p>Tags have been shipped to the client</p>
    </div>
    <div class="list">
        <h3>Received</h3>
        <p>Shipment has been redceived by client</p>
    </div>
</div>