<div class="setting-right">
    <div class="top-bar">
        <h1>Settings</h1>
    </div>
    <!-- billing -->
    <div class="content billing">
        <div class="sub-head-box">
            <div class="filter-box">
                <div class="item" routerLinkActive="active">
                    <a [routerLink]="['/setting/profile']">Your Profile</a>
                </div>
                <div class="item" routerLinkActive="active">
                    <a [routerLink]="['/setting/branches']">Branches</a>
                </div>
                <div class="item" routerLinkActive="active">
                    <a [routerLink]="['/setting/handover']">Handover Certificates</a>
                </div>
                
                <div class="item" routerLinkActive="active">
                    <a [routerLink]="['/setting/subscriptions']">Your Subscription</a>
                </div>
                <div class="item" routerLinkActive="active">
                    <a [routerLink]="['/setting/billing']">Billing Information</a>
                </div>
            </div>
        </div>

        <div class="title-bar">
            <div class="title-left">
                <h3>Update billing information</h3>
            </div>
        </div>
        <div class="info-box">
            <div class="form-box">
                <form action="">
                    <div class="form-two">
                        <div class="form-control-group">
                            <label>Name on card</label>
                            <div class="input">
                                <input type="text" [(ngModel)]="billing.name" name="name" placeholder="Enter name" 
                                (ngModelChange)="billing.name = capitalize(billing.name)"
                                >
                            </div>
                        </div>
                        <div class="form-control-group">
                            <label>Card number </label>
                            <div class="input left-icon" [hidden]="showCode">
                                <i class="icon icon-18 icon-billing"></i>
                                <input type="text" [(ngModel)]="billing.number" name="number" (focus)="focusField('number')" maxlength="16" placeholder="Enter card number">
                            </div>
                            <div class="card-number" [hidden]="!showCode">
                                <div id="card-number-element"></div>
                                <span class="error" *ngIf="numberError">{{ numberError }}</span>
                            </div>
                        </div>
                    </div>

                    <div class="form-four">
                        <div class="form-control-group form-stripe-group">
                            <div class="company-group">
                                <label>Expiration date</label>
                                <div class="input" [hidden]="showDate">
                                    <input type="text" class="form-control" placeholder="00/00" [(ngModel)]="expirationDate" (focus)="showDate=true" (focus)="focusField('date')"
                                        name="month" />
                                    <!--                                    <span> / </span>-->
                                    <!--                                    <input type="text" class="form-control" placeholder="00" [(ngModel)]="billing.year" (focus)="showDate=true" (focus)="focusDate()"-->
                                    <!--                                           name="year"/>-->
                                </div>
                                <div class="card-number" [hidden]="!showDate">
                                    <div id="card-expiry-element"></div>
                                    <span class="error" *ngIf="dateError">{{ dateError }}</span>
                                </div>
                            </div>
                            <div class="company-group  company-time-group">
                                <label>CVV </label>
                                <div class="input" [hidden]="showCvc">
                                    <input type="text" class="form-control" placeholder="Enter CVV" [(ngModel)]="billing.cvv" (focus)="focusField('cvv')" name="cvv" />
                                </div>
                                <div class="card-number" [hidden]="!showCvc">
                                    <div id="card-cvc-element"></div>
                                    <span class="error" *ngIf="cvcError">{{ cvcError }}</span>
                                </div>
                            </div>

                        </div>
                        <div class="form-control-group company-group company-control-group">
                            <label>Company Name</label>
                            <div class="input">
                                <input type="text" class="form-control" [(ngModel)]="billing.companyName" placeholder="Enter company name" name="companyName" 
                                (ngModelChange)="billing.companyName = capitalize(billing.companyName)"
                                />
                            </div>
                        </div>
                    </div>

                    <hr>
                    <div class="title-bar">
                        <div class="title-left">
                            <h3>Update additional information</h3>
                        </div>
                    </div>

                    <div class="form-two">
                        <div class="form-control-group">
                            <label>ABN/Tax ID</label>
                            <div class="input">
                                <input type="text" class="form-control" [(ngModel)]="billing.abn" (input)="changeAbn()" placeholder="Enter ABN" name="abn" 
                                (ngModelChange)="billing.abn = capitalize(billing.abn)"
                                />
                            </div>
                        </div>

                        <div class="form-control-group">
                            <label>Address</label>
                            <div class="input">
                                <input type="text" class="form-control" [(ngModel)]="billing.address" placeholder="Enter address" name="address"
                                (ngModelChange)="billing.address = capitalize(billing.address)"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="form-two">
                        <div class="form-control-group">
                            <label>City</label>
                            <div class="input">
                                <input type="text" class="form-control" [(ngModel)]="billing.city" placeholder="Enter city" name="city" 
                                (ngModelChange)="billing.city = capitalize(billing.city)"
                                />
                            </div>
                        </div>
                        <div class="form-control-group">
                            <label>Country</label>
                            <div class="input">
                                <input type="text" class="form-control" [(ngModel)]="billing.country" placeholder="Enter country" name="country" 
                                (ngModelChange)="billing.country = capitalize(billing.country)"/>
                            </div>
                        </div>
                    </div>
                    <div class="form-two">
                        <div class="form-control-group">
                            <label>Postcode</label>
                            <div class="input">
                                <input type="text" class="form-control" [(ngModel)]="billing.postcode" placeholder="Enter postcode" name="postcode" 
                                (ngModelChange)="billing.postcode = capitalizeUpperCase(billing.postcode)"
                                />
                            </div>
                        </div>
                        <div class="form-control-group">
                            <label>Billing contact email</label>
                            <div class="input">
                                <input type="text" class="form-control" [(ngModel)]="billing.email" placeholder="Enter email" name="email" />
                            </div>
                        </div>
                    </div>
                    <div class="form-two">
                        <div class="form-control-group">
                            <label>Billing contact first Name</label>
                            <div class="input">
                                <input type="text" class="form-control" [(ngModel)]="billing.firstName" placeholder="Enter billing contact first name" name="firstName" 
                                (ngModelChange)="billing.firstName = capitalizeFirst(billing.firstName)"
                                />
                            </div>
                        </div>
                        <div class="form-control-group">
                            <label>Billing contact last Name</label>
                            <div class="input">
                                <input type="text" class="form-control" [(ngModel)]="billing.lastName" placeholder="Enter billing contact last name" name="lastName" 
                                (ngModelChange)="billing.lastName = capitalizeFirst(billing.lastName)"
                                />
                            </div>
                        </div>
                    </div>
                </form>

                <div class="footer-btn">
                    <button class="btn btn-green2 bg-o update" [disabled]="loading" (click)="save()">Update Billing Info</button>
                </div>
            </div>
        </div>
    </div>
</div>