import {Component, KeyValueDiffer, KeyValueDiffers, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ToastRepository} from '../../../repository/toast-repository';
import {NgxLoadingSpinnerService} from '@k-adam/ngx-loading-spinner';
import {UserRepository} from '../../../repository/user-repository';
import {Address, Billing, OtherInfo, StripeBillDetail} from '../../../observable/model/billing';
import {BillingRepository} from '../../../repository/billing-repository';
import {SubscriptionRepository} from '../../../repository/subscription-repository';
declare var Stripe: any;
var cardNumberElement : any;
var cardCvcElement : any;
var cardExpiryElement : any;
var elements : any;
var stripes: any;
@Component({
    selector: 'app-forgot',
    templateUrl: './detail.component.html',
    styleUrls: ['./detail.component.less']
})
export class DetailComponent implements OnInit {
    public billing = new Billing();
    otherInfo = new OtherInfo();
    stripeBillDetail = new StripeBillDetail();
    address = new Address();
    numberError: string;
    dateError: string;
    cvcError: string;
    abnError: string;
    public loading = false;
    differ: KeyValueDiffer<string, any>;
    constructor(
        private differs: KeyValueDiffers,
        private router: Router,
        private billingRepository: BillingRepository,
        private spinnerService: NgxLoadingSpinnerService,
        private subscriptionRepository: SubscriptionRepository,
        private userRepository: UserRepository,
        private toastRepository: ToastRepository) {
        this.differ = this.differs.find({}).create();
    }

    ngOnInit(): void {
        this.getPubkeyInfo();
        this.checkBtn();
    }
    ngDoCheck() {
        const change = this.differ.diff(this.billing);
        if (change) {
            console.log(change,'>>>>>');
            this.checkBtn()
        }
    }
    getPubkeyInfo(){
        this.subscriptionRepository.getPubkey().subscribe(result => {
            if(result){
                stripes = new Stripe(result);
                elements = stripes.elements({
                    locale: "en", // 设置默认显示语种   en 英文 cn 中文 auto 自动获取语种
                });
                setTimeout(()=>{
                    this.initElement();
                },200)
            }
        })
    }



    checkBtn(){
        let result = false;
        for (const key in this.billing) {
            if( key != 'abn' && key != 'cvv' && key != 'number' && key != 'month' && key != 'year'){
                if(this.billing[key] == '') {
                    result = true;
                    break;
                }
            }
        }
        if(result || this.cvcError != '' || this.dateError != '' || this.numberError != '' ){
            this.loading = true;
        }else{
            this.loading = false;
        }
    }

    changeAbn():void{
        this.billing.abn = this.billing.abn.replace(/\s*/g,"");
        this.abnError = this.billing.abn == '' ? 'Couldn’t identify ABN' : '';
    }

    confirm(): void {
        if(this.cvcError != '' || this.dateError != '' || this.numberError != ''){
            this.toastRepository.showDanger('Please enter your credit card information');
            return;
        }
        this.loading = true;
        this.address.city = this.billing.city;
        this.address.line1 = this.billing.address;
        this.address.postal_code = this.billing.postcode;
        // this.address.country = this.billing.country;

        this.stripeBillDetail.email = this.billing.email;
        this.stripeBillDetail.name = this.billing.name;
        this.stripeBillDetail.address = this.address;
        stripes
            .createPaymentMethod({
                type: "card",
                card: cardNumberElement, // 创建好的cardNumber实例
                billing_details: this.stripeBillDetail, // 组装好的billing_details对象数据
            }).then(res => {
            console.log(res,'res');
            if(res.error){
                this.loading = false;
                this.toastRepository.showDanger(res.error.message || '');
            }else {
                //    调用保存回调接口
                this.otherInfo.paymentMethodId = res.paymentMethod.id || '';
                this.saveInfo();
            }
        }).catch(err=>{
            console.log(err,'rrreee');
            this.toastRepository.showDanger(err.error.message || '');

        })
    }
    saveInfo(): void{
        this.otherInfo.abn = this.billing.abn
        this.otherInfo.country = this.billing.country
        this.otherInfo.billingContactFirstName = this.billing.firstName
        this.otherInfo.billingContactLastName = this.billing.lastName
        this.otherInfo.saveFlag = this.billing.saveFlag ? 1 : 0;
        this.otherInfo.companyName = this.billing.companyName ;
        this.billingRepository.save(this.otherInfo).subscribe(result => {
            this.loading = false;
        });
    }

    initElement(): void {
        let style = {
            base: {
                fontFamily: "Inter",
                fontSize: "14px",
                color: "#000000",
                fontWeight: "600",
                lineHeight: "48px",
                ":-webkit-autofill": {
                    color: "rgba(0,0,0,0.25)",
                },
                "::placeholder": {
                    color: "rgba(0,0,0,0.25)",
                    fontSize: "14px",
                    fontWeight: "500",
                    fontFamily: "Inter",
                },
            },

        };
        // 创建cardNumber并实例化
        cardNumberElement = elements.create("cardNumber", {
            showIcon: true,
            placeholder: "Enter number here",
            style: style,

        });
        cardNumberElement.on("change", ({error}) => {
            if (error) {
                this.numberError = error.message || "";
            } else {
                this.numberError = "";
            }
        });
        cardNumberElement.mount('#card-number-element');
        cardNumberElement.on("blur", (event) => {
            this.checkBtn();
        });

        // 创建cardExpiry并实例化
        cardExpiryElement = elements.create("cardExpiry", {
            placeholder: "00/00",
            style: style,
        });
        cardExpiryElement.mount("#card-expiry-element");
        cardExpiryElement.on("change", ({error}) => {
            if (error) {
                this.dateError = "Wrong Expiration date";
            } else {
                this.dateError = "";
            }
        });
        cardExpiryElement.on("blur", (event) => {
            this.checkBtn();
        });


        // 创建cardCvc并实例化
        cardCvcElement = elements.create("cardCvc", {
            placeholder: "Enter CVV here",
            style: style,
        });
        cardCvcElement.mount("#card-cvc-element");
        cardCvcElement.on("change", ({error}) => {
            if (error) {
                this.cvcError = "Wrong CVV";
            } else {
                this.cvcError = "";
            }
        });
        cardCvcElement.on("blur", (event) => {
            this.checkBtn();
        });

    }



}
