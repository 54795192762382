import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Subject, Subscription, timer} from 'rxjs';
import {map} from 'rxjs/operators';
import {UserRepository} from '../../../repository/user-repository';
import {EnterNewEmailComponent} from '../enter-new-email/enter-new-email.component';
import {ProfileRepository} from '../../../repository/profile-repository';
import {ToastRepository} from '../../../repository/toast-repository';
import {LocalStorageObServable} from '../../../observable/local-storage-observable';
import { JWTUser } from 'src/app/observable/model/user';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
    selector: 'app-email-code',
    templateUrl: './email-code.component.html',
    styleUrls: ['./email-code.component.less']
})
export class EmailCodeComponent implements OnInit, AfterViewInit {

    @ViewChild('input0')
    public input0: ElementRef<HTMLInputElement>;

    @ViewChild('input1')
    public input1: ElementRef<HTMLInputElement>;

    @ViewChild('input2')
    public input2: ElementRef<HTMLInputElement>;

    @ViewChild('input3')
    public input3: ElementRef<HTMLInputElement>;

    public current: Subject<number> = new Subject<number>();

    public num1;

    public num2;

    public num3;

    public num4;

    account?: string;

    canResend = true;

    displayText: string;

    displayTextSubscription: Subscription;

    reset = false;

    constructor(private router: Router,
                public activatedRoute: ActivatedRoute,
                public profileRepository: ProfileRepository,
                private ngbActiveModal: NgbActiveModal,
                private toastRepository: ToastRepository,
                public localStorageObservrable: LocalStorageObServable,
                private ngbModal: NgbModal,
                private jwt: JwtHelperService,) {
        this.account = activatedRoute.snapshot.queryParamMap.get('account');
        this.startTimer();
    }


    startTimer(): void {
        this.displayTextSubscription = timer(0, 1000).pipe(
            map(time => 60 - time)).subscribe(time => {
            if (time == 0) {
                this.displayText = 'Resend code';
                this.displayTextSubscription.unsubscribe();
            } else {
                this.displayText = `Resend code in ${time} s`;
            }
        });
    }

    ngAfterViewInit(): void {
        this.current.asObservable().subscribe(num => {
            setTimeout(() => {
                console.log(1);
                this.getInputs()[num].nativeElement.focus();
            });
        });
        setTimeout(() => {
            this.current.next(0);
        });
    }

    getInputs(): Array<ElementRef<HTMLInputElement>> {
        return [this.input0, this.input1, this.input2, this.input3];
    }

    ngOnInit(): void {
        this.profileRepository.send(this.account).subscribe(result => {
            this.current.next(0);
        });
    }

    resend(): void {
        if (this.account == null) {
            return;
        }
        if (!this.displayTextSubscription.closed) {
            return;
        }
        this.startTimer();
        this.profileRepository.send(this.account).subscribe(() => {
        });
    }

    verify(): void {
        const code = [this.num1, this.num2, this.num3, this.num4].join('');
        if (code.length != 4) {
            return;
        }
        if (this.reset) {
            this.profileRepository.reset(code, this.account).subscribe(result => {
                const jwtUser = this.jwt.decodeToken(result).inf as JWTUser;
                this.localStorageObservrable.setItem('accessToken', result);
                this.localStorageObservrable.setItem('step', jwtUser.step);
                this.toastRepository.showSuccess('Email updated successfully. Please use the new email moving forward.');
                this.cancel();
            });
        } else {
            this.profileRepository.verify(code).subscribe((accessToken: string) => {
                const ngbModalRef = this.ngbModal.open(EnterNewEmailComponent, {backdrop: 'static', keyboard: true, size: 'sm'});
                ngbModalRef.result.then(result => {
                    this.cancel();
                });
            });
        }

    }

    changeModel(value: number, order: number): void {
        if (value.toString().length > 0) {
            order++;
            order = order > 3 ? 3 : order;
        } else {
            order--;
            order = order < 0 ? 0 : order;
        }
        this.current.next(order);
    }

    cancel(): void {
        this.ngbActiveModal.close();
    }
}
