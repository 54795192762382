import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Billing, Address, StripeBillDetail, OtherInfo} from '../../../observable/model/billing';
import {ToastRepository} from '../../../repository/toast-repository';
import {SubscriptionRepository} from '../../../repository/subscription-repository';
import {BillingRepository} from '../../../repository/billing-repository';
import {UserRepository} from '../../../repository/user-repository';

@Component({
    selector: 'app-signup-verified',
    templateUrl: './signup-complete-stripe.component.html',
    styleUrls: ['./signup-complete-stripe.component.less']
})
export class SignupCompleteStripeComponent implements OnInit {

    constructor(private userRepository: UserRepository,private billingRepository: BillingRepository,private router: Router,private toastRepository: ToastRepository,  private subscriptionRepository: SubscriptionRepository,) {
    }

    ngOnInit(): void {

    }

    next(){
        this.toastRepository.showSuccess('Welcome to Scafflinq.');
        this.router.navigate(['/project'])
    }

}
