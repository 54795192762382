import { Branch ,BranchNumberInfo } from 'src/app/observable/model/branch';
import { Component, OnInit } from '@angular/core';
import { AddBranchComponent } from '../../modal/add-branch/add-branch.component'; 
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BranchesRepository } from 'src/app/repository/branches-repository'; 
import { BranchNoticeComponent } from '../../modal/branch-notice/branch-notice.component';
import { Page } from 'src/app/observable/model/base';
import { PageCondition } from 'src/app/observable/model/condition';

@Component({
  selector: 'app-branches',
  templateUrl: './branches.component.html',
  styleUrls: ['./branches.component.less']
})
export class BranchesComponent implements OnInit {
  branchesPage: Page<Branch> = new Page<Branch>();
  branchNumberInfo = new BranchNumberInfo();
  condition = new PageCondition('', 10);
  currentClass = '';
 
  constructor(private ngbModal: NgbModal,
              private branchesRepository: BranchesRepository) { 
                
              }

  ngOnInit(): void {
    this.branchNumberInfo.active = 0;
    this.branchNumberInfo.inactive = 0;
    this.condition.size =  6;
    this.initData();
   
  }

  initData(): void {
    this.getBranchData();
  }

  statueSearchBranchList(status: string): void {
    this.condition.status = status;
    this.condition.current = 1;
    this.getBranchData();
}


  getBranchNumber(): void {
    this.branchesRepository.getBranchNumber().subscribe(result => {
      const requiredFields = {
        all: 0,
        active: 0,
        inactive: 0
      };
      for (const field in requiredFields) {
        if (!(field in result)) {
          result[field] = requiredFields[field];
        }
      }
      this.branchNumberInfo = result;      
    });
}

  getBranchListByPage(current: number): void {
    this.condition.current = current;
    this.getBranchData();
}

  getBranchData(): void {
    this.getBranchNumber();
    this.branchesRepository.getBranchList(this.condition).subscribe(res => {
      if (res) {
        this.branchesPage = res;
      
      }
    });
  }



  

  addBranch(): void {
    const ngbModalRef = this.ngbModal.open(AddBranchComponent, {
      backdrop: 'static',
      keyboard: true,
      size: 'sm'
    });

    ngbModalRef.result.then(result => {
      this.initData();
    });
  }

  editBranch(branch: Branch): void {
    const branchData = { ...branch };
    const ngbModalRef = this.ngbModal.open(AddBranchComponent, {
      backdrop: 'static',
      keyboard: true,
      size: 'sm'
    });

    ngbModalRef.componentInstance.branch = branchData;
    ngbModalRef.componentInstance.isEdit = true;
    ngbModalRef.result.then(result => {
      this.initData();
    });
  }
 
}
