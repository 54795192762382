import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'userRolePipe'
})
export class UserRolePipe implements PipeTransform {
    transform(value: number): string {
        switch (value) {
            case 1:
                return 'Admin';
            case 2:
                return 'Inspector';
            default:
                return 'Inspector';
        }
    }
}
