<div class="create-pay invitation-modal">
    <div class="modal-header">
        <h4 class="modal-title">Member Invite</h4>
        <div class="sub-title">Enter your team members details below. We will send them an invite.</div>
    </div>
    <div class="modal-body">
        <div class="form modal-form">
            <div class="form-group">
                <!--https://www.cnblogs.com/ypppt/p/12912980.html?ivk_sa=1024320u-->
                <label class="control-label">&#917;mail address</label>
                <input type="text" class="form-control" placeholder="johnsmith@email.com" autocomplete="false"
                    autofocus="false" [(ngModel)]="inviteUser.account" />
            </div>
            <div class="form-group">
                <label class="control-label">Role</label>
                <div class="select special-select">
                    <ng-select id="roleType" name="roleType" required class="select-single" placeholder="Select role"
                        [(ngModel)]="inviteUser.roleType" [searchable]="false"
                        (change)="onRoleTypeChange()"
                        >
                        <ng-option [value]="1">Admin</ng-option>
                        <ng-option [value]="2">Inspector</ng-option>
                    </ng-select>
                    <i class="icon icon-18 icon-down-arrow"></i>
                </div>
            </div>
            <div class="form-group">
                <label class="control-label">Mobile number</label>
                <input type="text" class="form-control" placeholder="0412 345 678" autocomplete="false"
                    autofocus="false" [(ngModel)]="inviteUser.phone" />
            </div>
            <div class="form-group">
                <label class="control-label">Scaffold ticket/license no</label>
                <input type="text" class="form-control" placeholder="HRW463875" autocomplete="false" autofocus="false"
                    [(ngModel)]="inviteUser.ticketNumber" />
            </div>
            <div class="form-group">
                <label class="control-label">Ticket class</label>
                <input type="text" class="form-control" placeholder="e.g. Scaffold Advanced, Scaffold Intermediate"
                    autocomplete="false" autofocus="false" [(ngModel)]="inviteUser.ticketClass" />
            </div>
            <div class="form-group">
                <label class="control-label">Team member has access to these Branches:</label>
            </div>
            <div class="select-content content">
                <div class="select-item" *ngFor="let item of branchList">
                    <label>
                        <input type="checkbox" [disabled]="inviteUser.roleType == 1" [(ngModel)]="item.checked" (change)="selectBranchBox(item)"> {{ item.name }}
                    </label>
                </div>
            </div>
            <div class="tip" *ngIf="inviteUser.roleType == 1">
                <p>Admins have access to all Branches</p>
            </div>
            <div class="form-button form-button-two-with_div">
                <div class="close">
                    <button class="color-o" (click)="cancel()">Cancel</button>
                </div>
                <div class="submit">
                    <button class="btn btn-o" [disabled]="isLoading" (click)="invite()">Invite</button>
                </div>

            </div>
        </div>
    </div>

</div>