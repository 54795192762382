import { Component, KeyValueDiffer, KeyValueDiffers, OnInit } from '@angular/core';
import { Address, Billing, OtherInfo, StripeBillDetail, StripeBillInfo, StripeCard } from '../../../observable/model/billing';
import { BillingRepository } from '../../../repository/billing-repository';
import { ToastRepository } from '../../../repository/toast-repository';
import { UserRepository } from '../../../repository/user-repository';
declare var Stripe: any;
var cardNumberElement : any;
var cardCvcElement : any;
var cardExpiryElement : any;
var elements : any;
var stripes: any;
@Component({
    selector: 'app-billing',
    templateUrl: './billing.component.html',
    styleUrls: ['./billing.component.less']
})
export class BillingComponent implements OnInit {

    public billing = new Billing();
    public copyBilling = new Billing();
    public otherInfo = new OtherInfo();
    public stripeBillDetail = new StripeBillDetail();
    public StripeBillInfo = new StripeBillInfo();
    public stripeCard = new StripeCard();
    public address = new Address();
    expirationDate:string;
    pubKey: string;
    numberError: string;
    dateError: string;
    cvcError: string;
    showCode: boolean;
    numberComplete = false;
    cvcComplete = false;
    dateComplete = false;
    showCvc: boolean;
    showDate: boolean;
    loading = false;
    differ: KeyValueDiffer<string, any>;
    constructor(private differs: KeyValueDiffers,private userRepository: UserRepository,private billingRepository: BillingRepository, private toastRepository: ToastRepository) {
        this.differ = this.differs.find({}).create();
    }
    ngOnInit(): void {
        
        const stripeInfo = localStorage.getItem('stripeInfo');
        this.pubKey = JSON.parse(stripeInfo).pubKey || '';
        stripes = new Stripe(this.pubKey);
        elements = stripes.elements({
            locale: "en", // 设置默认显示语种   en 英文 cn 中文 auto 自动获取语种
        });
        this.initElement();
        this.billingRepository.info().subscribe(result => {
        
            if(result){
                this.getValue(result);

            }else{
                this.showCvc = false;
                this.showDate = false;
                this.showCode = false;

                this.billing.number = '';
                this.billing.cvv = '';
                this.billing.email = '';
                this.billing.postcode = '';
                this.billing.firstName = '';
                this.billing.lastName = '';
                this.billing.month = '';
                this.billing.year = '';
                this.billing.address = '';
                this.billing.abn = '';
                this.billing.city = '';
                this.billing.name = '';
                this.billing.country = '';
                this.billing.companyName = '';
            }
        });
    }

    getValue(result){
        this.billing.number = result.cardNum;
        this.billing.cvv = '***';

        this.billing.firstName = result.billingContactFirstName;
        this.billing.lastName = result.billingContactLastName;
        this.billing.month = result.cardExpMonth.length == 1 ? '0' + result.cardExpMonth : result.cardExpMonth;
        this.billing.year = result.cardExpYear.substr(-2);
        this.billing.abn = result.abn;

        this.billing.address = result.address;
        this.billing.city = result.city;
        this.billing.name = result.cardName;
        this.billing.email = result.billingContactEmail;
        this.billing.postcode = result.postCode;

        this.billing.country = result.country;
        this.billing.companyName = result.companyName;
        this.billing.paymentMethodId = result.paymentMethodId;
        this.copyBilling = JSON.parse(JSON.stringify(this.billing));
        this.expirationDate =  this.billing.month + ' / ' +  this.billing.year
        this.numberError = '';
        this.dateError = "";
        this.cvcError = "";
 
    }
    ngDoCheck() {
        const change = this.differ.diff(this.billing);
        if (change) {
            this.checkBtn()
        }
    }
    checkInput(val){
        return val == undefined || val.replace(/(^\s*)|(\s*$)/g, "") == "";
    }
    checkBtn(){

        let result = true;
        if(JSON.stringify(this.billing) != '{}'){
            for (const key in this.billing) {
                if(key != 'abn' && key != 'cvv' && key != 'number' && key != 'month' && key != 'year'){
                    if(this.checkInput(this.billing[key])) {
                        result = false;
                        break;
                    }
                }
            }
        }else{
            result = false;
        }
        if(result && (this.numberError == '' || (this.numberError == undefined && this.billing.number)) &&  (this.dateError == ''|| (this.dateError == undefined && this.billing.year)) && (this.cvcError == ''|| (this.cvcError == undefined && this.billing.cvv)) ){
            this.loading = false;
        }else{
            this.loading = true;
        }
    }

    focusField(type):void{
        this.showCvc=true;
        this.showCode = true;
        this.showDate = true;
        this.loading = true;
        setTimeout(()=>{
            if(type == 'number'){
                cardNumberElement.focus();
            }else if(type == 'date'){
                cardExpiryElement.focus();
            }else{
                cardCvcElement.focus();
            }
        },200)
    }

    changeAbn(): void{
        this.billing.abn = this.billing.abn.replace(/\s*/g,"");
    }
    initElement(){
        let style = {
            base: {
                fontFamily: "Inter",
                fontSize: "14px",
                color: "#000000",
                fontWeight: "600",
                lineHeight: "48px",
                ":-webkit-autofill": {
                    color: "rgba(0, 0, 0, 0.25)",
                },
                "::placeholder": {
                    color: "rgba(0, 0, 0, 0.25)",
                    fontSize: "14px",
                    fontWeight: "500",
                    fontFamily: "Inter",
                },
            },
        };
        // 创建cardNumber并实例化
        cardNumberElement = elements.create("cardNumber", {
            placeholder: "Enter card number",
            style: style,
        });
        cardNumberElement.on("change", (res) => {
            console.log(res,'....');
            if (res.error) {
                this.numberError = res.error.message;
            } else {
                if(res.empty){
                    this.numberError = undefined;
                    this.showCode = false;
                }else{
                    this.numberError = "";
                    this.numberComplete = true;
                }
            }
            console.log(this.numberError,'this.numberError1');
            this.checkBtn();
        });
        cardNumberElement.mount('#card-number-element');
        cardNumberElement.on("blur", (res) => {
            console.log(this.numberError,'this.numberError2');
            if(this.numberError == undefined  || (this.numberError == '' && !this.numberComplete)){
                this.showCvc=false;
                this.showCode = false;
                this.showDate = false;
                this.loading = false;
            }
            this.checkBtn();
        });
        // 创建cardExpiry并实例化
        cardExpiryElement = elements.create("cardExpiry", {
            style: style,
            placeholder: "00/00",
        });
        cardExpiryElement.mount("#card-expiry-element");
        cardExpiryElement.on("change", (res) => {
            if (res.error) {
                this.dateError = res.error.message;
            } else {
                if(res.empty){
                    this.dateError = undefined;
                    this.showDate = false
                }else{
                    this.dateError = "";
                    this.dateComplete = true;
                }
            }
            this.checkBtn();
        });
        cardExpiryElement.on("blur", (event) => {
            // this.showDate = false
            if(this.dateError == undefined || (this.dateError == '' && !this.dateComplete)){
                this.showCvc=false;
                this.showCode = false;
                this.showDate = false;
                this.loading = false;
            }
            this.checkBtn();
        });

        // 创建cardCvc并实例化
        cardCvcElement = elements.create("cardCvc", {
            placeholder: "Enter CVV",
            style: style,
        });
        cardCvcElement.mount("#card-cvc-element");
        cardCvcElement.on("change", (res) => {
            if (res.error) {
                this.cvcError = res.error.message;
            } else {
                if(res.empty){
                    this.cvcError = undefined;
                    this.showCvc = false
                }else{
                    this.cvcError = "";
                    this.cvcComplete = true;
                }
            }
            this.checkBtn();
        });
        cardCvcElement.on("blur", (event) => {
            if(this.cvcError == undefined || (this.cvcError == '' && !this.cvcComplete)){
                this.showCvc=false;
                this.showCode = false;
                this.showDate = false;
                this.loading = false;
            }
            this.checkBtn();
        });
    }
    validateField():void{
        var list = [];
        if(this.billing.month && this.billing.month.indexOf('*') > -1){
            list.push('Date');
        }
        if(this.billing.cvv && this.billing.cvv.indexOf('*') > -1){
            list.push('Cvv');
        }
        if(this.billing.number && this.billing.number.indexOf('*') > -1){
            list.push('Card number');
        }
        this.showDate = true;
        this.showCvc = true;
        this.showCode = true;
        if(list.length > 0){
            this.toastRepository.showDanger(list.join(', ') + ' are required');
            return;
        }
    }
    save(): void {
        let other = this.copyBilling.name != this.billing.name || this.copyBilling.companyName != this.billing.companyName || this.copyBilling.email != this.billing.email || this.copyBilling.address != this.billing.address || this.copyBilling.city != this.billing.city || this.copyBilling.postcode != this.billing.postcode ;
        if( this.showCvc || this.showDate || this.showCode){
            this.loading = true
            // if(this.showCvc || this.showDate || this.showCode){
            //     this.validateField();
            // }
            this.updateStripe('refresh');
         
           
        }else if(other){
            this.loading = true
            this.updateInfo()
        }else{
            
            this.otherInfo.paymentMethodId = this.billing.paymentMethodId;
            this.updateDetail();
       
        }
    }
    updateInfo(): void{
        this.StripeBillInfo.email = this.validate(this.billing.email);
        this.StripeBillInfo.name = this.validate(this.billing.name);
        this.StripeBillInfo.paymentMethodId = this.billing.paymentMethodId;
        this.StripeBillInfo.addressLine1 = this.validate(this.billing.address);
        this.StripeBillInfo.addressPostalCode = this.validate(this.billing.postcode);
        this.StripeBillInfo.addressCity = this.validate(this.billing.city);
        this.billingRepository.update(this.StripeBillInfo).subscribe(result => {
            this.otherInfo.paymentMethodId =  this.billing.paymentMethodId;
            this.updateDetail();
            this.billing.address = this.StripeBillInfo.addressLine1;
            this.billing.city = this.StripeBillInfo.addressCity;
            this.billing.name = this.StripeBillInfo.name;
            this.billing.email = this.StripeBillInfo.email;
            this.billing.postcode = this.StripeBillInfo.addressPostalCode;
            // this.toastRepository.showSuccess('Billing details is saved successfully.');
            // this.showCvc = false;
            // this.showCode = false;
            // this.showDate = false;
            // this.loading = false;
        },error => {
            this.loading = false;
            console.log(error,'..');})
    }
    updateDetail(value?:string):void {
        let refreshPage = value == 'refresh' ? true : false;
        this.loading = true
        this.otherInfo.abn = this.billing.abn;
        this.otherInfo.country = this.billing.country.replace(/^\s+|\s+$/g,"");
        this.otherInfo.billingContactFirstName = this.billing.firstName.replace(/^\s+|\s+$/g,"");
        this.otherInfo.billingContactLastName = this.billing.lastName.replace(/^\s+|\s+$/g,"");
        this.otherInfo.companyName = this.billing.companyName.replace(/^\s+|\s+$/g,"");
        this.otherInfo.billingContactEmail = this.billing.email.replace(/^\s+|\s+$/g,"");
        this.otherInfo.saveFlag =  1 ;
        this.billingRepository.save(this.otherInfo).subscribe(result => {
            this.loading = false;
            if(result){
                this.getValue(result);
                this.showCvc = false;
                this.showCode = false;
                this.showDate = false;
                this.toastRepository.showSuccess('Billing details updated successfully.');
                this.userRepository.getStripeStatus().subscribe(event => {

                    localStorage.setItem('stripeInfo',JSON.stringify(event));
                    // window.location.reload();
                })
                if(refreshPage){
                    window.location.reload();
                    localStorage.removeItem('responseCode');
                }
            }
        });
    }
    validate(val){
        if(!val){
            return '';
        }
        return val.replace(/^\s+|\s+$/g,"");
    }
    updateStripe(value:string): void {
        let refreshBool = value == 'refresh' ? true : false;
        this.stripeCard.exp_month = this.billing.month ;
        this.stripeCard.exp_year = this.billing.year;
        this.stripeCard.cvc = this.billing.cvv;
        this.stripeCard.number = this.billing.number;

        this.address.city = this.validate(this.billing.city);
        this.address.line1 = this.validate(this.billing.address);
        this.address.postal_code = this.validate(this.billing.postcode);

        this.stripeBillDetail.email = this.validate(this.billing.email);
        this.stripeBillDetail.name = this.validate(this.billing.name);
        this.stripeBillDetail.address = this.address;
        stripes
            .createPaymentMethod({
                type: "card",
                card: cardNumberElement, // 创建好的cardNumber实例
                billing_details: this.stripeBillDetail, // 组装好的billing_details对象数据
            }).then(res => {
            if(res.error){
                this.loading = false;
                this.toastRepository.showDanger(res.error.message || '');
            }else {
                this.otherInfo.paymentMethodId = res.paymentMethod.id || '';
                this.otherInfo.deletePaymentMethodId = this.billing.paymentMethodId;
                this.updateDetail('refresh')
              
            }
        }).catch(err=>{
            this.loading = false;
            this.toastRepository.showDanger(err.error.message || '');
        })
    }

    capitalizeFirst(value: string): string {
        if (!value) return value;
        return value.charAt(0).toUpperCase() + value.slice(1);
      }

      capitalize(value: string): string {
        if (!value) return value;
        return value.toLowerCase().replace(/( |^)[a-z]/g, (L) => L.toUpperCase());  
    }
    capitalizeUpperCase(value: string): string {
        if (!value) return value;
        return value.toUpperCase();  
    }
}
