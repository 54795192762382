export class BaseInfo {
    id: string;
    createTime: Date;
    updateTime: Date;
    createUser: string;
    updateUser: string;
}

export class Page<T> {
    records: Array<T> = [];
    total: number;
    size: number;
    current: number;
    pages: number;
}


export class StripeInfo {
    billFlag: boolean;
    price: string;
    pubKey: string;
    useFlag: boolean;
    taxRate: number;
    baseTaxRate: number;
    trialEnd: string;
    region: string;
    currentTime: string;
    subscriptionStatus: string;
    currentPeriodEnd: string;
    cancelAt:string;
}

export class  Register {
    step: number;
    status: number;
} 