import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpResult } from '../observable/model/common/http-result';
import { PageCondition } from '../observable/model/condition';
import { CurrentUser, InviteUser, LoginUser, RestPassword, TeamUser, TeamUserPassword, UserForgot } from '../observable/model/user';
import { UserInfo } from "../pages/auth/model/user-info";
import { RegisterInfo } from "../pages/auth/signup/sign-up-model/register-info";

@Injectable({
    providedIn: 'root'
})
export class UserRepository {

    constructor(private http: HttpClient) {

    }

    public login(loginUser: LoginUser): Observable<Object> {
        const body = new FormData()
        body.append('account', loginUser.account)
        body.append('password', loginUser.password)
        return this.http.post(environment.baseURL + '/auth/authorize', body,);
    }

    public register(register: RegisterInfo): Observable<UserInfo> {
        return this.http.post<UserInfo>(environment.baseURL + '/auth/register', register);
    }

    public resetPassword(restPassword: RestPassword): Observable<HttpResult<CurrentUser>> {
        return this.http.put<HttpResult<CurrentUser>>(environment.baseURL + `/public/auth/v0/reset`, restPassword);
    }

    public forgotPassword(account: string): Observable<HttpResult<any>> {
        return this.http.post<HttpResult<any>>(environment.baseURL + `/public/auth/v0/forget`, { account });
    }

    public getCurrentUser(): Observable<HttpResult<CurrentUser>> {
        return this.http.get<HttpResult<CurrentUser>>(environment.baseURL + `/user/v1/current`);
    }

    resend(account: string): Observable<Object> {
        let params: HttpParams = new HttpParams();
        let body = params.append("account", account).toString();
        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        return this.http.post(environment.baseURL + `/auth/register/resend`, body, { headers });
    }

    verify(code: string, account: string): Observable<Object> {
        const body = new FormData()
        body.append('code', code)
        body.append('account', account)
        return this.http.post(environment.baseURL + `/auth/verify`, body,);
    }

    public invite(inviteUser: InviteUser): Observable<HttpResult<CurrentUser>> {
        return this.http.post<HttpResult<CurrentUser>>(environment.baseURL + `/pc/team/v1/invite`, inviteUser);
    }

    public list(condition: PageCondition): Observable<any> {
        return this.http.post<HttpResult<CurrentUser>>(environment.baseURL + `/pc/team/v1/list`, condition);
    }

    public getUser(teamUser: TeamUser): Observable<any> {
        return this.http.post<HttpResult<CurrentUser>>(environment.baseURL + `/pc/team/v1/get`, teamUser);
    }
    public getUserStatusByAccount(teamUser: TeamUser): Observable<any> {
        return this.http.post<HttpResult<CurrentUser>>(environment.baseURL + `/pc/team/v1/getUserStatusByAccount`, teamUser);
    }

    public updateUserStatus(teamUser: TeamUser): Observable<any> {
        return this.http.post<HttpResult<CurrentUser>>(environment.baseURL + `/pc/team/v1/status`, teamUser);
    }

    public updateUser(teamUser: TeamUser): Observable<any> {
        return this.http.post<HttpResult<CurrentUser>>(environment.baseURL + `/pc/team/v1/update`, teamUser);
    }

    public reInvite(teamUser: TeamUser): Observable<any> {
        return this.http.post<HttpResult<CurrentUser>>(environment.baseURL + `/pc/team/v1/reInvite`, teamUser);
    }

    public agreeInvite(teamUserPassword: TeamUserPassword): Observable<any> {
        return this.http.post<HttpResult<CurrentUser>>(environment.baseURL + `/pc/team/v1/agreeInvite`, teamUserPassword);
    }
    public sendForgotEmail(userForgot: UserForgot): Observable<any> {
        return this.http.post<HttpResult<CurrentUser>>(environment.baseURL + `/pc/team/v1/send/forgot/email`, userForgot);
    }
    public validateForgotCode(userForgot: UserForgot): Observable<any> {
        return this.http.post<HttpResult<CurrentUser>>(environment.baseURL + `/pc/team/v1/validate/forgot/code`, userForgot);
    }
    public updatePassword(userForgot: UserForgot): Observable<any> {
        return this.http.post<HttpResult<CurrentUser>>(environment.baseURL + `/pc/team/v1/update/password`, userForgot);
    }
    public getStripeStatus(): Observable<any> {
        return this.http.get<HttpResult<CurrentUser>>(environment.baseURL + `/pc/stripe/subscription/v1/get`, {});
    }

    public getTeamNumber(): Observable<any> {
        return this.http.post<HttpResult<CurrentUser>>(environment.baseURL + `/pc/team/v1/getUserStatusNumVo`, {});
    }

    public cancelInvite(userId: String) {
        return this.http.post<HttpResult<CurrentUser>>(environment.baseURL + `/pc/team/v1/invite/cancel/${userId}`, {});
    }

    public registerStep(step: number): Observable<HttpResult<any>> {
        const body = new FormData();
        body.append('step', step.toString());
        return this.http.post<HttpResult<any>>(environment.baseURL + `/pc/team/v1/step`, body);
    }
}
