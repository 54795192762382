<div class="admin-wrapper">
    <div class="admin">
        <div class="admin-left">
            <div class="logo"></div>
            <div class="bg-box"></div>
        </div>
        <div class="admin-right">
            <div class="text-box">
                <div class="form">
                    <div class="back-top" (click)="back()"> <span class="icon icon-24 icon-arrow-back"></span>{{this.fromType == 'forgot' ? 'Forgot password':'Welcome'}}</div>
                    <h2 class="title">Email Sent</h2>
                    <div class="tip" *ngIf="account != null">Verification email has been sent to: </div>
                    <div class="email-info" *ngIf="account != null" [innerText]="account"></div>
                    <div class="form-control-group form-icon">
                        <input type="text" placeholder="Enter code" class="placeholder-input" [(ngModel)]="verifyCode" (keydown)="numberKey($event)" (keydown.enter)="verify()">
                        <span class="icon icon-16 icon-password icon-first-pic"></span>
                    </div>
                    <div class="form-button">
                        <button class="btn btn-o" [disabled]="verifyCode.length < 1" (click)="verify()">Next
                        </button>
                    </div>
                    <div class="code-tip"><a href="javascript:void(0)" (click)="back2Login()">Back to log in</a> <span>
                            | </span>
                        <a href="javascript:void(0)" (click)="resend()" [ngClass]="{'disabled': !canResend}">{{displayText}}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>