import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Page } from '../../observable/model/base';
import { Box } from '../../observable/model/box';
import { Condition, PageCondition } from '../../observable/model/condition';
import { BoxObj, BoxStatusNumVo, Order, OrderInfo, OrderStatusNumVo } from '../../observable/model/order';
import { JWTUser } from '../../observable/model/user';
import { BillingRepository } from '../../repository/billing-repository';
import { OrderRepository } from '../../repository/order-repository';
import { ToastRepository } from '../../repository/toast-repository';
import { CreateOrderComponent } from '../modal/create-order/create-order.component';

@Component({
    selector: 'app-order',
    templateUrl: './order.component.html',
    styleUrls: ['./order.component.less']
})

export class OrderComponent implements OnInit {
    editModal: BsModalRef;
    orderPage: Page<Order> = new Page<Order>();
    boxPage: Page<BoxObj> = new Page<BoxObj>();
    condition = new PageCondition('', 10);
    currnetSort = new Map();
    userRole: number;
    public jwtUser: JWTUser;
    switchStatus: number;
    boxList: Array<Box> = [];
    orderStatusNumVo: OrderStatusNumVo = new OrderStatusNumVo();
    boxStatusNumVo: BoxStatusNumVo = new BoxStatusNumVo();
    orderInfo = new OrderInfo();
    title: string;
    screenHeight = document.body.clientHeight;
    constructor(
        private billingRepository: BillingRepository,
        private orderRepository: OrderRepository,
        private modalService: NgbModal,
        private modalAdService: NgbModal,
        private ngbActiveModal: NgbActiveModal,
        private router: Router,
        public toastRepository: ToastRepository,
        private jwtHelperService: JwtHelperService
    ) {
        let page = new URLSearchParams(window.location.href.split('?')[1]).get('page');
        if (page) {
            this.condition.current = Number.parseInt(page);
        } else {
            this.setPageCondition(1);
        }
    }

    ngOnInit(): void {
        const accessToken = localStorage.getItem('accessToken');
          this.jwtUser = this.jwtHelperService.decodeToken(accessToken).inf as JWTUser;
        console.log(this.jwtUser);
        
        this.title = 'Orders';
        this.init();
    }

    init(): void {
        this.switchStatus = 0;
        var freeTrial = document.getElementsByClassName("fourteen-tip");
        let hideHeight = freeTrial && freeTrial.length > 0 ? 80 : 0;
        this.condition.size = Math.trunc((this.screenHeight - 72 - 99 - 98 - 46 - hideHeight) / 75) - 1;
        this.getOrderList();
        const accessToken = localStorage.getItem('accessToken');
        const jwtUser = this.jwtHelperService.decodeToken(accessToken).inf as JWTUser;
        this.userRole = jwtUser.role;
    }


    getOrderList(): void {
        this.boxPage = new Page<BoxObj>();
        this.orderPage = new Page<Order>();
        if (this.switchStatus == 0) {
            this.orderRepository.getOrderStatusNumVo().subscribe(result => {
                this.orderStatusNumVo = result;
            });
            this.orderRepository.listBox().subscribe(result => {
                this.boxList = result;
            });
            delete this.condition.boxStatus;
            this.orderRepository.list(this.condition).subscribe(result => {
                this.orderPage = result;
            });
        } else {
            this.boxPage = new Page<BoxObj>();
            this.orderRepository.getBoxStatusNumVo().subscribe(result => {
                this.boxStatusNumVo = result;
            });

            delete this.condition.orderStatus;
            this.orderRepository.boxList(this.condition).subscribe(result => {
                this.boxPage = result;
            });
        }
    }

    getOrderListByPage(current: number): void {
        this.condition.current = current;
        this.setPageCondition(current);
        this.orderRepository.list(this.condition).subscribe(result => {
            this.orderPage = result;
        });
    }

    getBoxListByPage(current: number): void {
        this.condition.current = current;
        this.orderRepository.boxList(this.condition).subscribe(result => {
            this.boxPage = result;
        });
    }

    sortOrderList(sortName: string): void {
        this.condition.sortName = sortName;
        this.condition.sortOrder = this.condition.sortOrder == 'ASC' ? 'DESC' : 'ASC';
        this.getOrderList();
        this.currnetSort = new Map();
        this.currnetSort[sortName] = this.condition.sortOrder;
    }

    statueSearchOrderList(status: number): void {
        this.condition.current = 1;
        if (this.switchStatus == 0) {
            this.condition.orderStatus = status;
        } else {
            this.condition.boxStatus = status;
        }
        this.getOrderList();
    }

    switchStatueOrderList(status: number): void {
        this.switchStatus = status;
        this.condition.orderStatus = null;
        this.condition.boxStatus = null;
        this.condition.name = '';
        this.condition.sortName = '';
        this.condition.current = 1;
        if (status == 0) {
            this.title = 'Orders';
            this.condition.sortOrder = 'ASC';
        } else {
            this.title = 'Inventory';
            this.condition.sortOrder = 'DESC';
        }
        this.getOrderList();
    }

    searchKeywordByPress($event: KeyboardEvent): void {
        if ($event.key != 'Enter') {
            return;
        }
        this.getOrderList();
    }

    changeOrderStatus(id: string, status: number): void {
        const condition = new Condition();
        condition.id = id;
        condition.orderStatus = status;
        this.orderRepository.updateStatus(condition).subscribe(result => {
            if (result) {
                this.getOrderList();
                this.toastRepository.showSuccess('Order Status updated successfully.');
            } else {
                this.toastRepository.showDanger(result);
            }
        });
    }

    // CreateOderModal(): void {
    //     this.billingRepository.info().subscribe(result => {
    //         if (result || this.userRole == 0) {
    //             this.openDialog();
    //         } else {
    //             const modalRef = this.modalAdService.open(BillingMissingComponent, {
    //                 backdrop: 'static',
    //                 keyboard: true,
    //                 windowClass: 'modal-610'
    //             });
    //             modalRef.result.then(() => {
    //                 this.ngbActiveModal.close();
    //                 this.router.navigate(['setting', 'billing']);
    //             }).catch(() => {
    //             });
    //         }
    //     })
    // }


    CreateOderModal(): void {
        const modalRef = this.modalService.open(CreateOrderComponent, {
            backdrop: 'static',
            keyboard: true,
            windowClass: 'modal-1280'
        });
        modalRef.componentInstance.status = this.switchStatus;
        modalRef.componentInstance.orderInfo = new OrderInfo();
        modalRef.result.then(() => {
            this.getOrderList();
        }).catch(() => {
            this.getOrderList();
        });
    }

    goOrderContent(order: Order): void {
        this.router.navigate(['order', order.id, 'tag'], { queryParams: { orderNum: order.orderNum, type: 'order' } });
    }

    goBoxContent(box: BoxObj): void {
        this.router.navigate(['order', box.id, 'tag'], { queryParams: { boxNum: box.boxNumName, type: 'box' } });
    }


    allotBox(orderId: string, boxId: string) {
        const condition = new Condition();
        condition.id = orderId;
        condition.boxInfoId = boxId;
        this.orderRepository.allotBox(condition).subscribe(result => {
            if (result) {
                this.getOrderList();
                this.toastRepository.showSuccess('Assigned successfully');
            } else {
                this.toastRepository.showDanger(result);
            }
        });
    }

    setPageCondition(current: Number): void {
        let params: URLSearchParams = new URLSearchParams(window.location.href.split('?')[1]);
        if (params.has('page')) {
            params.set('page', `${current}`);
        } else {
            params.append('page', `${current}`);
        }
        window.location.href = window.location.href.split('?')[0] + '?' + params;
    }
}
