<div class="create-pay edit-staff">
    <div class="modal-header">
        <h4 class="modal-title">Edit Staff</h4>
        <!--        <div class="admin-photo">-->
        <!--            <img src="assets/img/team-user.png" alt="">-->
        <!--        </div>-->
    </div>
    <div class="modal-body">
        <div class="form modal-form">

            <div class="form-two">
                <div class="form-group">
                    <label class="control-label">First name</label>
                    <input type="text" class="form-control" placeholder="Enter first name"
                        [(ngModel)]="user.firstName" 
                        (ngModelChange)="user.firstName = capitalizeFirst(user.firstName)"/>
                </div>
                <div class="form-group">
                    <label class="control-label">Last name</label>
                    <input type="text" class="form-control" placeholder="Enter last name" [(ngModel)]="user.lastName" 
                    (ngModelChange)="user.lastName = capitalizeFirst(user.lastName)"
                    />
                </div>
            </div>
            <div class="form-group">
                <label class="control-label">&#917;mail address</label>
                <input type="text" class="form-control" [(ngModel)]="user.account" />
            </div>
            <div class="form-two">
                <div class="form-group">
                    <label class="control-label">Phone</label>
                    <input type="text" class="form-control" placeholder="Enter phone" [(ngModel)]="user.phone" />
                </div>
                <div class="form-group">
                    <label class="control-label">Role</label>
                    <div class="select">
                        <ng-select id="roleType" name="roleType" required class="select-single"
                            [(ngModel)]="user.roleType" [searchable]="false" 
                            (change)="onRoleTypeChange()"
                            >
                            <ng-option [value]="1">Admin</ng-option>
                            <ng-option [value]="2">Inspector</ng-option>
                        </ng-select>
                        <i class="icon icon-18 icon-down-arrow"></i>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label class="control-label">Scaffold ticket/license no</label>
                <input type="text" class="form-control" [(ngModel)]="user.ticketNumber" />
            </div>
            <div class="form-group">
                <label class="control-label">Ticket class</label>
                <input type="text" class="form-control" [(ngModel)]="user.ticketClass" />
            </div>

            <div class="form-group">
                <label class="control-label">Team member has access to these Branches:</label>
            </div>
        
            <div class="select-content content">
                <div class="select-item" *ngFor="let item of branchList">
                    <label>
                        <input type="checkbox" [disabled]="user.roleType === 1" [(ngModel)]="item.checked" (change)="selectBranchBox(item)"> {{ item.name }}
                    </label>
                </div>
            </div>
            <div class="tip" *ngIf="user.roleType == 1">
                <p>Admins have access to all Branches</p>
            </div>
            <div class="form-button form-button-two-with_div">
                <div class="close">
                    <button class="color-o" (click)="cancel()">Cancel</button>
                </div>
                <div class="submit">
                    <button class="btn btn-green bg-o" [disabled]="isLoading" (click)="editUser()">Update Details</button>
                </div>
            </div>
        </div>
    </div>

</div>