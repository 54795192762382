import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OrderInfo } from '../../../observable/model/order';
import { OrderRepository } from '../../../repository/order-repository';
import { ToastRepository } from '../../../repository/toast-repository';
import { UserRepository } from 'src/app/repository/user-repository';
import { RegisterStep } from 'src/app/enum/register-step.enum';

@Component({
    selector: 'app-signup-free-tags',
    templateUrl: './signup-free-tags.component.html',
    styleUrls: ['./signup-free-tags.component.less']
})
export class SignupFreeTagsComponent implements OnInit {


    orderInfo: OrderInfo = new OrderInfo();

    constructor(private router: Router,
        private orderRepository: OrderRepository,
        public toastRepository: ToastRepository,
        public userRepository: UserRepository) {
    }

    ngOnInit(): void {
    }

    saveFreeOrder(): void {
        this.orderRepository.saveFree(this.orderInfo).subscribe(result => {
            this.userRepository.registerStep(RegisterStep.ShippingDetails).subscribe(v => { });
            this.router.navigate(['signup-stripe'], { queryParams: { fromPage: 'signup-verified' } });
        });
    }

    cancel(): void {
        // this.router.navigate(['/signup-verified']);
        history.back();
    }
    back(): void {
        this.router.navigate(['/signup-verified'])
    }
    capitalize(value: string): string {
        if (!value) return value;
        return value.toLowerCase().replace(/( |^)[a-z]/g, (L) => L.toUpperCase());  
    }
    capitalizeUpperCase(value: string): string {
        if (!value) return value;
        return value.toUpperCase();  
    }
}
