import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class LayoutObservable {

    private layoutState: BehaviorSubject<boolean> = new BehaviorSubject(false);

    private sideLayoutState: BehaviorSubject<boolean> = new BehaviorSubject(true);

    setLayout(layout: boolean): void {
        // this.layoutState = new BehaviorSubject(layout);
        this.layoutState.next(layout);
    }

    getLayout(): any {
        return this.layoutState;
    }

    setSideLayout(layout: boolean): void {
        this.sideLayoutState.next(layout);
    }

    getSideLayout(): BehaviorSubject<boolean> {
        return this.sideLayoutState;
    }
}
