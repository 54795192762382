<div class="confirm-purchase">
    <div class="page-header">
        <h4 class="modal-title">You have completed your payment details!</h4>
        <div class="sub-title">You will be able to access all the content available once the details have been received.</div>
    </div>
    <div class="form-button">
        <div class="mobile">
            <button class="btn btn-green bg-o" (click)="gotoApp()">Go to Scaflinq Mobile</button>
        </div>
        <div class="web">
            <button class="btn btn-white " (click)="gotoWeb()">Go to Scafflinq Web</button>
        </div>
    </div>
</div>
