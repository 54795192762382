<div class="setting-right">
    <div class="top-bar">
        <h1>Settings</h1>
    </div>
    <!-- Your profile -->
    <div class="content company">
        <div class="sub-head-box">
            <div class="filter-box">
                <div class="item" routerLinkActive="active">
                    <a [routerLink]="['/setting/profile']">Your Profile</a>
                </div>
                <div class="item" routerLinkActive="active">
                    <a [routerLink]="['/setting/branches']">Branches</a>
                </div>
                <div class="item" routerLinkActive="active">
                    <a [routerLink]="['/setting/handover']">Handover Certificates</a>
                </div>
                
                <div class="item" routerLinkActive="active">
                    <a [routerLink]="['/setting/subscriptions']">Your Subscription</a>
                </div>
                <div class="item" routerLinkActive="active">
                    <a [routerLink]="['/setting/billing']">Billing Information</a>
                </div>
            </div>
        </div>

   
        <div class="info-box">
            <div class="form-box">
                <div class="form-control-group">
                    <label>First handover certificate number</label>
                    <div class="input form-certificate-number" [ngClass]="{'disabled': isDisabled}">
                        <input type="text" 
                        [(ngModel)]="handoverCertificates.certificateNumber" 
                        placeholder="0000000001"
                        min="0" 
                        max="9999999999" 
                        (keypress)="isNumber($event)" 
                        (input)="limitInput($event)"
                        [disabled]='isDisabled' 
                        (blur)="handleNumber()"
                    />
                  
                    </div>
                
                </div>
                <div class="form-control-group">
                    <label>Logo</label>
                    <div class="upload-files" *ngIf="!succeed">
                        <ngx-file-drop   [multiple]="false" dropZoneLabel="Drop files here"  (onFileDrop)="dropped($event)" (onFileOver)="fileOver($event)"
                            (onFileLeave)="fileLeave($event)"  [accept]="accept">
                            <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                                <div class="file-content" (click)="openFileSelector()">
                                    <div class="upload-box">
                                        <div class="img-box"> <img src="assets/img/cloud-upload.svg" alt=""> </div>
                                        <div class="text-box">
                                            <p>Drag & drop file here or  <a href="javascript:void(0)">Browse a file</a> to upload.</p>
                                            <p class="mb-20">Only jpeg or png accepted.</p>
                                            <p>Max. Size 5MB. Dimensions:600px x 600px. File type: PNG or JPG.</p>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </ngx-file-drop>
                    </div>
                    <div class="upload-box" *ngIf="succeed">

                        <div class="upload-img">
                            <img [src]="logoUrl" alt="">
                        </div>  
                       
                         <div class="upload-info">
                            <p>Current logo:{{ fileName }}</p>
                            <ngx-file-drop   [multiple]="false" dropZoneLabel="Drop files here"  (onFileDrop)="dropped($event)"  (onFileOver)="fileOver($event)"
                            (onFileLeave)="fileLeave($event)"  [accept]="accept">
                            <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                                <div class="file-content" (click)="openFileSelector()">
                                    <div class="text-box">
                                        Upload a new logo
                                    </div>
                                </div>
                            </ng-template>
                        </ngx-file-drop>
                        <p class="remove" (click)="removeLogo()">Remove Logo</p>
                         </div>

                    </div>
                    
                </div>
                <div class="form-control-group">
                    <label>Hero text</label>
                    <div class="input">
                        <input type="text" maxlength="100"  [(ngModel)]="handoverCertificates.heroText" placeholder="">

                    </div>
                    <p class="tip">Displayed at the top of each page. Maximum 100 characters.</p>
                </div>

                <div class="form-control-group">
                    <label>Acceptance Conditions</label>
                    <div class="input">
                        <textarea name="" id=""  maxlength="800" cols="30" rows="10" placeholder="" [(ngModel)]="handoverCertificates.acceptance"></textarea>
                    </div>
                    <p class="tip">Displayed at the end of the Handover Certificate. Maximum 800 characters.</p>
                </div>
                <div class="footer-btn">
                    <button class="btn btn-green2 bg-o update" (click)="save()">Save</button>
                </div>
            </div>
        </div>
    </div>
</div>