<div class="admin-wrapper">
    <div class="admin">
        <div class="admin-left">
            <div class="logo"></div>
            <div class="bg-box"></div>
        </div>
        <div class="admin-right">
            <div class="text-box">
                <form action="" class="form">
                    <!-- <div class="back-top" (click)="back()"> <span class="icon icon-24 icon-arrow-back"></span>Scafftag Confirmation</div> -->
                    <h2 class="title">Order Scafftag</h2>
                    <div class="subtitle">Enter your address you’d like these delivered. We’ll send them out today.</div>
                    <div class="form-control-group  form-icon">
                        <!--                        <label>Street number and name</label>-->
                        <input type="text" placeholder="Enter street number and name" [(ngModel)]="orderInfo.streetNumber" name="streetNumber"
                        (ngModelChange)="orderInfo.streetNumber = capitalize(orderInfo.streetNumber)"
                        >
                        <span class="icon icon-16 icon-road icon-first-pic"></span>
                    </div>
                    <div class="form-control-group form-icon">
                        <!--                        <label>Suburb</label>-->
                        <input type="text" placeholder="Enter suburb" [(ngModel)]="orderInfo.suburb" name="suburb"
                        (ngModelChange)="orderInfo.suburb = capitalize(orderInfo.suburb)"
                        >
                        <span class="icon icon-16 icon-home icon-first-pic"></span>
                    </div>
                    <div class="form-control-group form-icon">
                        <!--                        <label>State</label>-->
                        <input type="text" placeholder="Enter state" [(ngModel)]="orderInfo.state" name="state" 
                           (ngModelChange)="orderInfo.state = capitalize(orderInfo.state)">
                        <span class="icon icon-16 icon-map icon-first-pic"></span>
                    </div>
                    <!--                    <div class="form-two">-->
                    <div class="form-control-group form-icon">
                        <!--                        <label>Postcode</label>-->
                        <input type="text" placeholder="Enter postcode" [(ngModel)]="orderInfo.postCode" name="postCode"
                        (ngModelChange)="orderInfo.postCode = capitalizeUpperCase(orderInfo.postCode)"
                        >
                        <span class="icon icon-16 icon-mailbox icon-first-pic"></span>
                    </div>
                    <div class="form-control-group  form-country form-icon">
                        <!--                        <label>Country</label>-->
                        <input type="text" placeholder="Enter country" [(ngModel)]="orderInfo.country" name="country"
                        (ngModelChange)="orderInfo.country = capitalize(orderInfo.country)"
                        >
                        <span class="icon icon-16 icon-globe icon-first-pic"></span>
                    </div>
                    <!--                    </div>-->
                    <div class="form-control-group form-icon">
                        <!--                        <label>Attention</label>-->
                        <input type="text" placeholder="Who should we send this to?" [(ngModel)]="orderInfo.recipientFullName" name="recipientFullName"
                        (ngModelChange)="orderInfo.recipientFullName = capitalize(orderInfo.recipientFullName)"
                        >
                        <span class="icon icon-16 icon-user icon-first-pic"></span>
                    </div>
                    <div class="btn-two">
                        <button class="btn btn-o btn-block"
                            [disabled]="!orderInfo.streetNumber || !orderInfo.suburb  || !orderInfo.recipientFullName || !orderInfo.state  || !orderInfo.postCode  || !orderInfo.country "
                            (click)="saveFreeOrder()">Ship my free tags</button>
                        <!-- <div class="cancel" (click)="cancel()">Cancel</div> -->
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>