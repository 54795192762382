import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxValidatorModule } from "@why520crazy/ngx-validator";
import { FloatInputComponent } from '../../components/float-input/float-input.component';
import { LoadingComponent } from '../../components/loading/loading.component';
import { DetailCompleteComponent } from './detail-complete/detail-complete.component';
import { DetailConfirmComponent } from './detail-confirm/detail-confirm.component';
import { DetailComponent } from './detail/detail.component';
import { DownloadComponent } from './download/download.component';
import { ForgotComponent } from './forgot/forgot.component';
import { InviteAgreeComponent } from './invite-agree/invite-agree.component';
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SignupCodeComponent } from './signup-code/signup-code.component';
import { SignupFreeTagsComponent } from './signup-free-tags/signup-free-tags.component';
import { SignupOrderCompletedComponent } from './signup-order-completed/signup-order-completed.component';
import { SignupStripeComponent } from './signup-stripe/signup-stripe.component';
import { SignupTaggedComponent } from './signup-tagged/signup-tagged.component';
import { SignupTeamEmailComponent } from './signup-team-email/signup-team-email.component';
import { SignupVerifiedComponent } from './signup-verified/signup-verified.component';
import { SignupComponent } from './signup/signup.component';

import { SignupCompleteStripeComponent } from './signup-complete-stripe/signup-complete-stripe.component';

const authRoutes: Routes = [
    {
        path: 'login',
        pathMatch: 'full',
        component: LoginComponent,
    },
    {
        path: 'forgot',
        pathMatch: 'full',
        component: ForgotComponent,
    },
    {
        path: 'signup',
        pathMatch: 'full',
        component: SignupComponent,
    },
    {
        path: 'invite-agree/:token',
        pathMatch: 'full',
        component: InviteAgreeComponent,
    },
    {
        path: 'signup-tagged',
        pathMatch: 'full',
        component: SignupTaggedComponent,
    },
    {
        path: 'signup-code',
        pathMatch: 'full',
        component: SignupCodeComponent,
    },
    {
        path: 'signup-verified',
        pathMatch: 'full',
        component: SignupVerifiedComponent,
    },
    {
        path: 'signup-free-tags',
        pathMatch: 'full',
        component: SignupFreeTagsComponent,
    },
    {
        path: 'signup-order-completed',
        pathMatch: 'full',
        component: SignupOrderCompletedComponent,
    },
    {
        path: 'signup-team-email',
        pathMatch: 'full',
        component: SignupTeamEmailComponent,
    },
    {
        path: 'reset-password',
        pathMatch: 'full',
        component: ResetPasswordComponent,
    },
    {
        path: 'download-app',
        pathMatch: 'full',
        component: DownloadComponent,
    },
    {
        path: 'signup-stripe',
        pathMatch: 'full',
        component: SignupStripeComponent,
    },
    {
        path: 'signup-complete-stripe',
        pathMatch: 'full',
        component: SignupCompleteStripeComponent,
    },
    {
        path: 'detail',
        pathMatch: 'full',
        component: DetailComponent,
    },
    {
        path: 'detail-confirm',
        pathMatch: 'full',
        component: DetailConfirmComponent,
    },
    {
        path: 'detail-complete',
        pathMatch: 'full',
        component: DetailCompleteComponent,
    },

];

@NgModule({
    imports: [CommonModule, FormsModule, RouterModule.forChild(authRoutes), ReactiveFormsModule, NgxValidatorModule, NgSelectModule],
    exports: [
        RouterModule,
        LoginComponent,
        ForgotComponent,
        LoadingComponent,
        SignupComponent,
        InviteAgreeComponent,
        ResetPasswordComponent,
        DownloadComponent,
        SignupStripeComponent,
        SignupCompleteStripeComponent,
        DetailComponent,
        DetailConfirmComponent,
        DetailCompleteComponent
    ],
    declarations: [
        LoginComponent,
        ForgotComponent,
        FloatInputComponent,
        LoadingComponent,
        ResetPasswordComponent,
        DownloadComponent,
        SignupComponent,
        InviteAgreeComponent,
        SignupTaggedComponent,
        SignupCodeComponent,
        SignupVerifiedComponent,
        SignupFreeTagsComponent,
        SignupOrderCompletedComponent,
        SignupTeamEmailComponent,
        SignupStripeComponent,
        SignupCompleteStripeComponent,
        DetailComponent,
        DetailConfirmComponent,
        DetailCompleteComponent
    ],
})
export class AuthModule {
}
