import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NgxLoadingSpinnerService } from '@k-adam/ngx-loading-spinner';
import { NgxValidatorConfig } from '@why520crazy/ngx-validator';
import { finalize } from 'rxjs/operators';
import { JWTUser, LoginUser } from 'src/app/observable/model/user';
import { LocalStorageObServable } from 'src/app/observable/local-storage-observable';
import { UserRepository } from 'src/app/repository/user-repository';
import { ToastRepository } from '../../../repository/toast-repository';



@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.less'],
    encapsulation: ViewEncapsulation.Emulated,
})
export class LoginComponent implements OnInit {

    validatorConfig: NgxValidatorConfig = {
        validationMessages: {
            account: {
                required: 'Email is required.',
                email: 'Please type right email.'
            },
            password: {
                required: 'Password is required.'
            }
        },
        validateOn: 'submit'
    };

    public loginUser = new LoginUser();

    public isLogin = false;

    public returnUrl = null;

    public jwtUser: JWTUser;

    showPassword: boolean;

    constructor(
        private spinnerService: NgxLoadingSpinnerService,
        private toastRepository: ToastRepository,
        private userRepository: UserRepository,
        private router: Router,
        public localStorageObservrable: LocalStorageObServable,
        public activatedRoute: ActivatedRoute,
        private jwt: JwtHelperService,
    ) {
        localStorage.clear();
        this.activatedRoute.queryParamMap.subscribe(paramMap => {
            this.returnUrl = paramMap.get('returnUrl');
        });
    }

    ngOnInit(): void {
        this.showPassword = false;
    }

    loginWithEmail(): void {
        const loginObj = { ...this.loginUser };
        this.isLogin = true;
        this.userRepository.login(loginObj)
            .pipe(finalize(() => this.isLogin = false))
            .subscribe((accessToken: string) => {
                const jwtUser = this.jwt.decodeToken(accessToken).inf as JWTUser;
                if (jwtUser.role == 2) {
                    this.toastRepository.showDanger('Inspectors don\'t have access to the web portal. Please contact your administrator.');
                    return;
                }
                this.localStorageObservrable.setItem('accessToken', accessToken);
                this.localStorageObservrable.setItem('step', jwtUser.step);
                if (jwtUser.step == 2) {
                    this.router.navigate(['/signup-free-tags']);
                    return;
                }
                if (jwtUser.step == 3) {
                    this.router.navigate(['signup-stripe'], { queryParams: { fromPage: 'signup-verified' } });
                    return;
                }
                this.userRepository.getStripeStatus().subscribe(event => {
                    localStorage.setItem('stripeInfo', JSON.stringify(event));
                    localStorage.setItem('login', 'true');
                    if (this.returnUrl == null) {
                        this.router.navigate(['project']);
                    } else {
                        this.router.navigate([this.returnUrl]);
                    }
                });
            });
    }
    changePassword(val) {
        this.showPassword = val == 'show' ? true : false;
    }
}
