import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-confirm-purchase',
    templateUrl: './detail-complete.component.html',
    styleUrls: ['./detail-complete.component.less']
})
export class DetailCompleteComponent implements OnInit {
    constructor() {
    }

    ngOnInit(): void {
        console.log(this,'??32234?');
    }

    gotoApp(): void{

    }
    gotoWeb():void{

    }

}
