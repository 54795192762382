import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {OutletContext, Router, RouterOutlet} from '@angular/router';
import {ProfileComponent} from '../../pages/setting/profile/profile.component';
import {AppComponent} from '../../app.component';

@Component({
    selector: 'app-layout-setting',
    templateUrl: './layout-setting.component.html',
    styleUrls: ['./layout-setting.component.less']
})
export class LayoutSettingComponent implements OnInit{

    @ViewChild(ProfileComponent) profileComponent: ProfileComponent;

    @ViewChild(RouterOutlet) routerOutlet: RouterOutlet;

    companyName: string;
    closeSubMenu = true;

    constructor(private router: Router) {
    }

    ngOnInit(): void {
    }

    closeMenu(): void{
        this.closeSubMenu = !this.closeSubMenu;
    }
}
