 


export class  Branch{
    company:string;
    createdAt:string;
    createdBy:string;
    deleted:boolean;
    id:string;
    name:string;
    status:string;
    updatedAt:string;
    updatedBy:string;
    checked:boolean;   
}


export class BranchNumberInfo {
    all: number;
    inactive: number;
    active: number;
    
}