import { Component, Input, OnInit, } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
    selector: 'app-agreement-user',
    templateUrl: './sign-out.component.html',
    styleUrls: ['./sign-out.component.less']
})

export class SignOutComponent implements OnInit {
    type: string;

    constructor(private ngbActiveModal: NgbActiveModal, private router: Router,) {

    }

    ngOnInit(): void {
        console.log(this.type, '..');
    }

    confirm(): void {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('stripeInfo');
        localStorage.removeItem('login');
        this.router.navigate(['login']);
        this.ngbActiveModal.close();
    }

    cancel(): void {
        this.ngbActiveModal.close();
    }
}

