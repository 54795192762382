import { APP_BASE_HREF } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutSettingComponent } from '../common/layout-setting/layout-setting.component';
import { LayoutComponent } from '../common/layout/layout.component';
import { AuthGuard } from '../interceptor/auth-guard';
import { BillingGuard } from '../interceptor/billing-guard';
import { ImportComponent } from '../pages/import/import.component';
import { InvoicesComponent } from '../pages/invoices/invoices.component';
import { LockComponent } from '../pages/lock/lock.component';
import { OrderContentComponent } from '../pages/order-content/order-content.component';
import { OrderComponent } from '../pages/order/order.component';
import { ProjectComponent } from '../pages/project/project.component';
import { ScaffoldComponent } from '../pages/scaffold/scaffold.component';
import { BillingComponent } from '../pages/setting/billing/billing.component';
import { ProfileComponent } from '../pages/setting/profile/profile.component';
import { HandoverComponent } from '../pages/setting/handover/handover.component'; 
import { BranchesComponent } from '../pages/setting/branches/branches.component';
import { SubscriptionsComponent } from '../pages/setting/subscriptions/subscriptions.component';
import { TagInspectionComponent } from '../pages/tag-inspection/tag-inspection.component';
import { TagComponent } from '../pages/tag/tag.component';
import { TeamComponent } from '../pages/team/team.component';
import { UploadComponent } from '../pages/upload/upload.component';


const routes: Routes = [
    {
        path: '',
        component: LayoutComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'scaffold',
            },
            {
                path: 'order/:orderId/tag',
                component: OrderContentComponent,
                canActivate: [AuthGuard, BillingGuard],
            },
            {
                path: 'order',
                component: OrderComponent,
                canActivate: [AuthGuard, BillingGuard],
            },
            {
                path: 'tag',
                component: TagComponent,
                canActivate: [AuthGuard, BillingGuard],
            },
            {
                path: 'tag/:orderId/tag',
                component: OrderContentComponent,
                canActivate: [AuthGuard, BillingGuard],
            },
            {
                path: 'project/:projectId/scaffold',
                component: ScaffoldComponent,
                canActivate: [AuthGuard, BillingGuard],
            },
            {
                path: 'project/scaffold/:scaffoldId/tag-inspection',
                component: TagInspectionComponent,
                canActivate: [AuthGuard, BillingGuard],
            },
            {
                path: 'project',
                component: ProjectComponent,
                canActivate: [AuthGuard, BillingGuard],
            },
            {
                path: 'import',
                component: ImportComponent,
                canActivate: [AuthGuard, BillingGuard],
            },
            {
                path: 'setting',

                component: LayoutSettingComponent,
                canActivate: [AuthGuard],
                children: [
                    {
                        path: 'profile',
                        component: ProfileComponent,
                        canActivate: [AuthGuard],
                    },
                    {
                        path: 'subscriptions',
                        component: SubscriptionsComponent,
                        canActivate: [AuthGuard],
                    },
                    {
                        path: 'billing',
                        component: BillingComponent,
                        canActivate: [AuthGuard],
                    },
                    {
                        path: 'handover',
                        component: HandoverComponent,
                        canActivate: [AuthGuard],
                    },
                    {
                        path: 'branches',
                        component: BranchesComponent,
                        canActivate: [AuthGuard],
                    }
                ]
            },
            {
                path: 'invoices',
                component: InvoicesComponent,
                canActivate: [AuthGuard, BillingGuard],
            },
            {
                path: 'team',
                component: TeamComponent,
                canActivate: [AuthGuard, BillingGuard],
            }
        ]
    },
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'scaffold',
    },
    {
        path: 'lock',
        component: LockComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'upload',
        component: UploadComponent,
        canActivate: [AuthGuard],
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            initialNavigation: 'enabled',
            scrollPositionRestoration: 'enabled',
            anchorScrolling: 'enabled',
            useHash: true,
        }),
    ],
    providers: [{ provide: APP_BASE_HREF, useValue: '/' }],
    exports: [RouterModule],
})
export class AppRoutingModule {

}
