import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { StripeInfo } from '../../observable/model/base';
import { JWTUser } from '../../observable/model/user';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.less']
})
export class LayoutComponent implements OnInit {
  private stripeInfo = new StripeInfo();
  billFlag = false;
  useFlag = false;
  day:number;
  role:number;
  closeSubMenu = false;
  constructor(
      public router: Router,
      private jwtHelperService: JwtHelperService
  ) {
  }

  ngOnInit(): void {
    const accessToken = localStorage.getItem('accessToken');
    const jwtUser = this.jwtHelperService.decodeToken(accessToken).inf as JWTUser;
    this.role = jwtUser.role;
  }

  gotoBilling(){
    this.router.navigate(['setting','billing']);
  }

  closeMenu(): void{
    this.closeSubMenu = !this.closeSubMenu;
  }
}
