export class Billing {
    id: string;
    name: string;
    number: string;
    year: string;
    month: string;
    cvv: string;
    companyName: string;
    address: string;
    country: string;
    city: string;
    postcode: string;
    firstName: string;
    lastName: string;
    saveFlag: boolean;
    email:string;
    abn:string;
    paymentMethodId:string;
}
export class StripeBillDetail {
    name: string;
    companyName: string;
    firstName: string;
    lastName: string;
    email:string;
    address:{};
}

export class StripeBillInfo {
    paymentMethodId: string;
    name: string;
    addressLine1: string;
    addressPostalCode: string;
    email:string;
    addressCity:string;
}

export class StripeCard {
    exp_month: string;
    exp_year: string;
    last4: string;
    number: string;
    cvc: string;
}


export class Address{
    city: string;
    country: string;
    line1: string;
    state: string;
    postal_code:string;
}

export class OtherInfo{
    paymentMethodId:string;
    billingContactEmail:string;
    abn:string;
    country:string;
    billingContactFirstName:string;
    billingContactLastName:string;
    saveFlag:number;
    companyName:string;
    address: string;
    deletePaymentMethodId: string;
    city:string;
    postcode:string;

}
