<div class="container">
    <div class="top-bar">
        <h1>
            <span [hidden]="selectTabType == inspectionTabName">Projects</span>
            <span [hidden]="selectTabType == tagTabName">Projects</span>
        </h1>


        <div class="right-bar">
            <div class="tab">
                <button [ngClass]="{'active': selectTabType == tagTabName}" (click)="changeTagInspectionTab(tagTabName)">Tags
                </button>
                <button [ngClass]="{'active': selectTabType == inspectionTabName}" (click)="changeTagInspectionTab(inspectionTabName)">Inspections
                </button>
            </div>

            <div ngbDropdown>
                <button class="btn add" id="gfg" ngbDropdownToggle>Actions</button>
                <div ngbDropdownMenu="gfg">
            
                    <button ngbDropdownItem (click)="downloadTagInspection()">Download List</button>
                </div>
            </div>
 
        </div>
    </div>
    <div class="breadcrumb-nav">
        <ul>
            <li><a [routerLink]="['/project']">Project</a> <span class="icon breadcrumb-right icon-16 xs-mr-8 xs-ml-8"></span></li>
            <li><a (click)="goBack()">{{projectName}}</a> <span class="icon breadcrumb-right icon-16 xs-mr-8 xs-ml-8"></span></li>
            <li> <a href="javaScript:void(0)">{{scaffoldName}}</a></li>
        </ul>
    </div>

    <div class="content">
        <div class="sub-head-box">
            <div class="botton-box">
                <div class="search gray-bg">
                    <input type="text" [placeholder]="placeHolderText" [(ngModel)]="condition.name" (keypress)="searchKeywordByPress($event)" (blur)="getTagInspectionList()">
                    <i class="icon-search"></i>
                </div>

                <!--                <div class="select-filter">-->
                <!--                    <div class="top-box">All projects</div>-->
                <!--                    <ng-select style="width: 180px;"  [closeOnSelect]="false" [multiple]="true" [(ngModel)]="selectProjects"-->
                <!--                        (add)="addProjectSelectItem($event)" (remove)="removeProjectSelectItem($event)" class="mr-8 title-select ">-->
                <!--                        <ng-option *ngFor="let projectOption of projectList" [value]="projectOption.value">-->
                <!--                            {{projectOption.label}}</ng-option>-->
                <!--                    </ng-select>-->
                <!--                </div>-->
                <!-- <div class="project-content">
                    <div class="select-filter-content">
                        <div class="select-filter" (click)="showSelect($event,'project')" >
                            <div class="box-name">{{selectText}}</div>
                            <img src="assets/img/icon-new/icon-caret-down.svg"  width="16px" height="16px"  alt="">
                        </div>
                        <div class="show-select" *ngIf="showSelectProject" (click)="stopEvent($event)"  >
                            <div class="top-btn select-btn">
                                <span (click)="selectClick('all','project')">Select all</span>
                                <span (click)="selectClick('none','project')">Clear</span>
                            </div>
                            <div class="select-content content">
                                <div *ngFor="let option of projectList" class="select-item">
                                    <input type="checkbox" [(ngModel)]="option.select"  >
                                    <span title="{{option.label}}">{{option.label}}</span>
                                </div>
                            </div>
                            <div class="bottom-btn select-btn">
                                <span (click)="changeDialog('cancel','project')">Cancel</span>
                                <span (click)="changeDialog('done','project')">Done</span>
                            </div>
                        </div>
                    </div>
                </div> -->
                <!-- <div class="scaffold-content">
                    <div class="select-filter-content">
                        <div class="select-filter" (click)="showSelect($event,'scaffold')">
                                               <div class="box-name">All Scaffolds</div>
                                               <ng-select style="width: 180px;"  [closeOnSelect]="false" [multiple]="true" [(ngModel)]="selectScaffolds"
                                                   (add)="addScaffoldSelectItem($event)" (remove)="removeScaffoldSelectItem($event)"  class="mr-40 title-select ">
                                                   <ng-option *ngFor="let option of scaffoldList" [value]="option.value">{{option.label}}
                                                   </ng-option>
                                               </ng-select>
                            <div class="box-name">{{selectScaffoldsText}}</div>
                            <img src="assets/img/icon-new/icon-caret-down.svg" width="16px" height="16px" alt="">
                        </div>
                        <div class="show-select scaffold-select" *ngIf="showSelectScaffold" (click)="stopEvent($event)">
                            <div class="top-btn select-btn">
                                <span (click)="selectClick('all','scaffold')">Select all</span>
                                <span (click)="selectClick('none','scaffold')">Clear</span>
                            </div>
                            <div class="select-content content">
                                <div *ngFor="let option of scaffoldList" class="select-item">
                                    <input type="checkbox" [(ngModel)]="option.select">
                                    <span title="{{option.label}}">{{option.label}}</span>
                                </div>
                            </div>
                            <div class="bottom-btn select-btn">
                                <span (click)="changeDialog('cancel','scaffold')">Cancel</span>
                                <span (click)="changeDialog('done','scaffold')">Done</span>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>

            <div class="filter-box" *ngIf="selectTabType == tagTabName">
                <div class="item" [ngClass]="{'active': condition.tagStatus==null}" (click)="statueSearchTagList(null)">
                    All ({{tagNumberInfo.allNumber}})</div>
                <div class="item" [ngClass]="{'active': condition.tagStatus=='Active'}" (click)="statueSearchTagList('Active')">Active ({{tagNumberInfo.tagActiveNum}})</div>
                <div class="item" [ngClass]="{'active': condition.tagStatus=='Replaced/lost'}" (click)="statueSearchTagList('Replaced/lost')">Replaced<span
                        class="design-type">/</span>lost ({{tagNumberInfo.tagReplacedLostNum}})</div>
            </div>
            <div class="filter-box" *ngIf="selectTabType == inspectionTabName">
                <div class="item" [ngClass]="{'active': condition.inspectionStatus==null}" (click)="statueSearchInspectionList(null)">All ({{tagNumberInfo.inspectionAllNum}})
                </div>
                <div class="item" [ngClass]="{'active': condition.inspectionStatus=='SAFE'}" (click)="statueSearchInspectionList('SAFE')">Safe ({{tagNumberInfo.inspectionSafeNum}})
                </div>
                <div class="item" [ngClass]="{'active': condition.inspectionStatus=='UNSAFE'}" (click)="statueSearchInspectionList('UNSAFE')">Unsafe
                    ({{tagNumberInfo.inspectionUnsafeNum}})
                </div>
            </div>
        </div>

        <div class="table access-box" [hidden]="selectTabType == inspectionTabName">
            <table>
                <thead>
                    <tr>
                        <th class="id" style="width: 15%">
                            <div class="id-box">
                                <p> Tag ID <i class="icon icon-16 icon-title-down-arrow"
                                        [ngClass]="{'icon-title-down-arrow': currentSort['tagCode'] == 'ASC' || !currentSort['tagCode'],'icon-up-arrow':currentSort['tagCode'] == 'DESC' }"
                                        (click)="sortTagInspectionList('tagCode')"></i></p>
                            </div>
                        </th>
                        <th style="width: 15%">
                            <p>Project name <i class="icon icon-16 icon-title-down-arrow"
                                    [ngClass]="{'icon-title-down-arrow': currentSort['projectName'] == 'ASC' || !currentSort['projectName'],'icon-up-arrow':currentSort['projectName'] == 'DESC' }"
                                    (click)="sortTagInspectionList('projectName')"></i></p>
                        </th>
                        <th style="width: 15%">
                            <p>Scaffold name<i class="icon icon-16 icon-title-down-arrow"
                                    [ngClass]="{'icon-title-down-arrow': currentSort['scaffoldName'] == 'ASC' || !currentSort['scaffoldName'],'icon-up-arrow':currentSort['scaffoldName'] == 'DESC' }"
                                    (click)="sortTagInspectionList('scaffoldName')"></i></p>
                        </th>
                        <th style="width: 15%">
                            <p>Date Erected<i class="icon icon-16 icon-title-down-arrow"
                                    [ngClass]="{'icon-title-down-arrow': currentSort['erectedDate'] == 'ASC' || !currentSort['erectedDate'],'icon-up-arrow':currentSort['erectedDate'] == 'DESC' }"
                                    (click)="sortTagInspectionList('erectedDate')"></i></p>
                        </th>
                        <th style="width: 15%">
                            <p>Last Inspected <i class="icon icon-16 icon-title-down-arrow"
                                    [ngClass]="{'icon-title-down-arrow': currentSort['lastInspectionDate'] == 'ASC' || !currentSort['lastInspectionDate'],'icon-up-arrow':currentSort['lastInspectionDate'] == 'DESC' }"
                                    (click)="sortTagInspectionList('lastInspectionDate')"></i></p>
                        </th>
                        <th style="width: 15%">
                            <p>Last inspector <i class="icon icon-16 icon-title-down-arrow"
                                    [ngClass]="{'icon-title-down-arrow': currentSort['lastInspectionUserName'] == 'ASC' || !currentSort['lastInspectionUserName'],'icon-up-arrow':currentSort['lastInspectionUserName'] == 'DESC' }"
                                    (click)="sortTagInspectionList('lastInspectionUserName')"></i></p>
                        </th>
                        <th class="status" style="width: 20%">
                            <p style="justify-content: flex-start">Status <i class="icon icon-16 icon-title-down-arrow"
                                    [ngClass]="{'icon-title-down-arrow': currentSort['state'] == 'ASC' || !currentSort['state'],'icon-up-arrow':currentSort['state'] == 'DESC' }"
                                    (click)="sortTagInspectionList('state')"></i></p>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let tag of tagPage.records">
                        <td class="id">
                            <div class="id-box">
                                <span class="icon icon-tags-blue icon-16 xs-mr-8"></span>
                                <p>{{tag.tagCode}}</p>
                            </div>
                        </td>
                        <td>
                            <p>{{tag.projectName|| '-----'}}</p>
                        </td>
                        <td>
                            <p>{{tag.scaffoldName|| '-----'}}</p>
                        </td>
                        <td>
                            <p *ngIf="tag.erectedDate">{{tag.erectedDate | date:'dd MMM yyyy'}}</p>
                            <p class="empty-ellipsis" *ngIf="!tag.erectedDate">-----</p>
                        </td>
                        <td>
                            <p *ngIf="tag.lastInspectionDate">{{tag.lastInspectionDate | date:'dd MMM yyyy'}}</p>
                            <p class="empty-ellipsis" *ngIf="!tag.lastInspectionDate">-----</p>
                        </td>
                        <td>
                            <div class="inspector">
                                <!--                                <img *ngIf="tag.lastInspectionUserName" src="assets/img/inspector.png" alt="">-->
                                <p>{{tag.lastInspectionUserName || '-----'}}</p>
                            </div>
                        </td>
                        <td class="button-item" style="text-align: left">
                            <button class="btn btn-w114" *ngIf="tag.state != 'Replaced/lost'"
                                [ngClass]="{'btn-active': tag.state=='Active','btn-inactive': tag.state=='Inactive'}">{{tag.state}}</button>
                            <button class="btn  btn-retired" *ngIf="tag.state == 'Replaced/lost'">Replaced<span class="design-type">/</span>Lost</button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <ngb-pagination class="justify-content-center" *ngIf="selectTabType == tagTabName && tagPage.pages>1" [(page)]="tagPage.current" [pageSize]="tagPage.size" [maxSize]="5"
                [boundaryLinks]="false" (pageChange)="getTagInspectionListByPage(tagPage.current)" [collectionSize]="tagPage.total">
            </ngb-pagination>
        </div>

        <div class="table access-box" [hidden]="selectTabType == tagTabName">
            <table class="inspections-table">
                <thead>
                    <tr>
                        <th class="id" style="width: 10%">
                            <div class="id-box">
                                <p>ID <i class="icon icon-16 icon-title-down-arrow"
                                        [ngClass]="{'icon-title-down-arrow': currentSort['inspectionNumber'] == 'ASC' || !currentSort['inspectionNumber'],'icon-up-arrow':currentSort['inspectionNumber'] == 'DESC' }"
                                        (click)="sortTagInspectionList('inspectionNumber')"></i></p>
                            </div>
                        </th>
                        <th style="width: 15%">
                            <p>Project name <i class="icon icon-16 icon-title-down-arrow"
                                    [ngClass]="{'icon-title-down-arrow': currentSort['projectName'] == 'ASC' || !currentSort['projectName'],'icon-up-arrow':currentSort['projectName'] == 'DESC' }"
                                    (click)="sortTagInspectionList('projectName')"></i></p>
                        </th>
                        <th style="width: 20%">
                            <p>Scaffold name<i class="icon icon-16 icon-title-down-arrow"
                                    [ngClass]="{'icon-title-down-arrow': currentSort['scaffoldName'] == 'ASC' || !currentSort['scaffoldName'],'icon-up-arrow':currentSort['scaffoldName'] == 'DESC' }"
                                    (click)="sortTagInspectionList('scaffoldName')"></i></p>
                        </th>
                        <th style="width: 10%">
                            <p>Tag ID <i class="icon icon-16 icon-title-down-arrow"
                                    [ngClass]="{'icon-title-down-arrow': currentSort['tagCode'] == 'ASC' || !currentSort['tagCode'],'icon-up-arrow':currentSort['tagCode'] == 'DESC' }"
                                    (click)="sortTagInspectionList('tagCode')"></i></p>
                        </th>
                        <th style="width: 15%">
                            <p>Date Erected<i class="icon icon-16 icon-title-down-arrow"
                                    [ngClass]="{'icon-title-down-arrow': currentSort['erectedDate'] == 'ASC' || !currentSort['erectedDate'],'icon-up-arrow':currentSort['erectedDate'] == 'DESC' }"
                                    (click)="sortTagInspectionList('erectedDate')"></i></p>
                        </th>
                        <th style="width: 15%">
                            <p>Last Inspected<i class="icon icon-16 icon-title-down-arrow"
                                    [ngClass]="{'icon-title-down-arrow': currentSort['inspectionData'] == 'ASC' || !currentSort['inspectionData'],'icon-up-arrow':currentSort['inspectionData'] == 'DESC' }"
                                    (click)="sortTagInspectionList('inspectionData')"></i></p>
                        </th>
                        <th style="width: 15%">
                            <p>Inspector <i class="icon  icon-16 icon-title-down-arrow"
                                    [ngClass]="{'icon-title-down-arrow': currentSort['inspectionUserName'] == 'ASC' || !currentSort['inspectionUserName'],'icon-up-arrow':currentSort['inspectionUserName'] == 'DESC' }"
                                    (click)="sortTagInspectionList('inspectionUserName')"></i></p>
                        </th>
                        <th style="width: 10%">
                            <p>Outcome <i class="icon  icon-16 icon-title-down-arrow"
                                    [ngClass]="{'icon-title-down-arrow': currentSort['outCome'] == 'ASC' || !currentSort['outCome'],'icon-up-arrow':currentSort['outCome'] == 'DESC' }"
                                    (click)="sortTagInspectionList('outCome')"></i></p>
                        </th>
                        <th>
                            <p>
                                Status
                            </p>
                        </th>
                        <th style="width: 30%">
                            <p>Handover Certificate  </p>
                        </th> 
                    </tr>
                </thead> 
                <tbody>
                    <tr *ngFor="let inspection of inspectionPage.records">
                        <td class="id">
                            <div class="id-box">
                                <span class="icon icon-tags-blue icon-16 xs-mr-8"></span>
                                <p>{{inspection.inspectionNumber}}</p>
                            </div>
                        </td>
                        <td class="project-name">
                            <p>{{inspection.projectName|| '-----'}}</p>
                        </td>
                        <td class="scaffold-name">
                            <p>{{inspection.scaffoldName|| '-----'}}</p>
                        </td>
                        <td>
                            <p style="text-transform: uppercase;">{{inspection.tagCode|| '-----'}}</p>
                        </td>
                        <td>
                            <p *ngIf="inspection.erectedDate">{{inspection.erectedDate | date:'dd MMM yyyy'}}</p>
                            <p class="empty-ellipsis" *ngIf="!inspection.erectedDate">-----</p>
                        </td>
                        <td>
                            <p *ngIf="inspection.inspectionData">{{inspection.inspectionData | date:'dd MMM yyyy'}}</p>
                            <p class="empty-ellipsis" *ngIf="!inspection.inspectionData">-----</p>
                        </td>
                        <td style="border-right: none">
                            <div class="inspector">
                                <!--                                <img src="assets/img/inspector.png" alt="">-->
                                <p>{{inspection.inspectionUserName || '-----'}}</p>
                            </div>
                        </td>
                        <td class="outcome">
                            <p [ngClass]="{  'green': inspection.outCome.toUpperCase() == 'SAFE','red': inspection.outCome.toUpperCase() == 'UNSAFE' ,'gray': inspection.step == 'DRAFT'}   "  >
                                {{  inspection.step == 'DRAFT' ? '-----' : inspection.outCome}}
                            
                            </p>
                        </td>
                        <td class="step">
                            <p *ngIf="inspection.step == 'SUBMIT' &&  inspection.timeline == 'CURRENT' ">
                                Submitted
                            </p>
                            <p *ngIf="inspection.step == 'SUBMIT' &&  inspection.timeline == 'PAST' ">
                                {{  inspection.timeline.toLocaleLowerCase()}}
                            </p>
                            <p *ngIf="inspection.step == 'DRAFT' &&  inspection.timeline == 'CURRENT' ">
                                {{  inspection.step.toLocaleLowerCase()}}
                            </p>
                        </td>
                        <td class="download-pdf" [ngClass]="{'pdf': inspection.step == 'SUBMIT' &&  inspection.timeline == 'CURRENT' , 'pdf-text':inspection.step == 'DRAFT'}">
                            <p *ngIf="jwtUser.role != 0" (click)="inspection.step == 'SUBMIT' &&  inspection.timeline == 'CURRENT' ?  downloadHandoverCertificate(inspection.id) : null">
                                <img src="assets/img/icon-pdf.svg" alt="" srcset="" *ngIf="inspection.step == 'SUBMIT'  && inspection.timeline == 'CURRENT' ">
                                <span *ngIf="inspection.step == 'SUBMIT'  && inspection.timeline == 'PAST'">-----</span>
                                {{ inspection.step == 'DRAFT' ? '-----': '' }}
                            </p>
                            <p *ngIf="jwtUser.role == 0">-----</p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <ngb-pagination class="justify-content-center" *ngIf="selectTabType == inspectionTabName && inspectionPage.pages>1" [(page)]="inspectionPage.current"
                [boundaryLinks]="false" [pageSize]="inspectionPage.size" [maxSize]="5" (pageChange)="getTagInspectionListByPage(inspectionPage.current)"
                [collectionSize]="inspectionPage.total">
            </ngb-pagination>
        </div>



    </div>
</div>