import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StripeInfo } from '../observable/model/base';
import { UserRepository } from '../repository/user-repository';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    private stripeInfo = new StripeInfo();
    constructor(
        private userRepository: UserRepository,
        private router: Router,
        private modalService: NgbModal,
        private ngbActiveModal: NgbActiveModal,
        private jwtHelperService: JwtHelperService
    ) {
    }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const isLogin = localStorage.getItem('login');
        const accessToken = localStorage.getItem('accessToken');
        if (isLogin == null) {
            this.router.navigate(['/login']);
            return false;
        }
        if (isLogin != null && accessToken != null && this.jwtHelperService.isTokenExpired(accessToken)) {
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            return false;
        }
        return true;
    }
}
