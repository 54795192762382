import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'app-signup-order-completed',
    templateUrl: './signup-order-completed.component.html',
    styleUrls: ['./signup-order-completed.component.less']
})
export class SignupOrderCompletedComponent implements OnInit {

    public orderNum = null;

    constructor(private router: Router,public activatedRoute: ActivatedRoute) {
        this.activatedRoute.queryParamMap.subscribe(paramMap => {
            this.orderNum = paramMap.get('orderNum');
        });
    }

    ngOnInit(): void {
    }

    download(): void{
        this.router.navigate(['download-app']);
    }

    invite(): void {
        this.router.navigate(['/signup-team-email']);
    }

}
