<div class="upload-main">
    <div class="content">
        <div class="steps">
            <ul class="lineUl">
                <li *ngFor="let item of steps; let i = index" [ngClass]="{'liWidth': i + 1 == steps.length}">
                    <div class="stepsIcon">
                        <div class="radioSelect">
                            <div [ngClass]="item.state|classPipe:['step-line-current','step-line-complete','step-line-default']">
                                <p>{{item.info}}</p>
                            </div>
                        </div>
                        <div *ngIf="i + 1 != steps.length" class="line" [ngClass]="{'lineDefaule': i + 2 > step}"></div>
                    </div>
                </li>
            </ul>

            <ul class="text">
                <li *ngFor="let item of steps; let i = index" [ngClass]="{'radioSelectDisabled': i +1 > step}">
                    <div class="title" *ngIf="!!item.title">{{ !item.title[item.state] ? item.title[0] : item.title[item.state] }}</div>
                </li>
            </ul>

            <div class="main">
                <div class="active uploaded" *ngIf="step <= 1">
                    <h3>Upload Files</h3>
                    <div class="upload-files">
                        <ngx-file-drop *ngIf="state == 'default'" [multiple]="false" dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)" (onFileOver)="fileOver($event)"
                            (onFileLeave)="fileLeave($event)" accept=".csv,.xls,.xlsx">
                            <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                                <div class="file-content" (click)="openFileSelector()">
                                    <div class="upload-box">
                                        <div class="img-box"> <img src="assets/img/cloud-upload.svg" alt=""> </div>
                                        <div class="text-box">
                                            <p>Drag & drop files here or <a href="javascript:void(0)">Browse a file</a> to upload your Projects. Only .csv, .xls or .xlsx files are
                                                supported.</p>
                                            <p>Maximum size is 20MB. Files should contain headers and no emojis.</p>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </ngx-file-drop>

                        <div class="upload-box succeed" *ngIf="state=='success'">
                            <div class="img-box"> <img src="assets/img/icon/icon-upload-succeed.svg" alt=""> </div>
                            <div class="text-box">

                                <p>File uploaded successfully “{{file.name}} ” {{file.size | fileSizePipe}}</p>
                            </div>
                        </div>
                        <ngx-file-drop *ngIf="state == 'failed'" [multiple]="false" dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)" (onFileOver)="fileOver($event)"
                            (onFileLeave)="fileLeave($event)" accept=".csv,.xls,.xlsx">
                            <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                                <div class="file-content" (click)="openFileSelector()">
                                    <div class="upload-box  error">
                                        <div class="text-box">
                                            <h2>
                                                Upload failed (import projects {{file.name}} - {{file.size | fileSizePipe}})
                                            </h2>
                                            <h3>{{message}}</h3>
                                            <p>Please <a href="javascript:void(0)">Upload a different file</a></p>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </ngx-file-drop>

                    </div>
                    <p class="download-template">Not sure if your file is ready to be imported? <a (click)="download()" href="javascript:void(0)">Download</a> our .csv template</p>
                </div>
                <div class="active preview" *ngIf="step == 2">
                    <h3>Information Preview</h3>
                    <div class="table upload-table">
                        <table>

                            <tbody>
                                <tr *ngFor="let row of data">
                                    <td *ngFor="let val of row">
                                        {{val}}
                                    </td>
                                </tr>


                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="active details" *ngIf="step == 3">
                    <h3>Final Details</h3>
                    <p class="file-name">File name*</p>
                    <input class="file-input" [(ngModel)]='fileName'>
                    <p>A file name cannot contain any of the following characters \ / : * ? ” <>
                            Maximum number of characters 255</p>
                    <p class="error">File name exceeds to maximum number of characters: 255</p>
                </div>
            </div>
        </div>


    </div>
    <div class="footer">
        <a href="javascript:void(0)" (click)='back()'>Back</a>
        <button class="next" [ngClass]="{'next-disable':step == 0}" [disabled]="step == 0" (click)="next()"> Next</button>
    </div>

</div>