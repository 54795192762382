<div class="admin-wrapper">
    <div class="admin">
        <div class="admin-left">
            <div class="logo"></div>
            <div class="bg-box"></div>
        </div>
        <div class="admin-right">
            <div class="text-box">
                <form class="form" name="taggedForm" [ngxFormValidator]="validatorConfig">
                    <div class="back-top" (click)="back()"> <span class="icon icon-24 icon-arrow-back"></span>Sign up</div>
                    <h2 class="title">Welcome!</h2>
                    <div class="subtitle">Enter your company details to proceed</div>
                    <div class="form-control-group form-icon">
                        <input type="text" id="companyName" maxlength="50" required name="companyName"
                            [(ngModel)]="registerInfo.companyName" placeholder="Enter company name" 
                            (ngModelChange)="registerInfo.companyName = capitalize(registerInfo.companyName)"
                            >
                        <span class="icon icon-16 icon-building-1 icon-first-pic"></span>
                    </div>
                    <div class="form-control-group form-icon">
                        <input type="text" id="mobile" name="mobile" required maxlength="50"
                            [(ngModel)]="registerInfo.mobile" placeholder="Enter mobile">
                        <span class="icon icon-16 icon-phone icon-first-pic"></span>
                    </div>
                    <div class="form-control-group form-icon">
                        <div class="input select">
                            <ng-select  placeholder="Select your region" name="region" [searchable]="false"
                                        [(ngModel)]="registerInfo.region">
                                <ng-option *ngFor="let option of regionList" [value]="option.value">{{option.label}}
                                </ng-option>
                            </ng-select>
                            <i class="icon icon-18 icon-down-arrow"></i>
                            <span class="icon icon-16 icon-globe icon-first-pic"></span>
                        </div>
                    </div>
                    <div class="tagged-tips">
                        <i class="icon icon-18 icon-tagged-tip"></i>
                        <p>Once the region is set up, it cannot be changed</p>
                    </div>
                    <div class="form-button">
                        <button class="btn btn-o" (ngxFormSubmit)="register()" [disabled]="loading">Next</button>
                    </div>
                    <p class="cancel"><a href="javascript:void(0)" (click)="back()">Cancel</a></p>
                </form>
            </div>
        </div>
    </div>
</div>
