<div class="admin-wrapper">
    <div class="admin">
        <div class="admin-left">
            <div class="logo"></div>
            <div class="bg-box"></div>
        </div>
        <div class="admin-right">
            <div class="text-box">
                <form class="form" name="signUp" [ngxFormValidator]="validatorConfig">
                    <h2 class="title">Sign Up</h2>
                    <div class="subtitle"> Enter your details to proceed</div>
                    <div class="form-control-group form-icon">
                        <input type="text" id="firstName" name="firstName" [(ngModel)]="registerInfo.firstName" required
                            maxlength="50" placeholder="Enter first name" (ngModelChange)="registerInfo.firstName = capitalizeFirst(registerInfo.firstName)" >
                        <span class="icon icon-16 icon-user icon-first-pic"></span>
                    </div>
                    <div class="form-control-group form-icon">
                        <input type="text" id="lastName" name="lastName" [(ngModel)]="registerInfo.lastName" required
                            maxlength="50" placeholder="Enter last name"
                            (ngModelChange)="registerInfo.lastName = capitalizeFirst(registerInfo.lastName)"
                            >
                        <span class="icon icon-16 icon-user icon-first-pic"></span>
                    </div>
                    <div class="form-control-group form-icon">
                        <input type="email" id="email" name="email" required [(ngModel)]="registerInfo.email"
                            maxlength="255" email="true" placeholder="Enter email">
                        <span class="icon icon-16 icon-email icon-first-pic"></span>
                    </div>
                    <div class="form-control-group form-icon">
                        <input type="password" name="password"  [(ngModel)]="registerInfo.password" minlength="8" maxlength="32" *ngIf="!showPassword"
                               required placeholder="Enter password">
                           <input type="text" name="password" [(ngModel)]="registerInfo.password" minlength="8" maxlength="32"  *ngIf="showPassword"
                                  required placeholder="Enter password">
                        <span class="icon icon-16 icon-password icon-first-pic"></span>
                        <span class="icon icon-16 icon-eye-close icon-right-pic" [hidden]="showPassword" (click)="changePassword('show')"></span>
                        <span class="icon icon-16 icon-eye-open icon-right-pic" [hidden]="!showPassword" (click)="changePassword('hide')"></span>
                    </div>
                    <div class="form-control-group form-icon">
<!--                        <label for="confirmPassword">Confirm password</label>-->
                        <input type="password" name="confirmPassword" [(ngModel)]="registerInfo.confirmPassword" minlength="8" maxlength="32"
                               required [ngxUniqueCheck]="passwordEqual" *ngIf="!showConfirmPassword"
                               placeholder="Enter password again">
                        <input type="text" name="confirmPassword"  [(ngModel)]="registerInfo.confirmPassword" minlength="8" maxlength="32"
                               required [ngxUniqueCheck]="passwordEqual" *ngIf="showConfirmPassword"
                               placeholder="Enter password again">
                        <span class="icon icon-16 icon-password icon-first-pic"></span>
                        <span class="icon icon-16 icon-eye-close icon-right-pic" [hidden]="showConfirmPassword" (click)="changeConfirmPassword('show')"></span>
                        <span class="icon icon-16 icon-eye-open icon-right-pic" [hidden]="!showConfirmPassword" (click)="changeConfirmPassword('hide')"></span>
                    </div>

                    <div class="check">
                        <input name="trcheck" required type="checkbox" [(ngModel)]="registerInfo.trcheck" id="check">
                        <div class="tip">
                            <label for="check">I agree with</label>&nbsp;<span (click)="openTerms()"> terms & conditions</span>&nbsp;&&nbsp;<span (click)="openPolicy()" class="privacy"> privacy policy</span>
                        </div>
                    </div>

                    <button class="btn btn-o btn-block" (ngxFormSubmit)="signUp()">Sign Up</button>
                    <div class="bottom-tip">Already have an account? <a [routerLink]="['/login']" href="javascript:void(0)">Sign in</a></div>
                </form>
            </div>
        </div>
    </div>
</div>
