import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Billing} from '../observable/model/billing';
import {Injectable} from '@angular/core';
import {HttpResult} from '../observable/model/common/http-result';

@Injectable({
    providedIn: 'root'
})
export class SubscriptionRepository {
    constructor(private http: HttpClient) {
    }

    public create(billing: Billing): Observable<any> {
        return this.http.post(environment.baseURL + '/pc/subscription/create', billing);
    }

    public upgrade(): Observable<any> {
        return this.http.post(environment.baseURL + '/pc/subscription/upgrade', {});
    }

    public cancel(subscriptionId: string): Observable<any> {
        return this.http.post(environment.baseURL + `/pc/subscription/cancel/${subscriptionId}`, {});
    }

    public info(): Observable<any> {
        return this.http.get(environment.baseURL + '/pc/stripe/subscription/v1/get');
    }

    public getPubkey(): Observable<any> {
        return this.http.get(environment.baseURL + `/pc/common/v1/getStripePubKey`);
    }
}
