<div class="create-project">
    <div class="modal-header">
        <h4 class="modal-title">{{title}}</h4>
       
    </div>
    <div class="modal-body">
        <div class="form modal-form">
            <div class="form-group">
            
                <label class="control-label">Project Name</label>
                <input type="text" class="form-control" placeholder="" autocomplete="false" [(ngModel)]="project.name" 
                (ngModelChange)="project.name = capitalize(project.name)"
                    autofocus="false"/>
            </div>
            <div class="form-group">
   
            <div class="form-group">
                <label class="control-label">Client Name</label>
                <input type="text" class="form-control" placeholder="" autocomplete="false" [(ngModel)]="project.client"
                (ngModelChange)="project.client = capitalize(project.client)"
                    autofocus="false" />
            </div>
            <div class="form-group">
                <label class="control-label">Site Address</label>
                <input type="text" class="form-control" placeholder="" autocomplete="false" autofocus="false" [(ngModel)]="project.address"
                (ngModelChange)="project.address = capitalize(project.address)"
                  />
            </div>
            <div class="form-group">
                <label class="control-label">Branch</label>
                <div class="select special-select-all" [ngClass]="{'select-disabled': isEdit}">
                    <ng-select 
                        [disabled]="isEdit"
                        [(ngModel)]="project.branch"   
                        [searchable]="false" 
                        (change)="getCheckBranchValue($event)">
                        <ng-option *ngFor="let branch of branchList" [value]="branch.id">
                            {{ branch.name }}
                        </ng-option>
                    </ng-select>
                    <i class="icon icon-18 icon-down-arrow"></i>
                </div>
            </div>
            
            <div class="form-group">
                <label class="control-label">Project Icon</label>
                <div class="project-icon" >
                    <div class="check-icon" *ngFor="let item of hexList"  (click)="selectProject(item)" [ngClass]="{'selected': item.label === selectedIcon || project.label ===  item.label}">
                       <img src="assets/img/icon-{{item.icon}}.png" alt="">
                    </div>
                </div>
            </div>
           <div *ngIf="branchUserList.length > 0">
            <div class="form-group">
                <h2>Assign Inspectors for this project</h2>
                <label class="control-label">Showing inspectors from {{project.branchName}} branch.</label>
            </div>

            <div class="select-content content">
                <div class="select-item" [ngClass]="{'select-disabled': project.state=='ARCHIVED'}"  *ngFor="let item of branchUserList">
                  <label>
                    <input type="checkbox" [disabled]="project.state=='ARCHIVED'" [(ngModel)]="item.checked" (change)="selectBranchBox(item)"> {{item.value}}
                  </label>
                </div>
              </div>
           </div>
              
          
            <div class="form-button form-button-two-with_div" [ngClass]="{'form-bottom-edit': isEdit}">
                <div class="unarchive" *ngIf="isEdit">
                    <button (click)="unarchive(project.state)">{{ project.state == 'ACTIVATED'  ? 'Archive this project': 'Unarchive this project' }}</button>
                </div>
                <div class="close">
                    <button class="color-o" (click)="cancel()">Cancel</button>
                </div>
                <div class="submit">
                    <button class="btn btn-o" (click)="addProject()"> {{ isEdit ? 'Save': 'Add Project' }} </button>
                </div>

            </div>
        </div>
    </div>

</div>
