import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { List } from 'echarts';
import { FileSystemDirectoryEntry, FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { History } from 'src/app/observable/model/history';
import { FileRepository } from 'src/app/repository/file-repository';
import { ToastRepository } from 'src/app/repository/toast-repository';
// import * as XLSX from 'xlsx';

type AOA = any[][];


@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.less']
})
export class UploadComponent implements OnInit {
  data: AOA = [[1, 2], [3, 4]];
  step: number = 0;
  file: File;
  fileName: string;
  succeed: boolean;
  state: string = 'default';
  message: string;
  steps: Array<any> = [
    {
      title: ['Upload', 'Uploaded'],
      info: '1',
      state: 0,
    },
    {
      title: ['Preview'],
      info: '2',
      state: 2,
    },
    {
      title: ['Details'],
      info: '3',
      state: 2,
    },
  ];


  constructor(public fileRepository: FileRepository, public toastRepository: ToastRepository) { }

  ngOnInit() { }


  public dropped(files: NgxFileDropEntry[]): void {
    const list = files.filter(f => f.fileEntry.isFile);
    if (list.length == 0) {
      return;
    }
    const fileSystemFileEntry = files[0].fileEntry as FileSystemFileEntry;
    fileSystemFileEntry.file(f => {
    
      if (f.size > 20 * 1024 * 1024) {
        this.state = 'failed';
        this.file = f;
        this.message = 'Your file exceeds the file size limit.';
        return;
      }
      this.fileRepository.uploadFile(f).subscribe((value) => {
         
        
        this.state = 'success';
        this.file = f;
        this.steps[0].state = 1;
        this.step = 1;
      }, (res: HttpErrorResponse) => {
        if ([406, 417].includes(res.error.status)) {
          this.state = 'failed';
          this.file = f;
          this.message = res.error.message;
        } else {
          this.toastRepository.showDanger(res.error.message, 'toastTop');
        }
      });
    });
  }

  download(): void {
    this.fileRepository.downloadTemplate().subscribe((b) => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(b);
      link.download = 'Template.csv';
      link.click();
      link.remove();
    });
  }

  public fileOver(event): void {
    console.log(event);
  }

  public fileLeave(event): void {
    console.log(event);
  }


  back(): void {
    switch (this.step) {
      case 0:
      case 1:
        history.back();
        break;
      case 2:
        this.step = 1;
        break;
      case 3:
        this.step = 2;
        break;
    }
  }
  next(): void {
    history.back();
    // switch (this.active) {
    //   case 1:
    //     this.active = 2;
    //     break;
    //   case 2:
    //     this.active = 3;
    //     break;
    //   case 3:

    //     break;
    // }
  }

}
