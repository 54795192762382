export class Tag {
    tagCode: string;
    projectName: string;
    scaffoldName: string;
    erectedDate: Date;
    lastInspectionDate: Date;
    lastInspectionUserName: string;
    state: string;
}

export class Inspection {
    inspectionNumber: string;
    projectName: string;
    scaffoldName: string;
    inspectionData: Date;
    inspectionUserName: string;
    outCome: string;
    tagCode: string;
    erectedDate: Date;
    step:string;
    id:string;
    timeline:string;
}

export class TagByOrder {
    tagCode: string;
    companyName: string;
    orderedAd: Date;
    orderAdString: string;
    dateScanned: Date;
    projectName: string;
    state: string;
    link: string;
}

export class TagNumberInfo {
    tagActiveNum: number;
    tagReplacedLostNum: number;
    tagInactiveNum: number;
    inspectionSafeNum: number;
    inspectionUnsafeNum: number;
    allNumber: number;
    inspectionAllNum:number;

}
