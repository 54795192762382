import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs/internal/Observable';
import {Branch} from '../observable/model/branch';
import { HandoverCertificates } from '../observable/model/handover-certificates';

@Injectable({
    providedIn: 'root'
})
export class HandoverCertificatesRepository {
     
    constructor(private http: HttpClient) {
    }
    public getHandoverCertificate(): Observable<any> {
        return this.http.get<any>(environment.baseURL + `/pc/handoverCertificate/info`);
    }

    public saveHandoverCertificate(data: HandoverCertificates): Observable<any> {
        return this.http.post<any>(environment.baseURL + `/pc/handoverCertificate/save`, data);
    }
    public download(id:string){
        return this.http.get<any>(environment.baseURL + `/pc/handoverCertificate/download/${id}`);
    }
    

}
