import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SubscriptionRepository } from '../../../repository/subscription-repository';
import { Subscription } from '../../../observable/model/subscription';
import { ToastRepository } from '../../../repository/toast-repository';
import { TeamUser } from 'src/app/observable/model/user';
import { UserRepository } from 'src/app/repository/user-repository';

@Component({
    selector: 'app-cancel-subscription',
    templateUrl: './cancel-invite.component.html',
    styleUrls: ['./cancel-invite.component.less']
})
export class CancelInviteComponent implements OnInit {
    user: TeamUser;

    constructor(
        private ngbActiveModal: NgbActiveModal,
        private userRepository: UserRepository,
        private toastRepository: ToastRepository
    ) {
    }

    ngOnInit(): void {
    }

    confirm(): void {
        this.toastRepository.showLoading(true);
        this.userRepository.cancelInvite(this.user.userId).subscribe(result => {
            this.toastRepository.showLoading(false);
            this.cancel();
            this.ngbActiveModal.close();
        });
    }

    cancel(): void {
        this.ngbActiveModal.close({ type: '1' });
    }
}
