import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Page } from '../../observable/model/base';
import { Condition, PageCondition } from '../../observable/model/condition';
import { BoxObj, BoxStatusNumVo, Order, OrderInfo, OrderStatusNumVo } from '../../observable/model/order';
import { JWTUser } from '../../observable/model/user';
import { OrderRepository } from '../../repository/order-repository';
import { ToastRepository } from '../../repository/toast-repository';
import { Box } from '../../observable/model/box';
import { BillingRepository } from '../../repository/billing-repository';

@Component({
    selector: 'app-order',
    templateUrl: './tag.component.html',
    styleUrls: ['./tag.component.less']
})

export class TagComponent implements OnInit {
    boxPage: Page<BoxObj> = new Page<BoxObj>();
    condition = new PageCondition('', 10);
    currnetSort = new Map();
    userRole: number;
    switchStatus: number;
    boxList: Array<Box> = [];
    boxStatusNumVo: BoxStatusNumVo = new BoxStatusNumVo();
    orderInfo = new OrderInfo();
    title: string;
    screenHeight = document.body.clientHeight;

    constructor(
        private billingRepository: BillingRepository,
        private orderRepository: OrderRepository,
        private modalService: NgbModal,
        private modalAdService: NgbModal,
        private ngbActiveModal: NgbActiveModal,
        private router: Router,
        public toastRepository: ToastRepository,
        private jwtHelperService: JwtHelperService
    ) {
        let page = new URLSearchParams(window.location.href.split('?')[1]).get('page');
        if (page) {
            this.condition.current = Number.parseInt(page);
        } else {
            this.setPageCondition(1);
        }
    }

    ngOnInit(): void {
        this.title = 'Tags';
        this.init();
    }

    init(): void {
        var freeTrial = document.getElementsByClassName("fourteen-tip");
        let hideHeight = freeTrial && freeTrial.length > 0 ? 80 : 0;
        this.condition.size = Math.trunc((this.screenHeight - 72 - 99 - 98 - 46 - hideHeight) / 75) - 1;
        this.switchStatus = 1;
        this.getOrderList();
        const accessToken = localStorage.getItem('accessToken');
        const jwtUser = this.jwtHelperService.decodeToken(accessToken).inf as JWTUser;
        this.userRole = jwtUser.role;
    }


    getOrderList(): void {
        this.boxPage = new Page<BoxObj>();
        this.orderRepository.getBoxStatusNumVo().subscribe(result => {
            this.boxStatusNumVo = result;
            this.boxStatusNumVo.allNumber = result.assignedNum + result.unassignedNum;
        });

        delete this.condition.orderStatus;
        this.orderRepository.boxList(this.condition).subscribe(result => {
            this.boxPage = result;
        });
    }


    getBoxListByPage(current: number): void {
        this.condition.current = current;
        this.setPageCondition(current);
        this.orderRepository.boxList(this.condition).subscribe(result => {
            this.boxPage = result;
        });
    }

    sortOrderList(sortName: string): void {
        this.condition.sortName = sortName;
        this.condition.sortOrder = this.condition.sortOrder == 'ASC' ? 'DESC' : 'ASC';
        this.getOrderList();
        this.currnetSort = new Map();
        this.currnetSort[sortName] = this.condition.sortOrder;
    }

    statueSearchOrderList(status: number): void {
        this.condition.current = 1;
        this.condition.boxStatus = status;
        this.getOrderList();
    }

    searchKeywordByPress($event: KeyboardEvent): void {
        if ($event.key != 'Enter') {
            return;
        }
        this.getOrderList();
    }


    goBoxContent(box: BoxObj): void {
        this.router.navigate(['tag', box.id, 'tag'], { queryParams: { boxNum: box.boxNumName, type: 'box' } });
    }

    setPageCondition(current: Number): void {
        let params: URLSearchParams = new URLSearchParams(window.location.href.split('?')[1]);
        if (params.has('page')) {
            params.set('page', `${current}`);
        } else {
            params.append('page', `${current}`);
        }
        window.location.href = window.location.href.split('?')[0] + '?' + params;
    }
}
