import {Component, OnInit} from '@angular/core';
import {NgxValidatorConfig} from '@why520crazy/ngx-validator';
import {Observable, of} from 'rxjs';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {TeamUserPassword} from '../../../observable/model/user';
import {ToastRepository} from '../../../repository/toast-repository';
import {UserRepository} from '../../../repository/user-repository';
import {AgreementUserComponent} from '../../modal/agreement-user/agreement-user.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';


@Component({
    selector: 'app-invite-agree',
    templateUrl: './invite-agree.component.html',
    styleUrls: ['./invite-agree.component.less']
})
export class InviteAgreeComponent implements OnInit{
    showPassword :boolean;
    showConfirmPassword :boolean;
    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                private ngbModal: NgbModal,
                private userRepository: UserRepository,
                public toastRepository: ToastRepository) {

    }

    public teamUserPassword = new TeamUserPassword();

    validatorConfig: NgxValidatorConfig = {
        validationMessages: {
            firstName: {
                required: 'First name is required.'
            },
            lastName: {
                required: 'Last name is required.'
            },
            phone: {
                required: 'Phone is required.'
            },
            password: {
                required: 'password is required.'
            },
            confirmPassword: {
                required: 'Confirm Password is required.',
                ngxUniqueCheck: 'Confirm password must equal to password.'
            },
            trcheck: {
                required: 'Please read and agree with terms & conditions.'
            },
        },
        validateOn: 'submit'
    };


    ngOnInit(): void {
        this.activatedRoute.params.subscribe((params: Params) => {
            this.teamUserPassword.token = params.token;
        });
    }
    changePassword(val){
        this.showPassword =  val == 'show' ? true :false;
    }
    changeConfirmPassword(val){
        this.showConfirmPassword =  val == 'show' ? true :false;
    }
    signUp(): void {
        this.userRepository.agreeInvite(this.teamUserPassword).subscribe(result => {
            if (result) {
                this.toastRepository.showSuccess('Welcome to Scafflinq');
                if(result.roleType == 1){
                    this.router.navigate(['/login']);
                }else{
                    this.router.navigate(['download-app'], {queryParams: {fromPage: 'invite'}});
                }
            } else {
                this.toastRepository.showDanger('Oops! Something went wrong, please try again.');
            }

        });
    }
    openTerms(){
        const ngbModalRef = this.ngbModal.open(AgreementUserComponent,
            {backdrop: 'static', keyboard: true, size: 'big'});
        ngbModalRef.componentInstance.type = '1';
        ngbModalRef.result.then(result => {
            console.log(result,'result');
        });
    }
    openPolicy(){
        const ngbModalRef = this.ngbModal.open(AgreementUserComponent, {backdrop: 'static', keyboard: true, size: 'big'});
        ngbModalRef.componentInstance.type = '2';
        ngbModalRef.result.then(result => {
            console.log(result,'result');
        });
    }
    passwordEqual = (value): Observable<boolean> => {
        return of(this.teamUserPassword.password != value);
    }
}
