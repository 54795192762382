import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Branch } from 'src/app/observable/model/branch';
import { ProjectRepository } from 'src/app/repository/project-repository';
import { TeamUser } from '../../../observable/model/user';
import { ToastRepository } from '../../../repository/toast-repository';
import { UserRepository } from '../../../repository/user-repository';

@Component({
    selector: 'app-edit-staff',
    templateUrl: './edit-staff.component.html',
    styleUrls: ['./edit-staff.component.less']
})
export class EditStaffComponent implements OnInit {
    user: TeamUser;
    isLoading = false;
    branchList: Branch[] = [];
    previousBranches: string[] = []; 
   
    constructor(private ngbActiveModal: NgbActiveModal,
                private userRepository: UserRepository,
                private projectRepository: ProjectRepository,
                public toastRepository: ToastRepository) {
    }

    ngOnInit(): void {
        this.user.branches = this.user.branches || [];
        this.previousBranches = [...this.user.branches]; 
        this.getBranchList();
    }

    getBranchList(): void {
        this.projectRepository.getBranch('active').subscribe(result => {
            this.branchList = result.map(branch => {
                return {
                    ...branch,
                    checked: this.user.branches.includes(branch.id)
                };
            });

        
            if (this.user.roleType === 1) {
                this.selectAllBranches();
            }
        });
    }

    selectAllBranches(): void {
        this.branchList.forEach(branch => {
            branch.checked = true;
            if (!this.user.branches.includes(branch.id)) {
                this.user.branches.push(branch.id);
            }
        });
    }

    selectBranchBox(item: Branch): void {
   
        if (this.user.roleType === 1) {
            item.checked = true;
            return;
        }

        if (item.checked) {
            this.user.branches.push(item.id);
        } else {
            const index = this.user.branches.indexOf(item.id);
            if (index > -1) {
                this.user.branches.splice(index, 1);
            }
        }
    }

    onRoleTypeChange(): void {
        if (this.user.roleType === 1) {
            this.selectAllBranches();
        } else if (this.user.roleType === 2) {
            this.restorePreviousBranches();
        }
    }

    restorePreviousBranches(): void {
        this.user.branches = [...this.previousBranches];  
        this.branchList.forEach(branch => {
            branch.checked = this.user.branches.includes(branch.id);
        });
    }

    cancel(): void {
        this.ngbActiveModal.close();
    }

    editUser(): void {
        this.isLoading = true;
        this.userRepository.updateUser(this.user).subscribe(result => {
            if (result) {
                this.toastRepository.showSuccess('Staff edited successfully.');
            } else {
                this.toastRepository.showDanger('Oops! Something went wrong, please try again.');
            }
            this.ngbActiveModal.close();
        },error => {
            this.isLoading = false;
        });
    }

    capitalizeFirst(value: string): string {
        if (!value) return value;
        return value.charAt(0).toUpperCase() + value.slice(1);
    }

}
