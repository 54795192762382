import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserRepository} from '../../../repository/user-repository';
import {InviteUser} from '../../../observable/model/user';
import {ToastRepository} from '../../../repository/toast-repository';

@Component({
    selector: 'app-signup-team-email',
    templateUrl: './signup-team-email.component.html',
    styleUrls: ['./signup-team-email.component.less']
})
export class SignupTeamEmailComponent implements OnInit {
    inviteUser: InviteUser = new InviteUser();

    constructor(private router: Router, private userRepository: UserRepository,
                public toastRepository: ToastRepository) {
    }

    ngOnInit(): void {
        this.inviteUser.roleType = 1;
    }

    invite(): void {
        const isSuccess = this.check();
        if (!isSuccess) {
            return;
        }
        this.userRepository.invite(this.inviteUser).subscribe(result => {
            if (!result) {
                this.toastRepository.showDanger('Oops! Something went wrong, please try again.');
                return false;
            }
            this.toastRepository.showSuccess('Invite successfully sent.');
            this.router.navigate(['download-app'],{queryParams: {fromPage: 'email'}});
        });
    }

    check(): boolean {
        if (!this.inviteUser.account) {
            this.toastRepository.showDanger('Email is required.');
            return false;
        }
        if (!this.inviteUser.roleType) {
            this.toastRepository.showDanger('Role is required.');
            return false;
        }
        return true;
    }

    back2Login(): void {
        // this.router.navigate(['login']);
        this.router.navigate(['signup-stripe'],{queryParams: {fromPage: 'invite'}});
    }

}
