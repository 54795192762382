<div class="container">
    <div class="top-bar">
        <h1>Invoices</h1>
    </div>
    <div class="content">
        <div class="sub-head-box">
            <div class="search">
                <input type="text"  [(ngModel)]="condition.name"  placeholder="Search by payment ID and customer..." (keypress)="searchKeywordByPress($event)" (blur)="getInvoiceList()" >
                <i class="icon-search"></i>
            </div>

            <div class="filter-box">
                <div class="item" [ngClass]="{'active': condition.invoiceStatus==''}"
                     (click)="statueSearchInvoiceList('')">All ({{invoiceNumberVo.allNumber}})
                </div>
                <div class="item" [ngClass]="{'active': condition.invoiceStatus=='paid'}"
                     (click)="statueSearchInvoiceList('paid')">Paid ({{invoiceNumberVo.invoicePaidNum}})
                </div>
                <div class="item" [ngClass]="{'active': condition.invoiceStatus=='draft'}"
                     (click)="statueSearchInvoiceList('draft')">Draft ({{invoiceNumberVo.invoiceDraftNum}})
                </div>
                <div class="item" [ngClass]="{'active': condition.invoiceStatus=='open'}"
                     (click)="statueSearchInvoiceList('open')">Open ({{invoiceNumberVo.invoiceOpenNum}})
                </div>
                <div class="item" [ngClass]="{'active': condition.invoiceStatus=='void'}"
                     (click)="statueSearchInvoiceList('void')">Void ({{invoiceNumberVo.invoiceVoidNum}})
                </div>
                <div class="item" [ngClass]="{'active': condition.invoiceStatus=='uncollectible'}"
                     (click)="statueSearchInvoiceList('uncollectible')">Uncollectible ({{invoiceNumberVo.invoiceUncollectibleNum}})
                </div>
            </div>
        </div>
        <div class="table invoices-table">
            <table>
                <thead>
                <tr>
                    <th style="width: 25%;">
                        <div class="id-box">
                            <p>Payment ID <i class="icon icon-16 icon-title-down-arrow"
                                             [ngClass]="{'icon-title-down-arrow': currnetSort['invoiceNum'] == 'ASC' || !currnetSort['invoiceNum'],'icon-up-arrow':currnetSort['invoiceNum'] == 'DESC' }"
                                             (click)="sortInvoiceList('invoiceNum')"></i></p>
                        </div>
                    </th>
                    <th style="width: 20%;">
                        <p>Customer <i class="icon icon-16 icon-title-down-arrow"
                                       [ngClass]="{'icon-title-down-arrow': currnetSort['companyName'] == 'ASC' || !currnetSort['companyName'],'icon-up-arrow':currnetSort['companyName'] == 'DESC' }"
                                       (click)="sortInvoiceList('companyName')"></i></p>
                    </th>
<!--                    <th>-->
<!--                        <p>Products <i class="icon icon-16 icon-title-down-arrow"-->
<!--                                       [ngClass]="{'icon-title-down-arrow': currnetSort['product'] == 'ASC' || !currnetSort['product'],'icon-up-arrow':currnetSort['product'] == 'DESC' }"-->
<!--                                       (click)="sortInvoiceList('product')"></i></p>-->
<!--                    </th>-->
<!--                    <th>-->
<!--                        <p>Quantity <i class="icon icon-16 icon-title-down-arrow"-->
<!--                                       [ngClass]="{'icon-title-down-arrow': currnetSort['quantity'] == 'ASC' || !currnetSort['quantity'],'icon-up-arrow':currnetSort['quantity'] == 'DESC' }"-->
<!--                                       (click)="sortInvoiceList('quantity')"></i>-->
<!--                        </p>-->
<!--                    </th>-->
<!--                    <th>-->
<!--                        <p>Invoice to<i class="icon icon-16 icon-title-down-arrow"-->
<!--                                        [ngClass]="{'icon-title-down-arrow': currnetSort['customerEmail'] == 'ASC' || !currnetSort['customerEmail'],'icon-up-arrow':currnetSort['customerEmail'] == 'DESC' }"-->
<!--                                        (click)="sortInvoiceList('customerEmail')"></i></p>-->
<!--                    </th>-->
                    <th style="width: 15%;">
                        <p>Issue Date <i class="icon icon-16 icon-title-down-arrow"
                                         [ngClass]="{'icon-title-down-arrow': currnetSort['issueDate'] == 'ASC' || !currnetSort['issueDate'],'icon-up-arrow':currnetSort['issueDate'] == 'DESC' }"
                                         (click)="sortInvoiceList('issueDate')"></i></p>
                    </th>
                    <th style="width: 15%;">
                        <p>Price <i class="icon icon-16 icon-title-down-arrow"
                                    [ngClass]="{'icon-title-down-arrow': currnetSort['price'] == 'ASC' || !currnetSort['price'],'icon-up-arrow':currnetSort['price'] == 'DESC' }"
                                    (click)="sortInvoiceList('price')"></i>
                        </p>
                    </th>
                    <th style="width: 15%;">
                        <p>Status <i class="iconicon  icon-18 icon-title-down-arrow"
                                     [ngClass]="{'icon-title-down-arrow': currnetSort['invoiceStatus'] == 'ASC' || !currnetSort['invoiceStatus'],'icon-up-arrow':currnetSort['invoiceStatus'] == 'DESC' }"
                                     (click)="sortInvoiceList('invoiceStatus')"></i></p>
                    </th>
                    <th style="width: 10%;"></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let invoice of invoicePage.records">
                    <td class="id">
                        <div class="id-box">
<!--                            <span class="icon icon-52 green-bg brs"><i class="icon-18 icon icon-invoices"></i></span>-->
                            <p><a href="javascript:void(0)">{{invoice.invoiceNum}}</a></p>
                        </div>
                    </td>
                    <td class="customer">
                        <p>{{invoice.companyName}}</p>
                    </td>
<!--                    <td class="products">-->
<!--                        <p>{{invoice.product}}</p>-->
<!--                    </td>-->
<!--                    <td class="quantity">-->
<!--                        <p>{{invoice.quantity}}&nbsp;&nbsp;{{invoice.unit}}</p>-->
<!--                    </td>-->
<!--                    <td class="email">-->
<!--                        <p>{{invoice.customerEmail}}</p>-->
<!--                    </td>-->
                    <td class="issue-date">
                        <p>{{invoice.issueDate | date:'dd MMM yyyy'}}</p>
                    </td>
                    <td class="price">
                        <p>{{invoice.price | currency}}</p>
                    </td>
                    <td>
                        <div>
                            <button class="btn btn-statue" [ngClass]="{'btn-active': invoice.invoiceStatus.toLowerCase()=='paid','btn-pink': invoice.invoiceStatus.toLowerCase()=='draft','btn-purple': invoice.invoiceStatus.toLowerCase()=='open','btn-grey': invoice.invoiceStatus.toLowerCase()=='void','btn-red': invoice.invoiceStatus.toLowerCase()=='uncollectible'}">{{handleWord(invoice.invoiceStatus)}}</button>
                        </div>
                    </td>
                    <td class="button-item">
<!--                        <a href="javascript:void(0)"><i class="icon icon-18 icon-print"></i></a>-->
                        <a href="{{invoice.pdfUrl}}" target="_blank"><i class="icon icon-export-new icon-18-14"></i></a>
                    </td>
                </tr>
                </tbody>
            </table>
            <ngb-pagination class="justify-content-center" *ngIf="invoicePage.pages>1" [(page)]="invoicePage.current"
                            [pageSize]="invoicePage.size" [maxSize]="5" [boundaryLinks]="false"
                            (pageChange)="getInvoiceListByPage(invoicePage.current)"
                            [collectionSize]="invoicePage.total">
            </ngb-pagination>
        </div>
    </div>
</div>
