import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UserRepository} from '../../../repository/user-repository';
import {TeamUser} from '../../../observable/model/user';
import {ToastRepository} from '../../../repository/toast-repository';

@Component({
    selector: 'app-disable-staff',
    templateUrl: './disable-staff.component.html',
    styleUrls: ['./disable-staff.component.less']
})
export class DisableStaffComponent implements OnInit {

    user: TeamUser;
    isLoading = false;
    constructor(private ngbActiveModal: NgbActiveModal,
                private userRepository: UserRepository,
                public toastRepository: ToastRepository) {
    }

    ngOnInit(): void {
    }

    cancel(): void {
        this.ngbActiveModal.close();
    }

    disable(): void {
        this.isLoading = true;
        this.userRepository.updateUserStatus(this.user).subscribe(result => {
            if (!result) {
                this.toastRepository.showSuccess('Staff has been disabled.');
            } else {
                this.toastRepository.showDanger('Oops! Something went wrong, please try again.');
            }
            this.ngbActiveModal.close();
        },error => {
            this.isLoading = false;
        });
    }
}
