<!--<div class="menu_btn transition" [ngClass]="closeSubMenu ? 'menu_closed':'' "  (click)="closeMenu()">-->
<!--    <div class="icon icon-arr-menu transition"></div>-->
<!--</div>-->

<!--<div class="sub-sidebar" [ngClass]="closeSubMenu ? 'menu_closed':'' ">-->
<!--    <div class="top">-->
<!--        <div class="trademark">-->
<!--            <img src="assets/img/trademark.png" alt="">-->
<!--        </div>-->
<!--        <p class="p">Welcome,</p>-->
<!--        <h2 class="h2">{{companyName}}</h2>-->
<!--    </div>-->
<!--    <nav>-->
<!--        <li routerLinkActive="active">-->
<!--            <a [routerLink]="['./profile']">-->
<!--                <i class="icon icon-22  icon-profile"></i>-->
<!--                <div class="text-box">-->
<!--                    <p>Your profile</p>-->
<!--                    <span>Profile info, password & timezone</span>-->
<!--                </div>-->
<!--            </a>-->
<!--        </li>-->

<!--        <li routerLinkActive="active">-->
<!--            <a [routerLink]="['./subscriptions']">-->
<!--                <i class="icon icon-22 icon-subscriptions"></i>-->
<!--                <div class="text-box">-->
<!--                    <p>Subscriptions</p>-->
<!--                    <span>Available services</span>-->
<!--                </div>-->
<!--            </a>-->
<!--        </li>-->
<!--        <li routerLinkActive="active">-->
<!--            <a [routerLink]="['./billing']">-->
<!--                <i class="icon icon-22  icon-billing"></i>-->
<!--                <div class="text-box">-->
<!--                    <p>Billing</p>-->
<!--                    <span>Setup payment methods</span>-->
<!--                </div>-->
<!--            </a>-->
<!--        </li>-->
<!--&lt;!&ndash;        <li>&ndash;&gt;-->
<!--&lt;!&ndash;            <a>&ndash;&gt;-->
<!--&lt;!&ndash;                <i class="icon icon-22  icon-grey-delete" [routerLink]="['']"></i>&ndash;&gt;-->
<!--&lt;!&ndash;                <div class="text-box">&ndash;&gt;-->
<!--&lt;!&ndash;                    <p class="red">Deactivate account</p>&ndash;&gt;-->
<!--&lt;!&ndash;                    <span>Hide & disable current account</span>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--&lt;!&ndash;            </a>&ndash;&gt;-->
<!--&lt;!&ndash;        </li>&ndash;&gt;-->
<!--    </nav>-->
<!--</div>-->


<div class="container">
    <router-outlet></router-outlet>
</div>

