<div class="create-branch">
    <div class="modal-header">
        <h4 class="modal-title"> {{ isEdit ? 'Edit  Branch' : 'Add Branch'}} </h4>
    </div>
    <div class="modal-body">
        <div class="form modal-form">
            <div class="form-group">
                <label class="control-label">Branch Name</label>
                <input type="text" class="form-control"  [(ngModel)]="branch.name" />
            </div>
      
            <div class="select-content content">
                <div class="select-item">
                   <label>
                        <input type="checkbox"  
                        [(ngModel)]="isInactive" (change)="updateStatus()" 
                        > Make Branch Inactive 
                    </label>
                </div>
            </div>

            <div class="form-button form-button-two-with_div">
                <div class="close">
                    <button class="color-o" (click)="cancel()">Cancel</button>
                </div>
                <div class="submit">
                    <button class="btn btn-green bg-o" (click)="addBranch()"> {{ isEdit ? 'Save': "Add"}}</button>
                </div>
            </div>
        </div>
    </div>

</div>