<div class="create-pay invitation-modal">
    <div class="modal-header">
        <div class="content-one" *ngIf="type=='2'">
            <div><p style="font-size:16pt; line-height:120%; margin:10pt 0pt 0pt; page-break-after:avoid"><span
                    style="color:#595959; font-family:'DM Sans'; font-size:20px!important; font-weight:bold">SCAFFLINQ</span><span
                    style="color:#595959; font-family:'DM Sans'; font-size:16pt; font-weight:bold"> – </span><span
                    style="color:#595959; font-family:'DM Sans'; font-size:18px!important; font-weight:bold">PRIVACY</span><span
                    style="color:#595959; font-family:'DM Sans'; font-size:20px!important; font-weight:bold"> POLICY</span></p>
                <p style="font-size:11px; line-height:120%; margin:6px 0px 0px; text-align:justify"><span
                        style="font-family:'DM Sans'; font-size:10px">This Privacy Policy sets out our commitment to protecting the privacy of personal information provided to us, or otherwise collected by us, offline or online, including through our</span><span
                        style="font-family:'DM Sans'; font-size:10px"> website</span><span
                        style="font-family:'DM Sans'; font-size:10px"> </span><a style="color:#0563c1"
                                                                                 href="http://www.productiiv.app"><span
                        style="color:#0563c1; font-family:'DM Sans'; font-size:10px; text-decoration:underline"> www.</span></a><span
                        style="color:#0563c1; font-family:'DM Sans'; font-size:10px; text-decoration:underline">scafflinq.com</span><span
                        style="font-family:'DM Sans'; font-size:10px"> </span><span
                        style="font-family:'DM Sans'; font-size:10px"> and</span><span
                        style="font-family:'DM Sans'; font-size:10px"> </span><a style="color:#0563c1"
                                                                                 href="https://app.scafflinq."><span
                        style="color:#0563c1; font-family:'DM Sans'; font-size:10px; text-decoration:underline"> https://app.scafflinq.</span></a><span
                        style="color:#0563c1; font-family:'DM Sans'; font-size:10px; text-decoration:underline">com</span><span
                        style="font-family:'DM Sans'; font-size:10px"> </span><span
                        style="font-family:'DM Sans'; font-size:10px"> (</span><span
                        style="font-family:'DM Sans'; font-size:10px; font-weight:bold">Site</span><span
                        style="font-family:'DM Sans'; font-size:10px">). In this Privacy Policy </span><span
                        style="font-family:'DM Sans'; font-size:10px; font-weight:bold">we</span><span
                        style="font-family:'DM Sans'; font-size:10px">, </span><span
                        style="font-family:'DM Sans'; font-size:10px; font-weight:bold">us</span><span
                        style="font-family:'DM Sans'; font-size:10px"> or </span><span
                        style="font-family:'DM Sans'; font-size:10px; font-weight:bold">our</span><span
                        style="font-family:'DM Sans'; font-size:10px"> means </span><span
                        style="font-family:'DM Sans'; font-size:10px">Construction Tag Technologies Pty</span><span
                        style="font-family:'DM Sans'; font-size:10px"> Limited </span><span
                        style="font-family:'DM Sans'; font-size:10px">ABN </span><span
                        style="font-family:'DM Sans'; font-size:10px; font-weight:bold">23 654 187 934</span><span
                        style="font-family:'DM Sans'; font-size:10px">.</span><span
                        style="font-family:'DM Sans'; font-size:10px"> </span></p>
                <p style="font-size:14px; line-height:120%; margin:10px 0px 0px; page-break-after:avoid"><span
                        style="color:#595959; font-family:'DM Sans'; font-size:18px!important; font-weight:bold">Personal information</span>
                </p>
                <p style="font-size:10px; line-height:120%; margin:6px 0px 0px"><span
                        style="font-family:'DM Sans'; font-size:10px; font-weight:normal">The types of personal information we may collect about you include: </span>
                </p>
                <p style="margin:6pt 0pt 0pt 56.7pt; text-indent:-28.35pt"><span
                        style="font-family:'Wingdings 2'; font-size:10pt">&bull;</span><span
                        style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
                        style="font-family:'DM Sans'; font-size:10pt">your name;</span></p>
                <p style="margin:6pt 0pt 0pt 56.7pt; text-indent:-28.35pt"><span
                        style="font-family:'Wingdings 2'; font-size:10pt">&bull;</span><span
                        style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
                        style="font-family:'DM Sans'; font-size:10pt">images of you;</span></p>
                <p style="margin:6pt 0pt 0pt 56.7pt; text-indent:-28.35pt"><span
                        style="font-family:'Wingdings 2'; font-size:10pt">&bull;</span><span
                        style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
                        style="font-family:'DM Sans'; font-size:10pt">your contact details, including email address, mailing address, street address and/or telephone number; </span>
                </p>
                <p style="margin:6pt 0pt 0pt 56.7pt; text-indent:-28.35pt"><span
                        style="font-family:'Wingdings 2'; font-size:10pt">&bull;</span><span
                        style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
                        style="font-family:'DM Sans'; font-size:10pt">your marital status and/or anniversary;</span></p>
                <p style="margin:6pt 0pt 0pt 56.7pt; text-indent:-28.35pt"><span
                        style="font-family:'Wingdings 2'; font-size:10pt">&bull;</span><span
                        style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
                        style="font-family:'DM Sans'; font-size:10pt">your age and/or date of birth;</span></p>
                <p style="margin:6pt 0pt 0pt 56.7pt; text-indent:-28.35pt"><span
                        style="font-family:'Wingdings 2'; font-size:10pt">&bull;</span><span
                        style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
                        style="font-family:'DM Sans'; font-size:10pt">your credit card details;</span></p>
                <p style="margin:6pt 0pt 0pt 56.7pt; text-indent:-28.35pt"><span
                        style="font-family:'Wingdings 2'; font-size:10pt">&bull;</span><span
                        style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
                        style="font-family:'DM Sans'; font-size:10pt">your demographic information, such as postcode;</span>
                </p>
                <p style="margin:6pt 0pt 0pt 56.7pt; text-indent:-28.35pt"><span
                        style="font-family:'Wingdings 2'; font-size:10pt">&bull;</span><span
                        style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
                        style="font-family:'DM Sans'; font-size:10pt">your preferences and/or opinions;</span></p>
                <p style="margin:6pt 0pt 0pt 56.7pt; text-indent:-28.35pt"><span
                        style="font-family:'Wingdings 2'; font-size:10pt">&bull;</span><span
                        style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
                        style="font-family:'DM Sans'; font-size:10pt">information you provide to us through customer surveys;</span>
                </p>
                <p style="margin:6pt 0pt 0pt 56.7pt; text-indent:-28.35pt"><span
                        style="font-family:'Wingdings 2'; font-size:10pt">&bull;</span><span
                        style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
                        style="font-family:'DM Sans'; font-size:10pt">details of products and services we have provided to you and/or that you have enquired about, and our response to you;</span>
                </p>
                <p style="margin:6pt 0pt 0pt 56.7pt; text-indent:-28.35pt"><span
                        style="font-family:'Wingdings 2'; font-size:10pt">&bull;</span><span
                        style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
                        style="font-family:'DM Sans'; font-size:10pt">your browser session and geo-location data, device and network information, statistics on page views and sessions, acquisition sources, search queries and/or browsing behaviour;</span>
                </p>
                <p style="margin:6pt 0pt 0pt 56.7pt; text-indent:-28.35pt"><span
                        style="font-family:'Wingdings 2'; font-size:10pt">&bull;</span><span
                        style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
                        style="font-family:'DM Sans'; font-size:10pt">information about your access and use of our Site, including through the use of Internet cookies, your communications with our Site, the type of browser you are using, the type of operating system you are using and the domain name of your Internet service provider;</span>
                </p>
                <p style="margin:6pt 0pt 0pt 56.7pt; text-indent:-28.35pt"><span
                        style="font-family:'Wingdings 2'; font-size:10pt">&bull;</span><span
                        style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
                        style="font-family:'DM Sans'; font-size:10pt">additional personal information that you provide to us, directly or indirectly, through your use of our Site, associated applications, associated social media platforms and/or accounts from which you permit us to collect information; and</span>
                </p>
                <p style="margin:6pt 0pt 0pt 56.7pt; text-indent:-28.35pt"><span
                        style="font-family:'Wingdings 2'; font-size:10pt">&bull;</span><span
                        style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
                        style="font-family:'DM Sans'; font-size:10pt">any other personal information requested by us and/or provided by you or a third party.]</span>
                </p>
                <p style="font-size:10pt; line-height:120%; margin:6pt 0pt 0pt"><span
                        style="font-family:'DM Sans'; font-size:10pt">We may collect these types of personal information directly from you or from third parties.</span>
                </p>
                <p style="font-size:14pt; line-height:120%; margin:10pt 0pt 0pt; page-break-after:avoid"><span
                        style="color:#595959; font-family:'DM Sans'; font-size:18px!important; font-weight:bold">Collection</span><span
                        style="color:#215ffe; font-family:'DM Sans'; font-size:14pt; font-weight:bold"> </span><span
                        style="color:#595959; font-family:'DM Sans'; font-size:18px!important; font-weight:bold"> and use of personal information</span>
                </p>
                <p style="font-size:10pt; line-height:120%; margin:6pt 0pt 0pt"><span
                        style="font-family:'DM Sans'; font-size:10pt">We may collect, hold, use and disclose personal information for the following purposes: </span>
                </p>
                <p style="margin:6pt 0pt 0pt 56.7pt; text-indent:-28.35pt"><span
                        style="font-family:'Wingdings 2'; font-size:10pt">&bull;</span><span
                        style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
                        style="font-family:'DM Sans'; font-size:10pt">to enable you to access and use our Site, associated applications and associated social media platforms;</span>
                </p>
                <p style="margin:6pt 0pt 0pt 56.7pt; text-indent:-28.35pt"><span
                        style="font-family:'Wingdings 2'; font-size:10pt">&bull;</span><span
                        style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
                        style="font-family:'DM Sans'; font-size:10pt">to contact and communicate with you;</span></p>
                <p style="margin:6pt 0pt 0pt 56.7pt; text-indent:-28.35pt"><span
                        style="font-family:'Wingdings 2'; font-size:10pt">&bull;</span><span
                        style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
                        style="font-family:'DM Sans'; font-size:10pt">for internal record keeping and administrative purposes;</span>
                </p>
                <p style="margin:6pt 0pt 0pt 56.7pt; text-indent:-28.35pt"><span
                        style="font-family:'Wingdings 2'; font-size:10pt">&bull;</span><span
                        style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
                        style="font-family:'DM Sans'; font-size:10pt">for analytics, market research and business development, including to operate and improve our Site, associated applications and associated social media platforms;</span>
                </p>
                <p style="margin:6pt 0pt 0pt 56.7pt; text-indent:-28.35pt"><span
                        style="font-family:'Wingdings 2'; font-size:10pt">&bull;</span><span
                        style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
                        style="font-family:'DM Sans'; font-size:10pt">to run competitions and/or offer additional benefits to you; </span>
                </p>
                <p style="margin:6pt 0pt 0pt 56.7pt; text-indent:-28.35pt"><span
                        style="font-family:'Wingdings 2'; font-size:10pt">&bull;</span><span
                        style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
                        style="font-family:'DM Sans'; font-size:10pt">for advertising and marketing, including to send you promotional information about our products and services and information about third parties that we consider may be of interest to you;</span>
                </p>
                <p style="margin:6pt 0pt 0pt 56.7pt; text-indent:-28.35pt"><span
                        style="font-family:'Wingdings 2'; font-size:10pt">&bull;</span><span
                        style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
                        style="font-family:'DM Sans'; font-size:10pt">to comply with our legal obligations and resolve any disputes that we may have; and</span>
                </p>
                <p style="margin:6pt 0pt 0pt 28.35pt; padding-left:28.35pt; text-indent:-28.35pt"><span
                        style="font-family:'DM Sans'; font-size:10pt">&#xa0;</span></p>
                <p style="margin:6pt 0pt 0pt 28.35pt; padding-left:28.35pt; text-indent:-28.35pt"><span
                        style="font-family:'DM Sans'; font-size:10pt">&#xa0;</span></p>
                <p style="font-size:14pt; line-height:120%; margin:10pt 0pt 0pt; page-break-after:avoid"><span
                        style="color:#595959; font-family:'DM Sans'; font-size:18px!important; font-weight:bold">Disclosure of personal information to third parties</span>
                </p>
                <p style="font-size:10pt; line-height:120%; margin:6pt 0pt 0pt"><span
                        style="font-family:'DM Sans'; font-size:10pt">We may disclose personal information to: </span>
                </p>
                <p style="margin:6pt 0pt 0pt 56.7pt; text-indent:-28.35pt"><span
                        style="font-family:'Wingdings 2'; font-size:10pt">&bull;</span><span
                        style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
                        style="font-family:'DM Sans'; font-size:10pt">third party service providers for the purpose of enabling them to provide their services, including (without limitation) IT service providers, data storage, web-hosting and server providers, debt collectors, maintenance or problem-solving providers, marketing or advertising providers, professional advisors and payment systems operators;</span>
                </p>
                <p style="margin:6pt 0pt 0pt 56.7pt; text-indent:-28.35pt"><span
                        style="font-family:'Wingdings 2'; font-size:10pt">&bull;</span><span
                        style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
                        style="font-family:'DM Sans'; font-size:10pt">our employees, contractors and/or related entities;</span>
                </p>
                <p style="margin:6pt 0pt 0pt 56.7pt; text-indent:-28.35pt"><span
                        style="font-family:'Wingdings 2'; font-size:10pt">&bull;</span><span
                        style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
                        style="font-family:'DM Sans'; font-size:10pt">our existing or potential agents or business partners;</span>
                </p>
                <p style="margin:6pt 0pt 0pt 56.7pt; text-indent:-28.35pt"><span
                        style="font-family:'Wingdings 2'; font-size:10pt">&bull;</span><span
                        style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
                        style="font-family:'DM Sans'; font-size:10pt">sponsors or promoters of any competition we run;</span>
                </p>
                <p style="margin:6pt 0pt 0pt 56.7pt; text-indent:-28.35pt"><span
                        style="font-family:'Wingdings 2'; font-size:10pt">&bull;</span><span
                        style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
                        style="font-family:'DM Sans'; font-size:10pt">anyone to whom our business or assets (or any part of them) are, or may (in good faith) be, transferred;</span>
                </p>
                <p style="margin:6pt 0pt 0pt 56.7pt; text-indent:-28.35pt"><span
                        style="font-family:'Wingdings 2'; font-size:10pt">&bull;</span><span
                        style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
                        style="font-family:'DM Sans'; font-size:10pt">credit reporting agencies, courts, tribunals and regulatory authorities, in the event you fail to pay for goods or services we have provided to you;</span>
                </p>
                <p style="margin:6pt 0pt 0pt 56.7pt; text-indent:-28.35pt"><span
                        style="font-family:'Wingdings 2'; font-size:10pt">&bull;</span><span
                        style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
                        style="font-family:'DM Sans'; font-size:10pt">courts, tribunals, regulatory authorities and law enforcement officers, as required by law, in connection with any actual or prospective legal proceedings, or in order to establish, exercise or defend our legal rights; [and]</span>
                </p>
                <p style="margin:6pt 0pt 0pt 56.7pt; text-indent:-28.35pt"><span
                        style="font-family:'Wingdings 2'; font-size:10pt">&bull;</span><span
                        style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
                        style="font-family:'DM Sans'; font-size:10pt">third parties, including agents or sub-contractors, who assist us in providing information, products, services or direct marketing to you. This may include parties located, or that store data, outside of Australia</span>
                </p>
                <p style="margin:6pt 0pt 0pt 56.7pt; text-indent:-28.35pt"><span
                        style="font-family:'Wingdings 2'; font-size:10pt">&bull;</span><span
                        style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
                        style="font-family:'DM Sans'; font-size:10pt">third parties to collect and process data, such as [Google Analytics or other relevant businesses]. This may include parties that store data outside of Australia</span><span
                        style="font-family:'DM Sans'; font-size:10pt">.</span></p>
                <p style="font-size:10pt; line-height:120%; margin:6pt 0pt 0pt"><span
                        style="font-family:'DM Sans'; font-size:10pt">By providing us with personal information, you consent to the disclosure of your personal information to third parties who reside outside Australia and acknowledge that we are not required to ensure that those third parties comply with Australian privacy laws.</span><span
                        style="font-family:'DM Sans'; font-size:10pt"> </span></p>
                <p style="font-size:14pt; line-height:120%; margin:10pt 0pt 0pt; page-break-after:avoid"><span
                        style="color:#595959; font-family:'DM Sans'; font-size:18px!important; font-weight:bold">How we treat personal information that is also sensitive information</span>
                </p>
                <p style="font-size:10pt; line-height:120%; margin:6pt 0pt 0pt"><span
                        style="font-family:'DM Sans'; font-size:10pt">Sensitive information is a subset of personal information that is given a higher level of protection under the Australian Privacy Principles. </span><span
                        style="font-family:'DM Sans'; font-size:10pt; font-weight:bold">Sensitive information</span><span
                        style="font-family:'DM Sans'; font-size:10pt"> means information relating to your racial or ethnic origin, political opinions, religion, trade union or other professional associations or memberships, philosophical beliefs, sexual orientation or practices, criminal records, health information or biometric information. </span><span
                        style="font-family:'DM Sans'; font-size:10pt"> </span></p>
                <p style="font-size:14pt; line-height:120%; margin:10pt 0pt 0pt; page-break-after:avoid"><span
                        style="color:#595959; font-family:'DM Sans'; font-size:18px; font-weight:bold">Your rights and controlling your personal information</span>
                </p>
                <p style="font-size:10pt; line-height:120%; margin:6pt 0pt 0pt"><span
                        style="font-family:'DM Sans'; font-size:10pt; font-weight:bold">Choice and consent</span><span
                        style="font-family:'DM Sans'; font-size:10pt">: Please read this Privacy Policy carefully. By providing personal information to us, you consent to us collecting, holding, using and disclosing your personal information in accordance with this Privacy Policy. You do not have to provide personal information to us, however, if you do not, it may affect your use of this Site or the products and/or services offered on or through it.</span>
                </p>
                <p style="font-size:10pt; line-height:120%; margin:6pt 0pt 0pt"><span
                        style="font-family:'DM Sans'; font-size:10pt; font-weight:bold">Information from third parties</span><span
                        style="font-family:'DM Sans'; font-size:10pt">: If we receive personal information about you from a third party, we will protect it as set out in this Privacy Policy. If you are a third party providing personal information about somebody else, you represent and warrant that you have such person’s consent to provide the personal information to us. </span>
                </p>
                <p style="font-size:10pt; line-height:120%; margin:6pt 0pt 0pt"><span
                        style="font-family:'DM Sans'; font-size:10pt; font-weight:bold">Restrict</span><span
                        style="font-family:'DM Sans'; font-size:10pt">: You may choose to restrict the collection or use of your personal information.  If you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time by contacting us using the details below.</span>
                </p>
                <p style="font-size:10pt; line-height:120%; margin:6pt 0pt 0pt"><span
                        style="font-family:'DM Sans'; font-size:10pt; font-weight:bold">Access</span><span
                        style="font-family:'DM Sans'; font-size:10pt">: You may request details of the personal information that we hold about you.  An administrative fee may be payable for the provision of such information.  In certain circumstances, as set out in the </span><span
                        style="font-family:'DM Sans'; font-size:10pt; font-style:italic">Privacy Act 1988</span><span
                        style="font-family:'DM Sans'; font-size:10pt"> (Cth), we may refuse to provide you with personal information that we hold about you. </span>
                </p>
                <p style="font-size:10pt; line-height:120%; margin:6pt 0pt 0pt"><span
                        style="font-family:'DM Sans'; font-size:10pt; font-weight:bold">Correction</span><span
                        style="font-family:'DM Sans'; font-size:10pt">: If you believe that any information we hold about you is inaccurate, out of date, incomplete, irrelevant or misleading, please contact us using the details below. We will take reasonable steps to correct any information found to be inaccurate, incomplete, misleading or out of date.</span>
                </p>
                <p style="font-size:10pt; line-height:120%; margin:6pt 0pt 0pt"><span
                        style="font-family:'DM Sans'; font-size:10pt; font-weight:bold">Complaints</span><span
                        style="font-family:'DM Sans'; font-size:10pt">: If you believe that we have breached the Australian Privacy Principles and wish to make a complaint, please contact us using the details below and provide us with full details of the alleged breach. We will promptly investigate your complaint and respond to you, in writing, setting out the outcome of our investigation and the steps we will take to deal with your complaint.</span>
                </p>
                <p style="font-size:10pt; line-height:120%; margin:6pt 0pt 0pt"><span
                        style="font-family:'DM Sans'; font-size:10pt; font-weight:bold">Unsubscribe</span><span
                        style="font-family:'DM Sans'; font-size:10pt">: To unsubscribe from our e-mail database or opt-out of communications (including marketing communications), please contact us using the details below or opt-out using the opt-out facilities provided in the communication.</span>
                </p>
                <p style="font-size:14pt; line-height:120%; margin:10pt 0pt 0pt; page-break-after:avoid"><span
                        style="color:#595959; font-family:'DM Sans'; font-size:18px!important; font-weight:bold">Storage and security</span>
                </p>
                <p style="font-size:10pt; line-height:120%; margin:6pt 0pt 0pt"><span
                        style="font-family:'DM Sans'; font-size:10pt">We are committed to ensuring that the personal information we collect is secure. In order to prevent unauthorised access or disclosure, we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the personal information and protect it from misuse, interference, loss and unauthorised access, modification and disclosure.</span>
                </p>
                <p style="font-size:10pt; line-height:120%; margin:6pt 0pt 0pt"><span
                        style="font-family:'DM Sans'; font-size:10pt">We cannot guarantee the security of any information that is transmitted to or by us over the Internet. The transmission and exchange of information is carried out at your own risk. Although we take measures to safeguard against unauthorised disclosures of information, we cannot assure you that the personal information we collect will not be disclosed in a manner that is inconsistent with this Privacy Policy.</span>
                </p>
                <p style="font-size:14pt; line-height:120%; margin:10pt 0pt 0pt; page-break-after:avoid"><span
                        style="color:#595959; font-family:'DM Sans'; font-size:18px!important; font-weight:bold">Cookies and web beacons</span>
                </p>
                <p style="font-size:10pt; line-height:120%; margin:6pt 0pt 0pt"><span
                        style="font-family:'DM Sans'; font-size:10pt">We may use cookies on our Site from time to time. Cookies are text files placed in your computer's browser to store your preferences. Cookies, by themselves, do not tell us your email address or other personally identifiable information. However, they do allow third parties, such as Google and Facebook, to cause our advertisements to appear on your social media and online media feeds as part of our retargeting campaigns. If and when you choose to provide our Site with personal information, this information may be linked to the data stored in the cookie.</span>
                </p>
                <p style="font-size:10pt; line-height:120%; margin:6pt 0pt 0pt"><span
                        style="font-family:'DM Sans'; font-size:10pt">We may use web beacons on our Site from time to time. Web beacons (also known as Clear GIFs) are small pieces of code placed on a web page to monitor the visitor’s behaviour and collect data about the visitor’s viewing of a web page. For example, web beacons can be used to count the users who visit a web page or to deliver a cookie to the browser of a visitor viewing that page.</span>
                </p>
                <p style="font-size:14pt; line-height:120%; margin:10pt 0pt 0pt; page-break-after:avoid"><span
                        style="color:#595959; font-family:'DM Sans'; font-size:18px!important; font-weight:bold">Links to other websites</span>
                </p>
                <p style="font-size:10pt; line-height:120%; margin:6pt 0pt 0pt"><span
                        style="font-family:'DM Sans'; font-size:10pt">Our Site may contain links to other websites. We do not have any control over those websites and we are not responsible for the protection and privacy of any personal information which you provide whilst visiting those websites. Those websites are not governed by this Privacy Policy.</span>
                </p>
                <p style="font-size:14pt; line-height:120%; margin:10pt 0pt 0pt; page-break-after:avoid"><span
                        style="color:#595959; font-family:'DM Sans'; font-size:18px!important; font-weight:bold">Amendments</span>
                </p>
                <p style="font-size:10pt; line-height:120%; margin:6pt 0pt 0pt"><span
                        style="font-family:'DM Sans'; font-size:10pt">We may, at any time and at our discretion, vary this Privacy Policy by publishing the amended Privacy Policy on our Site. We recommend you check our Site regularly to ensure you are aware of our current Privacy Policy.</span>
                </p>
                <p style="font-size:10pt; line-height:120%; margin:6pt 0pt 0pt; page-break-after:avoid"><span
                        style="font-family:'DM Sans'; font-size:10pt; font-weight:bold">For any questions or notices, please contact our Privacy Officer at:</span>
                </p>
                <p style="font-size:10pt; line-height:120%; margin:6pt 0pt 0pt"><span
                        style="font-family:'DM Sans'; font-size:10pt">Construction Tag Technologies Pty</span><span
                        style="font-family:'DM Sans'; font-size:10pt"> Limited </span><span
                        style="font-family:'DM Sans'; font-size:10pt">ABN </span><span
                        style="font-family:'DM Sans'; font-size:10pt; font-weight:bold">23 654 187 934</span><span
                        style="font-family:'DM Sans'; font-size:10pt"> </span></p>
                <p style="font-size:11pt; line-height:120%; margin:6pt 0pt 0pt"><span
                        style="font-family:'DM Sans'; font-size:10pt">Email: </span><a
                        style="color:#0563c1" href="mailto:support@scafflinq.com"><span
                        style="color:#0563c1; font-family:'DM Sans'; font-size:10pt; text-decoration:underline">support@scafflinq.com</span></a>
                </p>
                <p style="font-size:10pt; line-height:120%; margin:6pt 0pt 0pt"><span
                        style="font-family:'DM Sans'; font-size:10pt; font-weight:bold">Last update</span><span
                        style="font-family:'DM Sans'; font-size:10pt">: </span><span
                        style="font-family:'DM Sans'; font-size:10pt">22 July 2022</span>
                </p>
                <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:12pt">&#xa0;</span></p>
            </div>
            <div class="cnzz" style="display: none;">
            </div>
            <div class="docpe" style="position: absolute;color: white;margin-left:-450;">
            </div>
        </div>

        <div class="content-two" *ngIf="type=='1'">

            <div>
                <p style="font-size:14pt; line-height:120%; margin:10pt 0pt 0pt; page-break-after:avoid; text-align:center"><span
                        style="color:#7f7f7f; font-family:'DM Sans'; font-size:14pt; font-weight:bold">SCAFFLINQ</span><span
                        style="color:#7f7f7f; font-family:'DM Sans'; font-size:14pt; font-weight:bold"> </span><span
                        style="color:#7f7f7f; font-family:'DM Sans'; font-size:14pt; font-weight:bold">– WEBSITE TERMS OF USE</span>
                </p>
                <p style="font-size:11pt; line-height:120%; margin:6pt 0pt 0pt"><span
                        style="font-family:'DM Sans'; font-size:10pt; font-weight:normal">This website (</span><span
                        style="font-family:'DM Sans'; font-size:10pt; font-weight:bold">Site</span><span
                        style="font-family:'DM Sans'; font-size:10pt; font-weight:normal">) is operated by </span><span
                        style="font-family:'DM Sans'; font-size:10pt; font-weight:normal">Construction Tag Technologies Pty</span><span
                        style="font-family:'DM Sans'; font-size:10pt; font-weight:normal"> Limited </span><span
                        style="font-family:'DM Sans'; font-size:10pt; font-weight:normal">ABN </span><span
                        style="font-family:'DM Sans'; font-size:10pt; font-weight:bold">23 654 187 934</span><span
                        style="font-family:'DM Sans'; font-size:10pt; font-weight:bold"> </span><span
                        style="font-family:'DM Sans'; font-size:10pt; font-weight:normal">(</span><span
                        style="font-family:'DM Sans'; font-size:10pt; font-weight:bold">we</span><span
                        style="font-family:'DM Sans'; font-size:10pt; font-weight:normal">, </span><span
                        style="font-family:'DM Sans'; font-size:10pt; font-weight:bold">our</span><span
                        style="font-family:'DM Sans'; font-size:10pt; font-weight:normal"> or </span><span
                        style="font-family:'DM Sans'; font-size:10pt; font-weight:bold">us</span><span
                        style="font-family:'DM Sans'; font-size:10pt; font-weight:normal">).  It is available at: </span><a
                        style="color:#0563c1" href="https://app.scafflinq."><span
                        style="color:#0563c1; font-family:'DM Sans'; font-size:10pt; font-weight:normal; text-decoration:underline">https://app.scafflinq.</span></a><span
                        style="color:#0563c1; font-family:'DM Sans'; font-size:10pt; font-weight:normal; text-decoration:underline">com</span><span
                        style="font-family:'DM Sans'; font-size:10pt; font-weight:normal"> </span><span
                        style="font-family:'DM Sans'; font-size:10pt; font-weight:normal"> and may be available through other addresses or channels.</span>
                </p>
                <p style="font-size:10pt; line-height:120%; margin:6pt 0pt 12pt"><span
                        style="font-family:'DM Sans'; font-size:10pt; font-weight:bold">Consent:</span><span
                        style="font-family:'DM Sans'; font-size:10pt"> By accessing and/or using our Site, you agree to these </span><span
                        style="font-family:'DM Sans'; font-size:10pt">T</span><span
                        style="font-family:'DM Sans'; font-size:10pt">erms of </span><span
                        style="font-family:'DM Sans'; font-size:10pt">U</span><span
                        style="font-family:'DM Sans'; font-size:10pt">se </span><span
                        style="font-family:'DM Sans'; font-size:10pt">(</span><span
                        style="font-family:'DM Sans'; font-size:10pt; font-weight:bold">Terms</span><span
                        style="font-family:'DM Sans'; font-size:10pt">) and our Privacy Policy available on our Site.</span><span
                        style="color:#ff0000; font-family:'DM Sans'; font-size:10pt; font-weight:bold"> </span><span
                        style="font-family:'DM Sans'; font-size:10pt">Please read these Terms carefully and immediately cease using our Site if you do not agree to them. </span>
                </p>
                <p style="font-size:10pt; line-height:120%; margin:6pt 0pt 12pt"><span
                        style="font-family:'DM Sans'; font-size:10pt; font-weight:bold">Variations:</span><span
                        style="font-family:'DM Sans'; font-size:10pt"> We may, at any time and at our discretion, vary these Terms by publishing the varied terms on our Site. We recommend you check our Site regularly to ensure you are aware of our current terms. Materials and information on this Site (</span><span
                        style="font-family:'DM Sans'; font-size:10pt; font-weight:bold">Content</span><span
                        style="font-family:'DM Sans'; font-size:10pt">) are subject to </span><span
                        style="font-family:'DM Sans'; font-size:10pt">change</span><span
                        style="font-family:'DM Sans'; font-size:10pt"> without notice. We do not undertake to keep our Site up-to-date and we are not liable if any Content is inaccurate or out-of-date.</span>
                </p>
                <p style="font-size:10pt; line-height:120%; margin:6pt 0pt 12pt"><span
                        style="font-family:'DM Sans'; font-size:10pt; font-weight:bold">Licence to use our Site</span><span
                        style="font-family:'DM Sans'; font-size:10pt">: We grant you a non-exclusive, royalty-free, revocable, worldwide, non-transferable licence to use our Site in accordance with these Terms.  All other uses are prohibited without our prior written consent.</span>
                </p>
                <p style="font-size:10pt; line-height:120%; margin:6pt 0pt 12pt; page-break-after:avoid"><span
                        style="font-family:'DM Sans'; font-size:10pt; font-weight:bold">Prohibited conduct</span><span
                        style="font-family:'DM Sans'; font-size:10pt">: You must not do or attempt to do anything: that is unlawful; prohibited by any laws applicable to our Site; which we would consider inappropriate; or which might bring us or our Site into disrepute, including (without limitation):</span>
                </p>
                <h3 style="font-size:10pt; font-weight:normal; line-height:120%; margin:6pt 0pt 12pt 36pt; text-indent:-18pt"><span
                        style="font-family:'DM Sans'; font-size:10pt">(a)</span><span
                        style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0; </span><span
                        style="font-family:'DM Sans'; font-size:10pt">anything that would constitute a breach of an individual’s privacy (including uploading private or personal information without an individual's consent) or any other legal rights;</span>
                </h3>
                <h3 style="font-size:10pt; font-weight:normal; line-height:120%; margin:6pt 0pt 12pt 36pt; text-indent:-18pt"><span
                        style="font-family:'DM Sans'; font-size:10pt">(b)</span><span
                        style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0; </span><span
                        style="font-family:'DM Sans'; font-size:10pt">using our Site to defame, harass, threaten, menace or offend any person;</span>
                </h3>
                <h3 style="font-size:10pt; font-weight:normal; line-height:120%; margin:6pt 0pt 12pt 36pt; text-indent:-18pt"><span
                        style="font-family:'DM Sans'; font-size:10pt">(c)</span><span
                        style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0; </span><span
                        style="font-family:'DM Sans'; font-size:10pt">interfering with any user using our Site;</span>
                </h3>
                <h3 style="font-size:10pt; font-weight:normal; line-height:120%; margin:6pt 0pt 12pt 36pt; text-indent:-18pt"><span
                        style="font-family:'DM Sans'; font-size:10pt">(d)</span><span
                        style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0; </span><span
                        style="font-family:'DM Sans'; font-size:10pt">tampering with or modifying our Site, knowingly transmitting viruses or other disabling features, or damaging or interfering with our Site, including (without limitation) using trojan horses, viruses or piracy or programming routines that may damage or interfere with our Site;</span>
                </h3>
                <h3 style="font-size:10pt; font-weight:normal; line-height:120%; margin:6pt 0pt 12pt 36pt; text-indent:-18pt"><span
                        style="font-family:'DM Sans'; font-size:10pt">(e)</span><span
                        style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0; </span><span
                        style="font-family:'DM Sans'; font-size:10pt">using our Site to send unsolicited email </span><span
                        style="font-family:'DM Sans'; font-size:10pt">messages.</span></h3>
                <p style="margin:0pt 0pt 0pt 36pt; text-indent:-18pt"><span
                        style="font-family:'DM Sans'; font-size:10pt">(f)</span><span
                        style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0; </span><span
                        style="font-family:'DM Sans'; font-size:10pt">perform</span><span
                        style="font-family:'DM Sans'; font-size:10pt">ing</span><span
                        style="font-family:'DM Sans'; font-size:10pt"> actions that damage the site, its reputation, or its intellectual property</span>
                </p>
                <p style="margin:0pt 0pt 0pt 36pt"><span
                        style="font-family:'DM Sans'; font-size:10pt">&#xa0;&#xa0;</span></p>
                <p style="margin:0pt 0pt 0pt 36pt; text-indent:-18pt"><span
                        style="font-family:'DM Sans'; font-size:10pt">(g)</span><span
                        style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0; </span><span
                        style="font-family:'DM Sans'; font-size:10pt">attempt</span><span
                        style="font-family:'DM Sans'; font-size:10pt">ing</span><span
                        style="font-family:'DM Sans'; font-size:10pt"> to copy, steal, imitate, reverse engineer</span><span
                        style="font-family:'DM Sans'; font-size:10pt"> features used on our Site for your commercial benefit</span>
                </p>
                <p style="margin:0pt"><span style="font-family:'DM Sans'; font-size:10pt">&#xa0;</span></p>
                <p style="margin:0pt 0pt 0pt 36pt; text-indent:-18pt"><span
                        style="font-family:'DM Sans'; font-size:10pt">(h)</span><span
                        style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0; </span><span
                        style="font-family:'DM Sans'; font-size:10pt">attempting to create derivative works, decompile or extract source code from us</span>
                </p>
                <p style="margin:0pt"><span style="font-family:'DM Sans'; font-size:10pt">&#xa0;</span></p>
                <h3 style="font-size:10pt; font-weight:normal; line-height:120%; margin:6pt 0pt 12pt 36pt; text-indent:-18pt"><span
                        style="font-family:'DM Sans'; font-size:10pt">(i)</span><span
                        style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0; </span><span
                        style="font-family:'DM Sans'; font-size:10pt">facilitating or assisting a third party to do any of the above acts.</span>
                </h3>
                <p style="font-size:10pt; line-height:120%; margin:6pt 0pt 12pt"><span
                        style="font-family:'DM Sans'; font-size:10pt; font-weight:bold">Exclusion of competitors</span><span
                        style="font-family:'DM Sans'; font-size:10pt">: You are prohibited from using our Site, including the Content, in any way that competes with our business.  </span>
                </p>
                <p style="font-size:10pt; line-height:120%; margin:6pt 0pt 12pt"><span
                        style="font-family:'DM Sans'; font-size:10pt; font-weight:bold">Information</span><span
                        style="font-family:'DM Sans'; font-size:10pt">: The Content is not comprehensive and is for general information purposes only.  It does not take into account your specific needs, objectives or circumstances, and it is not advice.  While we use reasonable attempts to ensure the accuracy and completeness of the Content, we make no representation or warranty in relation to it, to the maximum extent permitted by law. </span>
                </p>
                <p style="font-size:10pt; line-height:120%; margin:6pt 0pt 0pt"><span
                        style="font-family:'DM Sans'; font-size:10pt; font-weight:bold">Intellectual Property rights:</span><span
                        style="font-family:'DM Sans'; font-size:10pt"> Unless otherwise indicated, we own or licence all rights, title and interest (including intellectual property rights) in our Site and all of the Content. Your use of our Site and your use of and access to any Content does not grant or transfer to you any rights, title or interest in relation to our Site or the Content. You must not: </span>
                </p>
                <h3 style="font-size:10pt; font-weight:normal; line-height:120%; margin:6pt 0pt 12pt 28.35pt; text-indent:-28.35pt">
                    <span style="font-family:'DM Sans'; font-size:11pt">(a)</span><span
                        style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
                        style="font-family:'DM Sans'; font-size:10pt">copy or use, in whole or in part, any Content; </span>
                </h3>
                <h3 style="font-size:10pt; font-weight:normal; line-height:120%; margin:6pt 0pt 12pt 28.35pt; text-indent:-28.35pt">
                    <span style="font-family:'DM Sans'; font-size:11pt">(b)</span><span
                        style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
                        style="font-family:'DM Sans'; font-size:10pt">reproduce, retransmit, distribute, disseminate, sell, publish, broadcast or circulate any Content to any third party; or</span>
                </h3>
                <h3 style="font-size:10pt; font-weight:normal; line-height:120%; margin:6pt 0pt 12pt 28.35pt; text-indent:-28.35pt">
                    <span style="font-family:'DM Sans'; font-size:11pt">(c)</span><span
                        style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
                        style="font-family:'DM Sans'; font-size:10pt">breach any intellectual property rights connected with our Site or the Content, including (without limitation) altering or modifying any of the Content, causing any of the Content to be framed or embedded in another website or platform, or creating derivative works from the Content.</span>
                </h3>
                <p style="font-size:10pt; line-height:120%; margin:6pt 0pt 0pt"><span
                        style="font-family:'DM Sans'; font-size:10pt; font-weight:bold">User Content:</span><span
                        style="font-family:'DM Sans'; font-size:10pt"> You may be permitted to post, upload, publish, submit or transmit relevant information and content (</span><span
                        style="font-family:'DM Sans'; font-size:10pt; font-weight:bold">User Content</span><span
                        style="font-family:'DM Sans'; font-size:10pt">) on our Site.  By making available any User Content on or through our Site, you grant to us a worldwide, irrevocable, perpetual, non-exclusive, transferable, royalty-free licence to use the User Content, with the right to use, view, copy, adapt, modify, distribute, license, sell, transfer, communicate, publicly display, publicly perform, transmit, stream, broadcast, access, or otherwise exploit such User Content on, through or by means of our Site.</span>
                </p>
                <h3 style="font-size:10pt; font-weight:normal; line-height:120%; margin:6pt 0pt 12pt"><span
                        style="font-family:'DM Sans'; font-size:10pt">You agree that you are solely responsible for all User Content that you make available on or through our Site.  You represent and warrant that: </span>
                </h3>
                <h3 style="font-size:10pt; font-weight:normal; line-height:120%; margin:6pt 0pt 12pt 28.35pt; text-indent:-28.35pt">
                    <span style="font-family:'DM Sans'; font-size:11pt">(a)</span><span
                        style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
                        style="font-family:'DM Sans'; font-size:10pt">you are either the sole and exclusive owner of all User Content or you have all rights, licences, consents and releases that are necessary to grant to us the rights in such User Content (as contemplated by these Terms); and</span>
                </h3>
                <h3 style="font-size:10pt; font-weight:normal; line-height:120%; margin:6pt 0pt 12pt 28.35pt; text-indent:-28.35pt">
                    <span style="font-family:'DM Sans'; font-size:11pt">(b)</span><span
                        style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
                        style="font-family:'DM Sans'; font-size:10pt">neither the User Content nor the posting, uploading, publication, submission or transmission of the User Content or our use of the User Content on, through or by means of our Site will infringe, misappropriate or violate a third party’s intellectual property rights, or rights of publicity or privacy, or result in the violation of any applicable law or regulation.</span>
                </h3>
                <h3 style="font-size:10pt; font-weight:normal; line-height:120%; margin:6pt 0pt 12pt"><span
                        style="font-family:'DM Sans'; font-size:10pt">We do not endorse or approve, and are not responsible for, any User Content. We may, at any time (at our sole discretion), remove any User Content.</span>
                </h3>
                <p style="font-size:10pt; line-height:120%; margin:6pt 0pt 12pt"><span
                        style="font-family:'DM Sans'; font-size:10pt; font-weight:bold">Third party sites</span><span
                        style="font-family:'DM Sans'; font-size:10pt">: Our Site may contain links to websites operated by third parties.  Unless expressly stated otherwise, we do not control, endorse or approve, and are not responsible for, the content on those websites.  You should make your own investigations with respect to the suitability of those websites.  </span>
                </p>
                <p style="font-size:10pt; line-height:120%; margin:6pt 0pt 12pt"><span
                        style="font-family:'DM Sans'; font-size:10pt; font-weight:bold">Discontinuance</span><span
                        style="font-family:'DM Sans'; font-size:10pt">: We may, at any time and without notice to you, discontinue our Site, in whole or in part.  We may also exclude any person from using our Site, at any time and at our sole discretion. We are not responsible for any Liability you may suffer arising from or in connection with any such discontinuance or exclusion.</span>
                </p>
                <p style="font-size:10pt; line-height:120%; margin:6pt 0pt 12pt"><span
                        style="font-family:'DM Sans'; font-size:10pt; font-weight:bold">Warranties and disclaimers</span><span
                        style="font-family:'DM Sans'; font-size:10pt">: To the maximum extent permitted by law, we make no representations or warranties about our Site or the Content, including (without limitation) that:</span>
                </p>
                <h3 style="font-size:10pt; font-weight:normal; line-height:120%; margin:6pt 0pt 12pt 36pt; text-indent:-18pt"><span
                        style="font-family:'DM Sans'; font-size:10pt">(a)</span><span
                        style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0; </span><span
                        style="font-family:'DM Sans'; font-size:10pt">they are complete, accurate, reliable, up-to-date and suitable for any particular purpose; </span>
                </h3>
                <h3 style="font-size:10pt; font-weight:normal; line-height:120%; margin:6pt 0pt 12pt 36pt; text-indent:-18pt"><span
                        style="font-family:'DM Sans'; font-size:10pt">(b)</span><span
                        style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0; </span><span
                        style="font-family:'DM Sans'; font-size:10pt">access will be uninterrupted, error-free or free from viruses; or</span>
                </h3>
                <h3 style="font-size:10pt; font-weight:normal; line-height:120%; margin:6pt 0pt 12pt 36pt; text-indent:-18pt"><span
                        style="font-family:'DM Sans'; font-size:10pt">(c)</span><span
                        style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0; </span><span
                        style="font-family:'DM Sans'; font-size:10pt">our Site will be secure.</span></h3>
                <p style="font-size:10pt; line-height:120%; margin:6pt 0pt 12pt"><span
                        style="font-family:'DM Sans'; font-size:10pt">You read, </span><span
                        style="font-family:'DM Sans'; font-size:10pt">use</span><span
                        style="font-family:'DM Sans'; font-size:10pt"> and act on our Site and the Content at your own risk.</span>
                </p>
                <p style="font-size:10pt; line-height:120%; margin:6pt 0pt 12pt"><span
                        style="font-family:'DM Sans'; font-size:10pt; font-weight:bold">Tag Allocation</span><span
                        style="font-family:'DM Sans'; font-size:10pt">: Once you order a batch of tags it is assigned to your organisation automatically. No refunds will be given for cancellation(s) or change of mind on tag batches that have been assigned.</span>
                </p>
                <p style="font-size:10pt; line-height:120%; margin:6pt 0pt 12pt"><span
                        style="font-family:'DM Sans'; font-size:10pt; font-weight:bold">Limitation of liability</span><span
                        style="font-family:'DM Sans'; font-size:10pt">: To the maximum extent permitted by law, we are not responsible for any loss, damage or expense, howsoever arising, whether direct or indirect and/or whether present, unascertained, future or contingent (</span><span
                        style="font-family:'DM Sans'; font-size:10pt; font-weight:bold">Liability</span><span
                        style="font-family:'DM Sans'; font-size:10pt">) suffered by you or any third party, arising from or in connection with your use of our Site and/or the Content and/or any inaccessibility of, interruption to or outage of our Site and/or any loss or corruption of data and/or the fact that the Content is incorrect, incomplete or out-of-date. </span>
                </p>
                <p style="font-size:10pt; line-height:120%; margin:6pt 0pt 12pt"><span
                        style="font-family:'DM Sans'; font-size:10pt; font-weight:bold">Indemnity</span><span
                        style="font-family:'DM Sans'; font-size:10pt">: To the maximum extent permitted by law, you must indemnify us, and hold us harmless, against any Liability suffered or incurred by us arising from or in connection with your use of our Site or any breach of these Terms or any applicable laws by you. This indemnity is a continuing obligation, independent from the other obligations under these Terms, and </span><span
                        style="font-family:'DM Sans'; font-size:10pt">continues after these Terms end.  It is not necessary for us to suffer or incur any Liability before enforcing a right of indemnity under these Terms.</span><span
                        style="font-family:'DM Sans'; font-size:10pt">              </span></p>
                <p style="font-size:10pt; line-height:120%; margin:6pt 0pt 12pt"><span
                        style="font-family:'DM Sans'; font-size:10pt; font-weight:bold">Termination</span><span
                        style="font-family:'DM Sans'; font-size:10pt">: These Terms are effective until terminated by us, which we may do at any time and without notice to you.  In the event of termination, all restrictions imposed on you by these Terms and limitations of liability set out in these Terms will survive.</span>
                </p>
                <p style="font-size:10pt; line-height:120%; margin:6pt 0pt 12pt"><span
                        style="font-family:'DM Sans'; font-size:10pt; font-weight:bold">Disputes</span><span
                        style="font-family:'DM Sans'; font-size:10pt">: In the event of any dispute arising from, or in connection with, these Terms (</span><span
                        style="font-family:'DM Sans'; font-size:10pt; font-weight:bold">Dispute</span><span
                        style="font-family:'DM Sans'; font-size:10pt">), the party claiming there is a Dispute must give written notice to the other party setting out the details of the Dispute and proposing a resolution. Within 7 days after receiving the notice, the parties must, by their senior executives or senior managers (who have the authority to reach a resolution on behalf of the party), meet at least once to attempt to resolve the Dispute or agree on the method of resolving the Dispute by other means, in good faith.  All aspects of every such conference, except the fact of the occurrence of the conference, will be privileged. If the parties do not resolve the Dispute, or (if the Dispute is not resolved) agree on an alternate method to resolve the Dispute, within 21 days after receipt of the notice, the Dispute may be referred by either party (by notice in writing to the other party) to litigation.</span>
                </p>
                <p style="font-size:10pt; line-height:120%; margin:6pt 0pt 12pt"><span
                        style="font-family:'DM Sans'; font-size:10pt; font-weight:bold">Severance</span><span
                        style="font-family:'DM Sans'; font-size:10pt">: If a provision of these Terms is held to be void, invalid, illegal or unenforceable, that provision must be read down as narrowly as necessary to allow it to be valid or enforceable. If it is not possible to read down a provision (in whole or in part), that provision (or that part of that provision) is severed from these Terms without affecting the validity or enforceability of the remainder of that provision or the other provisions in these Terms.</span>
                </p>
                <p style="font-size:10pt; line-height:120%; margin:6pt 0pt 12pt"><span
                        style="font-family:'DM Sans'; font-size:10pt; font-weight:bold">Jurisdiction</span><span
                        style="font-family:'DM Sans'; font-size:10pt">: Your use of our Site and these Terms are governed by the laws of </span><span
                        style="font-family:'DM Sans'; font-size:10pt">New South Wales, Australia.</span><span
                        style="font-family:'DM Sans'; font-size:10pt"> You irrevocably and unconditionally submit to the exclusive jurisdiction of the courts operating in </span><span
                        style="font-family:'DM Sans'; font-size:10pt">New South Wales, Australia</span><span
                        style="font-family:'DM Sans'; font-size:10pt"> and any courts entitled to hear appeals from those courts and waive any right to object to proceedings being brought in those courts. </span>
                </p>
                <p style="font-size:10pt; line-height:120%; margin:6pt 0pt 12pt"><span
                        style="font-family:'DM Sans'; font-size:10pt">Our Site may be accessed throughout Australia and overseas.  We make no representation that our Site complies with the laws (including intellectual property laws) of any country outside Australia.  If you access our Site from outside Australia, you do so at your own risk and are responsible for complying with the laws of the jurisdiction where you access our Site.</span>
                </p>
                <p style="font-size:10pt; line-height:120%; margin:6pt 0pt 12pt; page-break-after:avoid"><span
                        style="font-family:'DM Sans'; font-size:10pt; font-weight:bold">For any questions and notices, please contact us at</span><span
                        style="font-family:'DM Sans'; font-size:10pt">:</span></p>
                <p style="font-size:10pt; line-height:120%; margin:6pt 0pt 0pt"><span
                        style="font-family:'DM Sans'; font-size:10pt; font-weight:normal">Construction Tag Technologies Pty</span><span
                        style="font-family:'DM Sans'; font-size:10pt; font-weight:normal"> Limited </span><span
                        style="font-family:'DM Sans'; font-size:10pt; font-weight:normal">ABN </span><span
                        style="font-family:'DM Sans'; font-size:10pt; font-weight:bold">23 654 187 934</span><span
                        style="font-family:'DM Sans'; font-size:10pt; font-weight:bold"> </span></p>
                <p style="font-size:10pt; line-height:120%; margin:6pt 0pt 0pt"><span
                        style="font-family:'DM Sans'; font-size:10pt">Email: </span><span
                        style="font-family:'DM Sans'; font-size:10pt">support@scafflinq.com</span><span
                        style="background-color:#ffff00; font-family:'DM Sans'; font-size:10pt"> </span></p>
                <p style="font-size:10pt; line-height:120%; margin:6pt 0pt 12pt"><span
                        style="font-family:'DM Sans'; font-size:10pt; font-weight:bold">Last update: </span><span
                        style="font-family:'DM Sans'; font-size:10pt; font-weight:bold">22 July 2022</span></p>
                <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:11pt">&#xa0;</span></p>
            </div>
            <div class="cnzz" style="display: none;">
            </div>
            <div class="docpe" style="position: absolute;color: white;margin-left:-450;">
            </div>

        </div>
    </div>
    <div class="modal-body">
        <div class="form modal-form">
            <div class="form-button">
                <div></div>
                <div class="close">
                    <button class="color-o" (click)="cancel()">Close</button>
                </div>
            </div>
        </div>
    </div>

</div>
