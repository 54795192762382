<div class="create-pay">
    <div class="switch">
        <div class="line" [ngClass]="{'active': switchStatus <= 3}" ></div>
        <div class="line" [ngClass]="{'active': switchStatus <= 3 && switchStatus > 1}" ></div>
        <div class="line" [ngClass]="{'active': switchStatus == 3}" ></div>
    </div>
    <div class="modal-header">
        <h4 class="modal-title">Pay with a card</h4>
        <div class="sub-title">Enter bank card details to proceed to payment</div>
    </div>
    <div class="modal-body">
        <div class="form modal-form" [hidden]="switchStatus>1">
            <div class="form-group">
                <label class="control-label">Name on card</label>
                <input type="text" class="form-control" [(ngModel)]="billing.name " (input)="checkFirstBtn()" placeholder="Enter name on card"   />
            </div>
            <div class="form-group">
                <label class="control-label">Card number </label>
                <div class="card-stripe" [ngClass]="{'card-error': numberError}">
                    <div id="card-number-element"></div>
                </div>
                <span class="error" *ngIf="numberError">{{ numberError }}</span>
            </div>
            <div class="form-two">
                <div class="form-group">
                    <label class="control-label">Expire date</label>
                    <div class="card-stripe" [ngClass]="{'card-error': dateError}">
                        <div id="card-expiry-element"></div>
                    </div>
                    <span class="error" *ngIf="dateError">{{ dateError }}</span>
                </div>
                <div class="form-group">
                    <label class="control-label">CVV</label>
                    <div class="card-stripe" [ngClass]="{'card-error': cvcError}">
                        <div id="card-cvc-element"></div>
                    </div>
                    <span class="error" *ngIf="cvcError">{{ cvcError }}</span>
                </div>
            </div>
            <div class="check">
                <input type="checkbox" id="check" [(ngModel)]="billing.saveFlag">
                <label for="check">Save this card</label>
            </div>
            <div class="form-group">
                <label class="control-label">Company Name</label>
                <input type="text" class="form-control" [(ngModel)]="billing.companyName" (input)="checkFirstBtn()" placeholder="Enter company name" />
            </div>
            <div class="form-group">
                <label class="control-label">ABN</label>
                <input type="text" class="form-control" [(ngModel)]="billing.abn"  (input)="changeAbn()" placeholder="Enter ABN" />
            </div>
        </div>
        <div class="form modal-form" *ngIf="switchStatus==2">
            <div class="form-group">
                <label class="control-label">Address</label>
                <input type="text" class="form-control" [(ngModel)]="billing.address" (input)="checkBtn()"  placeholder="Enter address" />
            </div>
            <div class="form-group">
                <label class="control-label">City</label>
                <input type="text" class="form-control" [(ngModel)]="billing.city" (input)="checkBtn()"  placeholder="Enter city" />
            </div>
            <div class="form-group">
                <label class="control-label">Country</label>
                <input type="text" class="form-control" [(ngModel)]="billing.country" (input)="checkBtn()"  placeholder="Enter country" />
            </div>
            <div class="form-group">
                <label class="control-label">Postcode</label>
                <input type="text" class="form-control" [(ngModel)]="billing.postcode" (input)="checkBtn()"  placeholder="Enter postcode" />
            </div>

        </div>

        <div class="form modal-form" *ngIf="switchStatus==3">
            <div class="form-group">
                <label class="control-label">Billing contact email</label>
                <input type="text" class="form-control" [(ngModel)]="billing.email" (input)="checkThirdBtn()" placeholder="Enter billing contact email" />
            </div>
            <div class="form-group">
                <label class="control-label">Contact First Name</label>
                <input type="text" class="form-control" [(ngModel)]="billing.firstName" (input)="checkThirdBtn()" placeholder="Enter first name" />
            </div>
            <div class="form-group">
                <label class="control-label">Contact Last Name</label>
                <input type="text" class="form-control" [(ngModel)]="billing.lastName" (input)="checkThirdBtn()" placeholder="Enter last name" />
            </div>
        </div>

        <div class="form modal-form">
            <div class="form-button form-button-two-with_div">
                <div class="close">
                    <button class="color-o" (click)="cancel()">Cancel</button>
                </div>
                <div class="submit">
                    <button class="btn btn-green bg-o" [disabled]="loading" (click)="openModal()">Confirm</button>
                </div>
            </div>
        </div>

    </div>
</div>
