<div class="admin-wrapper">
    <div class="admin">
        <div class="admin-left">
            <div class="logo"></div>
            <div class="bg-box"></div>
        </div>
        <div class="admin-right">
            <div class="text-box">
                <div class="form">
<!--                    <div class="back-top" (click)="back()"> <span class="icon icon-24 icon-arrow-back"></span>Email Sent</div>-->
                    <h2 class="title">Scafftag Confirmation</h2>
                    <div class="tip" >Your email address has been verified!</div>
                    <div class="email-info">Have you or anyone from your company received Tags?</div>
                    <div class="form-button">
                        <button class="btn btn-o mb-16" (click)="login()">Yes, I have tags</button>
                        <button class="btn btn-new-default" (click)="getFreeOrder()">No, I don’t. Order free now</button>
                    </div>
                    <div class="code-tip" (click)="login()">Skip for now, I'll order a tag later.</div>
                </div>
            </div>
        </div>
    </div>
</div>
