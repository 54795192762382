<div class="branch-notice">
    <div class="modal-header">
        <h4 class="modal-title"> Notice </h4>
    </div>
    <div class="modal-body">
        <p>Oops! We cannot make this branch inactive because it contains active projects. Please archive all the active projects and try again.</p>
       <div class="form-button">
        <button class="btn btn-green bg-o" (click)="cancel()">Ok</button>
       </div>
    </div>

</div>