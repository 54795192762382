import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-branch-notice',
  templateUrl: './branch-notice.component.html',
  styleUrls: ['./branch-notice.component.less']
})
export class BranchNoticeComponent implements OnInit {

  constructor(
    private ngbActiveModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
  }
  cancel(): void {
    this.ngbActiveModal.close();
  }
}
