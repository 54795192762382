import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Page } from '../../observable/model/base';
import { PageCondition } from '../../observable/model/condition';
import { Scaffold, ScaffoldNumVo } from '../../observable/model/scaffold';
import { SelectStringCV } from '../../observable/model/selectVo';
import { ProjectRepository } from '../../repository/project-repository';
import { ScaffoldRepository } from '../../repository/scaffold-repository';

@Component({
    selector: 'app-scaffold',
    templateUrl: './scaffold.component.html',
    styleUrls: ['./scaffold.component.less'],
    template: ``
})
export class ScaffoldComponent implements OnInit {

    scaffoldPage: Page<Scaffold> = new Page<Scaffold>();
    condition = new PageCondition('', 10);
    projectList: Array<SelectStringCV>;
    ScaffoldNumVo = new ScaffoldNumVo();
    selectProjects: Array<string> = new Array<string>();
    currnetSort = new Map();
    screenHeight = document.body.clientHeight;
    showSelectDialog: boolean;
    selectText: string;
    projectName: string;
    breadcrumbs: any;
    constructor(
        private activateInfo: ActivatedRoute,
        private scaffoldRepository: ScaffoldRepository,
        private projectRepository: ProjectRepository,
        private router: Router,
        private renderer: Renderer2,
        private route: ActivatedRoute) {
        activateInfo.queryParams.subscribe(queryParams => {
            this.projectName = queryParams.name;
        });


        this.renderer.listen('window', 'click', (e: Event) => {
            if (this.showSelectDialog) {
                this.showSelectDialog = false;
                if (this.projectList.length > 0) {
                    this.projectList.forEach(it => {
                        it['select'] = this.selectProjects.includes(it.value)
                    })
                }
            }
        });

        let page = new URLSearchParams(window.location.href.split('?')[1]).get('page');
        if (page) {
            this.condition.current = Number.parseInt(page);
        } else {
            this.setPageCondition(1);
        }
    }

    ngOnInit(): void {
        this.selectProjects.push(this.route.snapshot.paramMap.get('projectId'));
        this.condition.sortOrder = 'DESC';
        var freeTrial = document.getElementsByClassName("fourteen-tip");
        let hideHeight = freeTrial && freeTrial.length > 0 ? 80 : 0;
        this.condition.size = Math.trunc((this.screenHeight - 72 - 99 - 98 - 46 - hideHeight) / 75) - 1;
        this.init();
    }

    init(): void {
        this.projectRepository.listParam().subscribe(result => {
            this.projectList = result;
            // this.projectList.unshift({label: 'All projects', value: ''});
            this.projectList.forEach(it => {
                it['select'] = this.selectProjects.includes(it.value)
            })
            this.watchSelect()

        });
        this.getScaffoldList();
        let test = this.router.routerState.snapshot.url;

        console.log(test)

    }

    getScaffoldNumber(): void {
        this.scaffoldRepository.getScaffoldNumberInfo(this.condition).subscribe(result => {
            this.ScaffoldNumVo.dismantleNum = result.scaffoldDismantledNum;
            this.ScaffoldNumVo.installNum = result.scaffoldInstalledNum;
            this.ScaffoldNumVo.allNumber = result.scaffoldInstalledNum + result.scaffoldDismantledNum;
        });
    }

    showSelect(event: Event): void {
        event.stopPropagation();
        this.showSelectDialog = !this.showSelectDialog
    }
    watchSelect() {
        if (this.selectProjects.length == this.projectList.length) {
            this.selectText = 'all projects'
        } else {
            let text = this.selectProjects.length > 1 ? ' projects ' : ' project '
            this.selectText = this.selectProjects.length + text + 'selected'
        }
    }

    stopEvent(event: Event) {
        event.stopPropagation();
    }
    changeSelect(val) {
        console.log(val, 'LLL');
    }
    changeDialog(value: string): void {
        this.showSelectDialog = false
        if (value == 'done') {
            this.selectProjects = [];
            this.projectList.forEach(p => {
                if (p.select) {
                    this.selectProjects.push(p.value)
                }
            });
            this.watchSelect();
            this.getScaffoldList();
        } else {
            if (this.projectList.length > 0) {
                this.projectList.forEach(it => {
                    it['select'] = this.selectProjects.includes(it.value)
                })
            }
        }
    }
    selectClick(value: string): void {
        let bool = value == 'all' ? true : false;
        this.projectList.forEach(it => {
            it['select'] = bool
        })
    }

    getScaffoldList(): void {
        if (this.selectProjects.length == 0) {
            this.condition.projectIds = new Array<string>();
            this.condition.projectIds.push('-1');
        } else {
            this.condition.projectIds = this.selectProjects;
        }
        this.scaffoldRepository.list(this.condition).subscribe(result => {
            this.scaffoldPage = result;
        });
        this.getScaffoldNumber();
    }

    addSelectItem(value: string): void {
        if (value == '') {
            //勾选了全部
            this.selectProjects = this.projectList.map(p => p.value);
        } else {
            //最后一个时也要勾选全部
            if (this.selectProjects.length == this.projectList.length - 1) {
                this.selectProjects = this.projectList.map(p => p.value);
            }
        }
        this.getScaffoldList();
    }

    removeSelectItem(object: any): void {
        if (object.value == '') {
            this.selectProjects = new Array<string>();
        } else {
            this.selectProjects = this.selectProjects.filter(p => p != '');
        }
        this.getScaffoldList();
    }


    getScaffoldListByPage(current: number): void {
        this.condition.current = current;
        this.setPageCondition(current);
        this.getScaffoldList();
    }

    sortScaffoldList(sortName: string): void {
        this.condition.sortName = sortName;
        this.condition.sortOrder = this.condition.sortOrder == 'ASC' ? 'DESC' : 'ASC';
        this.getScaffoldList();
        this.currnetSort = new Map();
        this.currnetSort[sortName] = this.condition.sortOrder;

    }

    statueSearchScaffoldList(scaffoldStatus: string): void {
        this.condition.scaffoldStatus = scaffoldStatus;
        this.condition.current = 1;
        this.getScaffoldList();
    }

    searchKeywordByPress($event: KeyboardEvent): void {
        if ($event.key != 'Enter') {
            return;
        }
        this.getScaffoldList();
    }

    goTagInspection(scaffold: Scaffold): void {
        this.router.navigate(['project/scaffold', scaffold.id, 'tag-inspection'], {
            queryParams: {
                scaffoldName: scaffold.scaffoldName,
                projectName: scaffold.projectName,
                projectId: scaffold.projectId
            }
        });
    }

    downloadScaffold() {
        this.scaffoldRepository
            .downLoad(this.condition)
            .subscribe(blob => {
                const a = document.createElement('a')
                const objectUrl = URL.createObjectURL(blob)
                a.href = objectUrl
                a.download = "Scaffold List";
                a.click();
                URL.revokeObjectURL(objectUrl);
            });
    }

    goBack(): void {
        history.back();
    }

    setPageCondition(current: Number): void {
        let params: URLSearchParams = new URLSearchParams(window.location.href.split('?')[1]);
        if (params.has('page')) {
            params.set('page', `${current}`);
        } else {
            params.append('page', `${current}`);
        }
        window.location.href = window.location.href.split('?')[0] + '?' + params;
    }
}

