<div class="admin-wrapper">
    <div class="admin">
        <div class="admin-left">
            <div class="logo"></div>
            <div class="bg-box"></div>
        </div>
        <div class="admin-right">
            <div class="text-box">
                <div class="form">
<!--                    <div class="back-top" (click)="back()"> <span class="icon icon-24 icon-arrow-back"></span>Order Scafftag</div>-->
                    <h2 class="title">Order Sent!</h2>
                    <div class="tip" >Your first box of complementary scaffold tags are on the way</div>
                    <div class="email-info">We have sent you an email confirming the order.</div>
                    <div class="form-button">
                        <button class="btn btn-o mb-16" (click)="download()">Download the Mobile App</button>
                        <button class="btn btn-new-default" (click)="invite()">Invite a team member</button>
                    </div>
                    <div class="code-tip"><a [routerLink]="['/login']">Login</a></div>
                </div>
            </div>
        </div>
    </div>
</div>
