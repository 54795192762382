<!-- <header class="head">
    <span></span>
    <span></span>
    <span></span>
</header> -->

<div class="sidebar">
    <a class="logo">
        <img src="assets/img/logo-new.png" class="logo-img"/>
    </a>
    <nav>
        <ul class="list-unstyled">
<!--            <li><a><i class="icon-dashboard"></i><div class="hover-name">Dashboard</div></a></li>-->
            <li routerLinkActive="active"><a [routerLink]="['/order']" routerLinkActive="activeContent"><span class="icon icon-orders-new icon-24"></span> <div class="hover-name">Orders</div> </a></li>
            <li routerLinkActive="active"  *ngIf="userRole==0" ><a [routerLink]="['/tag']" routerLinkActive="activeContent"><span class="icon icon-tags-new icon-24"></span> <div class="hover-name">Tags</div></a></li>
            <li routerLinkActive="active"><a [routerLink]="['/project']" routerLinkActive="activeContent"><span class="icon icon-projects-new icon-24"></span> <div class="hover-name">Projects</div></a></li>
            <li routerLinkActive="active"><a [routerLink]="['/team']" routerLinkActive="activeContent"><span class="icon icon-teams-new icon-24"></span> <div class="hover-name">Teams</div></a></li>
            <li routerLinkActive="active"><a [routerLink]="['/invoices']" routerLinkActive="activeContent"><span class="icon icon-invoices-new icon-24"></span> <div class="hover-name">Invoices</div></a></li>
            <li routerLinkActive="active" *ngIf="userRole==1"><a [routerLink]="['/setting']" routerLinkActive="activeContent"
                                                                 (click)="goSettingInit()"><span class="icon icon-settings-new icon-24"></span> <div class="hover-name">Settings</div></a>
            </li>
        </ul>
    </nav>
    <div class="sidebar-bottom">
        <a href="javascript:void(0)" (click)="logout();" class="profile-box">
            <span class="icon icon-16 icon-sign-out"></span>
            Log out
        </a>
<!--        <a class="profile-box">-->
<!--            <img src="assets/img/avatar.png">-->
<!--            <label class="label-status label-green"></label>-->
<!--        </a>-->
    </div>

</div>
