<div class="container">
    <div class="top-bar">
        <h1><span class="icon icon-arrow-left icon-32 xs-mr-12" (click)="goBack()"></span> {{numberName}}</h1>
        <div class="right-bar" *ngIf="type == 'order'">
            <div class="add">
                <span class="icon icon-add-new icon-24"></span>
                <p>Create New Order</p>
            </div>
        </div>
    </div>
    <div class="content">
        <div class="sub-head-box">
            <div class="search">
                <input type="text" [(ngModel)]="condition.name"  placeholder="Search by tag ID and project... " (keypress)="searchKeywordByPress($event)"  (blur)="init()">
                <i class="icon-search"></i>
            </div>

            <div class="filter-box">
                <div class="item" [ngClass]="{'active': condition.tagStatus==null}"
                     (click)="statueSearchITagByOrderList(null)">All ({{BoxNumVo.allNumber}})
                </div>
                <div class="item" [ngClass]="{'active': condition.tagStatus=='Active'}"
                     (click)="statueSearchITagByOrderList('Active')">Active ({{BoxNumVo.tagActiveNum}})
                </div>
                <div class="item" [ngClass]="{'active': condition.tagStatus=='Replaced/lost'}"
                     (click)="statueSearchITagByOrderList('Replaced/lost')">Replaced<span class="design-type">/</span>Lost ({{BoxNumVo.tagReplacedLostNum}})
                </div>
            </div>
            <!--<div class="botton-box">
                <a href="javascript:void(0)"><i class="icon icon-18 icon-export"></i>CSV</a>
             &lt;!&ndash;   <a href="javascript:void(0)"><i class="icon icon-18 icon-print"></i></a>&ndash;&gt;
            </div>-->
        </div>
        <div class="table order-box">
            <table>
                <thead>
                <tr>
                    <th style="width: 15%">
                        <p>Tag ID <i class="icon icon-16 icon-title-down-arrow" [ngClass]="{'icon-title-down-arrow': currnetSort['tagCode'] == 'ASC' || !currnetSort['tagCode'],'icon-up-arrow':currnetSort['tagCode'] == 'DESC' }"
                            (click)="sortTagByOrderList('tagCode')" ></i></p>
                    </th>
                    <th style="width: 15%">
                        <p>Link</p>
                    </th>
                    <th style="width: 15%">
                        <p>Customer <i class="icon icon-16 icon-title-down-arrow" [ngClass]="{'icon-title-down-arrow': currnetSort['companyName'] == 'ASC' || !currnetSort['companyName'],'icon-up-arrow':currnetSort['companyName'] == 'DESC'}"
                                       (click)="sortTagByOrderList('companyName')"></i></p>
                    </th>
                    <th style="width: 20%">
                        <p>Ordered At<i class="icon icon-16 icon-title-down-arrow" [ngClass]="{'icon-title-down-arrow': currnetSort['orderedAd'] == 'ASC' || !currnetSort['orderedAd'],'icon-up-arrow':currnetSort['orderedAd'] == 'DESC'}"
                                        (click)="sortTagByOrderList('orderedAd')"></i></p>
                    </th>
                    <th style="width: 15%">
                        <p>Date Scanned <i class="icon icon-16 icon-title-down-arrow" [ngClass]="{'icon-title-down-arrow': currnetSort['dateScanned'] == 'ASC' || !currnetSort['dateScanned'],'icon-up-arrow':currnetSort['dateScanned'] == 'DESC'}"
                                           (click)="sortTagByOrderList('dateScanned')"></i></p>
                    </th>
                    <th style="width: 15%">
                        <p>Project <i class="icon  icon-16 icon-title-down-arrow"  [ngClass]="{'icon-title-down-arrow': currnetSort['projectName'] == 'ASC' || !currnetSort['projectName'],'icon-up-arrow':currnetSort['projectName'] == 'DESC'}"
                                      (click)="sortTagByOrderList('projectName')"></i></p>
                    </th>
                    <th style="width: 20%">
                        <p>Status <i class="icon  icon-16 icon-title-down-arrow" [ngClass]="{'icon-title-down-arrow': currnetSort['state'] == 'ASC' || !currnetSort['state'],'icon-up-arrow':currnetSort['state'] == 'DESC'}"
                                     (click)="sortTagByOrderList('state')"></i></p>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let tagByOrder of tagByOrderPage.records">
                    <td class="id">
                        <div class="id-box">
                            <span class="icon icon-tags-blue icon-16 xs-mr-8"></span>
                            <p>{{tagByOrder.tagCode|| '-----'}}</p>
                        </div>
                    </td>
                    <td>
                        <div *ngIf="tagByOrder.link">{{tagByOrder.link}}</div>
                        <div *ngIf="!tagByOrder.link" class="empty-ellipsis">-----</div>
                    </td>
                    <td>
                        <div *ngIf="tagByOrder.companyName">{{tagByOrder.companyName}}</div>
                        <div *ngIf="!tagByOrder.companyName" class="empty-ellipsis">-----</div>
                    </td>
                  
                    <td>
                        <div *ngIf="tagByOrder.orderAdString">{{tagByOrder.orderAdString}}</div>
                        <div *ngIf="!tagByOrder.orderAdString" class="empty-ellipsis">-----</div>
                    </td>
                    <td>
                        <div *ngIf="tagByOrder.dateScanned">{{tagByOrder.dateScanned | date:'dd MMM yyyy'}}</div>
                        <div *ngIf="!tagByOrder.dateScanned" class="empty-ellipsis">-----</div>
                    </td>
                    <td>
                        <div *ngIf="tagByOrder.projectName">{{tagByOrder.projectName}}</div>
                        <div *ngIf="!tagByOrder.projectName" class="empty-ellipsis">-----</div>
                    </td>
                    <td>
                        <p *ngIf="tagByOrder.state != 'Replaced/lost'" class="label-status" [ngClass]="tagByOrder.state==='Active' ? 'label-green':'label-red'">{{tagByOrder.state}}</p>
                        <p *ngIf="tagByOrder.state == 'Replaced/lost'" class="label-status label-orange">Replaced<span class="design-type">/</span>Lost</p>
                    </td>
                </tr>
                </tbody>
            </table>
            <ngb-pagination class="justify-content-center" *ngIf="tagByOrderPage.pages>1"
                            [(page)]="tagByOrderPage.current" [pageSize]="tagByOrderPage.size" [maxSize]="5"
                            (pageChange)="getTagByOrderListByPage(tagByOrderPage.current)" [boundaryLinks]="false"
                            [collectionSize]="tagByOrderPage.total">
            </ngb-pagination>
        </div>
    </div>
</div>
