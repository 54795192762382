<div class="disable-modal">
    <div class="modal-header">
        <h4 class="modal-title xs-mb-12">Disable Staff</h4>
    </div>
    <div class="modal-body">
        <div class="form form-content">
            <div class="inner-box">
                <p>Disabling the staff will remove the app accessibility from the staff. He/she will not be able to
                    login to the app.</p>
            </div>
            <div class="form-button form-button-two-with_div">
                <div class="close">
                    <button class="color-o" (click)="cancel()">No, take me back</button>
                </div>
                <div class="submit">
                    <button class="btn btn-red" [disabled]="isLoading" (click)="disable()">Yes, disable staff</button>
                </div>
            </div>
        </div>
    </div>
</div>
