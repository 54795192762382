<div class="container">
    <div class="top-bar">
        <h1>{{title}}</h1>
        <div class="right-bar">
            <div *ngIf="userRole==0 || userRole==1" class="add" (click)="CreateOderModal();">
                <span class="icon icon-add-new icon-24"></span>
                <p>Create New Order</p>
            </div>
<!--            <div class="switch" *ngIf="userRole==0">-->
<!--                <div class="item" [ngClass]="{'active': switchStatus == 0}" (click)="switchStatueOrderList(0)">-->
<!--                    Orders-->
<!--                </div>-->
<!--                <div class="item" [ngClass]="{'active': switchStatus == 1}" (click)="switchStatueOrderList(1)">-->
<!--                    Inventory-->
<!--                </div>-->
<!--            </div>-->
        </div>
    </div>
    <div class="content">
        <div class="sub-head-box">
            <div class="search">
                <input type="text" [(ngModel)]="condition.name" (keypress)="searchKeywordByPress($event)" placeholder="Search by request ID, company name and box ID... "  (blur)="getOrderList()">
                <i class="icon-search"></i>
            </div>

            <div class="filter-box">
                <div class="item" [ngClass]="{'active':condition.orderStatus==null}"
                     (click)="statueSearchOrderList(null)">All ({{orderStatusNumVo.newOrderNum + orderStatusNumVo.boxAssignedNum + orderStatusNumVo.shippedNum + orderStatusNumVo.completedNum + orderStatusNumVo.archivedNum}})
                </div>
                <!-- <div class="item" [ngClass]="{'active': condition.orderStatus==1}" (click)="statueSearchOrderList(1)">
                    Pending payment
                </div>
                <div class="item" [ngClass]="{'active': condition.orderStatus==2}" (click)="statueSearchOrderList(2)">
                    Paid
                </div>
                <div class="item" [ngClass]="{'active': condition.orderStatus==3}" (click)="statueSearchOrderList(3)">
                    Sent to manufacturer
                </div>
                <div class="item" [ngClass]="{'active': condition.orderStatus==4}" (click)="statueSearchOrderList(4)">
                    Shipped
                </div>
                <div class="item" [ngClass]="{'active': condition.orderStatus==5}" (click)="statueSearchOrderList(5)">
                    Received
                </div> -->
                <div class="item" [ngClass]="{'active': condition.orderStatus==1}" (click)="statueSearchOrderList(1)" >
                    New Order ({{orderStatusNumVo.newOrderNum}})
                </div>
<!--                <div class="item" [ngClass]="{'active': condition.orderStatus==2}" (click)="statueSearchOrderList(2)" >-->
<!--                    Box Assigned ({{orderStatusNumVo.boxAssignedNum}})-->
<!--                </div>-->
                <div class="item" [ngClass]="{'active': condition.orderStatus==3}" (click)="statueSearchOrderList(3)">
                    Shipped ({{orderStatusNumVo.shippedNum}})
                </div>
                <div class="item" [ngClass]="{'active': condition.orderStatus==4}" (click)="statueSearchOrderList(4)" >
                    Completed ({{orderStatusNumVo.completedNum}})
                </div>
                <div class="item" [ngClass]="{'active': condition.orderStatus==5}" (click)="statueSearchOrderList(5)" >
                    Archived ({{orderStatusNumVo.archivedNum}})
                </div>

            </div>

        </div>
        <div class="table order-table">
            <table >
                <thead>
                <tr>
                    <th>
                        <div class="id-box">
                            <p>Request ID <i class="icon icon-16 icon-title-down-arrow"
                                             [ngClass]="{'icon-title-down-arrow': currnetSort['orderNum'] == 'ASC' || !currnetSort['orderNum'],'icon-up-arrow':currnetSort['orderNum'] == 'DESC' }"
                                             (click)="sortOrderList('orderNum')"></i>
                            </p>
                        </div>
                    </th>
                    <th >
                        <p>Customer Name<i class="icon icon-16 icon-title-down-arrow"  [ngClass]="{'icon-title-down-arrow': currnetSort['customerName'] == 'ASC' || !currnetSort['customerName'],'icon-up-arrow':currnetSort['customerName'] == 'DESC' }"
                                           (click)="sortOrderList('customerName')"></i></p>
                    </th>
                    <th >
                        <p>Company Name<i class="icon icon-16 icon-title-down-arrow"
                                          [ngClass]="{'icon-title-down-arrow': currnetSort['companyName'] == 'ASC' || !currnetSort['companyName'],'icon-up-arrow':currnetSort['companyName'] == 'DESC' }"
                                          (click)="sortOrderList('companyName')"></i></p>
                    </th>
                    <th class="email">
                        <p>Email</p>
                    </th>
                    <th class="phone">
                        <p>Ph/Mob</p>
                    </th>
                    <th class="address">
                        <p>
                            Full Address
                        </p>
                    </th>
                    <th class="type" >
                        <p>Request Type<i class="icon icon-16 icon-title-down-arrow" [ngClass]="{'icon-title-down-arrow': currnetSort['orderType'] == 'ASC' || !currnetSort['orderType'],'icon-up-arrow':currnetSort['orderType'] == 'DESC' }"
                                          (click)="sortOrderList('orderType')"></i></p>
                    </th>

                    <th >
                        <p>Order Status <i class="icon icon-16 icon-title-down-arrow"
                                           [ngClass]="{'icon-title-down-arrow': currnetSort['orderStatus'] == 'ASC' || !currnetSort['orderStatus'],'icon-up-arrow':currnetSort['orderStatus'] == 'DESC' }"
                                           (click)="sortOrderList('orderStatus')"></i></p>
                    </th>
                    <th>
                        <p>Box <i class="icon  icon-16 icon-title-down-arrow" [ngClass]="{'icon-title-down-arrow': currnetSort['boxNum'] == 'ASC' || !currnetSort['boxNum'],'icon-up-arrow':currnetSort['boxNum'] == 'DESC' }"
                                  (click)="sortOrderList('boxNum')"></i></p>
                    </th>
                    <!-- <th style="width: 10%">
                        <p>Price<i class="icon icon-16 icon-title-down-arrow"
                                          [ngClass]="{'icon-title-down-arrow': currnetSort['price'] == 'ASC' || !currnetSort['price'],'icon-up-arrow':currnetSort['price'] == 'DESC' }"
                                          (click)="sortOrderList('price')"></i></p>
                    </th> -->
                </tr>
                </thead>
                <tbody >
                <tr *ngFor="let order of orderPage.records">
                    <td class="id">
                        <div class="id-box">
                            <p (click)="goOrderContent(order)">{{order.orderNum || '-----'}}</p>
                        </div>
                    </td>
                    <td>
                        <p>{{order.customerName || '-----'}}</p>
                    </td>
                    <td class="companyname">
                        <p>{{order.companyName || '-----'}}</p>
                    </td>
                    <td class="email">
                        <p>{{order.email || '-----' }}</p>
                    </td>
                    <td>
                        <p>{{order.mobile || '-----'}}</p>
                    </td>
                    <td class="address">
                        <p *ngIf="order.streetNumber || order.suburb || order.state || order.postCode || order.country; else emptyAddress">
                            {{ order.streetNumber }} {{ order.suburb }} {{ order.state }} {{ order.postCode }} {{ order.country }}
                        </p>
                        <ng-template #emptyAddress>-----</ng-template>
                    </td>
                    <td class="type">
                        <!-- 状态颜色  free  paid-->
                        <p class="paid" *ngIf="order.orderType==1">Paid</p>
                        <p class="free" *ngIf="order.orderType == 2 || order.orderType==3">Free</p>
                    </td>

                    <td [ngClass]="{'cursorClass': userRole==0}" class="status">
                        <!-- 状态颜色 order   assigned  shipped   completed-->
                        <p [ngClass]="{'order': order.orderStatus==1,'assigned': order.orderStatus==2,'shipped': order.orderStatus == 3,'completed': order.orderStatus==4,'archived': order.orderStatus==5}" title="{{order.orderStatusName}}">
                            {{order.orderStatusName}} <span [ngbPopover]="popContent" placement="bottom-right"  *ngIf="userRole == 0 && ( order.orderStatus == 1 || order.orderStatus == 5)"
                                                            class="icon icon-18"> <i
                                                                                     class="icon icon-13 icon-font-arrow"></i>
                                </span></p>
                        <ng-template #popContent>
                            <div class="list-main">
                                <div class="list" *ngIf="order.orderStatus==5"
                                     (click)="changeOrderStatus(order.id, 1)">
                                    <h3>New order</h3>
                                </div>
<!--                                <div class="list" *ngIf="(order.orderStatus<2 || order.orderStatus==5) && order.boxNum"-->
<!--                                     (click)="changeOrderStatus(order.id,2)">-->
<!--                                    <h3>Box assigned</h3>-->
<!--                                </div>-->
                                <div class="list" *ngIf="order.orderStatus == 1  "
                                     (click)="changeOrderStatus(order.id,3)">
                                    <h3>Shipped</h3>
                                </div>
<!--                                <div class="list"  *ngIf="order.orderStatus==2"-->
<!--                                     (click)="changeOrderStatus(order.id,4)">-->
<!--                                    <h3>Completed</h3>-->
<!--                                </div>-->
                                <div class="list" *ngIf="order.orderStatus==1 "
                                     (click)="changeOrderStatus(order.id,5)">
                                    <h3>Archived</h3>
                                </div>
                            </div>
                        </ng-template>
                    </td>
                    <td class="box-id">
<!--                        <div class="box-select">-->
<!--                            <ng-select *ngIf="!order.boxNum && userRole==0" (change)="allotBox(order.id,$event)"-->
<!--                                       placeholder="Select a box">-->
<!--                                <ng-option *ngFor="let box of boxList" [value]="box.id">{{box.boxNum}}-->
<!--                                </ng-option>-->
<!--                            </ng-select>-->
<!--                        </div>-->
                        <div *ngIf="order.boxNum">{{order.boxNum }}</div>
                        <div class="empty-ellipsis" *ngIf="!order.boxNum">-----</div>
                    </td>
                    <!-- <td class="price">
                        <div *ngIf="order.orderType == 1">{{order.price | currency}}</div>
                        <div *ngIf="order.orderType == 2 || order.orderType == 3">Free</div>
                    </td> -->
                </tr>
                </tbody>
            </table>
<!--            <table *ngIf="switchStatus==1">-->
<!--                <thead>-->
<!--                <tr>-->
<!--                    <th>-->
<!--                        <p>Box ID<i class="icon icon-16 icon-title-down-arrow"  [ngClass]="{'icon-title-down-arrow': currnetSort['boxNum'] == 'ASC' || !currnetSort['boxNum'],'icon-up-arrow':currnetSort['boxNum'] == 'DESC' }"-->
<!--                                           (click)="sortOrderList('boxNum')"></i></p>-->
<!--                    </th>-->
<!--                    <th>-->
<!--                        <p>Company name<i class="icon icon-16 icon-title-down-arrow"-->
<!--                                          [ngClass]="{'icon-title-down-arrow': currnetSort['companyName'] == 'ASC' || !currnetSort['companyName'],'icon-up-arrow':currnetSort['companyName'] == 'DESC' }"-->
<!--                                          (click)="sortOrderList('companyName')"></i></p>-->
<!--                    </th>-->
<!--                    <th></th>-->
<!--                </tr>-->
<!--                </thead>-->
<!--                <tbody >-->
<!--                <tr *ngFor="let box of boxPage.records">-->
<!--                    <td class="id"  >-->
<!--                        <p style="overflow: inherit;white-space: nowrap" (click)="goBoxContent(box)">{{box.boxNumName || '-&#45;&#45;&#45;&#45;'}}</p>-->
<!--                    </td>-->
<!--                    <td class="companyname">-->
<!--                        <p>{{box.companyName || '-&#45;&#45;&#45;&#45;'}}</p>-->
<!--                    </td>-->
<!--                    <td class="end-right"></td>-->
<!--                </tr>-->
<!--                </tbody>-->
<!--            </table>-->
            <ngb-pagination class="justify-content-center" *ngIf="orderPage.pages>1 " [(page)]="orderPage.current"
                            [pageSize]="orderPage.size" [maxSize]="5"  [boundaryLinks]="false"
                            (pageChange)="getOrderListByPage(orderPage.current)"
                            [collectionSize]="orderPage.total">
            </ngb-pagination>
<!--            <ngb-pagination class="justify-content-center" *ngIf="boxPage.pages>1&& switchStatus == 1" [(page)]="boxPage.current"-->
<!--                            [pageSize]="boxPage.size" [maxSize]="5" [boundaryLinks]="false"-->
<!--                            (pageChange)="getBoxListByPage(boxPage.current)"-->
<!--                            [collectionSize]="boxPage.total">-->
<!--            </ngb-pagination>-->
        </div>

    </div>
</div>
