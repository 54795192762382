import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JWTUser } from '../observable/model/user';


@Injectable({providedIn: 'root'})
export class BillingGuard implements CanActivate {
    // stripeInfo = new StripeInfo;
    excludes: string[] = ['/login', '/signup-stripe'];
    constructor(
        private router: Router,
        private jwtHelperService: JwtHelperService,
        private modalAdService: NgbModal,
        private ngbActiveModal: NgbActiveModal,
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const accessToken = localStorage.getItem('accessToken');
        const responseCode = localStorage.getItem('responseCode');
        const step = localStorage.getItem('step') && Number(localStorage.getItem('step'));
      
        const jwtUser = this.jwtHelperService.decodeToken(accessToken).inf as JWTUser;
        if (jwtUser.role === 0) {
            return true;
        }
        if( (step != 0 && step != 4)|| responseCode == '402'){
            this.router.navigate(['setting', 'billing']);
            return false;
        }
        return true;
    }
}
