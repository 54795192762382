import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastRepository} from '../../../repository/toast-repository';
import {OrderRepository} from '../../../repository/order-repository';
import {Address, Billing, OtherInfo, StripeBillDetail, StripeCard} from '../../../observable/model/billing';
import {SubscriptionRepository} from '../../../repository/subscription-repository';
import {BillingRepository} from '../../../repository/billing-repository';
import {StripeInfo} from '../../../observable/model/base';
@Component({
    selector: 'app-confirm-purchase',
    templateUrl: './confirm-purchase-pay.component.html',
    styleUrls: ['./confirm-purchase-pay.component.less']
})


export class ConfirmPurchasePayComponent implements OnInit {
    billing :  Billing;
    stripes: any;
    cardNumberElement:any;
    public loading = false;
    otherInfo = new OtherInfo();
    stripeInfo = new StripeInfo();
    public address = new Address();
    public stripeBillDetail = new StripeBillDetail();
    price:string;
    taxPrice:string;
    totalPrice: string;
    constructor(
        private billingRepository: BillingRepository,
        public activeModal: NgbActiveModal,
        private orderRepository: OrderRepository,
        private subscriptionRepository: SubscriptionRepository,
        private toastRepository: ToastRepository) {

    }
    ngOnInit(): void {
        this.stripeInfo = JSON.parse(localStorage.getItem('stripeInfo'));
        this.price = Number(this.stripeInfo.price).toFixed(2);
        if(this.stripeInfo.region == 'AU/NZ' ){
            if(this.billing.abn){
                this.taxPrice = this.stripeInfo.baseTaxRate == 0 ? '0.00' : this.stripeInfo.baseTaxRate ? (Number(this.stripeInfo.price) / this.stripeInfo.baseTaxRate).toFixed(2) : '18.09';
            }else{
                this.taxPrice =  '0.00' ;
            }
        }else{
            this.taxPrice = '0.00';
        }
        this.totalPrice = (Number(this.price) + Number(this.taxPrice)).toFixed(2);
    }

    cancel(): void {
        this.activeModal.close();
    }

    saveConfirm(): void{
        this.saveStripe()
    }

    saveStripe(){
        this.address.city = this.billing.city.replace(/^\s+|\s+$/g,"");
        this.address.line1 = this.billing.address.replace(/^\s+|\s+$/g,"");
        this.address.postal_code = this.billing.postcode.replace(/^\s+|\s+$/g,"");

        this.stripeBillDetail.email = this.billing.email.replace(/^\s+|\s+$/g,"");
        this.stripeBillDetail.name = this.billing.name.replace(/^\s+|\s+$/g,"");
        this.stripeBillDetail.address = this.address;
        this.loading = true;
        this.stripes
            .createPaymentMethod({
                type: "card",
                card: this.cardNumberElement, // 创建好的cardNumber实例
                billing_details: this.stripeBillDetail, // 组装好的billing_details对象数据
            }).then(res => {
            if(res.error){
                this.loading = false;
                this.toastRepository.showDanger(res.error.message || '');
            }else {
                 //    调用保存回调接口
                this.otherInfo.paymentMethodId = res.paymentMethod.id || '';
                this.saveInfo();
            }
        }).catch(err=>{
            this.loading = false;
            this.toastRepository.showDanger(err.error.message || '');
        })
    }
    saveInfo(): void{
        this.otherInfo.abn = this.billing.abn.replace(/^\s+|\s+$/g,"");
        this.otherInfo.country = this.billing.country.replace(/^\s+|\s+$/g,"");
        this.otherInfo.billingContactFirstName = this.billing.firstName.replace(/^\s+|\s+$/g,"");
        this.otherInfo.billingContactLastName = this.billing.lastName.replace(/^\s+|\s+$/g,"");
        this.otherInfo.saveFlag = this.billing.saveFlag ? 1 : 0;
        this.otherInfo.companyName = this.billing.companyName.replace(/^\s+|\s+$/g,"") ;
        this.billingRepository.save(this.otherInfo).subscribe(result => {
            if(result.error){
                this.activeModal.close({'type':1});
                this.toastRepository.showDanger(result.error.message || '');
                this.loading = false;
            }
        },error => {
            this.activeModal.close({'type':1});
            this.loading = false;
        });
    }
}
