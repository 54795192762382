<div class="sign-out">
    <div class="modal-header">
        <h4 class="modal-title">Log out?</h4>
    </div>
    <div class="form form-content">
        <div class="inner-box-1">
            <p>Are you sure you want to log out of Scafflinq?</p>
        </div>
        <div class="form-button form-button-two">
            <button class="btn color-o" (click)="cancel()">No, take me back</button>
            <button class="btn btn-red" (click)="confirm()">Yes, log out</button>
        </div>
    </div>
</div>
