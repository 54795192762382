<div class="admin-wrapper">
    <div class="admin">
        <div class="admin-left">
            <div class="logo"></div>
            <div class="bg-box"></div>
        </div>
        <div class="admin-right">
            <div class="text-box">
                <form class="form" name="loginForm" [ngxFormValidator]="validatorConfig">
                    <h2 class="title">Login</h2>
                    <div class="subtitle">Enter your details to proceed</div>
                    <div class="form-control-group form-icon">
                        <!--                        <label class="control-label" for="account">Email</label>-->
                        <input type="email" id="account" name="account" [(ngModel)]="loginUser.account" email="true"
                            required="required" placeholder="Enter email">
                        <span class="icon icon-16 icon-email icon-first-pic"></span>
                    </div>
                    <div class="form-control-group form-icon">
                        <!--                        <label class="control-label" for="password">Password</label>-->
                        <input class="erro" name="password" type="password" [(ngModel)]="loginUser.password"
                            required="required" placeholder="Enter password" *ngIf="!showPassword">
                        <input class="erro" name="password" type="text" [(ngModel)]="loginUser.password"
                            required="required" placeholder="Enter password" *ngIf="showPassword">
                        <span class="icon icon-16 icon-password icon-first-pic"></span>
                        <span class="icon icon-16 icon-eye-close icon-right-pic" [hidden]="showPassword"
                            (click)="changePassword('show')"></span>
                        <span class="icon icon-16 icon-eye-open icon-right-pic" [hidden]="!showPassword"
                            (click)="changePassword('hide')"></span>
                    </div>
                    <!--                    <p class="erro-text">Email or password does not match the record in our system. Try again.</p>-->

                    <div class="form-bottom">
                        <a [routerLink]="['/forgot']" class="link-text">Forgot password?</a>
                    </div>
                    <button class="btn btn-o btn-block" [ngClass]="{'btn-loading': isLogin}"
                        (ngxFormSubmit)="loginWithEmail()">{{isLogin ? 'Loading...' : 'Login'}}</button>
                    <div class="bottom-tip">Don’t have an account yet? <a [routerLink]="['/signup']">Sign up</a></div>
                </form>
            </div>
        </div>

    </div>
</div>