<div class="create-pay invitation-modal">
    <div class="modal-header">
        <h4 class="modal-title">Enter new email</h4>
        <div class="sub-title">The current email is verified. We will send another verification code to the new email
            you entered below.</div>
    </div>
    <div class="modal-body">
        <div class="form modal-form">
            <div class="form-group">
                <label class="control-label">&#917;mail</label>
                <input type="email" class="form-control" [(ngModel)]="email" />
            </div>


            <div class="form-button form-button-two-with_div">
                <div class="close">
                    <button class="color-o" (click)="cancel()">Cancel</button>
                </div>
                <div class="submit">
                    <button (click)="send()" class="btn btn-o">Send</button>
                </div>

            </div>
        </div>
    </div>

</div>
