<div class="confirm-purchase">
    <div class="modal-header">
        <h4 class="modal-title">Confirm Purchase</h4>
        <div class="sub-title">Enter bank card details to proceed to payment</div>
    </div>
    <div class="modal-body">
        <div class="form form-content">
            <div class="form-two ">
                <p>Subscription</p>
                <p><strong>Professional plan</strong></p>
            </div>
            <div class="form-two line">
                <p>Frequency</p>
                <p><strong>Monthly</strong></p>
            </div>
            <div class="form-two">
                <p>Price</p>
                <p><strong>${{price}}</strong></p>
            </div>
            <div class="form-two">
                <p>Tax</p>
                <p><strong>${{taxPrice}}</strong></p>
            </div>
            <div class="form-two line">
                <p>Discount</p>
                <p class="green2-color"><strong>-$0.00</strong></p>
            </div>
            <div class="form-two">
                <p>Total</p>
                <p><strong>${{totalPrice}}</strong></p>
            </div>

            <div class="form-button form-button-two-with_div">
                <div class="close">
                    <button class="color-o" (click)="cancel()">Cancel</button>
                </div>
                <div class="submit">
                    <button class="btn btn-green bg-o"  [disabled]="loading" (click)="saveConfirm()">Confirm the payment</button>
                </div>

            </div>
        </div>
    </div>
</div>
