import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs/internal/Observable';

@Injectable({
    providedIn: 'root'
})
export class CompanyRepository {

    constructor(private http: HttpClient) {
    }

    public listParam(): Observable<any> {
        return this.http.post<any>(environment.baseURL + `/pc/company/v1/param/list`, null);
    }

    public getInfo(id:string): Observable<any> {
        return this.http.get<any>(environment.baseURL + `/pc/order/v1/getFreeInfoByCompanyId?companyId=${id}`, );
    }
}
