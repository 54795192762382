    <div class="detail">
        <div class="detail-content">
             <div class="text-box ">
                <h3>
                    Enter your payment details below
                </h3>
                <h4>
                    You will be able to access all the content available once the details have been received.
                </h4>
                <div class="stripe-body">
                    <div class="form modal-form" >
                        <div class="form-group">
                            <label class="control-label">Name on card</label>
                            <input type="text" class="form-control" [(ngModel)]="billing.name " placeholder="Enter name"  />
                        </div>
                        <div class="form-group">
                            <label class="control-label">Card number </label>
                            <div class="card-stripe" [ngClass]="{'card-error': numberError}">
                                <div id="card-number-element"></div>
                            </div>
                            <span class="error" *ngIf="numberError">{{ numberError }}</span>
                        </div>
                        <div class="form-two">
                            <div class="form-group">
                                <label class="control-label">Expire date</label>
                                <div class="card-stripe" [ngClass]="{'card-error': dateError}">
                                    <div id="card-expiry-element"></div>
                                </div>
                                <span class="error" *ngIf="dateError">{{ dateError }}</span>
                            </div>
                            <div class="form-group">
                                <label class="control-label">CVV</label>
                                <div class="card-stripe" [ngClass]="{'card-error': cvcError}">
                                    <div id="card-cvc-element"></div>
                                </div>
                                <span class="error" *ngIf="cvcError">{{ cvcError }}</span>
                            </div>
                        </div>

                        <div class="form-group">
                            <label class="control-label">Company Name</label>
                            <input type="text" class="form-control" [(ngModel)]="billing.companyName" placeholder="Enter company name" />
                        </div>

                        <div class="form-group">
                            <label class="control-label">Address</label>
                            <input type="text" class="form-control" [(ngModel)]="billing.address" placeholder="Enter address" />
                        </div>
                        <div class="form-group">
                            <label class="control-label">Country</label>
                            <input type="text" class="form-control" [(ngModel)]="billing.country" placeholder="Enter country" />
                        </div>
                        <div class="form-group">
                            <label class="control-label">City</label>
                            <input type="text" class="form-control" [(ngModel)]="billing.city" placeholder="Enter city" />
                        </div>
                        <div class="form-group">
                            <label class="control-label">Billing contact First Name</label>
                            <input type="text" class="form-control" [(ngModel)]="billing.firstName" placeholder="Enter billing contact name" />
                        </div>
                        <div class="form-group">
                            <label class="control-label">Billing contact Last Name</label>
                            <input type="text" class="form-control" [(ngModel)]="billing.lastName" placeholder="Enter billing contact name" />
                        </div>

                        <div class="form-group">
                            <label class="control-label">Postcode</label>
                            <input type="text" class="form-control" [(ngModel)]="billing.postcode" placeholder="Enter postcode" />
                        </div>
                        <div class="form-group">
                            <label class="control-label">ABN</label>
                            <input type="text" class="form-control" [ngClass]="{'card-error': abnError}"  [(ngModel)]="billing.abn"  placeholder="Enter ABN" />
                            <span class="error" >{{abnError}}</span>
                        </div>
                        <div class="form-group">
                            <label class="control-label">Billing contact email</label>
                            <input type="text" class="form-control" [(ngModel)]="billing.email" placeholder="Enter billing contact email" />
                        </div>
                    </div>
                </div>
                <div class="form-button">
                    <button class="btn btn-o"  [disabled]="loading" (click)="confirm()" >Confirm Detail</button>
                </div>
            </div>

        </div>
    </div>
