<div class="admin-wrapper">
    <div class="admin">
        <div class="admin-left">
            <div class="logo"></div>
            <div class="bg-box"></div>
        </div>
        <div class="admin-right">
            <div class="text-box">
                <div class="title">Member Invite</div>
                <div class="subtitle">Enter your team members details below. We will send them an invite.</div>
                <div class="form-control-group form-icon">
<!--                    <label>Email</label>-->
                    <input type="email" placeholder="Enter email" [(ngModel)]="inviteUser.account">
                    <span class="icon icon-16 icon-email icon-first-pic"></span>
                </div>
                <div class="form-control-group form-icon">
<!--                    <label>Role</label>-->
                    <div class="select">
                        <ng-select id="roleType" name="roleType" required class="select-single select" placeholder="Select your Region"
                            [(ngModel)]="inviteUser.roleType" [searchable]="false">
                            <ng-option [value]="1">Admin</ng-option>
                            <ng-option [value]="2">Inspector</ng-option>
                        </ng-select>
                        <i class="icon icon-18 icon-down-arrow"></i>
                        <span class="icon icon-16 icon-box-1 icon-first-pic"></span>
                    </div>
                </div>
                <div class="form-button mb-24">
                    <button class="btn btn-o" (click)="invite()">Next</button>
                </div>
                <p class="cancel"><a href="javascript:void(0)" (click)="back2Login()">Skip for now</a></p>
            </div>
        </div>
    </div>
</div>
