<div class="container">
    <div class="top-bar">
        <h1>Teams</h1>
        <div class="right-bar">
            <div *ngIf="userRole==1" class="add" (click)="invite();">
                <span class="icon icon-add-new icon-24"></span>
                <p>Invite New Member</p>
            </div>
        </div>
    </div>

    <div class="content">
        <div class="sub-head-box">
            <div class="search">
                <input type="text" [(ngModel)]="condition.name" (keypress)="searchKeywordByPress($event)"
                    (blur)="getUserList()" placeholder="Search by name, email and mobile...">
                <i class="icon-search"></i>
            </div>

            <div class="filter-box">
                <div class="item" [ngClass]="{'active': condition.userStatus==null}"
                    (click)="statueSearchUserList(null)">All ({{teamNumberInfo.allNumber}})
                </div>
                <div class="item" [ngClass]="{'active': condition.userStatus=='Active'}"
                    (click)="statueSearchUserList('Active')">Active ({{teamNumberInfo.teamActiveNum}})
                </div>
                <div class="item" [ngClass]="{'active': condition.userStatus=='Pending'}"
                    (click)="statueSearchUserList('Pending')">Pending ({{teamNumberInfo.teamPendingNum}})
                </div>
                <div class="item" [ngClass]="{'active': condition.userStatus=='Disabled'}"
                    (click)="statueSearchUserList('Disabled')">Disabled ({{teamNumberInfo.teamDisabledNum}})
                </div>
            </div>
        </div>

        <div class="table team-box">
            <table>
                <thead>
                    <tr>
                        <th class="id" style="width: 15%">
                            <div class="id-box">
                                <p>Name <i class="icon icon-16 icon-title-down-arrow"
                                        [ngClass]="{'icon-title-down-arrow': currnetSort['fullName'] == 'ASC' || !currnetSort['fullName'],'icon-up-arrow':currnetSort['fullName'] == 'DESC' }"
                                        (click)="sortProjectList('fullName')"></i></p>
                            </div>
                        </th>
                        <th style="width: 15%">
                            <p>Customer<i class="icon icon-16 icon-title-down-arrow"
                                    [ngClass]="{'icon-title-down-arrow': currnetSort['companyName'] == 'ASC' || !currnetSort['companyName'],'icon-up-arrow':currnetSort['companyName'] == 'DESC' }"
                                    (click)="sortProjectList('companyName')"></i></p>
                        </th>
                        <th style="width: 20%">
                            <p>Email<i class="icon icon-16 icon-title-down-arrow"
                                    [ngClass]="{'icon-title-down-arrow': currnetSort['account'] == 'ASC' || !currnetSort['account'],'icon-up-arrow':currnetSort['account'] == 'DESC' }"
                                    (click)="sortProjectList('account')"></i></p>
                        </th>
                        <th style="width: 10%">
                            <p>Mobile<i class="icon icon-16 icon-title-down-arrow"
                                    [ngClass]="{'icon-title-down-arrow': currnetSort['phone'] == 'ASC' || !currnetSort['phone'],'icon-up-arrow':currnetSort['phone'] == 'DESC' }"
                                    (click)="sortProjectList('phone')"></i></p>
                        </th>
                        <th style="width: 20%">
                            <p>Date Joined<i class="icon icon-16 icon-title-down-arrow"
                                    [ngClass]="{'icon-title-down-arrow': currnetSort['createTime'] == 'ASC' || !currnetSort['createTime'],'icon-up-arrow':currnetSort['createTime'] == 'DESC' }"
                                    (click)="sortProjectList('createTime')"></i></p>
                        </th>
                        <th style="width: 10%">
                            <p>Status<i class="icon icon-16 icon-title-down-arrow"
                                    [ngClass]="{'icon-title-down-arrow': currnetSort['status'] == 'ASC' || !currnetSort['status'],'icon-up-arrow':currnetSort['status'] == 'DESC' }"
                                    (click)="sortProjectList('status')"></i></p>
                        </th>
                        <th style="width: 20%" *ngIf="userRole==1"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let user of teamUserPage.records">
                        <td class="id">
                            <div class="id-box">
                                <span class="icon icon-user-new icon-16"></span>
                                <div class="text-box">
                                    <h3 *ngIf="user.firstName && user.lastName" class="h3cursor">{{user.firstName + ' '
                                        + user.lastName}}</h3>
                                    <p>{{user.roleType | userRolePipe}}</p>
                                </div>
                            </div>
                        </td>
                        <td>
                            <p>{{user.companyName|| '-----'}}</p>
                        </td>
                        <td class="email">
                            <p>{{user.account|| '-----'}}</p>
                        </td>
                        <td class="phone">
                            <p>{{user.phone|| '-----'}}</p>
                        </td>
                        <td class="date">
                            <p>{{user.createTime | date:'dd MMM yyyy'}}</p>
                        </td>
                        <td>
                            <div>
                                <button class="btn"
                                    [ngClass]="{'btn-active': user.status=='Active','btn-retired':user.status=='Pending','btn-disabled':user.status=='Disabled'}">{{user.status}}</button>
                            </div>
                        </td>
                        <td class="button-item" *ngIf="userRole==1">
                            <a *ngIf="user.status=='Active' && user.selfFlag!=1" (click)="disableUser(user);">
                                <i class="icon icon-18 icon-prohibit"></i></a>
                            <a *ngIf="user.status=='Disabled' && user.selfFlag!=1" (click)="reactiveUser(user)">
                                <i class="icon icon-18 icon-back"></i></a>
                            <a *ngIf="user.status=='Pending' && user.selfFlag!=1" (click)="inviteEmail(user)">
                                <i class="icon icon-16 icon-email-new"></i></a>
                            <a (click)="editUser(user);"><i class="icon icon-16 icon-edit-new"></i></a>
                            <a *ngIf="user.status=='Pending' && user.selfFlag!=1" (click)="inviteCancel(user)">
                                <i class="icon icon-16 icon-delete"></i></a>
                        </td>
                    </tr>
                </tbody>
            </table>
            <ngb-pagination class="justify-content-center" *ngIf="teamUserPage.pages>1" [(page)]="teamUserPage.current"
                [boundaryLinks]="false" [pageSize]="teamUserPage.size" [maxSize]="5"
                (pageChange)="getUserListByPage(teamUserPage.current)" [collectionSize]="teamUserPage.total">
            </ngb-pagination>
        </div>
    </div>
</div>