<div class="container">
    <div class="top-bar">
        <h1>Projects</h1>
    </div>
    <div class="breadcrumb-nav">
        <ul>
            <li><a (click)="goBack()">Project</a> <span class="icon breadcrumb-right icon-16 xs-mr-8 xs-ml-8"></span></li>
            <li><a>Import</a></li>
        </ul>
    </div>
    <div class="import-content">
        <div class="import-info">
            <div class="img-box">
                <img src="assets/img/import-bg.png" alt="" srcset="">
            </div>
            <h2>We’ve got your leg work covered.</h2>
            <p>Import project information and get your team up and running in no time.</p>
            <p><a (click)="download()" href="javascript:void(0)">Download</a> .csv template to begin</p>
            <button [routerLink]="['/upload']" class="btn btn-add">Start Import</button>
        </div>
        <div *ngIf="list.length > 0">
            <div class="title">Past Imports</div>
            <div class="table upload-table">
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>New records</th>
                            <th>Status</th>
                            <th>User</th>
                            <th>Created</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let l of list">
                            <td>{{l.fileName}}</td>
                            <td>{{l.total}}</td>
                            <td>Import successful</td>
                            <td>{{l.user}}</td>
                            <td>{{l.createdAt | date:'dd/MM/yyyy HH:mm a'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>