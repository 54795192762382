export class Invoice {
    id: string;
    companyName: string;
    invoiceNum: string;
    product: string;
    quantity: number;
    unit: string;
    price: number;
    customerEmail: string;
    issueDate: Date;
    dueDate: Date;
    invoiceStatus: string;
    pdfUrl: string;
}

export  class InvoiceNumberVo {
    allNumber:number;
    invoiceDraftNum:number;
    invoiceOpenNum:number;
    invoicePaidNum:number;
    invoiceUncollectibleNum:number;
    invoiceVoidNum:number;
}
