<div class="container">
    <div class="top-bar">
        <div class="nav-left">
            <h1>Projects</h1>
        </div>
        <div class="right-bar">
            <div ngbDropdown>
                <button class="btn add" id="gfg" ngbDropdownToggle>Actions</button>
                <div ngbDropdownMenu="gfg">
                    <button ngbDropdownItem (click)="downloadScaffold()">Download List</button>
                </div>
            </div>
           
        </div>
    </div>
    <div class="breadcrumb-nav">
        <ul>
            <li><a (click)="goBack()">Project</a> <span class="icon breadcrumb-right icon-16 xs-mr-8 xs-ml-8"></span>
            </li>
            <li><a>{{projectName}}</a></li>
        </ul>
    </div>
    <div class="content">
        <div class="sub-head-box">
            <div class="botton-box">
                <div class="search gray-bg">
                    <input type="text" placeholder="Search by scaffolds, project name and project address..."
                        [(ngModel)]="condition.name" (keypress)="searchKeywordByPress($event)"
                        (blur)="getScaffoldList()">
                    <i class="icon-search"></i>
                </div>

                <!-- <div class="select-filter-content">
                    <div class="select-filter" (click)="showSelect($event)" >
                        <div class="box-name">{{selectText}}</div>
                        <img src="assets/img/icon-new/icon-caret-down.svg"  width="16px" height="16px"  alt="">
                    </div>
                    <div class="show-select" *ngIf="showSelectDialog" (click)="stopEvent($event)"  >
                        <div class="top-btn select-btn">
                            <span (click)="selectClick('all')">Select all</span>
                            <span (click)="selectClick('none')">Clear</span>
                        </div>
                        <div class="select-content content">
                            <div *ngFor="let option of projectList" class="select-item">
                                <input type="checkbox" [(ngModel)]="option.select"  (change)="changeSelect(option)" >
                                <span title="{{option.label}}">{{option.label}}</span>
                            </div>
                        </div>
                        <div class="bottom-btn select-btn">
                            <span (click)="changeDialog('cancel')">Cancel</span>
                            <span (click)="changeDialog('done')">Done</span>
                        </div>
                    </div>
                </div> -->
                <!--                <a href="javascript:void(0)" (click)="downloadScaffold()"><i class="icon icon-18 icon-export"></i>CSV</a>-->
            </div>


            <div class="filter-box">
                <div class="item" [ngClass]="{'active': condition.scaffoldStatus==null}"
                    (click)="statueSearchScaffoldList(null)">All ({{ScaffoldNumVo.allNumber}})
                </div>
                <div class="item" [ngClass]="{'active': condition.scaffoldStatus=='Installed'}"
                    (click)="statueSearchScaffoldList('Installed')">Installed ({{ScaffoldNumVo.installNum}})
                </div>
                <div class="item" [ngClass]="{'active': condition.scaffoldStatus=='Dismantled'}"
                    (click)="statueSearchScaffoldList('Dismantled')">Dismantled ({{ScaffoldNumVo.dismantleNum}})
                </div>
            </div>

        </div>
        <div class="table scaffold-table">
            <table>
                <thead>
                    <tr>
                        <th style="width: 10%">
                            <div class="id-box">
                                <!--                            <div class="check">-->
                                <!--                                <input class="icon-span" type="checkbox">-->
                                <!--                                <label></label>-->
                                <!--                            </div>-->

                                <p>Scaffolds <i class="icon icon-18 icon-title-down-arrow"
                                        [ngClass]="{'icon-title-down-arrow': currnetSort['scaffoldName'] == 'ASC' || !currnetSort['scaffoldName'],'icon-up-arrow':currnetSort['scaffoldName'] == 'DESC' }"
                                        (click)="sortScaffoldList('scaffoldName')"></i></p>
                            </div>
                        </th>
                        <th style="width: 15%">
                            <p>Project Name <i class="icon icon-18 icon-title-down-arrow"
                                    [ngClass]="{'icon-title-down-arrow': currnetSort['projectName'] == 'ASC' || !currnetSort['projectName'],'icon-up-arrow':currnetSort['projectName'] == 'DESC' }"
                                    (click)="sortScaffoldList('projectName')"></i></p>
                        </th>
                        <th style="width: 13%">
                            <p>Project Address <i class="icon icon-18 icon-title-down-arrow"
                                    [ngClass]="{'icon-title-down-arrow': currnetSort['projectAddress'] == 'ASC' || !currnetSort['projectAddress'],'icon-up-arrow':currnetSort['projectAddress'] == 'DESC' }"
                                    (click)="sortScaffoldList('projectAddress')"></i></p>
                        </th>
                        <th style="width: 13%">
                            <p>Last Inspected<i class="icon icon-18 icon-title-down-arrow"
                                    [ngClass]="{'icon-title-down-arrow': currnetSort['lastInspectionDate'] == 'ASC' || !currnetSort['lastInspectionDate'],'icon-up-arrow':currnetSort['lastInspectionDate'] == 'DESC' }"
                                    (click)="sortScaffoldList('lastInspectionDate')"></i></p>
                        </th>
                        <th style="width: 13%">
                            <p>Date Erected <i class="iconicon  icon-18 icon-title-down-arrow"
                                    [ngClass]="{'icon-title-down-arrow': currnetSort['erectedDate'] == 'ASC' || !currnetSort['erectedDate'],'icon-up-arrow':currnetSort['erectedDate'] == 'DESC' }"
                                    (click)="sortScaffoldList('erectedDate')"></i></p>
                        </th>
                        <th style="width: 13%">
                            <p>Expires In <i class="iconicon  icon-18 icon-title-down-arrow"
                                    [ngClass]="{'icon-title-down-arrow': currnetSort['expireDay'] == 'ASC' || !currnetSort['expireDay'],'icon-up-arrow':currnetSort['expireDay'] == 'DESC' }"
                                    (click)="sortScaffoldList('expireDay')"></i></p>
                        </th>
                        <th style="width: 13%">
                            <p>Duty Category<i class="iconicon  icon-18 icon-title-down-arrow"
                                    [ngClass]="{'icon-title-down-arrow': currnetSort['grade'] == 'ASC' || !currnetSort['grade'],'icon-up-arrow':currnetSort['grade'] == 'DESC' }"
                                    (click)="sortScaffoldList('grade')"></i>
                            </p>
                        </th>
                        <th style="width: 10%">
                            <p>Status<i class="iconicon  icon-18 icon-title-down-arrow"
                                    [ngClass]="{'icon-title-down-arrow': currnetSort['state'] == 'ASC' || !currnetSort['state'],'icon-up-arrow':currnetSort['state'] == 'DESC' }"
                                    (click)="sortScaffoldList('state')"></i></p>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let scaffold of scaffoldPage.records">
                        <td class="id">
                            <div class="id-box">
                                <!--                            <div class="check">-->
                                <!--                                <input type="checkbox" class="checkbox" class="icon-span">-->
                                <!--                                <label></label>-->
                                <!--                            </div>-->
                                <p><a href="javascript:void(0)" (click)="goTagInspection(scaffold)"
                                        title="{{scaffold.scaffoldName}}">{{scaffold.scaffoldName || '-----'}}</a></p>
                            </div>
                        </td>
                        <td class="name">
                            <p title="{{scaffold.projectName }}">{{scaffold.projectName|| '-----'}}</p>
                        </td>
                        <td class="address">
                            <p title="{{scaffold.projectAddress}}">{{scaffold.projectAddress || '-----'}}</p>
                        </td>
                        <td class="last-inspected">
                            <p *ngIf="scaffold.lastInspectionDate">{{scaffold.lastInspectionDate | date:'dd MMM yyyy'}}
                            </p>
                            <p class="empty-ellipsis" *ngIf="!scaffold.lastInspectionDate">-----</p>
                        </td>
                        <td class="date-erected">
                            <p *ngIf="scaffold.erectedDate">{{scaffold.erectedDate | date:'dd MMM yyyy'}} </p>
                            <p class="empty-ellipsis" *ngIf="!scaffold.erectedDate">-----</p>
                        </td>
                        <td class="expires">
                            <p
                                [ngClass]="{'green': scaffold.expireDay>=7,'yellow':scaffold.expireDay<7 && scaffold.expireDay>0,'red': scaffold.expireDay<=0}">
                                {{scaffold.expireDay}} days</p>
                        </td>
                        <td class="grade">
                            <p>{{scaffold.grade || '-----'}}</p>
                        </td>
                        <td>
                            <div>
                                <button class="btn"
                                    [ngClass]="{'btn-active': scaffold.state=='Installed','btn-retired':scaffold.state=='Dismantled','btn-disabled':scaffold.state=='Expired'}">
                                    {{scaffold.state | statePipe}}</button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <ngb-pagination class="justify-content-center" *ngIf="scaffoldPage.pages>1" [(page)]="scaffoldPage.current"
                [pageSize]="scaffoldPage.size" [maxSize]="5" [boundaryLinks]="false"
                (pageChange)="getScaffoldListByPage(scaffoldPage.current)" [collectionSize]="scaffoldPage.total">
            </ngb-pagination>
        </div>
    </div>
</div>