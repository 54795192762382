import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs/internal/Observable';
import {Branch} from '../observable/model/branch';
import { PageCondition } from '../observable/model/condition';

@Injectable({
    providedIn: 'root'
})
export class BranchesRepository {

    constructor(private http: HttpClient) {
    }
    public getBranchList(condition: PageCondition): Observable<any> {
        return this.http.post<any>(environment.baseURL + `/pc/branch/list`,condition);
    }

    public saveBranch(branch: Branch): Observable<any> {
        return this.http.post<any>(environment.baseURL + `/pc/branch/save`, branch);
    }

    public getBranchNumber(): Observable<any> {
        return this.http.post<any>(environment.baseURL + `/pc/branch/list/number`,{});
    }


}
