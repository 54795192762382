import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {SubscriptionRepository} from '../../../repository/subscription-repository';
import {Subscription} from '../../../observable/model/subscription';
import {ToastRepository} from '../../../repository/toast-repository';

@Component({
    selector: 'app-cancel-subscription',
    templateUrl: './cancel-order.component.html',
    styleUrls: ['./cancel-order.component.less']
})
export class CancelOrderComponent implements OnInit {

    subscription: Subscription;

    constructor(
        private ngbActiveModal: NgbActiveModal,
        private subscriptionRepository: SubscriptionRepository,
        private toastRepository: ToastRepository
    ) {
    }

    ngOnInit(): void {
    }

    confirm(): void {
        // this.toastRepository.showLoading(true);
        // this.subscriptionRepository.cancel(this.subscription.subscriptionId).subscribe(result => {
        //     this.toastRepository.showLoading(false);
        //     this.cancel();
        // });
        this.ngbActiveModal.close();
    }

    cancel(): void {
        this.ngbActiveModal.close({type:'1'});
    }
}
