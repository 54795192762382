import {Component, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {EmailCodeComponent} from '../email-code/email-code.component';

@Component({
    selector: 'app-enter-new-email',
    templateUrl: './enter-new-email.component.html',
    styleUrls: ['./enter-new-email.component.less']
})
export class EnterNewEmailComponent implements OnInit {

    email: string;

    constructor(private ngbActiveModal: NgbActiveModal, private ngbModal: NgbModal) {
    }

    ngOnInit(): void {
    }

    send(): void {
        const ngbModalRef = this.ngbModal.open(EmailCodeComponent, {backdrop: 'static', keyboard: true, size: 'md'});
        ngbModalRef.componentInstance.account = this.email;
        ngbModalRef.componentInstance.reset = true;
        ngbModalRef.result.then(result => {
            this.cancel();
        });
    }

    cancel(): void {
        this.ngbActiveModal.close();
    }
}
