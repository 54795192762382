import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DisableStaffComponent } from '../modal/disable-staff/disable-staff.component';
import { EditStaffComponent } from '../modal/edit-staff/edit-staff.component';
import { InvitationEmailComponent } from '../modal/invitation-email/invitation-email.component';
import { ReactiveStaffComponent } from '../modal/reactive-staff/reactive-staff.component';
import { Page } from '../../observable/model/base';
import { PageCondition } from '../../observable/model/condition';
import { JWTUser, TeamNumberInfo, TeamUser } from '../../observable/model/user';
import { UserRepository } from '../../repository/user-repository';
import { ToastRepository } from '../../repository/toast-repository';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CancelInviteComponent } from '../modal/cancel-invite/cancel-invite.component';

@Component({
    selector: 'app-team',
    templateUrl: './team.component.html',
    styleUrls: ['./team.component.less']
})
export class TeamComponent implements OnInit {

    teamUserPage: Page<TeamUser> = new Page<TeamUser>();
    teamNumberInfo = new TeamNumberInfo();
    condition = new PageCondition('', 10);
    currnetSort = new Map();
    userRole: number;
    screenHeight = document.body.clientHeight;
    constructor(private ngbModal: NgbModal,
        private userRepository: UserRepository,
        public toastRepository: ToastRepository,
        private jwtHelperService: JwtHelperService
    ) {
    }

    ngOnInit(): void {
        // this.condition.sortOrder = 'DESC';
        var freeTrial = document.getElementsByClassName("fourteen-tip");
        let hideHeight = freeTrial && freeTrial.length > 0 ? 80 : 0;
        this.condition.size = Math.trunc((this.screenHeight - 72 - 99 - 98 - 46 - hideHeight) / 75) - 1;
        this.init();
    }

    init(): void {
        this.getUserList();
        const accessToken = localStorage.getItem('accessToken');
        const jwtUser = this.jwtHelperService.decodeToken(accessToken).inf as JWTUser;
        this.userRole = jwtUser.role;
    }
    getUserNumber(): void {
        this.userRepository.getTeamNumber().subscribe(result => {
            this.teamNumberInfo = result;
            this.teamNumberInfo.allNumber = result.teamActiveNum + result.teamPendingNum + result.teamDisabledNum;
        });
    }
    getUserList(): void {
        this.getUserNumber();
        this.userRepository.list(this.condition).subscribe(result => {
            this.teamUserPage = result;
        });
    }

    getUserListByPage(current: number): void {
        this.condition.current = current;
        this.getUserList();
    }

    sortProjectList(sortName: string): void {
        this.condition.sortName = sortName;
        this.condition.sortOrder = this.condition.sortOrder == 'ASC' ? 'DESC' : 'ASC';
        this.getUserList();
        this.currnetSort = new Map();
        this.currnetSort[sortName] = this.condition.sortOrder;
    }
    statueSearchUserList(userStatus: string): void {
        this.condition.userStatus = userStatus;
        this.condition.current = 1;
        this.getUserList();
    }

    searchKeywordByPress($event: KeyboardEvent): void {
        if ($event.key != 'Enter') {
            return;
        }
        this.getUserList();
    }


    disableUser(user: TeamUser): void {
        const copy = { ...user };
        copy.status = 'Disabled';
        const ngbModalRef = this.ngbModal.open(DisableStaffComponent, { backdrop: 'static', keyboard: true });
        ngbModalRef.componentInstance.user = copy;
        ngbModalRef.result.then(() => {
            this.getUserList();
        }).catch(() => {
            this.getUserList();
        });
    }

    reactiveUser(user: TeamUser): void {
        const copy = { ...user };
        copy.status = 'Active';
        const ngbModalRef = this.ngbModal.open(ReactiveStaffComponent, {
            backdrop: 'static',
            keyboard: true,
            size: 'md'
        });
        ngbModalRef.componentInstance.user = copy;
        ngbModalRef.result.then(() => {
            this.getUserList();
        }).catch(() => {
            this.getUserList();
        });
    }

    editUser(user: TeamUser): void {
        const copy = { ...user };
        const ngbModalRef = this.ngbModal.open(EditStaffComponent, { backdrop: 'static', keyboard: true });
        ngbModalRef.componentInstance.user = copy;
        ngbModalRef.result.then(() => {
            this.getUserList();
        }).catch(() => {
            this.getUserList();
        });
    }


    inviteEmail(user: TeamUser): void {
        this.userRepository.reInvite(user).subscribe(result => {
            if (!result) {
                this.toastRepository.showSuccess('Invitation sent successfully.');
            } else {
                this.toastRepository.showDanger('Oops! Something went wrong, please try again.');
            }
        });
    }

    invite(): void {
        const ngbModalRef = this.ngbModal.open(InvitationEmailComponent, {
            backdrop: 'static',
            keyboard: true,
            size: 'sm'
        });
        ngbModalRef.result.then(() => {
            this.getUserList();
        }).catch(() => {
            this.getUserList();
        });
    }

    inviteCancel(user: TeamUser): void {
        const ngbModalRef = this.ngbModal.open(CancelInviteComponent, {
            backdrop: 'static',
            keyboard: true,
            size: 'sm'
        });
        ngbModalRef.componentInstance.user = { ...user };
        ngbModalRef.result.then(() => {
            this.getUserList();
        }).catch(() => {
            this.getUserList();
        });
    }
}
