import {Component, OnInit} from '@angular/core';
import {NgxValidatorConfig} from '@why520crazy/ngx-validator';
import {RegisterInfo} from './sign-up-model/register-info';
import {Observable, of} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {AgreementUserComponent} from '../../modal/agreement-user/agreement-user.component';
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';


@Component({
    selector: 'app-signup',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.less']
})


export class SignupComponent implements OnInit {
    showPassword :boolean;
    showConfirmPassword :boolean;
    public registerInfo = new RegisterInfo();

    constructor(private router: Router, private  activatedRoute: ActivatedRoute, private ngbModal: NgbModal) {
        const personalInformation = activatedRoute.snapshot.queryParamMap.get("personalInformation");
        if (personalInformation != null) {
            try {
                this.registerInfo = JSON.parse(decodeURIComponent(personalInformation));
            } catch (e) {
                this.registerInfo = new RegisterInfo();
            }
        }
    }
    ngOnInit(): void {
        this.showPassword = false;
        this.showConfirmPassword = false;
    }

    validatorConfig: NgxValidatorConfig = {
        validationMessages: {
            firstName: {
                required: 'First name is required.',
            },
            lastName: {
                required: 'Last name is required.',
            },
            email: {
                required: 'Email is required.',
                email: 'Please type right email.',
            },
            password: {
                required: 'password is required.',
                // pattern: 'Password must be contain digit, lowercase character, uppercase character. and least 8 characters in length, but no more than 32'
                maxlength: 'Password length must be greater than 8, but no more then 32.',
                minlength: 'Password length must be greater than 8, but no more then 32.'

            },
            confirmPassword: {
                required: 'Confirm Password is required.',
                // pattern: 'Password must be contain digit, lowercase character, uppercase character. and least 8 characters in length, but no more than 32',
                ngxUniqueCheck: 'Confirm password must equal to password.',
                maxlength: 'Password length must be greater than 8, but no more then 32.',
                minlength: 'Password length must be greater than 8, but no more then 32.'
            },
            trcheck: {
                required: 'Please read and agree with terms & conditions.'
            },
        },
        validateOn: 'submit'
    };

    signUp() {
        const personalInformation: String = encodeURIComponent(JSON.stringify(this.registerInfo));
        this.router.navigate(["signup-tagged"], {queryParams: {personalInformation: personalInformation}});
    }

    passwordEqual = (value): Observable<boolean> => {
        return of(this.registerInfo.password != value)
    }


    openTerms(){
        const ngbModalRef = this.ngbModal.open(AgreementUserComponent,
          {backdrop: 'static', keyboard: true, size: 'big'});
        ngbModalRef.componentInstance.type = '1';
        ngbModalRef.result.then(result => {
            console.log(result,'result');
        });
    }
    openPolicy(){
        const ngbModalRef = this.ngbModal.open(AgreementUserComponent, {backdrop: 'static', keyboard: true, size: 'big'});
        ngbModalRef.componentInstance.type = '2';
        ngbModalRef.result.then(result => {
            console.log(result,'result');
        });
    }
    changePassword(val){
        this.showPassword =  val == 'show' ? true :false;
    }
    changeConfirmPassword(val){
        this.showConfirmPassword =  val == 'show' ? true :false;
    }
    capitalizeFirst(value: string): string {
        if (!value) return value;
        return value.charAt(0).toUpperCase() + value.slice(1);
      }
}
