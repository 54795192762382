<div class="admin-wrapper">
    <div class="admin">
        <div class="admin-left">
            <div class="logo"></div>
            <div class="bg-box"></div>
        </div>
        <div class="admin-right">
            <div class="text-box">
                <form class="form" name="loginForm">
                    <div class="back-top" (click)="geToBack()"> <span class="icon icon-24 icon-arrow-back"></span>Login</div>
                    <h2 class="title">Set New Password</h2>
                    <div class="subtitle">Enter the new password for your account.</div>
                    <div class="form-control-group form-icon">
<!--                        <label class="control-label" for="newPassword">Password</label>-->
                        <input class="erro"  name="newPassword" type="password"
                               [(ngModel)]="userForgot.newPassword" required="required" placeholder="Enter password" *ngIf="!showPassword">
                        <input class="erro"  name="newPassword" type="text"
                               [(ngModel)]="userForgot.newPassword" required="required" placeholder="Enter password" *ngIf="showPassword">
                        <span class="icon icon-16 icon-password icon-first-pic"></span>
                        <span class="icon icon-16 icon-eye-close icon-right-pic" [hidden]="showPassword" (click)="changePassword('show')"></span>
                        <span class="icon icon-16 icon-eye-open icon-right-pic" [hidden]="!showPassword" (click)="changePassword('hide')"></span>
                    </div>
                    <div class="form-control-group form-icon mb-40">
                        <input class="erro"  name="confirmNewPassword" type="password"
                               [(ngModel)]="userForgot.confirmNewPassword" required="required" placeholder="Enter password" *ngIf="!showConfirmPassword">
                        <input class="erro"  name="confirmNewPassword" type="text"
                               [(ngModel)]="userForgot.confirmNewPassword" required="required" placeholder="Enter password" *ngIf="showConfirmPassword">
                        <span class="icon icon-16 icon-password icon-first-pic"></span>
                        <span class="icon icon-16 icon-eye-close icon-right-pic" [hidden]="showConfirmPassword" (click)="changeConfirmPassword('show')"></span>
                        <span class="icon icon-16 icon-eye-open icon-right-pic" [hidden]="!showConfirmPassword" (click)="changeConfirmPassword('hide')"></span>
                    </div>
                    <button class="btn btn-o btn-block" (click)="confirm()"
                            [disabled]="!userForgot.newPassword || !userForgot.confirmNewPassword">Confirm
                    </button>
                </form>
            </div>
        </div>
    </div>
</div>
