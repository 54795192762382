import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription, timer } from "rxjs";
import { map } from "rxjs/operators";
import { TeamUser, UserForgot } from '../../../observable/model/user';
import { UserRepository } from "../../../repository/user-repository";
import { RegisterInfo } from '../signup/sign-up-model/register-info';
import { RegisterStep } from 'src/app/enum/register-step.enum';

@Component({
    selector: 'app-signup-code',
    templateUrl: './signup-code.component.html',
    styleUrls: ['./signup-code.component.less']
})
export class SignupCodeComponent implements OnInit, AfterViewInit {

    verifyCode = '';

    account?: string;
    sourceType?: string;
    token: string;
    fromType: string;

    canResend = true;


    displayText: string;

    displayTextSubscription: Subscription;
    public registerInfo = new RegisterInfo();
    public userForgot = new UserForgot();

    constructor(private router: Router, public activatedRoute: ActivatedRoute, public userRepository: UserRepository) {
        const personalInformation = activatedRoute.snapshot.queryParamMap.get('personalInformation');
        this.account = activatedRoute.snapshot.queryParamMap.get("account");
        this.sourceType = activatedRoute.snapshot.queryParamMap.get("sourceType");
        this.token = activatedRoute.snapshot.queryParamMap.get("token");
        this.fromType = activatedRoute.snapshot.queryParamMap.get("fromType");
        try {
            this.registerInfo = JSON.parse(decodeURIComponent(personalInformation));
        } catch (e) {

        }
        this.startTimer();
    }

    back(): void {
        if (this.fromType == 'forgot') {
            this.router.navigate(['forgot'], { queryParams: { account: this.account, token: this.token } });
        } else {
            const personalInformation: String = encodeURIComponent(JSON.stringify(this.registerInfo));
            this.router.navigate(['signup-tagged'], { queryParams: { personalInformation: personalInformation } });
        }
    }
    startTimer(): void {
        this.displayTextSubscription = timer(0, 1000).pipe(
            map(time => 60 - time)).subscribe(time => {
                if (time == 0) {
                    this.displayText = 'Resend code';
                    this.displayTextSubscription.unsubscribe();
                } else {
                    this.displayText = `Resend code in ${time} s`;
                }
            });
    }

    ngOnInit(): void {
        if (this.sourceType && this.sourceType == '1') {
            let teamUser = new TeamUser();
            teamUser.account = this.account;
            this.userRepository.getUserStatusByAccount(teamUser).subscribe(result => {
                if (result == 'Active') {
                    this.router.navigate(['/login']);
                }
            });
        }
        // this.account = 'james@arcadedevhouse.com.au';
    }


    ngAfterViewInit(): void {
    }

    back2Login() {
        this.router.navigate(['login']);
    }

    resend(): void {
        if (this.account == null) {
            return;
        }
        if (!this.displayTextSubscription.closed) {
            return;
        }
        this.startTimer();
        if (this.fromType == 'signup') {
            this.userRepository.resend(this.account).subscribe(() => {
            });
        }
        if (this.fromType == 'forgot') {
            this.userForgot.account = this.account;
            this.userRepository.sendForgotEmail(this.userForgot).subscribe((token) => {
                this.token = token;
            });
        }
    }

    verify(): void {
        if (this.verifyCode.length < 1) {
            return;
        }
        if (this.fromType == 'signup') {
            this.userRepository.verify(this.verifyCode, this.account).subscribe((accessToken: string) => {
                localStorage.setItem("accessToken", accessToken);
                this.userRepository.registerStep(RegisterStep.ValidateCode).subscribe(v => { });
                this.router.navigate(['/signup-free-tags']);
            });
        }
        if (this.fromType == 'forgot') {
            this.userForgot.account = this.account;
            this.userForgot.token = this.token;
            this.userForgot.code = this.verifyCode;
            this.userRepository.validateForgotCode(this.userForgot).subscribe(() => {
                this.router.navigate(['reset-password'], {
                    queryParams: {
                        account: this.userForgot.account,
                        token: this.userForgot.token,
                    }
                });
            });
        }
    }

    numberKey($event: KeyboardEvent) {
        let codeName = $event.code;
        let ctrlKey = $event.ctrlKey;
        return codeName.startsWith('Digit') || codeName == 'Backspace' || codeName == 'KeyV' || codeName == 'ControlLeft' || codeName == 'MetaLeft' || codeName == 'Enter' || codeName.startsWith('Numpad');
    }
}
