export class RegisterInfo {

    firstName?: string;

    lastName?: string;

    email?: string;

    password?: string;

    confirmPassword?: string;

    mobile?: string;

    companyName?: string;

    trcheck?: boolean;

    region?: string;

}
