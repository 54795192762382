import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { JWTUser } from 'src/app/observable/model/user';
import { HandoverCertificatesRepository } from 'src/app/repository/handover-certificates-repository';
import { environment } from '../../../environments/environment';
import { Page } from '../../observable/model/base';
import { Condition, PageCondition } from '../../observable/model/condition';
import { SelectStringCV } from '../../observable/model/selectVo';
import { Inspection, Tag, TagNumberInfo } from '../../observable/model/tag-inspection';
import { ProjectRepository } from '../../repository/project-repository';
import { ScaffoldRepository } from '../../repository/scaffold-repository';
import { TagInspectionRepository } from '../../repository/tag-inspection-repository';

import { JwtHelperService } from '@auth0/angular-jwt';
import { ToastRepository } from 'src/app/repository/toast-repository';
@Component({
    selector: 'app-tag-inspection',
    templateUrl: './tag-inspection.component.html',
    styleUrls: ['./tag-inspection.component.less']
})
export class TagInspectionComponent implements OnInit {
    
    tagPage: Page<Tag> = new Page<Tag>();
    inspectionPage: Page<Inspection> = new Page<Inspection>();
    condition = new PageCondition('', 10);
    inspectionTabName = 'inspection';
    tagTabName = 'tag';
    selectTabType = this.tagTabName;
    currentSort = new Map();
    tagNumberInfo = new TagNumberInfo();
    projectList: Array<SelectStringCV>;
    selectProjects: Array<string> = new Array<string>();
    scaffoldList: Array<SelectStringCV>;
    selectScaffolds: Array<string> = new Array<string>();
    firstLoad: boolean;
    selectText: string;
    selectScaffoldsText: string;
    placeHolderText: string;
    showSelectProject: boolean;
    showSelectScaffold: boolean;
    screenHeight = document.body.clientHeight;
    projectName: string;
    scaffoldName: string;
    currentCompanyId: string;
    handoverCertificatesId = '';
    jwtUser: JWTUser;
    constructor(
        private scaffoldRepository: ScaffoldRepository,
        private projectRepository: ProjectRepository,
        private renderer: Renderer2,
        private tagInspectionRepository: TagInspectionRepository,
        private route: ActivatedRoute,
        private handoverCertificatesRepository: HandoverCertificatesRepository,
        private jwtHelperService: JwtHelperService,
        private toastRepository: ToastRepository,
        
        
    ) {
        this.route.queryParams.subscribe(params => {
            this.projectName = params.projectName;
            this.scaffoldName = params.scaffoldName;
        });

        this.renderer.listen('window', 'click', (e: Event) => {
            if (this.showSelectProject) {
                this.showSelectProject = false;
                if (this.projectList.length > 0) {
                    this.projectList.forEach(it => {
                        it['select'] = this.selectProjects.includes(it.value)
                    })
                }
            }
            if (this.showSelectScaffold) {
                this.showSelectScaffold = false;
                if (this.scaffoldList.length > 0) {
                    this.scaffoldList.forEach(it => {
                        it['select'] = this.selectScaffolds.includes(it.value)
                    })
                }
            }
        });
    }

    ngOnInit(): void {
        const accessToken = localStorage.getItem('accessToken');
        this.jwtUser = this.jwtHelperService.decodeToken(accessToken).inf as JWTUser;
          console.log(this.jwtUser.role,"rolerolerolerolerole");
          
        this.currentCompanyId = this.jwtUser.companyId;
        var freeTrial = document.getElementsByClassName("fourteen-tip");
        let hideHeight = freeTrial && freeTrial.length > 0 ? 80 : 0;
        this.condition.size = Math.trunc((this.screenHeight - 72 - 99 - 98 - 46 - hideHeight) / 88) - 1;
        this.selectScaffolds.push(this.route.snapshot.paramMap.get('scaffoldId'));
        this.firstLoad = true;
        this.route.queryParams.subscribe(params => {
            this.selectProjects.push(params.projectId);
        });
        this.init();
    }

    init(): void {
        this.loadParamProject();

        this.loadParamScaffold();

        this.getTagInspectionList();
        
        this.getHandoverCertificatesId();
    }
    getTagNumber(): void {
        if (this.selectTabType == this.tagTabName) {
            this.placeHolderText = 'Search by tag ID, project and scaffold name...'
            this.tagInspectionRepository.getTagInspectionNumVo(this.condition).subscribe(result => {
                this.tagNumberInfo = result;
                this.tagNumberInfo.allNumber = result.tagActiveNum + result.tagReplacedLostNum + result.tagInactiveNum;
            });
        } else {
            this.placeHolderText = 'Search by ID, project name, scaffold name and tag ID...'
            this.tagInspectionRepository.getInspectionNumVo(this.condition).subscribe(result => {
                this.tagNumberInfo = result;
                this.tagNumberInfo.allNumber = result.inspectionSafeNum + result.inspectionUnsafeNum + result.tagInactiveNum;
            });
        }
    }

    loadParamProject(): void {
        this.projectRepository.listParam().subscribe(result => {
            this.projectList = result;
            // this.projectList.unshift({label: 'All projects', value: ''});
            this.projectList.forEach(it => {
                it['select'] = this.selectProjects.includes(it.value)
            })
            this.watchSelect('project');
        });
    }

    stopEvent(event: Event) {
        event.stopPropagation();
    }
    showSelect(event: Event, type: string): void {
        event.stopPropagation();
        if (type == 'project') {
            this.showSelectProject = !this.showSelectProject
            if (this.showSelectProject) {
                this.showSelectScaffold = false
            }
        } else {
            this.showSelectScaffold = !this.showSelectScaffold
            if (this.showSelectScaffold) {
                this.showSelectProject = false
            }
        }

    }
    changeDialog(value: string, type: string): void {
        this.showSelectProject = false
        this.showSelectScaffold = false
        if (value == 'done') {
            if (type == 'project') {
                this.selectProjects = [];
                this.projectList.forEach(p => {
                    if (p.select) {
                        this.selectProjects.push(p.value)
                    }
                });
                this.loadParamScaffold();
            } else {
                this.selectScaffolds = [];
                this.scaffoldList.forEach(p => {
                    if (p.select) {
                        this.selectScaffolds.push(p.value)
                    }
                });
                this.getTagInspectionList();
            }
            this.watchSelect(type);
        } else {
            if (this.projectList.length > 0) {
                this.projectList.forEach(it => {
                    it['select'] = this.selectProjects.includes(it.value)
                })
            }
            if (this.scaffoldList.length > 0) {
                this.scaffoldList.forEach(it => {
                    it['select'] = this.selectScaffolds.includes(it.value)
                })
            }
        }
    }
    selectClick(value: string, type: string): void {
        let bool = value == 'all' ? true : false;
        if (type == 'project') {
            this.projectList.forEach(it => {
                it['select'] = bool
            })
        } else {
            this.scaffoldList.forEach(it => {
                it['select'] = bool
            })
        }

    }

    watchSelect(type: string) {
        if (type == 'project') {
            if (this.selectProjects.length == this.projectList.length) {
                this.selectText = 'all projects'
            } else {
                let text = this.selectProjects.length > 1 ? ' projects ' : ' project '
                this.selectText = this.selectProjects.length + text + 'selected'
            }
        } else {
            if (this.scaffoldList.length == this.selectScaffolds.length) {
                this.selectScaffoldsText = 'all scaffolds'
            } else {
                let text = this.selectScaffolds.length > 1 ? ' scaffolds ' : ' scaffold '
                this.selectScaffoldsText = this.selectScaffolds.length + text + 'selected'
            }
        }

    }
    loadParamScaffold(): void {
        const scaffoldCondition = new Condition();
        scaffoldCondition.projectIds = this.selectProjects;
        if (this.selectProjects && this.selectProjects.length == 0) {
            this.selectScaffolds = new Array<string>();
        }
        this.firstLoad = false;
        this.scaffoldRepository.listParam(scaffoldCondition).subscribe(result => {
            this.scaffoldList = result;
            // this.scaffoldList.unshift({label: 'All scaffolds', value: ''});
            this.scaffoldList.forEach(it => {
                it['select'] = this.selectScaffolds.includes(it.value)
            })
            this.watchSelect('scaffold');
        });
    }

    getTagInspectionList(): void {
        if (this.selectProjects.length == 0) {
            this.condition.scaffoldIds = new Array<string>();
        } else {
            this.condition.scaffoldIds = this.selectScaffolds;
        }
        this.condition.projectIds = this.selectProjects;
        if (this.selectTabType == this.tagTabName) {
            this.tagInspectionRepository.listTag(this.condition).subscribe(result => {
                this.tagPage = result;
            });
        } else {
            this.tagInspectionRepository.listInspection(this.condition).subscribe(result => {
                if (result.records && result.records.length > 0) {
                    result.records.forEach(it => {
                        if (!it.outCome) {
                            it.outCome = '-----';   
                        }  
                    });
                }
                this.inspectionPage = result;

            });
        }
        this.getTagNumber();
    }

    statueSearchInspectionList(inspectionStatus: string): void {
        this.condition.inspectionStatus = inspectionStatus;
        this.condition.current = 1;
        this.getTagInspectionList();
    }

    statueSearchTagList(tagState: string): void {
        this.condition.tagStatus = tagState;
        this.condition.current = 1;
        this.getTagInspectionList();
    }

    sortTagInspectionList(sortName: string): void {
        this.condition.sortName = sortName;
        this.condition.sortOrder = this.condition.sortOrder == 'ASC' ? 'DESC' : 'ASC';
        this.getTagInspectionList();
        this.currentSort = new Map();
        this.currentSort[sortName] = this.condition.sortOrder;

    }


    changeTagInspectionTab(tabType: string): void {
        this.selectTabType = tabType;
        this.condition.inspectionStatus = null;
        this.condition.tagStatus = null;
        this.condition.current = 1;
        this.condition.sortName = '';
        this.getTagInspectionList();
    }

    getTagInspectionListByPage(current: number): void {
        this.condition.current = current;
        this.getTagInspectionList();
    }

    searchKeywordByPress($event: KeyboardEvent): void {
        if ($event.key != 'Enter') {
            return;
        }
        this.getTagInspectionList();
    }

    goBack(): void {
        history.back();
    }

    addProjectSelectItem(value: string): void {
        if (value == '') {
            //勾选了全部
            this.selectProjects = this.projectList.map(p => p.value);
        } else {
            //最后一个时也要勾选全部
            if (this.selectProjects.length == this.projectList.length - 1) {
                this.selectProjects = this.projectList.map(p => p.value);
            }
        }
        this.loadParamScaffold();
    }

    removeProjectSelectItem(object: any): void {
        if (object.value == '') {
            this.selectProjects = new Array<string>();
        } else {
            this.selectProjects = this.selectProjects.filter(p => p != '');
        }
        this.loadParamScaffold();
    }

    addScaffoldSelectItem(value: string): void {
        if (value == '') {
            //勾选了全部
            this.selectScaffolds = this.scaffoldList.map(p => p.value);
        } else {
            //最后一个时也要勾选全部
            if (this.selectScaffolds.length == this.scaffoldList.length - 1) {
                this.selectScaffolds = this.scaffoldList.map(p => p.value);
            }
        }
        this.getTagInspectionList();
    }

    removeScaffoldSelectItem(object: any): void {
        if (object.value == '') {
            this.selectScaffolds = new Array<string>();
        } else {
            this.selectScaffolds = this.selectScaffolds.filter(p => p != '');
        }
        this.getTagInspectionList();
    }

    downloadTagInspection() {
        if (this.selectTabType == this.tagTabName) {
            this.tagInspectionRepository
                .downLoadTag(this.condition)
                .subscribe(blob => {
                    const a = document.createElement('a')
                    const objectUrl = URL.createObjectURL(blob)
                    a.href = objectUrl
                    a.download = "Tag List";
                    a.click();
                    URL.revokeObjectURL(objectUrl);
                });
        } else {
            this.tagInspectionRepository
                .downLoadInspection(this.condition)
                .subscribe(blob => {
                    const a = document.createElement('a')
                    const objectUrl = URL.createObjectURL(blob)
                    a.href = objectUrl
                    a.download = "Inspection List";
                    a.click();
                    URL.revokeObjectURL(objectUrl);
                });
        }


    }


    getHandoverCertificatesId(): void{
        this.handoverCertificatesRepository.getHandoverCertificate().subscribe(res => {
            if(res){
             this.handoverCertificatesId = res.id;
            }   
        })
       }
    downloadHandoverCertificate (inspectionId:string){
        if(this.handoverCertificatesId){
            window.open(`${environment.baseURL}/pc/handoverCertificate/download/${inspectionId}`, '_blank');
        }else{
            this.toastRepository.showDanger('Download failed. Please complete the configuration of the Handover Certificate in Settings, and then try again.');
        }
    }
    
}
