import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.less']
})
export class StepComponent implements OnInit {
  @Input() stepsData:any;
  @Input() active:string;
  constructor() { }

  ngOnInit(): void {
    if(!!!this.active){
      this.active="1";
    }
  }

}
