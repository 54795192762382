import {SafetyEnum} from '../../enum/safety.enum';
import {StateEnum} from '../../enum/state.enum';
import {Moment} from 'moment';


export class Scaffold {
    id: string;
    scaffoldName: string;
    projectName: string;
    projectId: string;
    projectAddress: string;
    lastInspectionDate: Date;
    erectedDate: Date;
    expireDay: number;
    grade: string;
    state: string;
}

export class ScaffoldInfo {
    projectId: string;

    name: string;

    description: string;

    erectionDate: Moment;

    duty: string;

    safety: SafetyEnum;

    state: StateEnum;

    lastInspectionDate: Moment;

    lastInspector: string;

    imageId: string;
}


export class ScaffoldNumVo {
    installNum: number;
    dismantleNum: number;
    allNumber: number;
}
