import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Page } from '../../observable/model/base';
import { PageCondition } from '../../observable/model/condition';
import { Project, ProjectNumVo } from '../../observable/model/project';
import { ProjectRepository } from '../../repository/project-repository';
import { JWTUser } from 'src/app/observable/model/user';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddProjectComponent } from '../modal/add-project/add-project.component';
@Component({
    selector: 'app-project',
    templateUrl: './project.component.html',
    styleUrls: ['./project.component.less']
})
export class ProjectComponent implements OnInit {

    projectPage: Page<Project> = new Page<Project>();
    ProjectNumVo = new ProjectNumVo();
    condition = new PageCondition('', 10);
    currentSort = new Map();
    screenHeight = document.body.clientHeight;
    jwtUser: JWTUser;

    constructor(private ngbModal: NgbModal,private projectRepository: ProjectRepository, private router: Router, private jwtHelperService: JwtHelperService,) {
        let page = new URLSearchParams(window.location.href.split('?')[1]).get('page');
        if (page) {
            this.condition.current = Number.parseInt(page);
        } else {
            this.setPageCondition(1);
        }
    }

    ngOnInit(): void {
        var freeTrial = document.getElementsByClassName("fourteen-tip");
        let hideHeight = freeTrial && freeTrial.length > 0 ? 80 : 0;
        this.condition.size = Math.trunc((this.screenHeight - 72 - 99 - 98 - 46 - hideHeight) / 75) - 1;
        this.init();
        const accessToken = localStorage.getItem('accessToken');
        this.jwtUser = this.jwtHelperService.decodeToken(accessToken).inf as JWTUser;
    }

    init(): void {
        this.getProjectList();
        // this.getBranchData();
    }

    getNumber(): void {
        this.projectRepository.getProjectNumber().subscribe(result => {
            this.ProjectNumVo.activeNum = result.projectActiveNum;
            this.ProjectNumVo.allNumber = result.projectActiveNum + result.projectArchivedNum;
            this.ProjectNumVo.archivedNum = result.projectArchivedNum;
        })
    }
    getProjectList(): void {
        this.getNumber();
        this.projectRepository.list(this.condition).subscribe(result => {
             
            if (result.records && result.records.length > 0) {
                result.records.forEach(it => {
                    if (it.label) {
                        it.label = it.label.toLowerCase(); 
                        it.color = it.label; 
                    } else {
                        it.color = 'ff9B51e0';   
                    }
                });
            }
            this.projectPage = result;  
         
        });
    }
    
    // getBranchData(): void {
    //     this.branchesRepository.getBranchList('').subscribe(res => {
    //       if (res) {
    //         this.branches = res;
    //       }
    //     });
    //   }

    //   getBranchName(id){
    //     const item = this.branches.filter(it => it.id == id);
    //         if(item?.length){
    //             return item[0].name;
    //         }
    //         return '-----';
    //     }


    getProjectListByPage(current: number): void {
        this.condition.current = current;
        this.setPageCondition(current);
        this.projectRepository.list(this.condition).subscribe(result => {
            if (result.records && result.records.length > 0) {
                result.records.forEach(it => {
                    if (it.label) {
                        it.label = it.label.toLowerCase(); 
                        it.color = it.label; 
                    } else {
                        it.color = 'ff9B51e0';   
                    }
                });
            }
            this.projectPage = result;  
            
        });
    }

    sortProjectList(sortName: string): void {
        this.condition.sortName = sortName;
        this.condition.sortOrder = this.condition.sortOrder == 'ASC' ? 'DESC' : 'ASC';
        this.getProjectList();
        this.currentSort = new Map();
        this.currentSort[sortName] = this.condition.sortOrder;
    }

    statueSearchProjectList(status: string): void {
        this.condition.status = status;
        this.condition.current = 1;
        this.getProjectList();
    }

    searchKeywordByPress($event: KeyboardEvent): void {
        if ($event.key != 'Enter') {
            return;
        }
        this.getProjectList();
    }

    goScaffold(project: Project): void {
        this.router.navigate(['project', project.id, 'scaffold'], { queryParams: { name: project.projectName, page: 1 } });
    }

    openPage(): void {
        this.router.navigate(['import']);
    }

    setPageCondition(current: Number): void {
        let params: URLSearchParams = new URLSearchParams(window.location.href.split('?')[1]);
        if (params.has('page')) {
            params.set('page', `${current}`);
        } else {
            params.append('page', `${current}`);
        }
        window.location.href = window.location.href.split('?')[0] + '?' + params;
    }
    addProject(){
        const ngbModalRef = this.ngbModal.open(AddProjectComponent, {
            backdrop: 'static',
            keyboard: true,
            size: 'lg'
        });
        ngbModalRef.result.then(() => {
            this.getProjectList();
        }).catch(() => {
            this.getProjectList();
        });
    }
    editProject(item:Project){
        const projectDetail = {...item};
         
        const ngbModalRef = this.ngbModal.open(AddProjectComponent, {
            backdrop: 'static',
            keyboard: true,
            size: 'lg'
        });
        ngbModalRef.componentInstance.project = projectDetail;
        ngbModalRef.componentInstance.project.name = projectDetail.projectName;
        ngbModalRef.componentInstance.project.client = projectDetail.clientName;
        ngbModalRef.componentInstance.project.branchName = projectDetail.branchName;
        ngbModalRef.componentInstance.project.branch = projectDetail.branchId;
        ngbModalRef.componentInstance.project.label = projectDetail.label;
        ngbModalRef.componentInstance.isEdit = true;
        ngbModalRef.result.then(() => {
            this.getProjectList();
        }).catch(() => {
            this.getProjectList();
        });
    }
    
    }

