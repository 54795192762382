import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastRepository} from '../../../repository/toast-repository';
import {NgxLoadingSpinnerService} from '@k-adam/ngx-loading-spinner';
import {UserRepository} from '../../../repository/user-repository';

@Component({
    selector: 'app-forgot',
    templateUrl: './download.component.html',
    styleUrls: ['./download.component.less']
})
export class DownloadComponent implements OnInit {
     url : string;
    fromPage: string;
    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private spinnerService: NgxLoadingSpinnerService,
        private userRepository: UserRepository,
        private toastRepository: ToastRepository) {
        this.fromPage = activatedRoute.snapshot.queryParamMap.get('fromPage');
    }

    ngOnInit(): void {
    }

    getStripe(): void{
        this.router.navigate(['signup-stripe'], {queryParams: {fromPage: 'download'}});
    }
    downLoad(type): void{
        if(type == 'ios'){
            this.url = 'https://apps.apple.com/app/id6444101388';
        }else{
            this.url = 'https://play.google.com/store/apps/details?id=com.constructiontag.scafflinq';
        }
        window.open(this.url);
    }
}
