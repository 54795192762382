import { Component, OnInit } from '@angular/core';
import { Page } from '../../observable/model/base';
import { PageCondition } from '../../observable/model/condition';
import {Invoice, InvoiceNumberVo} from '../../observable/model/invoice';
import { InvoiceRepository } from '../../repository/invoice-repository';

@Component({
    selector: 'app-invoices',
    templateUrl: './invoices.component.html',
    styleUrls: ['./invoices.component.less']
})
export class InvoicesComponent implements OnInit {
    invoicePage: Page<Invoice> = new Page<Invoice>();
    condition = new PageCondition('', 20);
    invoiceNumberVo =  new InvoiceNumberVo();
    currnetSort = new Map();
    screenHeight = document.body.clientHeight;
    constructor(private invoiceRepository: InvoiceRepository,) {
    }

    ngOnInit(): void {
        var freeTrial = document.getElementsByClassName("fourteen-tip");
        let hideHeight = freeTrial && freeTrial.length > 0 ? 80 : 0;
        this.condition.size = Math.trunc((this.screenHeight - 72 - 99 - 98 - 46 - hideHeight) / 75) - 1;
        this.condition.sortOrder = 'DESC';
        this.condition.invoiceStatus = '';
        this.getInvoiceList();
    }
    getInvoiceNumber(): void {
        this.invoiceRepository.getInvoiceNumberInfo().subscribe(result => {
            this.invoiceNumberVo = result;
            this.invoiceNumberVo.allNumber = result.invoiceDraftNum + result.invoiceOpenNum + result.invoicePaidNum + result.invoiceUncollectibleNum + result.invoiceVoidNum ;
        });
    }
    getInvoiceList(): void {
        this.getInvoiceNumber();
        this.invoiceRepository.list(this.condition).subscribe(result => {
            this.invoicePage = result;
        });
    }

    searchKeywordByPress($event: KeyboardEvent): void {
        if ($event.key != 'Enter') {
            return;
        }
        this.getInvoiceList();
    }

    getInvoiceListByPage(current: number): void {
        this.condition.current = current;
        this.getInvoiceList();
    }

    sortInvoiceList(sortName: string): void {
        this.condition.sortName = sortName;
        this.condition.sortOrder = this.condition.sortOrder == 'ASC' ? 'DESC' : 'ASC';
        this.getInvoiceList();
        this.currnetSort = new Map();
        this.currnetSort[sortName] = this.condition.sortOrder;
    }

    statueSearchInvoiceList(invoiceStatus: string): void {
        this.condition.invoiceStatus = invoiceStatus;
        this.condition.current = 1;
        this.getInvoiceList();
    }


    handleWord(val){
        if(!val){
            return  '';
        }
        return val.slice(0,1).toUpperCase() + val.slice(1);
    }

}
