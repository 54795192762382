import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'statePipe'
})
export class StatePipe implements PipeTransform {
    transform(value: string): string {
        switch (value) {
            case 'ACTIVATED':
            case 'Active':
                return 'Active';
            case 'Installed':
                return 'Installed';
            case 'Dismantled':
                return 'Dismantled';
            default:
                return value;

        }
    }
}
