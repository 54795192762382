<div class="admin-wrapper">
    <div class="admin">
        <div class="admin-left">
            <div class="logo"></div>
            <div class="bg-box"></div>
        </div>
        <div class="admin-right">
            <div class="text-box" >
                <div class="title">Signup Complete!</div>
                <div class="subtitle" >Your Scafflinq account is now ready.</div>
                <div class="grid-container">
                <div class="icon">
                    <div class="img-box">
                        <img src="assets/img/signup-complete/scafflinq-box.png" alt="" srcset="">
                    </div>
                </div>
                <div class="content">
                    <p class="title">We’ll ship your tags to you</p>
                    <p>A Scafflinq rep will contact you to confirm some details before we ship your tags.</p>
                </div>
                <div class="icon">
                    <div class="img-box">
                        <img src="assets/img/signup-complete/mobile-app.png" alt="" srcset="">
                    </div>
                </div>
                <div class="content">
                    <p class="title">Download the Mobile App</p>
                    <p>Get the Scafflinq Mobile App on Google Play or the App Store.</p>
                </div>

                    <div class="icon">
                        <div class="img-box">
                            <img src="assets/img/signup-complete/invite-team.png" alt="" srcset="">
                        </div>
                    </div>
                    <div class="content">
                        <p class="title">Invite your team</p>
                        <p>Add Team Members to your Scafflinq Account.</p>
                    </div>

                    <div class="icon">
                        <div class="img-box">
                            <img src="assets/img/signup-complete/help-center.png" alt="" srcset="">
                        </div>
                    </div>
                    <div class="content">
                        <p class="title">If you get stuck</p>
                        <p>Our <a href="https://scafflinq.com/help-centre" target="_blank">Help Center</a> is equipped with how-to guides to assist you whenever you need help.</p>
                    </div>

                    <div class="icon">
                        <div class="img-box">
                            <img src="assets/img/signup-complete/email.png" alt="" srcset="">
                        </div>
                    </div>
                    <div class="content">
                        <p class="title">If you get really stuck</p>
                        <p>You can reach us anytime on <a href="mailto:support@scafflinq.com">support@scafflinq.com</a> </p>
                    </div>
            </div>
                <div class="form-button">
                    <button class="btn btn-o"  (click)="next()" >Go To Account</button>
                </div>
            </div>
        </div>

    </div>
</div>
