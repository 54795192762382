import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
    selector: 'app-signup-verified',
    templateUrl: './signup-verified.component.html',
    styleUrls: ['./signup-verified.component.less']
})
export class SignupVerifiedComponent implements OnInit {

    constructor(private router: Router) {
    }

    ngOnInit(): void {
        const accessToken = localStorage.getItem('accessToken');
    }


    login(): void {
        this.router.navigate(['signup-stripe'], {queryParams: {fromPage: 'signup-verified'}});
    }

    getFreeOrder(): void {
        this.router.navigate(['/signup-free-tags']);
    }

}
