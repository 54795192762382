<main [ngClass]="closeSubMenu ? 'menu_open':'' ">
    <app-sidebar></app-sidebar>
    <!-- <div class="fourteen-tip" *ngIf="billFlag && role != 0 && day && day > 0 && useFlag">
        <span (click)="gotoBilling()">{{day}} days left on your free trial</span>
    </div>
    <div class="fourteen-tip"  *ngIf=" role != 0 && !useFlag">
        <span style="text-decoration: none">You need to upgrade to Scafflinq Professional</span>
    </div> -->
    <div class="main" >
        <router-outlet></router-outlet>
    </div>

    <div class="menu_btn transition" [ngClass]="closeSubMenu ? 'menu_closed':'' "  (click)="closeMenu()">
        <span class="line"></span>
        <span class="line"></span>
        <span class="line"></span>
    </div>
</main>
