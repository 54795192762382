import {AfterContentInit, Component} from '@angular/core';
import {LocalStorageObServable} from './observable/local-storage-observable';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {ToastRepository} from './repository/toast-repository';
import {UserRepository} from './repository/user-repository';
import {Subscription} from 'rxjs';
import {MessagingService} from './service/messaging.service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {JwtHelperService} from '@auth0/angular-jwt';
import {BillingMissingComponent} from './pages/modal/billing-missing/billing-missing.component';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less'],
})
export class AppComponent {
    private routerEventsListener: Subscription;

    uncludes: string[] = ['/signup-verified', '/signup-free-tags', '/download-app', '/signup-stripe'];
    message;
    title: any;

    constructor(
        public localStorageService: LocalStorageObServable,
        public router: Router,
        public activatedRoute: ActivatedRoute,
        private ngbActiveModal: NgbActiveModal,
        private modalAdService: NgbModal,
        public userRepository: UserRepository,
        public toastRepository: ToastRepository,
        private messagingService: MessagingService,
        private modalService: BsModalService,
        private jwtHelperService: JwtHelperService
    ) {
        this.routerEventsListener = this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                toastRepository.showLoading(true);
            }
            if (event instanceof NavigationEnd) {
                const accessToken = localStorage.getItem('accessToken');
                toastRepository.showLoading();
                if(accessToken != null) {
                    this.userRepository.getStripeStatus().subscribe(event => {
                        localStorage.setItem('stripeInfo',JSON.stringify(event));
                    })
                }
            }
        })
        this.modalService.config.ignoreBackdropClick = true; // 设置全局的禁止backdrop点击关闭 modal
    }


    ngOnDestroy(): void {
        this.routerEventsListener.unsubscribe();
    }
}



