<div class="setting-right">
    <div class="top-bar">
      <h1>Settings</h1>
  
      <div class="right-bar">
        <div class="add" (click)="addBranch()">
          <span class="icon icon-add-new icon-24"></span>
          <p>Add Branch</p>
        </div>
      </div>
    </div>
  
    <div class="content company">
      <div class="sub-head-box">
        <div class="filter-box">
          <div class="item" routerLinkActive="active">
            <a [routerLink]="['/setting/profile']">Your Profile</a>
          </div>
          <div class="item" routerLinkActive="active">
            <a [routerLink]="['/setting/branches']">Branches</a>
          </div>
          <div class="item" routerLinkActive="active">
            <a [routerLink]="['/setting/handover']">Handover Certificates</a>
          </div>
          <div class="item" routerLinkActive="active">
            <a [routerLink]="['/setting/subscriptions']">Your Subscription</a>
          </div>
          <div class="item" routerLinkActive="active">
            <a [routerLink]="['/setting/billing']">Billing Information</a>
          </div>
        </div>
      </div>
  
      <div class="sub-head-box">
        <div class="filter-box">
          <div class="item" [ngClass]="{'active':condition.status == null|| condition.status==''}" (click)="statueSearchBranchList('')">All({{ this.branchNumberInfo.all }})</div>
          <div class="item"  [ngClass]="{'active': condition.status  == 'active'}"  (click)="statueSearchBranchList('active')">Active ({{ this.branchNumberInfo.active}})</div>
          <div class="item"  [ngClass]="{'active': condition.status  == 'inactive'}" (click)="statueSearchBranchList('inactive')">Inactive ({{this.branchNumberInfo.inactive}})</div>
        </div>
      </div>
      <div class="table">
        <table>
          <thead>
            <tr>
              <th style="width: 45%">
                <p>Branch Name</p>
              </th>
              <th style="width: 45%">
                <p>Status</p>
              </th>
              <th style="width: 10%"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of branchesPage.records">
              <td class="id">
                <p style="overflow: inherit;white-space: nowrap">{{ item.name }}</p>
              </td>
              <td>
                <button class="btn btn-statue" [ngClass]="{'btn-active': item.status === 'active', 'btn-inactive': item.status === 'inactive'}">
                  {{ item.status }}
                </button>
              </td>
              <td class="end-right button-item">
                <a (click)="editBranch(item)"><i class="icon icon-16 icon-edit-new"></i></a>
              </td>
            </tr>
          </tbody>
        </table>
        <ngb-pagination class="justify-content-center" *ngIf="branchesPage.pages>1" [(page)]="branchesPage.current"
          [boundaryLinks]="false" [pageSize]="branchesPage.size" [maxSize]="5"
          (pageChange)="getBranchListByPage(branchesPage.current)" [collectionSize]="branchesPage.total">
      </ngb-pagination>
      </div>
    </div>
  </div>
  