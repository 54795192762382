<div class="create-order">
    <div class="modal-header">
        <h4 class="modal-title">Create an order</h4>
    </div>
    <div class="modal-body">
        <div class="form modal-form">
            <div class="form-select form-select-group" *ngIf="userRole == 0">
                <label class="control-label">Order type</label>
                <div class="select special-select-all ">
                    <ng-select [items]="boxTypeList" placeholder="Select box type" [(ngModel)]="selectBox" (change)="getBoxValue(selectBox)"
                               [searchable]="false" ></ng-select>
                    <i class="icon icon-18 icon-down-arrow"></i>
                </div>
            </div>
            
            <div class="form-group" *ngIf="orderInfo.orderType && orderInfo.orderType == 1 || userRole == 1">
                <label class="control-label">Number of boxes (25 per box)</label>
                <div class="tags">
                    <input type="text" maxlength="10" min="0"  [(ngModel)]="orderInfo.boxNumber" placeholder="0" (blur)="blurHandler()"  (input)="changePrice($event)" (keypress)="keypress($event)"
                           class="form-control" >
                    <span *ngIf="orderInfo.boxNumber">{{orderInfo.boxNumber * 25}} Tags </span>
                </div>
                <!-- <div class="form-group">
                    <label class="control-label">Price ($25 per box)</label>
                    <input  min="0" placeholder="$00.00" class="form-control" [readOnly]="true"
                            [ngModel]="orderInfo.boxNumber ?  '$' + orderInfo.boxNumber * 25 : ''" />
                </div> -->
            </div>
            <div class="form-group" *ngIf="orderInfo.orderType && orderInfo.orderType == 3">
                <div class="form-group">
                    <label class="control-label">Number of boxes (25 per box)</label>
                    <div class="tags">
                        <input type="text" maxlength="10" min="0"  [(ngModel)]="orderInfo.boxNumber" placeholder="0" (blur)="blurHandler()"  (input)="changePrice($event)" (keypress)="keypress($event)"
                               class="form-control" >
                        <span *ngIf="orderInfo.boxNumber">{{orderInfo.boxNumber * 25}} Tags </span>
                    </div>
                </div>
            </div>
            <div class="form-select form-select-group">
                <label class="control-label">Company Name</label>
                <div class="select special-select-all ">
                    <ng-select [items]="companyArray" placeholder="Select company" [(ngModel)]="selectCompany"  [disabled]="userRole == 0 ? false:true"
                        [searchable]="false" (change)="getCompanyInfo(selectCompany)"></ng-select>
                    <i class="icon icon-18 icon-down-arrow"></i>
                </div>
            </div>
            <div class="form-group">
                <label class="control-label">Recipient Full name</label>
                <input type="text" class="form-control" placeholder="Enter full name"
                    [(ngModel)]="orderInfo.recipientFullName" 
                    (ngModelChange)="orderInfo.recipientFullName = capitalize(orderInfo.recipientFullName)"
                    />
            </div>
            <div class="form-group" *ngIf="userRole == 0">
                <label class="control-label">Email</label>
                <input type="text" class="form-control" placeholder="EnterEmail" [(ngModel)]="orderInfo.email" />
            </div>
            <div class="form-group" *ngIf="userRole == 0"> 
                <label class="control-label">Ph/Mob</label>
                <input type="text" class="form-control" placeholder="Enter Ph/Mob" [(ngModel)]="orderInfo.mobile" />
            </div>
            <div class="form-group">
                <label class="control-label">&#83;treet number and name</label>
                <input type="text" class="form-control" placeholder="Enter number and name" [(ngModel)]="orderInfo.streetNumber" 
                (ngModelChange)="orderInfo.streetNumber = capitalize(orderInfo.streetNumber)"
                />
            </div>
            <div class="form-two">
                <div class="form-group">
                    <label class="control-label">Suburb</label>
                    <input type="text" class="form-control" placeholder="Enter suburb" [(ngModel)]="orderInfo.suburb" 
                    (ngModelChange)="orderInfo.suburb = capitalize(orderInfo.suburb)"
                    />
                </div>
                <div class="form-group">
                    <label class="control-label">State</label>
<!--                    <input type="text" class="form-control" placeholder="Select state" [(ngModel)]="orderInfo.state" />-->
                    <div class="select special-select-all">
                        <ng-select [items]="stateArray" placeholder="Select state" [(ngModel)]="selectState"
                                   [searchable]="false"></ng-select>
                        <i class="icon icon-18 icon-down-arrow"></i>
                    </div>
                </div>
            </div>
            <div class="form-two">
                <div class="form-group">
                    <label class="control-label">Postcode</label>
                    <input type="text" class="form-control" placeholder="Enter postcode" [(ngModel)]="orderInfo.postCode" 
                    (ngModelChange)="orderInfo.postCode = capitalizeUpperCase(orderInfo.postCode)"
                    />
                </div>
                <div class="form-group">
                    <label class="control-label">Country</label>
<!--                    <input type="text" class="form-control" placeholder="Select country" [(ngModel)]="orderInfo.country" />-->
                    <div class="select special-select-all">
                    <ng-select [items]="countryArray" placeholder="Select country" [(ngModel)]="selectCountry"
                               [searchable]="false"></ng-select>
                        <i class="icon icon-18 icon-down-arrow"></i>
                    </div>
                </div>
            </div>
            <div class="form-button">
                <div class="close">
                    <button class="color-o" (click)="cancelCreateOrder()">Cancel</button>
                </div>
                <div class="submit">
                    <button class="btn btn-green bg-o" [disabled]="loading" (click)="saveCreateOrder()">Create order</button>
                </div>
            </div>
        </div>
    </div>

</div>
