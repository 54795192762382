import { Component, OnInit } from '@angular/core';
import { FileSystemDirectoryEntry, FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { HandoverCertificates } from 'src/app/observable/model/handover-certificates';
import { FileRepository } from 'src/app/repository/file-repository';
import { HandoverCertificatesRepository } from 'src/app/repository/handover-certificates-repository';
import { ToastRepository } from 'src/app/repository/toast-repository';

@Component({
  selector: 'app-handover',
  templateUrl: './handover.component.html',
  styleUrls: ['./handover.component.less']
})
export class HandoverComponent implements OnInit {
  public files: NgxFileDropEntry[] = [];
 
  fileName: string;
  logoUrl: string = '';
  succeed: boolean = false;
  state: string = 'default';
  message: string;
  accept = 'image/*';
  isDisabled = false;
  handoverCertificates = new HandoverCertificates();

  

  constructor(
    private toastRepository: ToastRepository,
    private handoverCertificatesRepository: HandoverCertificatesRepository,
    private fileRepository: FileRepository,
  ) { }

  ngOnInit(): void {
    this.isDisabled = true;
    this.handoverCertificates.certificateNumber = '0000000001';
    this.handoverCertificates.heroText = 'This scaffold is erected in accordance with AS/NZS 1576 PARTS 1-6 AND AS/NZS4576';
    this.handoverCertificates.acceptance = 'This certificate is only valid so long as the Builder and tradesman comply with the below conditions: The scaffold structure cannot be altered by any person other than a representative of our company. Scaffold must remain in the manner it was installed by us. The scaffold can’t be curtained with any material including but not limited to promotional signage, due to wind loading. I understand the duty category of the work platforms. I understand that the scaffold is now in my commission and I will communicate Duty Category Limits to other trades. Any breach in these conditions will result in decommissioning of the scaffold.';
    this.init();
  }

  
  init(): void{
     this.getData();
  }

 

  handleNumber(): void {
    let num = this.handoverCertificates.certificateNumber;
    if (num !== null && num !== undefined) {
      this.handoverCertificates.certificateNumber = num.toString().padStart(10, '0');
    }
  }
 
 
  isNumber(event: KeyboardEvent) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
    }
  }

 
  public limitInput(event: any) {
    if (event.target.value.length > 10) {
      event.target.value = event.target.value.slice(0, 10);
    }
  }         

    public dropped(files: NgxFileDropEntry[]) {
      this.fileName = '';
      this.files = files;
      console.log(files, 'files');
      try {
        if (files.length > 1) {
          this.toastRepository.showDanger('Single file upload');
          throw new Error('Single file upload');
        }
        for (const droppedFile of files) {
          if (droppedFile.fileEntry.isFile) {
            const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
  
            fileEntry.file((file: File) => {
              const maxSize = 5 * 1024 * 1024;
              const validImageTypes = [ 'image/jpeg', 'image/png'];
              if (!validImageTypes.includes(file.type)) {
                this.toastRepository.showDanger('Invalid file type. Please upload an image file.');
                throw new Error('Invalid file type');
              }
              if(file.size > maxSize){
                this.toastRepository.showDanger('Max. Size 5MB. Dimensions:600px x 600px. File type: PNG or JPG.');
                throw new Error('Picture oversize');
              }
              
              const reader = new FileReader();
              reader.onload = (e: any) => {
                const img = new Image();
                img.onload = () => {
              
                  
                  const maxWidth = 600;
                  const maxHeight = 600;
                  if (img.width > maxWidth || img.height > maxHeight) {
                    this.toastRepository.showDanger(`Upload failed. Please upload a square photo with a maximum resolution of  ${maxWidth} x ${maxHeight} pixels`);
                    throw new Error('Invalid image dimensions');
                  } else if( img.width != img.height ){
                    this.toastRepository.showDanger(`Upload failed. Please upload a square photo with a maximum resolution of  ${maxWidth} x ${maxHeight} pixels`);
                  } 
                  
                  else  {
                    this.fileRepository.upload('Certificates', file).subscribe(res => {
                      this.succeed = true;
                      this.fileName = droppedFile.relativePath;
                      this.logoUrl = res.visitUrl;
                      this.handoverCertificates.logo = res;
                      this.handoverCertificates.logo.name = droppedFile.relativePath;
                    });
                  }
                };
                img.src = e.target.result;
              };
              reader.readAsDataURL(file);
            });
          } else {
            const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
            console.log(droppedFile.relativePath, fileEntry);
          }
        }
      } catch (err) {
        this.files = null; // clear files
      }
    }
    
  public fileLeave(event): void {
    console.log(event);
  }
  public fileOver(event): void {
    console.log(event);
  }

  removeLogo(): void{
    this.fileName = '';
    this.logoUrl = '';
    this.handoverCertificates.logo = {
      name: '',
      path: '',
      ext: '',
      size: 0,
      visitUrl: ''
    };
 
    this.succeed = false;
 
  }
  

 getData(): void{
  this.handoverCertificatesRepository.getHandoverCertificate().subscribe(res => {
      if(res.id){
       this.handoverCertificates = res;
       this.logoUrl  = res.logo.visitUrl;
       this.fileName = res.logo.name;
       if(res.logo.path){
        this.succeed = true;
       }
    
      }   
  })
 }

  save(): void{
    if(!this.handoverCertificates.acceptance || !this.handoverCertificates.certificateNumber || !this.handoverCertificates.heroText){
      this.toastRepository.showDanger('Please enter the required information');
      return;
    }
    this.handoverCertificatesRepository.saveHandoverCertificate(this.handoverCertificates).subscribe(res =>{
      this.isDisabled = true;
      if(res){
        this.toastRepository.showSuccess('Handover saved successfully.');
      }else{
        this.toastRepository.showSuccess('Handover Certificates updated successfully.');
      }
    });
  }
 

}
