import { Component, OnInit } from '@angular/core';
import { Branch } from 'src/app/observable/model/branch';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BranchesRepository } from 'src/app/repository/branches-repository';
import { ToastRepository } from 'src/app/repository/toast-repository';
import { BranchNoticeComponent } from '../branch-notice/branch-notice.component';
@Component({
  selector: 'app-add-branch',
  templateUrl: './add-branch.component.html',
  styleUrls: ['./add-branch.component.less']
})
export class AddBranchComponent implements OnInit {
 
   branch:Branch;
   isInactive: boolean;
   isEdit = false;

  constructor(private ngbActiveModal: NgbActiveModal,
    private branchesRepository: BranchesRepository,
    public toastRepository: ToastRepository,
    private ngbModal: NgbModal,
    ) {
      this.branch = new Branch();
      this.isInactive = false;
}


  ngOnInit(): void {
    if (!this.branch.status) {
      this.branch.status = 'active';
    }
    this.isInactive = this.branch.status === 'inactive';
  }

  updateStatus(): void {
    this.branch.status = this.isInactive ? 'inactive' : 'active';
  }

  cancel(){
    this.ngbActiveModal.close();
  }

  addBranch() {
    console.log(this.branch);
    if(!this.branch.name){
      this.toastRepository.showDanger('Please enter the required information');
      return;
    }
    this.branchesRepository.saveBranch(this.branch).subscribe(
        res => {
            if (res) {
                this.handleSuccess(res);
            }   else {
              this.openBranchNotice();
            }
            this.cancel();
        },
       
    );
}

handleSuccess(res) {
    if (this.isEdit) {
        this.toastRepository.showSuccess('Edited branch successfully.');
    } else {
        this.toastRepository.showSuccess('Added branch successfully.');
    }
}

handleFailure(res) {
  this.toastRepository.showDanger('Failed to add branch.');
}

openBranchNotice():void{
  const ngbModalRef = this.ngbModal.open(BranchNoticeComponent, {
    backdrop: 'static',
    keyboard: true,
    size: 'sm'
  });
}
}
