<div class="setting-right">
    <div class="top-bar">
        <h1>Settings</h1>
    </div>
    <!-- Your profile -->
    <div class="content company">
        <div class="sub-head-box">
            <div class="filter-box">
                <div class="item" routerLinkActive="active">
                    <a [routerLink]="['/setting/profile']">Your Profile</a>
                </div>
                <div class="item" routerLinkActive="active">
                    <a [routerLink]="['/setting/branches']">Branches</a>
                </div>
                <div class="item" routerLinkActive="active">
                    <a [routerLink]="['/setting/handover']">Handover Certificates</a>
                </div>
                
                <div class="item" routerLinkActive="active">
                    <a [routerLink]="['/setting/subscriptions']">Your Subscription</a>
                </div>
                <div class="item" routerLinkActive="active">
                    <a [routerLink]="['/setting/billing']">Billing Information</a>
                </div>
            </div>
        </div>

        <div class="title-bar">
            <div class="title-left">
                <h3>Update company info</h3>
            </div>
        </div>
        <div class="info-box">
            <div class="form-box">
                <form action="">
                    <div class="form-two">
                        <div class="form-control-group">
                            <label>Company name</label>
                            <div class="input">
                                <input type="text" [(ngModel)]="profile.companyName" name="companyName" placeholder="Enter company name"
                                (ngModelChange)="profile.companyName = capitalize(profile.companyName)"
                                >
                            </div>
                        </div>
                        <div class="form-control-group">
                            <label>Mobile number </label>
                            <div class="input ">
                                <input type="text" [(ngModel)]="profile.companyPhone" name="companyPhone" placeholder="Enter company phone"
                            
                                >
                            </div>
                        </div>
                    </div>
                    <div class="form-two">
                        <div class="form-control-group">
                            <label>Region</label>
                            <div class="input email-input">
                                <input type="text" disabled="disabled" [(ngModel)]="profile.region" name="region" placeholder="Enter region">
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="title-bar">
                        <div class="title-left">
                            <h3>Update personal profile</h3>
                        </div>
                    </div>
                    <div class="form-two">
                        <div class="form-control-group">
                            <label>First name</label>
                            <div class="input">
                                <input type="text" [(ngModel)]="profile.firstName" name="firstName" placeholder="Enter first name"
                                (ngModelChange)="profile.firstName = capitalizeFirst(profile.firstName)"
                                >
                            </div>
                        </div>
                        <div class="form-control-group">
                            <label>Last name</label>
                            <div class="input">
                                <input type="text" [(ngModel)]="profile.lastName" name="lastName" placeholder="Enter last name"
                                (ngModelChange)="profile.lastName = capitalizeFirst(profile.lastName)"
                                >
                            </div>
                        </div>
                    </div>
                    <div class="form-two">
                        <div class="form-control-group">
                            <label>&#917;mail Address </label>
                            <div class="input email-input">
                                <input type="text" disabled="disabled" [(ngModel)]="profile.email" name="email" placeholder="Enter email">
                                <i class="icon icon-18 icon-edit" (click)="editEmail()"></i>
                            </div>
                        </div>
                        <div class="form-control-group">
                            <label>Phone</label>
                            <div class="input">
                                <input type="text" [(ngModel)]="profile.phone" name="phone" placeholder="Enter phone">
                            </div>
                        </div>
                    </div>
                    <div class="form-two">
                        <div class="form-control-group">
                            <label>Password</label>
                            <div class="input">
                                <input type="text" [(ngModel)]="profile.password" name="password" placeholder="Enter password">
                            </div>
                        </div>
                        <div class="form-control-group">
                            <label>Role</label>
                            <div class="input select special-select-all">
                                <ng-select [(ngModel)]="profile.role" name="role" [searchable]="false">
                                    <ng-option [value]='1'>Admin</ng-option>
                                    <ng-option [value]='2'>Inspector</ng-option>
                                </ng-select>
                                <i class="icon icon-18 icon-down-arrow"></i>
                            </div>
                        </div>
                    </div>

                    <div class="form-two">
                        <div class="form-control-group">
                            <label>Timezone </label>
                            <div class="input select special-select-all">
                                <ng-select [(ngModel)]="profile.timezone" name="timezone" [searchable]="false" placeholder="Select time">
                                    <ng-option value="Australia/Adelaide">GMT+10:30 AUSTRALIA/ADELAIDE</ng-option>
                                    <ng-option value="Australia/NSW">GMT+9.5 South Australia</ng-option>
                                    <ng-option value="Australia/Queensland">GMT+10 Queensland Queensland</ng-option>
                                    <ng-option value="Australia/West">GMT+8 Western Australia</ng-option>
                                    <ng-option value="Australia/North">GMT+9.5 Northern Territory</ng-option>
                                    <ng-option value="Australia/South">GMT+10 New South wales</ng-option>
                                    <ng-option value="Australia/ACT">GMT+10 Australian Capital Territory</ng-option>
                                    <ng-option value="Australia/Victoria">GMT+10 Victoria</ng-option>
                                    <ng-option value="Australia/Tasmania">GMT+10 Tasmania</ng-option>
                                </ng-select>
                                <i class="icon icon-18 icon-down-arrow"></i>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="footer-btn">
                    <button class="btn btn-green2 bg-o update mr-16" (click)="save()">Update Profile</button>
                </div>
            </div>
        </div>
    </div>
</div>